import { queryCache } from "react-query"

import { revelApi } from "@services/apis"
import handleError from "@services/errors"
import snackbar from "@services/notifications/snackbar"
import {
  mailingListSignup,
  profileCompletedSnack,
} from "@services/notifications/snacks"
import { buildAuthHeaders, buildVersionHeader } from "@services/util"

import { queryKeys } from "@constants"

export const startImpersonation = ({ userId, accessToken }) => {
  const config = {
    method: "POST",
    url: "admin/impersonate",
    data: { userId },
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config)
}

export const stopImpersonation = ({ accessToken }) => {
  const config = {
    method: "POST",
    url: "admin/stop-impersonate",
    data: {},
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config)
}

export const joinUserToMailingList = (data) => {
  const config = {
    method: "POST",
    url: "user/mailing-list",
    data,
  }

  return revelApi(config).then(() => {
    snackbar.enqueue(mailingListSignup)
  })
}

export const updateProfile = ({ profile, privacy = {}, accessToken }) => {
  const config = {
    method: "PUT",
    url: `user`,
    data: { profile, privacy },
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config)
}

export const setPicture = ({ picture, accessToken }) => {
  const config = {
    method: "PUT",
    url: `user`,
    data: { profile: { picture } },
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config).then(() => {
    queryCache.refetchQueries(queryKeys.USER_PROFILE)
    queryCache.refetchQueries(queryKeys.USER_PROFILE_DETAIL)
    queryCache.refetchQueries(queryKeys.USER_PROFILE_COMPLETE)
  })
}

export const completeUser = ({ user, token, accessToken }) => {
  const config = {
    method: "POST",
    url: `user/complete-user`,
    headers: {
      ...buildAuthHeaders(accessToken),
      ...buildVersionHeader(2),
    },
    data: { user, token },
  }

  return revelApi(config).then(() => {
    queryCache.refetchQueries(queryKeys.USER_PROFILE)
    queryCache.refetchQueries(queryKeys.USER_PROFILE_DETAIL)
    queryCache.refetchQueries(queryKeys.USER_PROFILE_COMPLETE)
    snackbar.enqueue(profileCompletedSnack)
  })
}

export const favoriteEvent = ({ eventId, accessToken }) => {
  const config = {
    method: "PUT",
    url: `events/${eventId}/favorite`,
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config)
    .then(() => {
      // Any queries for event cards + the detail page
      queryCache.refetchQueries(queryKeys.EVENT_DETAIL)
      queryCache.refetchQueries(queryKeys.EVENTS)
      queryCache.refetchQueries(queryKeys.EVENTS_BY_INTEREST)
    })
    .catch(handleError)
}

export const favoriteUser = ({ favoritedId, accessToken }) => {
  const config = {
    method: "PUT",
    url: `user/${favoritedId}/favorite`,
    headers: buildAuthHeaders(accessToken),
  }

  return revelApi(config)
    .then(() => {
      // Query on member directory
      queryCache.refetchQueries(queryKeys.FAVORITE_USERS)
    })
    .catch(handleError)
}
