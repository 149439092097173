import React, {
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { StatusBar } from "@capacitor/status-bar"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "react-router-dom"

import useRevelSession from "@hooks/useRevelSession"

import { backgroundColor } from "@components/Header/Header"

import { isAndroid, isNativeApp } from "@services/mobileHelpers"

import {
  DASHBOARD,
  DIRECTORY,
  EVENTS_SEARCH_QUERY,
  GROUPS,
  INBOX,
  JOIN,
  MOBILE_DASHBOARD,
  GROUPS_SEARCH_QUERY,
  LOGIN,
  LOGOUT,
  NOTIFICATIONS,
} from "@constants/routeConstants"
// eslint-disable-next-line no-restricted-imports -- required by LayoutProvider to create hooks
import {
  LAPTOP_BP_DESKTOP,
  TABLET_BP_LAPTOP,
  LARGE_MOBILE_BP_TABLET,
  DESKTOP_BP_WIDESCREEN,
  START_BP_MOBILE,
  SMALL_MOBILE_BP_MOBILE,
  MOBILE_BP_LARGE_MOBILE,
} from "@constants/screenSizes"

import LayoutContext, { getScreenSize } from "./LayoutContext"

const hasStickyTop = (pathname: string) =>
  pathname.includes(EVENTS_SEARCH_QUERY) ||
  pathname.includes(GROUPS_SEARCH_QUERY)
const hideFooter = (pathname: string) => {
  const isInfinitePage = [
    DASHBOARD,
    DIRECTORY,
    GROUPS,
    INBOX,
    JOIN,
    LOGIN,
    LOGOUT,
    NOTIFICATIONS,
  ].some((path) => pathname.startsWith(path))
  const isMobileDashboard = pathname === MOBILE_DASHBOARD

  return isInfinitePage || hasStickyTop(pathname) || isMobileDashboard
}

const LayoutProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { sessionReady } = useRevelSession()
  const { pathname } = useLocation()

  const headerRef = useRef(null)
  const footerRef = useRef(null)
  const navBarRef = useRef(null)

  // NOTE: If we move to server side rendering with rehydration, we should modify this
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [screenSize, setScreenSize] = useState(getScreenSize())

  const widescreenUp = useMediaQuery({ minWidth: DESKTOP_BP_WIDESCREEN + 1 })
  const desktopDown = useMediaQuery({ maxWidth: DESKTOP_BP_WIDESCREEN })
  const desktopUp = useMediaQuery({ minWidth: LAPTOP_BP_DESKTOP + 1 })
  const laptopDown = useMediaQuery({ maxWidth: LAPTOP_BP_DESKTOP })
  const laptopUp = useMediaQuery({ minWidth: TABLET_BP_LAPTOP + 1 })
  const tabletDown = useMediaQuery({ maxWidth: TABLET_BP_LAPTOP })
  const tabletUp = useMediaQuery({ minWidth: LARGE_MOBILE_BP_TABLET + 1 })
  const largeMobileDown = useMediaQuery({ maxWidth: LARGE_MOBILE_BP_TABLET })
  const largeMobileUp = useMediaQuery({ minWidth: MOBILE_BP_LARGE_MOBILE + 1 })
  const mobileDown = useMediaQuery({ maxWidth: MOBILE_BP_LARGE_MOBILE })
  const mobileUp = useMediaQuery({ minWidth: SMALL_MOBILE_BP_MOBILE + 1 })
  const smallMobileDown = useMediaQuery({ maxWidth: SMALL_MOBILE_BP_MOBILE })
  const smallMobileUp = useMediaQuery({ minWidth: START_BP_MOBILE + 1 })

  const footerHidden = useMemo(() => hideFooter(pathname), [pathname])

  useLayoutEffect(() => {
    if (isNativeApp()) {
      Promise.all([
        isNativeApp({ pluginName: "StatusBar" }) &&
          isAndroid() &&
          StatusBar.setBackgroundColor({ color: backgroundColor }),
      ])
    }
  }, [])

  useLayoutEffect(() => {
    const newScreensize = getScreenSize()
    if (
      newScreensize.width !== screenSize.width ||
      newScreensize.height !== screenSize.height
    ) {
      setScreenSize(newScreensize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [
    setScreenSize,
    headerRef.current,
    footerRef.current,
    navBarRef.current,
    footerHidden,
    sessionReady,
    pathname,
    widescreenUp,
    desktopDown,
    desktopUp,
    laptopDown,
    laptopUp,
    tabletDown,
    tabletUp,
    largeMobileDown,
    largeMobileUp,
    mobileDown,
    mobileUp,
    smallMobileDown,
    smallMobileUp,
  ])

  const values = useMemo(() => {
    return {
      footerRef,
      headerRef,
      footerHidden,
      navBarRef,
      widescreenUp,
      desktopDown,
      desktopUp,
      laptopDown,
      laptopUp,
      tabletDown,
      tabletUp,
      largeMobileDown,
      largeMobileUp,
      mobileDown,
      mobileUp,
      smallMobileDown,
      smallMobileUp,
      screenSize,
    }
  }, [
    footerRef,
    headerRef,
    footerHidden,
    navBarRef,
    widescreenUp,
    desktopDown,
    desktopUp,
    laptopDown,
    laptopUp,
    tabletDown,
    tabletUp,
    largeMobileDown,
    largeMobileUp,
    mobileDown,
    mobileUp,
    smallMobileDown,
    smallMobileUp,
    screenSize,
  ])

  return (
    <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>
  )
}

export default React.memo(LayoutProvider)
