import React, { useEffect } from "react"

import useAuthorizedMutation from "@hooks/useAuthorizedMutation"

import { stopImpersonation } from "@services/mutators/user"

import MutationButton from "../MutationButton"

const StopImpersonate = ({ className, children }) => {
  const [stopImpersonateMutation, { status }] =
    useAuthorizedMutation(stopImpersonation)

  useEffect(() => {
    if (status === "success") {
      window.location.reload()
    }
  }, [status])

  return (
    <MutationButton
      status={status}
      className={className}
      handleClick={stopImpersonateMutation}
    >
      {children || "Stop Impersonation"}
    </MutationButton>
  )
}

export default StopImpersonate
