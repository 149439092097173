import React from "react"

import useRevelSession from "@hooks/useRevelSession"

import { withErrorBoundary } from "@components/ErrorBoundary"
import { buildDesktopLinks } from "@components/Header/data"
import { AnchorLink } from "@components/Link"
import LinkToLogin from "@components/LinkToLogin"

import { JOIN, LOGIN } from "@constants/routeConstants"
import { JOIN_REVEL } from "@constants/tracking"

const NavLinks = () => {
  const { isLoggedIn, sessionReady } = useRevelSession()
  const loggedIn = sessionReady && isLoggedIn
  const linkType = loggedIn ? "member" : "guest"
  const links = buildDesktopLinks()[linkType]
  return (
    <div className="Header__LinkContainer_Nav">
      {sessionReady &&
        links.map((dest) => {
          const { label, url, id, isNew } = dest
          let className = "Header__Link_Nav"

          if (window.location.href.includes(url)) {
            className = "Header__Link_Nav--active"
          }

          return (
            <span key={url} className="Header__UltimateWrapper">
              {url === JOIN && <span className="Header__Divider" />}
              <span className="Header__LinkWrapper" key={url}>
                {isNew && (
                  <span className="Header__NewTag">
                    New{" "}
                    <span role="img" aria-label="sparkles">
                      ✨
                    </span>
                  </span>
                )}

                {url === LOGIN || url === JOIN ? (
                  // Handle login & join so redirect after works as expected
                  <LinkToLogin
                    isJoining={url === JOIN}
                    className={className}
                    analytics={{
                      event: `Clicked ${label}`,
                      data:
                        url === JOIN
                          ? {
                              category: JOIN_REVEL.category,
                              uiLocation: "Header",
                            }
                          : null,
                    }}
                  >
                    {label}
                  </LinkToLogin>
                ) : (
                  <AnchorLink
                    to={url}
                    className={className}
                    id={id}
                    analytics={{
                      event: `Clicked ${label}`,
                    }}
                  >
                    {label}
                  </AnchorLink>
                )}
              </span>
            </span>
          )
        })}
    </div>
  )
}

export default withErrorBoundary(NavLinks)
