import { useContext } from "react"

import RevelSessionContext from "@providers/RevelSessionProvider/RevelSessionContext"

// Consume the userId context and provide a nice API for components
// Note: when impersonating, returns the user ID of the impersonated user, _not_ the real user.
// use "useRealUserId" to obtain the real (logged-in) user id.
//
// Consume the access token context, and provide a nice API to components
const useRevelSession = () => {
  const {
    userId,
    userGqlId,
    realUserId,
    isLoading,
    isAuthenticated,
    hasProfile,
    getAccessToken,
  } = useContext(RevelSessionContext)
  const sessionReady = !isLoading
  const isLoggedIn = sessionReady && isAuthenticated
  const needsLogin = !isLoggedIn
  const isImpersonating = userId && realUserId && userId !== realUserId

  return {
    // `auth0` / DB id for user, will be impersonated user's id if impersonating
    userId,
    // GraphQL id for user, will be impersonated user's id if impersonating
    userGqlId,
    // Real `auth0` / DB id for user, will always be authorized user's id even if impersonating
    realUserId,
    // Is auth0 completely authenticated and finished renewing access token if needed?
    sessionReady,
    // Is user currently logged in? Will always be `false` until `sessionReady`
    isLoggedIn,
    // Does user need to log in? Will always be `true` until `sessionReady` (`!isLoggedIn`)
    needsLogin,
    // Is user currently impersonating?
    isImpersonating,
    // User has profile in `auth0`
    hasProfile,
    // Check access token and update from auth0 if needed
    getAccessToken,
    // Convenience alias for `isLoggedIn` to use with apollo hooks on queries requiring auth
    skip: needsLogin,
  }
}

export default useRevelSession
