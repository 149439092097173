import { useCapUpdater } from "@hooks/useCapUpdater"
import useCrashlytics from "@hooks/useCrashlytics"
import useInAppPurchases from "@hooks/useInAppPurchases"
import usePushNotifications from "@hooks/usePushNotifications"

export const useMobileApp = (): void => {
  usePushNotifications()
  useInAppPurchases()
  useCrashlytics()
  useCapUpdater()
}
