import React, { useContext } from "react"

import { VersionStatus } from "@graphql/codegen"

export type UseVersionCheckContextProps = {
  // Is the data in this context loaded?
  versionReady: boolean

  // What's the current app version. null if unavailable or if not on app.
  appVersion: string | null

  // What's the current bundle version. Pulled from env vars.
  bundleVersion: string | null

  // App version status, can be from cache or backend result
  // null if unavailable or if not on app.
  appVersionStatus: VersionStatus | null

  // Bundle version status, can be from cache or backend result
  // null if unavailable
  bundleVersionStatus: VersionStatus | null

  // Message that should be shown to the user regarding their current app or bundle version.
  versionMessage: string | null

  // If true, then the user is using an unsupported bundle version
  bundleVersionRejected: boolean

  // If false, then the user is using an unsupported app version
  appVersionRejected: boolean

  // Convenience value that's ready to be shown to the user.
  // It will contain the app and bundle version, but will be null if there's no version info available.
  prettyVersion: string | null
}

const UseVersionCheckContext = React.createContext<UseVersionCheckContextProps>(
  {
    versionReady: false,
    appVersion: null,
    bundleVersion: null,
    appVersionStatus: null,
    bundleVersionStatus: null,
    versionMessage: null,
    bundleVersionRejected: false,
    appVersionRejected: false,
    prettyVersion: null,
  },
)

export const useVersionCheck = (): UseVersionCheckContextProps => {
  return useContext(UseVersionCheckContext)
}

export default UseVersionCheckContext
