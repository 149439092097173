import React, { useCallback, useEffect } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { Browser } from "@capacitor/browser"
import { useNavigate } from "react-router-dom"

import useQueryParams from "@hooks/useQueryParams"
import useRevelSession from "@hooks/useRevelSession"

import {
  isiOS,
  isNativeApp,
  routeWithinCapacitor,
} from "@services/mobileHelpers"

import {
  MOBILE_DASHBOARD,
  getPathWithReturn,
  JOIN,
  JOIN_FORM,
} from "@constants/routeConstants"

const AppUrlListener: React.FC<never> = () => {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const returnTo = queryParams?.returnTo
    ? [queryParams?.returnTo].flat()[0]
    : null
  const { userId, sessionReady } = useRevelSession()

  const { handleRedirectCallback } = useAuth0()

  const appUrlOpen = useCallback(
    async (event: URLOpenListenerEvent) => {
      // Example url: https://www.hellorevel.com/events/10-23-2021-reveling-woolfers-roller-skate-gtfs
      // slug = /events/10-23-2021-reveling-woolfers-roller-skate-gtfs
      const { url } = event

      if (
        url.includes("com.hellorevel.mobile://") &&
        url.includes("/capacitor/com.hellorevel.mobile/callback")
      ) {
        if (isiOS()) {
          // No-op on Android, close browser if iOS logout
          await Browser.close()
        }
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          // Login action
          await handleRedirectCallback(url).then(() => {
            if (returnTo && returnTo.startsWith(JOIN_FORM)) {
              // During social login, users will be redirected to JOIN_FORM
              navigate(JOIN_FORM)
            } else if (returnTo && returnTo.startsWith(JOIN)) {
              // Doesn't make sense to redirect users to the landing JOIN page
              // after they are logged in, so let's give them the dashboard
              navigate(MOBILE_DASHBOARD)
            } else {
              // In all other cases, redirect user to dashboard after logging in
              navigate(
                returnTo
                  ? getPathWithReturn(MOBILE_DASHBOARD, true, returnTo)
                  : MOBILE_DASHBOARD,
              )
            }
          })
        } else if (window.location.pathname !== "/") {
          // Logout action, go back to dashboard if not there
          navigate(MOBILE_DASHBOARD)
        }
      } else {
        const [domainWithoutTLD] = url.split(".com")
        const isAuth = domainWithoutTLD.includes("auth")
        if (!isAuth) {
          routeWithinCapacitor({ url, navigate, userId })
        }
      }
      // If no match, do nothing - let regular routing
      // logic take over
    },

    [handleRedirectCallback, returnTo, userId, navigate],
  )

  useEffect(() => {
    if (isNativeApp()) {
      // Make sure not to pollute listeners
      App.removeAllListeners()
      App.addListener("appUrlOpen", appUrlOpen)
    }
  }, [appUrlOpen, sessionReady])

  return null
}

export default AppUrlListener
