// eslint-disable-next-line import/order -- load vendor css first
import "bootstrap/dist/css/bootstrap.min.css"
// eslint-disable-next-line import/order -- load vendor css first
import "react-datepicker/dist/react-datepicker.css" // Datepicker CSS
// Deprecated
// eslint-disable-next-line import/order -- load base variants before overrides
import "./assets/scss/variants.scss" // Custom variants of bootstrap components

import React from "react"

import { SnackbarProvider } from "notistack"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { RevelApolloProvider } from "@graphql/RevelApolloProvider"

import AppUrlListener from "@components/AppUrlListener"
import AnalyticsSettingsProvider from "@providers/AnalyticsSettingsProvider"
import Auth0Provider from "@providers/Auth0Provider"
import LayoutProvider from "@providers/LayoutProvider"
import RevelAlgoliaProvider from "@providers/RevelAlgoliaProvider"
import RevelSessionProvider from "@providers/RevelSessionProvider"
import RouteHistoryProvider from "@providers/RouteHistoryProvider"
import UseVersionCheckProvider from "@providers/UseVersionCheckProvider"

import { SnackbarUtilsConfigurator } from "@services/notifications/snackbar"
import store from "@services/state/store"

import App from "./App"

import "./assets/fonts/youngserif-regular/fontface.css"
import "./assets/scss/modal.scss" // Shared modal
import "./assets/scss/overrides.scss" // Overrides for third-party CSS

window.revelPlaces = () => {}

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <LayoutProvider>
        <Auth0Provider>
          <RevelSessionProvider>
            <SnackbarProvider
              maxSnack={3}
              classes={{ containerRoot: "Snackbar__Root" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <SnackbarUtilsConfigurator />
              <RouteHistoryProvider>
                <AppUrlListener />
                <AnalyticsSettingsProvider>
                  <RevelApolloProvider>
                    <RevelAlgoliaProvider>
                      <UseVersionCheckProvider>
                        <App />
                      </UseVersionCheckProvider>
                    </RevelAlgoliaProvider>
                  </RevelApolloProvider>
                </AnalyticsSettingsProvider>
              </RouteHistoryProvider>
            </SnackbarProvider>
          </RevelSessionProvider>
        </Auth0Provider>
      </LayoutProvider>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
