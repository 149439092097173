import React, { useEffect, Suspense, useState } from "react"

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"

import useAnalytics from "@hooks/useAnalytics"

import Layout from "@components/Layout"
import Spinner, { generateFunMessage } from "@components/Spinner"
import PromptToLoginRoute from "@components/auth/PromptToLoginRoute"
import { ProtectedRoute } from "@components/auth/ProtectedRoute"
import Lazy from "@views/lazy"

import { pageView } from "@services/tracking"

import {
  DASHBOARD,
  GROUPS_SEARCH_QUERY,
  EVENTS_SEARCH_QUERY,
  APP_VERSION_REJECTED,
  MANAGE_EVENTS,
  INBOX,
  NOTIFICATIONS,
} from "@constants/routeConstants"

const NavigateExternal = ({ to }) => {
  useEffect(() => {
    window.location.href = to
  }, [to])

  return null
}

const Redirect = ({ pathFn }) => {
  const { search, hash } = useLocation()
  const params = useParams()
  return <Navigate to={pathFn(params, search, hash)} replace />
}
const App = () => {
  useAnalytics()
  const [funMessage, setFunMessage] = useState(null)

  useEffect(() => {
    const delay = setTimeout(pageView, 1500)
    setFunMessage(generateFunMessage())
    return () => clearTimeout(delay)
  }, [])

  return (
    <Layout>
      <Suspense fallback={<Spinner fullscreen message={funMessage} />}>
        <Routes>
          <Route exact path="/" element={<Lazy.MobileLanding />} />
          <Route
            exact
            path="/home"
            element={
              <PromptToLoginRoute
                Component={Lazy.Home}
                message="view your home feed"
              />
            }
          />
          <Route path="/my-groups" element={<Navigate to={DASHBOARD} />} />
          <Route
            path="/dashboard/:eventId/:stars"
            element={
              <Redirect
                pathFn={(params) =>
                  `/manage-my-events?tab=past&rateEvent=${params.eventId}&rating=${params.stars}`
                }
              />
            }
          />
          <Route path="/home-dashboard" element={<Navigate to={DASHBOARD} />} />
          <Route path="/dashboard" element={<Navigate to={DASHBOARD} />} />

          {/* Vanity URLs that redirect to UTM link */}
          <Route
            exact
            path="/KCRW"
            element={
              <NavigateExternal to="https://www.hellorevel.com/?utm_source=kcrw&utm_medium=radio&utm_campaign=april_paid&utm_content=digital_culture" />
            }
          />

          <Route
            exact
            path="/racquet"
            element={
              <NavigateExternal to="https://www.hellorevel.com/?utm_source=partner&utm_medium=offline&utm_campaign=hazel&utm_content=launch_event" />
            }
          />

          <Route
            path="/flaunt"
            element={
              <NavigateExternal to="https://www.hellorevel.com/?utm_source=radio&utm_medium=podcast&utm_campaign=flaunt&utm_content=join_revel" />
            }
          />

          {/* Vanity URLs END */}

          <Route
            path={`${INBOX}/:action`}
            element={
              <PromptToLoginRoute
                Component={Lazy.Inbox}
                message="view your inbox"
              />
            }
          />
          <Route
            path={INBOX}
            element={
              <PromptToLoginRoute
                Component={Lazy.Inbox}
                message="view your inbox"
              />
            }
          />

          <Route path="/forums/inbox" element={<Navigate to={INBOX} />} />
          <Route path="/inbox" element={<Navigate to={INBOX} />} />
          <Route path="/message" element={<Navigate to={INBOX} />} />

          <Route
            exact
            path={GROUPS_SEARCH_QUERY}
            element={<Lazy.Communities />}
          />
          <Route exact path="/community" element={<Lazy.Communities />} />
          <Route path="/groups" element={<Navigate to="/community" />} />

          <Route
            path="/community/:slug/post"
            element={<Lazy.RedirectToGroupPost />}
          />
          <Route
            path="/community/:slug/post/:postId"
            element={<Lazy.GroupPost />}
          />
          <Route
            path="/community/:slug/post/:postId/comment/:commentId"
            element={<Lazy.GroupPost />}
          />
          <Route
            path="/community/:slug/post/:postId/comment/:commentId/thread/:threadId"
            element={<Lazy.GroupPost />}
          />

          <Route path="/community/:slug" element={<Lazy.GroupDetail />} />
          <Route
            path="/groups/:slug"
            element={
              <Redirect
                pathFn={(params, search, hash) =>
                  `/community/${params.slug}${search}${hash}`
                }
              />
            }
          />
          <Route path="/activity-log" element={<Lazy.ActivityLog />} />

          <Route exact path="/events" element={<Lazy.Events />} />
          <Route exact path={EVENTS_SEARCH_QUERY} element={<Lazy.Events />} />
          <Route
            exact
            path="/events/category/:eventCategory"
            element={<Lazy.EventCategory />}
          />
          <Route
            exact
            from="/events/c/:eventCategory"
            element={<Lazy.EventCategory />}
          />

          <Route
            exact
            path="/events/:eventIdOrSlug"
            element={<Lazy.EventDetail />}
          />
          <Route
            exact
            path="/events/:eventIdOrSlug/virtual-link"
            element={<Lazy.VirtualLink />}
          />
          <Route
            exact
            path="/events/:eventIdOrSlug/preview"
            element={<Lazy.EventDetail />}
          />
          <Route
            exact
            path="/new-events/:eventIdOrSlug"
            element={<Lazy.EventDetails />}
          />
          <Route
            exact
            path="/new-events/:eventIdOrSlug/preview"
            element={<Lazy.EventDetails />}
          />
          <Route
            exact
            path={MANAGE_EVENTS}
            element={
              <PromptToLoginRoute
                Component={Lazy.ManageMyEvents}
                message="manage your events"
              />
            }
          />
          <Route
            path="/your-events"
            element={<Navigate to={MANAGE_EVENTS} />}
          />
          <Route
            exact
            path="/events/:id/payment-completed"
            element={<ProtectedRoute component={Lazy.EventPaymentCompleted} />}
          />

          <Route path="/login" element={<Lazy.Login />} />
          <Route path="/logout" element={<Lazy.Logout />} />
          <Route path="/join" element={<Lazy.Join />} />
          <Route path="/join/form" element={<Lazy.Join />} />

          {/* This route is deprecated */}
          <Route
            path="/post-join"
            element={<Navigate to="/profile/contact-info" />}
          />
          {/* This route is deprecated */}
          <Route
            path="/profile/edit/:id"
            element={<Navigate to="/profile/contact-info" />}
          />
          <Route
            path="/profile/view/:id"
            element={<Redirect pathFn={(params) => `/profile/${params.id}`} />}
          />
          <Route
            path="/profile/contact-info"
            element={
              <PromptToLoginRoute
                Component={Lazy.PostSignup}
                message="manage your contact info"
              />
            }
          />
          <Route
            path="/profile/:id"
            element={
              <PromptToLoginRoute
                Component={Lazy.Profile}
                message="view this user's profile"
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PromptToLoginRoute
                Component={Lazy.Profile}
                message="view your profile"
              />
            }
          />
          <Route
            path="/account-settings"
            element={
              <PromptToLoginRoute
                Component={Lazy.AccountBillingSettings}
                message="manage your account"
              />
            }
          />

          <Route
            path="/create-event/:eventId"
            element={
              <PromptToLoginRoute
                Component={Lazy.CreateEvent}
                message="edit your event"
              />
            }
          />
          <Route
            path="/create-event"
            element={
              <PromptToLoginRoute
                Component={Lazy.CreateEvent}
                message="create an event"
              />
            }
          />

          <Route
            path="/directory"
            element={
              <PromptToLoginRoute
                Component={Lazy.MemberDirectory}
                message="visit the directory"
              />
            }
          />

          {/* Marketing */}
          <Route path="/information" element={<Lazy.Information />} />
          <Route path="/style-guide" element={<Lazy.StyleGuide />} />

          <Route
            path="/referral"
            element={
              <PromptToLoginRoute
                Component={Lazy.Referral}
                message="refer a friend"
              />
            }
          />
          <Route
            path="/new-power-host"
            element={
              <PromptToLoginRoute
                Component={Lazy.PowerHost}
                message="celebrate becoming a power host"
              />
            }
          />
          <Route path="/hosting" element={<Lazy.WhyHost />} />
          <Route path="/why-host" element={<Navigate to="/hosting" />} />

          <Route path="/cookie-policy" element={<Lazy.CookiePolicy />} />
          <Route
            path={APP_VERSION_REJECTED}
            element={<Lazy.AppVersionRejected />}
          />
          <Route path={NOTIFICATIONS} element={<Lazy.Notifications />} />

          <Route path="*" element={<Lazy.GenericNotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  )
}

const AppWithRouter = React.memo(App)

export default AppWithRouter
