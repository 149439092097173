import React from "react"

import { useMutation } from "@apollo/client"
import { loader } from "graphql.macro"

import useNotifications from "@hooks/useNotifications"

import ErrorBoundary from "@components/ErrorBoundary"
import { SetShowHook } from "@components/Header/Header.types"
import { NotificationsProvider } from "@providers/NotificationsProvider"

import { ReactComponent as Bell } from "@images/bell.svg"

const UPDATE_LAST_VIEWED_AT = loader("./markAllAsSeen.graphql")

export interface NotificationBellProps {
  setShowHook: SetShowHook
}

const InnerNotificationBell = ({
  setShowHook,
}: NotificationBellProps): JSX.Element => {
  const notifications = useNotifications()

  const [updateLastViewedAt] = useMutation(UPDATE_LAST_VIEWED_AT, {
    update: (cache, { data: mutationData }) => {
      const notificationsData =
        mutationData?.notification?.markAllAsSeen?.notifications
      if (!notificationsData) return

      cache.modify({
        id: cache.identify(notificationsData),
        fields: {
          unseenCount: (existing) =>
            mutationData?.notification?.markAllAsSeen?.notifications
              ?.unseenCount ?? existing,
        },
      })
    },
    optimisticResponse: {
      notification: {
        markAllAsSeen: {
          lastViewedNotificationsAt: new Date(),
          notifications: {
            id: notifications.id,
            unseenCount: 0,
          },
        },
      },
    },
  })

  const handleToggle = () =>
    setShowHook((prevState: boolean) => {
      if (!prevState) {
        // When opening the menu, update the notification view time
        updateLastViewedAt()
      }

      return !prevState
    })

  return (
    <button
      type="button"
      className="Header__Link_Nav HeaderDropdown__Button"
      data-cy="notifications:bell"
      onClick={handleToggle}
    >
      <Bell title="View your notifications" />
      {notifications.unseenEventsCount + notifications.unseenGroupsCount >
        0 && (
        <div
          className="Header__NotificationCount"
          data-cy="notifications:count"
        >
          <span>
            {notifications.unseenEventsCount + notifications.unseenGroupsCount >
            9
              ? "9+"
              : notifications.unseenEventsCount +
                notifications.unseenGroupsCount}
          </span>
        </div>
      )}
    </button>
  )
}

const NotificationBell = ({
  setShowHook,
}: NotificationBellProps): JSX.Element => (
  <NotificationsProvider>
    <ErrorBoundary>
      <InnerNotificationBell setShowHook={setShowHook} />
    </ErrorBoundary>
  </NotificationsProvider>
)

export default NotificationBell
