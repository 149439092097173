// Screen widths in pixels
const MINI = 320
export const START_BP_MOBILE = MINI
const MOBILE = 480
export const SMALL_MOBILE_BP_MOBILE = MOBILE
const SMALL = 580
export const MOBILE_BP_LARGE_MOBILE = SMALL
const MEDIUM = 767
export const LARGE_MOBILE_BP_TABLET = MEDIUM
const LARGE = 992
export const TABLET_BP_LAPTOP = LARGE
const DEFAULT = 1439
export const LAPTOP_BP_DESKTOP = DEFAULT
const MEGA = 1600
export const DESKTOP_BP_WIDESCREEN = MEGA

// Packages above constants for easy import
export const breakpoints = {
  smallMobile: START_BP_MOBILE,
  mobile: SMALL_MOBILE_BP_MOBILE,
  largeMobile: MOBILE_BP_LARGE_MOBILE,
  tablet: LARGE_MOBILE_BP_TABLET,
  laptop: TABLET_BP_LAPTOP,
  desktop: LAPTOP_BP_DESKTOP,
  widescreen: DESKTOP_BP_WIDESCREEN,
}

export const HEADER_HEIGHT_DEFAULT = 80
export const HEADER_HEIGHT_TABLET_DOWN = 80
export const NAVBAR_HEIGHT_TABLET_DOWN = 68
