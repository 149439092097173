import React, { useState, ReactElement, ReactNode, useMemo } from "react"

import { useApolloClient } from "@apollo/client"
import useQueue from "react-use-queue"

import useRevelSession from "@hooks/useRevelSession"

import {
  GroupDiscussionAnchoredPostDocument,
  Maybe,
  Notification,
  NotificationSource,
  NotificationsQueryResult,
  PageInfo,
  useNotificationsQuery,
  useNotificationsUnreadCountQuery,
  useNotificationsUnseenCountQuery,
} from "@graphql/codegen"
import type { GraphQLId } from "@graphql/types"

import { withErrorBoundary } from "@components/ErrorBoundary"
import commentSequenceIdFromLocation from "@views/GroupDetail/Discussion/commentSequenceIdFromLocation"
import nestedCommentSequenceIdFromLocation from "@views/GroupDetail/Discussion/nestedCommentSequenceIdFromLocation"
import postSequenceIdFromLocation from "@views/GroupDetail/Discussion/postSequenceIdFromLocation"

import { CLIENT_HOST } from "@constants"

export type NotificationsContextValue = {
  id: GraphQLId
  unseenEventsCount: number
  unseenGroupsCount: number
  unreadEventsCount: number
  unreadGroupsCount: number
  nodes: {
    Events: Notification[]
    Groups: Notification[]
  }
  fetchMoreGroups: () =>
    | ReturnType<NotificationsQueryResult["fetchMore"]>
    | undefined
  fetchMoreEvents: () =>
    | ReturnType<NotificationsQueryResult["fetchMore"]>
    | undefined
  hasNextGroupPage: boolean
  hasNextEventPage: boolean
  loadingEvents: boolean
  loadingGroups: boolean
}

export const NotificationsContext =
  React.createContext<NotificationsContextValue>({
    id: "__uninitialized notification id__",
    unseenEventsCount: 0,
    unseenGroupsCount: 0,
    unreadEventsCount: 0,
    unreadGroupsCount: 0,
    nodes: { Events: [], Groups: [] },
    fetchMoreGroups: () => undefined,
    fetchMoreEvents: () => undefined,
    hasNextGroupPage: false,
    hasNextEventPage: false,
    loadingEvents: true,
    loadingGroups: true,
  })

// Fetch maximum this number of notifications per page
const NOTIFICATIONS_PAGE_SIZE = 5

// Poll every 30 seconds
// Use a small interval, because the timer starts from 0 each time the document gains focus
const NOTIFICATION_UNSEEN_COUNT_POLLING_TIME = 1000 * 30

const InnerNotificationsProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const queue = useQueue()
  const apolloClient = useApolloClient()
  const [groupsPageInfo, setGroupsPageInfo] = useState<Maybe<PageInfo>>()
  const [eventsPageInfo, setEventsPageInfo] = useState<Maybe<PageInfo>>()
  const { isLoggedIn, needsLogin } = useRevelSession()

  const warmCache = async (notifications: [Notification]) =>
    // warm cache with content of all posts from notifications as loaded in
    Promise.all(
      notifications
        .filter(({ read, link }: Notification) => !read && link.startsWith("/"))
        .map(({ link }: Notification) => new URL(`${CLIENT_HOST}${link}`))
        .filter(
          ({ pathname }: URL) =>
            pathname.startsWith("/community") && pathname.endsWith("/post"),
        )
        .map((location: URL) => {
          const post = {
            slug: location.pathname.split("/").slice(-1)[0],
            anchoredPostSequenceId: postSequenceIdFromLocation({ location }),
            anchoredCommentSequenceId:
              commentSequenceIdFromLocation({
                location,
              }) ?? 0,
            anchoredNestedCommentSequenceId:
              nestedCommentSequenceIdFromLocation({
                location,
              }) ?? 0,
          }
          return {
            ...post,
            fetchAnchoredComment: !!post.anchoredCommentSequenceId,
            fetchAnchoredNestedComment: !!post.anchoredNestedCommentSequenceId,
          }
        })
        .map((variables) =>
          queue.addJob(async () => {
            try {
              await apolloClient.query({
                query: GroupDiscussionAnchoredPostDocument,
                variables,
              })
            } catch (e) {
              if (e instanceof Error && e.message?.includes("NotFoundError")) {
                console.warn(
                  `Cache warming could not find post with ${JSON.stringify(
                    variables,
                  )} (GroupDiscussionAnchoredPost)`,
                )
              } else {
                throw e
              }
            }
          }),
        ),
    )

  const unseenEventsCountQuery = useNotificationsUnseenCountQuery({
    skip: needsLogin,
    variables: {
      source: NotificationSource.Events,
    },
    pollInterval:
      isLoggedIn && document.hasFocus()
        ? NOTIFICATION_UNSEEN_COUNT_POLLING_TIME
        : 0,
  })

  const unseenGroupsCountQuery = useNotificationsUnseenCountQuery({
    skip: needsLogin,
    variables: {
      source: NotificationSource.Groups,
    },
    pollInterval:
      isLoggedIn && document.hasFocus()
        ? NOTIFICATION_UNSEEN_COUNT_POLLING_TIME
        : 0,
  })

  const unreadEventsCountQuery = useNotificationsUnreadCountQuery({
    skip: needsLogin,
    variables: {
      source: NotificationSource.Events,
    },
  })

  const unreadGroupsCountQuery = useNotificationsUnreadCountQuery({
    skip: needsLogin,
    variables: {
      source: NotificationSource.Groups,
    },
  })

  const eventsNotificationsQuery = useNotificationsQuery({
    skip: needsLogin,
    notifyOnNetworkStatusChange: true,
    variables: {
      sources: [NotificationSource.Events],
      connection: {
        last: NOTIFICATIONS_PAGE_SIZE,
        before: null,
      },
      includePageInfo: true,
    },
    onCompleted: (responseData) => {
      if (responseData?.notifications?.notifications?.pageInfo) {
        setEventsPageInfo(responseData?.notifications?.notifications?.pageInfo)
      }
    },
  })

  const groupsNotificationsQuery = useNotificationsQuery({
    skip: needsLogin,
    notifyOnNetworkStatusChange: true,
    variables: {
      sources: [NotificationSource.Groups],
      connection: {
        last: NOTIFICATIONS_PAGE_SIZE,
        before: null,
      },
      includePageInfo: true,
    },
    onCompleted: (responseData) => {
      const nodes = responseData?.notifications?.notifications?.nodes
      if (nodes && nodes.length) {
        warmCache(nodes.filter(Boolean) as [Notification])
      }

      if (responseData?.notifications?.notifications?.pageInfo) {
        setGroupsPageInfo(responseData?.notifications?.notifications?.pageInfo)
      }
    },
  })

  const notificationsId =
    unseenEventsCountQuery?.data?.notifications?.id ??
    unseenGroupsCountQuery?.data?.notifications?.id

  const unseenEventsCount =
    unseenEventsCountQuery?.data?.notifications?.unseenCount ?? 0
  const unseenGroupsCount =
    unseenGroupsCountQuery?.data?.notifications?.unseenCount ?? 0
  const unreadEventsCount =
    unreadEventsCountQuery?.data?.notifications?.unreadCount ?? 0
  const unreadGroupsCount =
    unreadGroupsCountQuery?.data?.notifications?.unreadCount ?? 0
  const loadingEvents = eventsNotificationsQuery?.loading ?? false
  const loadingGroups = groupsNotificationsQuery?.loading ?? false

  const notificationNodes = {
    Events: (
      eventsNotificationsQuery?.data?.notifications?.notifications?.nodes ?? []
    ).filter(Boolean) as [Notification],
    Groups: (
      groupsNotificationsQuery?.data?.notifications?.notifications?.nodes ?? []
    ).filter(Boolean) as [Notification],
  }

  const values = useMemo(() => {
    const fetchMoreEvents = () => {
      return eventsNotificationsQuery.fetchMore({
        variables: {
          sources: [NotificationSource.Events],
          connection: {
            last: NOTIFICATIONS_PAGE_SIZE,
            before: eventsPageInfo?.endCursor,
          },
        },
      })
    }

    const fetchMoreGroups = () => {
      return groupsNotificationsQuery.fetchMore({
        variables: {
          sources: [NotificationSource.Groups],
          connection: {
            last: NOTIFICATIONS_PAGE_SIZE,
            before: groupsPageInfo?.endCursor,
          },
        },
      })
    }

    return {
      id: notificationsId ?? "__uninitialized notification id__",
      unseenEventsCount,
      unseenGroupsCount,
      unreadEventsCount,
      unreadGroupsCount,
      nodes: notificationNodes,
      fetchMoreEvents,
      fetchMoreGroups,
      hasNextGroupPage: groupsPageInfo?.hasNextPage ?? false,
      hasNextEventPage: eventsPageInfo?.hasNextPage ?? false,
      loadingEvents,
      loadingGroups,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [
    unseenEventsCount,
    unseenGroupsCount,
    unreadEventsCount,
    unreadGroupsCount,
    notificationNodes,
    groupsPageInfo,
    eventsPageInfo,
    loadingEvents,
    loadingGroups,
  ])

  return (
    <NotificationsContext.Provider value={values}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const NotificationsProvider = withErrorBoundary(
  InnerNotificationsProvider,
)
