import React from "react"

import { Helmet } from "react-helmet"

import { CLIENT_HOST } from "@constants"
import { GlobalSeo } from "@constants/seo"

interface SeoTagsPropsType {
  canonical?: string
  title?: string
  description?: string
  image?: string
}

const SeoTags = ({
  canonical,
  title,
  description,
  image,
}: SeoTagsPropsType): React.ReactElement => {
  return (
    <Helmet>
      {canonical && <link rel="canonical" href={canonical} />}

      <title>{title || GlobalSeo.title}</title>
      <meta name="description" content={description || GlobalSeo.description} />

      <meta property="og:title" content={title || GlobalSeo.title} />
      <meta
        property="og:description"
        content={description || GlobalSeo.description}
      />
      <meta property="og:image" content={image || GlobalSeo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical || GlobalSeo.canonical} />

      <meta name="twitter:title" content={title || GlobalSeo.title} />
      <meta
        name="twitter:description"
        content={description || GlobalSeo.description}
      />
      <meta name="twitter:image" content={image || GlobalSeo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@hellorevel" />

      <meta
        name="facebook-domain-verification"
        content="dsoy90srbcutdluw9ix4aglhkmc9c9"
      />

      <meta
        name="apple-itunes-app"
        content={`app-id=1579196395, app-argument=${CLIENT_HOST}`}
      />
    </Helmet>
  )
}

export default SeoTags
