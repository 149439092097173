import React, { ReactElement } from "react"

import Image from "@components/Image/index"

import { transformFileStackRoute } from "@services/filestack/transformRoute"
import joinStyles from "@services/joinStyles"

import { DEFAULT_PROFILE_PICTURE } from "@constants"
import { GROUP_ROLES, ROLES } from "@constants/accessControl"
import { USER_ROLES } from "@constants/user"

import "./index.scss"

const getPicture = (picture?: string, size = 60): string => {
  const renderedPic = picture || DEFAULT_PROFILE_PICTURE

  return transformFileStackRoute({
    src: renderedPic,
    width: size,
    height: size,
  })
}

export type AvatarProps = {
  alt?: string
  badgeDataCy?: string
  classNames?: {
    wrapper?: string
    image?: string
    badge?: string
  }
  dataCy?: string
  role?: string
  src?: string
  size?: number
}

const Avatar = ({
  src,
  size = 40,
  alt = "Avatar",
  classNames,
  role,
  dataCy,
  badgeDataCy,
}: AvatarProps): ReactElement => {
  let badge = null

  switch (role?.toLowerCase()) {
    case ROLES.ADMIN:
      badge = USER_ROLES.STAFF
      break
    case ROLES.STAFF:
      badge = USER_ROLES.STAFF
      break
    case ROLES.CL:
      badge = USER_ROLES.HOST
      break
    case ROLES.POWER_HOST:
      badge = USER_ROLES.POWER_HOST
      break
    case ROLES.HOST:
      badge = USER_ROLES.HOST
      break
    case GROUP_ROLES.MODERATOR:
      badge = USER_ROLES.MODERATOR
      break
    default:
      break
  }

  return (
    <div className={joinStyles("Avatar__Wrapper", classNames?.wrapper ?? "")}>
      <Image
        alt={alt}
        className={joinStyles("Avatar", role ?? "", classNames?.image ?? "")}
        dataCy={dataCy}
        src={getPicture(src, size)}
        user
        width={size}
        height={size}
        style={{
          minWidth: `${size}px`,
          minHeight: `${size}px`,
          maxWidth: `${size}px`,
          maxHeight: `${size}px`,
          width: `${size}px`,
          height: `${size}px`,
        }}
      />

      {badge && (
        <div
          aria-label={`user role ${badge}`}
          className={joinStyles(
            "Avatar__Badge",
            role ?? "",
            classNames?.badge ?? "",
          )}
          data-cy={badgeDataCy}
        >
          {badge}
        </div>
      )}
    </div>
  )
}

export default React.memo(Avatar)
