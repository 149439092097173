/**
 * @param  {...string} styles
 * @example
 * joinStyles(styles.One, styles.Two, isActive && styles.Three)
 * // "One Two" if isActive === true or "One Two Three" is isActive === false
 *
 */
export default function joinStyles(...styles) {
  return styles.filter(Boolean).join(" ")
}
