import React from "react"

import Types from "prop-types"
import { useLocation } from "react-router-dom"

import { AnchorLink } from "@components/Link"

const LinkWithReturn = ({ to, returnTo, className, children, analytics }) => {
  // By default, return to the current page, with the same query params
  const location = useLocation()
  const returnPath = returnTo || location.pathname + location.search

  const pathname = to.pathname || to
  let search = `?returnTo=${returnPath}`
  // The to param can be a string or an object with url attributes.  String has
  // a search function so we must check the type of search.
  if (to.search && typeof to.search === "string") {
    search = `${to.search}&returnTo=${returnPath}`
  }

  return (
    <AnchorLink
      analytics={analytics}
      to={{
        pathname,
        search,
        hash: to.hash,
        state: to.state,
      }}
      className={className}
    >
      {children}
    </AnchorLink>
  )
}

LinkWithReturn.propTypes = {
  to: Types.oneOfType([
    Types.shape({
      pathname: Types.string,
      search: Types.string,
      hash: Types.string,
      state: Types.string,
    }),
    Types.string,
  ]).isRequired,
  returnTo: Types.string,
  className: Types.string,
  children: Types.oneOfType([Types.arrayOf(Types.node), Types.node]).isRequired,
}

LinkWithReturn.defaultProps = {
  className: "",
  returnTo: null,
}

export default LinkWithReturn
