import React from "react"

import useLayout from "@hooks/useLayout"
import useRevelSession from "@hooks/useRevelSession"

import { withErrorBoundary } from "@components/ErrorBoundary"
import ProfileLink from "@components/Header/ProfileLink"
import { buildDropdownLinks, buildMobileLinks } from "@components/Header/data"
import { AnchorLink } from "@components/Link"
import LinkToLogin from "@components/LinkToLogin"
import { useVersionCheck } from "@providers/UseVersionCheckProvider"

import { LOGIN, JOIN } from "@constants/routeConstants"

const DropdownLinks = ({ toggleShow }: { toggleShow: () => void }) => {
  const { isLoggedIn, sessionReady, userId } = useRevelSession()
  const loggedIn = sessionReady && isLoggedIn
  const { tabletDown } = useLayout()
  const { prettyVersion } = useVersionCheck()

  const closeOnClick = () => {
    toggleShow()
  }
  const linkType = loggedIn ? "member" : "guest"
  const links = tabletDown
    ? buildMobileLinks(userId)[linkType]
    : buildDropdownLinks(userId)

  return (
    <div className="Header__LinkContainer_Dropdown">
      {links.map(({ label, url, id }) => {
        const href = url

        if (label === "Click to View / Edit Profile") {
          return (
            <ProfileLink
              key={url}
              label={label}
              href={href}
              onClick={() => closeOnClick()}
            />
          )
        }

        if (url === LOGIN || url === JOIN) {
          return (
            <LinkToLogin
              className="Header__LoginButtonWrapper"
              isJoining={url === JOIN}
              key={url}
            >
              <button
                className="Header__LoginButton"
                type="button"
                onClick={() => closeOnClick()}
              >
                {label}
              </button>
            </LinkToLogin>
          )
        }

        return (
          <AnchorLink
            key={url}
            to={href}
            className="Header__Link_Dropdown"
            id={id}
            onClick={() => closeOnClick()}
          >
            {label}
          </AnchorLink>
        )
      })}
      <div className="Header__LinkContainer_DropdownSpacer" />
      <div className="Header__LinkContainer_DropdownVersion">
        <p>{prettyVersion && `Version: ${prettyVersion}`}</p>
      </div>
    </div>
  )
}

export default withErrorBoundary(DropdownLinks)
