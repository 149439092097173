import compact from "lodash/compact"

export const FILESTACK_ROUTE = "https://cdn.filestackcontent.com/"
export const transformFileStackRoute = ({
  src,
  width = 400,
  height = 400,
  fit = "crop",
} = {}) => {
  // Format of default Filestack URL path:
  // https://cdn.filestackcontent.com/j5OcgvezRpaqtU6cyG3G

  const widthParameter = width && `width:${width}`
  const heightParameter = height && `height:${height}`
  const fitParameter = fit && `fit:${fit}`
  const resizeParameters = compact([
    widthParameter,
    heightParameter,
    fitParameter,
  ]).join(",")

  const resize = `auto_image/resize=${resizeParameters}/compress`
  const splitSrc = src?.split(FILESTACK_ROUTE)
  if (splitSrc?.length >= 2) {
    const resizedSrc = `${FILESTACK_ROUTE}${resize}/${splitSrc[1]}`
    return resizedSrc
  }
  return src
}
