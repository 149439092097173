import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics"
import md5 from "md5"

import { SegmentAnalytics } from "@plugins/CapacitorSegmentPlugin"

import { isNativeApp } from "@services/mobileHelpers"

import { CLIENT_HOST } from "@constants"

const analyticsInitialized = () => {
  if (!(window.mobileTrackingQueue && window.mobileTrackingQueue.length > 0)) {
    window.mobileTrackingQueue = []
  }
  return isNativeApp() || window.analytics
}

const applyEvent = async (analyticsEvent, callback) => {
  if (analyticsInitialized()) {
    await analyticsEvent()
    if (!isNativeApp() && callback) callback()
  } else {
    window.mobileTrackingQueue.push(analyticsEvent)
    if (callback) callback()
  }
}

export const sendQueuedEvents = () => {
  return Promise.all((window.mobileTrackingQueue ?? []).map((fn) => fn()))
}

export const pageView = (location = window.location) => {
  const { pathname: path, search, hash } = location

  const event = async () => {
    if (isNativeApp()) {
      await FirebaseCrashlytics.setContext({
        key: "page",
        value: path.toString(),
        type: "string",
      })
    }
    await SegmentAnalytics.page({
      pageName: path,
      properties: isNativeApp()
        ? {
            path,
            search,
            hash,
            title: document.title,
            url: `${CLIENT_HOST}${path}${search}`,
            bundleVersion: process.env.REACT_APP_BUNDLE_VERSION,
          }
        : {},
    })
  }

  return applyEvent(event)
}

export const resetAnalytics = async () => {
  if (!analyticsInitialized()) return false
  window.mobileTrackingQueue = []
  await SegmentAnalytics.reset()
  return true
}

export const identify = (user, callback = () => {}) => {
  if (!user?.auth0Id) {
    return () => {}
  }

  const chosenUserProps = {
    authId: user.auth0Id,
    email: user.email,
    joined: user.joined,
    updatedAt: user.updatedAt,
    role: user.role,
    membershipStatus: user.membershipStatus,
    city: user.city,
    state: user.state,
    country: user.country,
    bundleVersion: process.env.REACT_APP_BUNDLE_VERSION,
  }
  const userIdHash = md5(user.auth0Id).toString()

  const event = async () => {
    await SegmentAnalytics.identify({
      userId: userIdHash,
      traits: chosenUserProps,
      callback: isNativeApp() ? undefined : callback,
    })
    if (isNativeApp()) {
      await FirebaseCrashlytics.setUserId({
        userId: user.auth0Id,
      })
    }
  }

  return applyEvent(event, callback)
}

export const track = (eventName, data = {}, callback = () => {}) => {
  const event = async () => {
    await SegmentAnalytics.track({
      eventName,
      properties: {
        ...data,
        bundleVersion: process.env.REACT_APP_BUNDLE_VERSION,
      },
      callback: isNativeApp() ? undefined : callback,
    })
  }

  return applyEvent(event, callback)
}

export const nextdoorSignupTrack = () => {
  const event = async () => {
    const ndp = await SegmentAnalytics.getNDP()
    // Documentation says "SIGN UP" but that has never triggered a
    // conversion in the months of having it. Trying SIGN_UP to match
    // casing of PAGE_VIEW.
    ndp("track", "SIGN_UP")
  }

  return applyEvent(event)
}
