import { ComponentType, lazy, LazyExoticComponent } from "react"

type PreloadFactory = () => Promise<boolean>

interface Lazy<T> {
  default: ComponentType<T> & {
    preloads?: PreloadFactory[]
  }
}

type LazyFactory<T> = () => Promise<Lazy<T>>

interface LazyWithPreload<T> extends LazyExoticComponent<ComponentType<T>> {
  preload: PreloadFactory
}

export const lazzy = <T>(
  filename: string,
  lazyFactory: LazyFactory<T>,
): LazyWithPreload<T> => {
  const key = `page-has-been-force-refreshed-by-${filename}`

  const lazyFactoryUnwrapped = async (): Promise<Lazy<T>> => {
    const component = await lazyFactory()
    window.localStorage.setItem(key, "false")
    return component
  }

  const lazyFactoryWrapped = async (): Promise<Lazy<T>> => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(key) || "false",
    )
    try {
      return await lazyFactoryUnwrapped()
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(key, "true")
        window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      return lazyFactoryUnwrapped()
    }
  }

  const Component = lazy(lazyFactoryWrapped) as LazyWithPreload<T>
  Component.preload = async () =>
    lazyFactory().then((component) =>
      Promise.all(
        (component.default?.preloads ?? []).map((preload) => preload()),
      )
        .then(() => {
          console.log(`Preloaded ${component.default.displayName}`)
          return true
        })
        .catch(() => {
          console.log(`Failed to preload ${component.default.displayName}`)
          return false
        }),
    )

  return Component
}
