// See https://stackoverflow.com/questions/54560790/
// detect-click-outside-react-component-using-hooks for more context

import { useState, useRef, useCallback } from "react"

import useDocumentEvent from "@hooks/useDocumentEvent"

// Listens for clicks outside of an element & escape key clicks, and
// updates the returned 'show' accordingly.
/**
 * @param {MutableRefObject<HTMLDivElement | null> | ((instance: HTMLDivElement | null) => void) | null} altRef
 * @param {boolean} initialState
 * @param {() => void} onAfterClose
 * @return {{ref: (React.MutableRefObject<HTMLDivElement|null>|(function((HTMLDivElement|null)): void)|React.MutableRefObject<null>), show: boolean, setShow: (boolean) => void}}
 */
const useOutsideClickListener = (
  altRef = null,
  initialState = false,
  onAfterClose = null,
) => {
  const ref = useRef(altRef || null)
  const [show, setShow] = useState(initialState)

  const handleClickOutside = useCallback(
    (event) => {
      if (
        show === false ||
        (ref.current && ref.current.contains(event.target))
      ) {
        return
      }
      setShow(false)

      if (onAfterClose) {
        onAfterClose()
      }
    },
    [ref, onAfterClose, show],
  )

  const handleHideDropdown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setShow(false)

        if (onAfterClose) {
          onAfterClose()
        }
      }
    },
    [onAfterClose],
  )

  useDocumentEvent(
    [
      { type: "click", callback: handleClickOutside },
      { type: "keydown", callback: handleHideDropdown },
    ],
    [show],
  )

  return { ref, show, setShow }
}

export default useOutsideClickListener
