import snackbar from "./notifications/snackbar"
import { errorSnack } from "./notifications/snacks"

const handleError = (err) => {
  const messageOptions = [
    err?.response?.data,
    err?.response?.data?.message,
    err.toString(),
    "An unknown error occurred",
  ]
  const message = messageOptions.filter(
    (option) => typeof option === "string",
  )[0]
  const notification = errorSnack(message)

  // Display the error for users to notice
  snackbar.enqueue(notification)

  return message
}

export const errorHandler = () => (err) => {
  handleError(err)
}

export default handleError
