import React, { forwardRef, useCallback } from "react"

import useLayout from "@hooks/useLayout"
import useOutsideClickListener from "@hooks/useOutsideClickListener"

import ErrorBoundary from "@components/ErrorBoundary"
import { DesktopHeader } from "@components/Header/DesktopHeader"
import { MobileHeader } from "@components/Header/MobileHeader"
import ImpersonationBanner from "@components/ImpersonateBanner"

import { envIsProd } from "@constants/environment"

import { Ref } from "./Header.types"

import "./Header.scss"

// When not on production, set header bg to Pantone color of the year 2022
export const backgroundColor = envIsProd() ? "#31122c" : "#6667ab"
export const headerStyles = (): { backgroundColor?: string } =>
  envIsProd()
    ? {}
    : {
        backgroundColor,
      }

export const REVEL_APP_ICON =
  "https://cdn.filestackcontent.com/s6nQV9oOTaWlz6QPMeGN"

const Header = forwardRef<Ref, unknown>(function Header(_, ref) {
  const { tabletDown } = useLayout()

  const { ref: altRef, show, setShow } = useOutsideClickListener(ref, false)
  const toggleShow = useCallback(() => {
    const toggled = !show
    setShow(toggled)
    return toggled
  }, [setShow, show])

  const Component = tabletDown ? MobileHeader : DesktopHeader

  return (
    <ErrorBoundary>
      <header>
        <Component
          ref={altRef}
          setShowMenu={setShow}
          showMenu={show}
          toggleShowMenu={toggleShow}
        />
        <ImpersonationBanner />
      </header>
    </ErrorBoundary>
  )
})
Header.displayName = "Header"

export default Header
