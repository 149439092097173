import { combineReducers } from "redux"

import events from "../events/reducer"
import posts from "../posts/reducer"
import profiles from "../profile"

export default combineReducers({
  events: events || null,
  profiles,
  posts,
})
