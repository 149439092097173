const ENV = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  STAGING: "staging",
  TEST: "test",
}

const envIsDev = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENV.DEVELOPMENT
}

const envIsTest = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENV.TEST
}

const envIsDevOrTest = () => {
  return envIsDev() || envIsTest()
}

const envIsStaging = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENV.STAGING
}

const envIsProd = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENV.PRODUCTION
}

export { ENV, envIsDev, envIsTest, envIsDevOrTest, envIsStaging, envIsProd }
