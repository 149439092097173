import { lazzy } from "@services/lazy"

export const LazyCookieConsent = lazzy(
  "LazyCookieConsent",
  () => import("@components/CookieConsent"),
)
export const LazyFileStackButton = lazzy(
  "LazyFileStackButton",
  () => import("@views/EventCreation/EventPhoto/FileStackButton"),
)
export const LazyFileStackUploadField = lazzy(
  "LazyFileStackUploadField",
  () => import("@components/GroupDiscussionFeed/LazyFileStackUploadField"),
)
export const LazyPostInput = lazzy(
  "LazyPostInput",
  () => import("@components/GroupDiscussionFeed/LazyPostInput"),
)
export const LazyProfilePic = lazzy(
  "LazyProfilePic",
  () => import("@components/ProfilePic/LazyProfilePic"),
)
export const LazyReactMarkdown = lazzy(
  "LazyReactMarkdown",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- works despite TS being unhappy
  // @ts-ignore
  () => import("react-markdown"),
)
export const LazyRichText = lazzy(
  "LazyRichText",
  () => import("@components/LazyRichText"),
)

export const LazyComponents = [
  LazyCookieConsent,
  LazyFileStackButton,
  LazyFileStackUploadField,
  LazyPostInput,
  LazyProfilePic,
  LazyReactMarkdown,
  LazyRichText,
]
