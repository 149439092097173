import React from "react"

import { useSnackbar } from "notistack"

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  const snackbar = useSnackbar()
  setUseSnackbarRef(snackbar)
  return null
}

let useSnackbarRef
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  )
}

export default {
  enqueue({ message, options }) {
    useSnackbarRef.enqueueSnackbar(message, options)
  },
}
