import { envIsDevOrTest, envIsStaging } from "../constants/environment"

export const cookieExists = (cookieName, cookieValue = "true") => {
  // eslint-disable-next-line no-restricted-properties -- required for service to function
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`))
  if (!match) return false
  if (match[2] === cookieValue) return true
  if (match[2] === "false") return false
  return match[2]
}

export const setCookie = (
  cookieName,
  cookieValue = true,
  expireTime = "Fri, 31 Dec 9999 23:59:59 GMT", // never
) => {
  let domain = envIsDevOrTest() ? "localhost" : ".hellorevel.com"
  if (
    envIsStaging() &&
    window.location.hostname.includes("revel-frontend.pages.dev")
  ) {
    domain = ".revel-frontend.pages.dev"
  }
  // negative time to delete cookie when cookieValue is false-y
  const expire = cookieValue ? expireTime : "Thu, 01 Jan 1970 00:00:00 GMT"
  // eslint-disable-next-line no-restricted-properties -- required for service to function
  document.cookie = `${cookieName}=${cookieValue};domain=${domain};path=/;SameSite=Lax;expires=${expire}`
}

export const DISMISSED_COOKIE_BANNER = "revel_dismissed_banner"
export const ACCEPTS_ANALYTICS_COOKIES = "revel_accepts_analytics_cookies"
export const ACCEPTS_PERFORMANCE_COOKIES = "revel_accepts_performance_cookies"
export const AJS_USER_ID = "ajs_user_id"
export const DAILY_REVEL_COOKIE = "dailyRevelSeen"
export const IMPERSONATED_USER_ID = "impersonated_user_id"
