import React, {
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
} from "react"

// eslint-disable-next-line no-restricted-imports -- required by LayoutProvider to set up defaults
import {
  LAPTOP_BP_DESKTOP,
  TABLET_BP_LAPTOP,
  LARGE_MOBILE_BP_TABLET,
  MOBILE_BP_LARGE_MOBILE,
  START_BP_MOBILE,
  DESKTOP_BP_WIDESCREEN,
  SMALL_MOBILE_BP_MOBILE,
} from "@constants/screenSizes"

export type ScreenSize = {
  width: number
  height: number
}

export type ScreenSizeBools = {
  widescreenUp: boolean
  desktopDown: boolean
  desktopUp: boolean
  laptopDown: boolean
  laptopUp: boolean
  tabletDown: boolean
  tabletUp: boolean
  largeMobileDown: boolean
  largeMobileUp: boolean
  mobileDown: boolean
  mobileUp: boolean
  smallMobileDown: boolean
  smallMobileUp: boolean
}

export type LayoutValues = {
  footerRef: MutableRefObject<ForwardRefExoticComponent<
    RefAttributes<HTMLDivElement>
  > | null> | null
  headerRef: MutableRefObject<ForwardRefExoticComponent<
    RefAttributes<HTMLDivElement>
  > | null> | null
  footerHidden: boolean
  navBarRef: MutableRefObject<ForwardRefExoticComponent<
    RefAttributes<HTMLDivElement>
  > | null> | null
  screenSize: ScreenSize
} & ScreenSizeBools

const generateScreenSizeBools = ({
  width,
}: {
  width: number
}): ScreenSizeBools => ({
  widescreenUp: width > DESKTOP_BP_WIDESCREEN,
  desktopDown: width <= DESKTOP_BP_WIDESCREEN,
  desktopUp: width > LAPTOP_BP_DESKTOP,
  laptopDown: width <= LAPTOP_BP_DESKTOP,
  laptopUp: width > TABLET_BP_LAPTOP,
  tabletDown: width <= TABLET_BP_LAPTOP,
  tabletUp: width > LARGE_MOBILE_BP_TABLET,
  largeMobileDown: width <= LARGE_MOBILE_BP_TABLET,
  largeMobileUp: width > MOBILE_BP_LARGE_MOBILE,
  mobileDown: width <= MOBILE_BP_LARGE_MOBILE,
  mobileUp: width > SMALL_MOBILE_BP_MOBILE,
  smallMobileDown: width <= SMALL_MOBILE_BP_MOBILE,
  smallMobileUp: width > START_BP_MOBILE,
})

export const getScreenSize = (): ScreenSize => {
  return {
    // eslint-disable-next-line no-restricted-properties -- required for helper to function
    width: document.documentElement.clientWidth,
    // eslint-disable-next-line no-restricted-properties -- required for helper to function
    height: document.documentElement.clientHeight,
  }
}

export const generateStartValues = ({
  width,
  height,
}: {
  width: number
  height: number
}): LayoutValues => ({
  footerRef: null,
  headerRef: null,
  footerHidden: true,
  navBarRef: null,
  screenSize: {
    width,
    height,
  },
  ...generateScreenSizeBools({ width }),
})

const LayoutContext = React.createContext(generateStartValues(getScreenSize()))

export default LayoutContext
