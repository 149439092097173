import { createStore, applyMiddleware, compose } from "redux"
import thunkMiddleware from "redux-thunk"

import rootReducer from "./reducers"

const devtoolsPresent =
  "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const composeSetup = devtoolsPresent
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

const store = createStore(
  rootReducer,
  composeSetup(applyMiddleware(thunkMiddleware)),
)

export default store
