import React, { ReactElement } from "react"

import sample from "lodash/sample"

import "./index.scss"

export const generateFunMessage = (noun = ""): string => {
  const generatedMessage = sample([
    `Beep bop, loading ${noun} 🤖`,
    `Fetching ${noun}`,
    "Can't wait to have you join the conversation",
    "Loading some delightful surprises...",
    "Get ready to step into our world",
    "In just a few seconds, you'll be Reveling with us",
  ])

  return generatedMessage || "all the things"
}

interface SpinnerPropsType {
  className?: string
  message?: string
  funMessage?: boolean
  noun?: string
  fullscreen?: boolean
}

const Spinner = ({
  className = "",
  message = "",
  funMessage = false,
  noun = "",
  fullscreen = false,
}: SpinnerPropsType): ReactElement => {
  const msg = message || (funMessage && generateFunMessage(noun))

  return (
    <div
      className={`Spinner__Wrapper ${className} ${
        fullscreen ? "mx-auto pt-5" : ""
      }`.trimEnd()}
    >
      <div className="Spinner__Border" role="status" />
      {msg && <p className="Spinner__Message">{msg}</p>}
    </div>
  )
}

export default Spinner
