import jwtDecode from "jwt-decode"

export const processToken = (token) => {
  return jwtDecode(token)
}

export const getImpersonatedUserIdFromToken = (token) => {
  const decoded = processToken(token)
  return decoded ? decoded.impersonatedUserId : ""
}
