import React, { useEffect, useMemo, useRef } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import RouteHistoryContext from "@components/RouteHistoryContext"

const RouteHistoryProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const routeHistoryRef = useRef([])

  // Store in-app history
  // This is used as a helper to create back buttons that only return to pages within the app
  useEffect(() => {
    if (!location) {
      return
    }

    if (routeHistoryRef.current.length === 0) {
      routeHistoryRef.current.push(location)
      return
    }

    const last = routeHistoryRef.current.pop()
    if (
      (last.search === location.search || last.search === "") &&
      last.pathname === location.pathname &&
      (last.hash === location.hash || last.hash === "")
    ) {
      // Route is the same! Replace rather than append.
      routeHistoryRef.current.push(location)
    } else {
      // Route is different: append it.
      routeHistoryRef.current.push(last, location)
    }
  }, [location])

  // Construct helper object to expose in child components.
  const RouteHistory = useMemo(
    () => ({
      replace: (locationAttributes) => {
        const last = routeHistoryRef.current.pop()
        const newLast = { ...last, ...locationAttributes }

        routeHistoryRef.current.push(newLast)
      },
      goBack: (locationAttributes) => {
        if (routeHistoryRef.current.length <= 1) {
          return null
        }

        // The last item is always the current route, so pop that first
        routeHistoryRef.current.pop()

        // Next pop should contain our previous route
        const previous = routeHistoryRef.current.pop()

        const previousWithOverwrites = {
          ...locationAttributes,
          ...previous,
        }
        navigate(previousWithOverwrites)

        return previousWithOverwrites
      },
    }),
    [navigate],
  )

  return (
    <RouteHistoryContext.Provider value={RouteHistory}>
      {children}
    </RouteHistoryContext.Provider>
  )
}

export default RouteHistoryProvider
