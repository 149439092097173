// ordered by most permissive to least
export const ROLES = {
  ADMIN: "admin",
  STAFF: "staff",
  /**
   * @deprecated community lead (CL) is no longer used. Users with CL role were changed
   * to HOST / POWER_HOST as appropriate.
   */
  CL: "cl",
  POWER_HOST: "power_host",
  HOST: "host",
  USER: "user",
}

export const HOST_ROLES = {
  CREATOR: "creator",
  COHOST: "co-host",
}

export const GROUP_ROLES = {
  MODERATOR: "moderator",
  MEMBER: "member",
}

export const ADMIN_ROLES = [ROLES.ADMIN, ROLES.STAFF]

// used on FE to bestow power host icons on users that are above the power host role
export const isPowerHost = (role) =>
  [ROLES.POWER_HOST, ROLES.STAFF, ROLES.ADMIN].includes(role)
