import produce from "immer"

// Allow reducers to be defined using a handler map
// Methods inside the handler map should be written as immer producers
export const createReducer = (initialState, handlers) => {
  // eslint-disable-next-line default-param-last -- redux code using this util expects this
  return (state = initialState, { type, payload }) => {
    // eslint-disable-next-line no-prototype-builtins -- util function that modifies in place
    if (handlers.hasOwnProperty(type)) {
      return produce(handlers[type])(state, payload)
    }
    return state
  }
}

// Server responses are structured as a tree of entities to update
// Under each key is an array of update entities
// This method updates the keyed storage and list of ids for any resource
export const updateResource = (draft, entities) => {
  entities.forEach((entity) => {
    if (!draft.byId[entity.id]) {
      draft.allIds.push(entity.id)
    }
    // eslint-disable-next-line no-param-reassign -- util function that modifies in place
    draft.byId[entity.id] = { ...draft.byId[entity.id], ...entity }
  })
}

export const sortResourceIds = (
  draft,
  entityKey,
  comparator,
  collectionKey = "allIds",
) => {
  draft[collectionKey].sort((a, b) => {
    const left = draft.byId[a][entityKey]
    const right = draft.byId[b][entityKey]

    if (!comparator) {
      return left - right
    }

    return comparator(left, right)
  })
}

export const filterResourceIds = (draft, collectionKey, filter) => {
  // eslint-disable-next-line no-param-reassign -- util function that modifies in place
  draft[collectionKey] = draft[collectionKey].filter((id) =>
    filter(draft.byId[id]),
  )
}
