import {
  createReducer,
  updateResource,
  sortResourceIds,
  filterResourceIds,
} from "../state/util"

import {
  ASSOCIATED_EVENT_DATA_FETCH_SUCCESS,
  EVENTS_FETCH_SUCCESS,
  INVALIDATE_EVENTS,
} from "./actions"

const dateComparator = (a, b) => {
  return Date.parse(a) - Date.parse(b)
}

const liveEventFilter = (event) => event.status === "live"

/* eslint-disable no-param-reassign -- TODO: refactor to be non-mutating / unnecessary with graphql */
const setEvents = (state, { events }) => {
  state.isLoading = false
  updateResource(state, events)
  sortResourceIds(state, "start", dateComparator)

  // Generate a set of ids representing only live events
  state.liveIds = state.allIds
  filterResourceIds(state, "liveIds", liveEventFilter)
}

const invalidateEvents = (state) => {
  state.byId = {}
  state.allIds = []
  state.liveIds = []
  state.isLoading = true
}
/* eslint-enable no-param-reassign -- TODO: refactor to be non-mutating / unnecessary with graphql */

const actionMap = {
  [EVENTS_FETCH_SUCCESS]: setEvents,
  [ASSOCIATED_EVENT_DATA_FETCH_SUCCESS]: setEvents,
  [INVALIDATE_EVENTS]: invalidateEvents,
}

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  liveIds: [],
}

export default createReducer(initialState, actionMap)
