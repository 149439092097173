import { useEffect } from "react"

import { useMutation } from "@apollo/client"
import { PushNotifications } from "@capacitor/push-notifications"
import { loader } from "graphql.macro"
import { useNavigate } from "react-router-dom"

import useRevelSession from "@hooks/useRevelSession"

import {
  useNotificationsUnseenCountLazyQuery,
  usePushNotificationMarkAsReadMutation,
} from "@graphql/codegen"

import { isNativeApp, routeWithinCapacitor } from "@services/mobileHelpers"
import { getFcmToken, setFcmToken } from "@services/storage"
import { track } from "@services/tracking"

const ADD_DEVICE_MUTATION = loader("./addDeviceMutation.graphql")

const PUSH_NOTIFICATIONS_CATEGORY = "Push notification click"

// Sets up push notification listeners on initial load if the user is
// accessing Revel from the app
const usePushNotifications = () => {
  const { userId, isLoggedIn } = useRevelSession()
  const navigate = useNavigate()

  const [addDevice] = useMutation(ADD_DEVICE_MUTATION)
  const [markAsRead] = usePushNotificationMarkAsReadMutation()
  const [refreshUnseenCountQuery] = useNotificationsUnseenCountLazyQuery({
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    // Only do this if we're running the mobile app
    // and the user is logged in
    if (isLoggedIn && isNativeApp({ pluginName: "PushNotifications" })) {
      getFcmToken().then(({ value: savedFcmToken }) => {
        if (savedFcmToken) {
          console.log(
            `📮 Already registered for push notifications as ${savedFcmToken} (fcmToken)`,
          )
        }

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            // Listens for when the device receives a token from Firebase
            PushNotifications.addListener(
              "registration",
              ({ value: fcmToken }) => {
                addDevice({ variables: { fcmToken } }).then(() => {
                  setFcmToken(fcmToken).then(() => {
                    console.log(
                      `📮 Registered for push notifications as ${fcmToken} (fcmToken)`,
                    )
                  })
                })
              },
            )
            PushNotifications.register()
          }
        })
      })
    }
  }, [addDevice, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && isNativeApp({ pluginName: "PushNotifications" })) {
      PushNotifications.removeAllListeners().then(() => {
        // Listens for a user clicking on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (event) => {
            if (!event?.notification?.data) return

            const { data } = event.notification
            // If the notification included a type, track the click
            if (data.analyticsLabel) {
              const {
                notification: {
                  id,
                  title,
                  subtitle,
                  body,
                  link,
                  badge,
                  data: { analyticsLabel },
                },
              } = event
              track(analyticsLabel, {
                category: PUSH_NOTIFICATIONS_CATEGORY,
                message_name: title,
                message_id: id,
                message_subtitle: subtitle,
                message_body: body,
                message_link: link,
                message_badge: badge,
              })
            }

            // If the notification has a link data passed, navigate her there
            if (data.link) {
              const { link } = data
              routeWithinCapacitor({
                url: link,
                navigate,
                userId,
              })

              // If the notification also has a type passed, mark it as read
              const type = data.type && parseInt(data.type, 10)
              if (type) {
                markAsRead({
                  variables: {
                    link,
                    type,
                  },
                }).then(() => refreshUnseenCountQuery())
              }
            }
          },
        )
      })
    }
  }, [isLoggedIn, userId, navigate, markAsRead, refreshUnseenCountQuery])
}

export default usePushNotifications
