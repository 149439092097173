import React, { forwardRef, KeyboardEventHandler, useState } from "react"

import { useLocation } from "react-router-dom"

import useRevelSession from "@hooks/useRevelSession"

import { useDesktopHeaderQuery } from "@graphql/codegen"

import Avatar from "@components/Avatar"
import Banner from "@components/Banner"
import DropdownLinks from "@components/Header/DropdownLinks"
import { headerStyles } from "@components/Header/Header"
import {
  DelegatedHeaderProps,
  Ref,
  ShowHook,
} from "@components/Header/Header.types"
import InboxButton from "@components/Header/InboxButton"
import NavLinks from "@components/Header/NavLinks"
import NotificationWidget from "@components/Header/NotificationWidget"
import { logoLink } from "@components/Header/data"
import { AnchorLink } from "@components/Link"
import LazyViews from "@views/lazy"

import { JOIN } from "@constants/routeConstants"

import { ReactComponent as Brand } from "@images/ribbons/brand.svg"

export const DesktopHeader = forwardRef<Ref, DelegatedHeaderProps>(
  function DesktopHeader(
    {
      showMenu,
      toggleShowMenu = () => {
        // Do nothing by default
      },
    },
    ref,
  ) {
    const { isLoggedIn, needsLogin } = useRevelSession()
    const [showNotificationMenu, setShowNotificationMenu] = useState(false)

    const { pathname } = useLocation()
    const showNav = !pathname.startsWith(JOIN)

    const { data } = useDesktopHeaderQuery({
      skip: needsLogin,
    })
    const currentUser = data?.currentUser

    // Setup menu functions to close the other menu on open
    const toggleProfileMenu = () => {
      // If we are opening the profile menu then close the notification menu
      if (!showMenu) {
        setShowNotificationMenu(false)
      }
      toggleShowMenu()
    }
    const setShowNotificationDropdown = (getNextState: ShowHook) => {
      const nextState = getNextState(showNotificationMenu)
      // If we are opening the notification menu then close the profile menu
      if (nextState && showMenu) {
        toggleShowMenu()
      }

      if (nextState) {
        Promise.all([
          LazyViews.GroupPost.preload(),
          LazyViews.GroupDetail.preload(),
        ])
      }

      setShowNotificationMenu(nextState)
    }

    const actionKeys = ["Enter", " "]
    const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (e) => {
      if (actionKeys.includes(e.key)) toggleShowMenu()
    }

    return (
      <div className="Header__Wrapper" ref={ref}>
        <Banner
          show={!!process.env.REACT_APP_BANNER_TEXT}
          className="Header__Banner"
        >
          {process.env.REACT_APP_BANNER_TEXT || ""}
          {process.env.REACT_APP_BANNER_CTA_LINK && (
            <AnchorLink to={process.env.REACT_APP_BANNER_CTA_LINK}>
              {process.env.REACT_APP_BANNER_CTA_TEXT || "Learn More"}
            </AnchorLink>
          )}
        </Banner>
        <div className="Header__Container" style={headerStyles()}>
          <AnchorLink to={logoLink(!needsLogin)}>
            <Brand className="Header__Brand" />
          </AnchorLink>

          {showNav && <NavLinks />}

          {isLoggedIn && showNav && (
            <>
              <InboxButton />
              <NotificationWidget
                show={showNotificationMenu}
                setShow={setShowNotificationDropdown}
              />
              <div
                tabIndex={0}
                role="button"
                className="Header__Dropdown_Container"
                onKeyUp={handleKeyUp}
                onClick={toggleProfileMenu}
              >
                <Avatar
                  alt="Click to open dropdown"
                  classNames={{ image: "Header__Dropdown_Image" }}
                  src={currentUser?.profilePictureUrl ?? undefined}
                  size={56}
                />
              </div>
            </>
          )}
          {showMenu && <DropdownLinks toggleShow={toggleProfileMenu} />}
        </div>
      </div>
    )
  },
)
