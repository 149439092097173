import React, { ReactNode, Suspense, useEffect, useState } from "react"

import { EmailLink } from "@components/Link"
import Spinner from "@components/Spinner"

import { isNativeApp } from "@services/mobileHelpers"
import { clearStorage } from "@services/storage"

import { SUPPORT_EMAIL } from "@constants"
import { DASHBOARD } from "@constants/routeConstants"

import "./index.scss"

type ErrorBoundaryProps = {
  fallBackRender?: ReactNode
  resetErrorBoundary?: () => void
} & React.ComponentProps<"div">

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  render(): JSX.Element {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      const {
        fallBackRender,
        resetErrorBoundary = () => window.location.reload(),
      } = this.props
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment -- fragment converts to JSX.Element
        <>
          {fallBackRender || (
            <div className="ErrorBoundary__Wrapper">
              <strong className="ErrorBoundary__Backup">
                Something went wrong.
              </strong>
              <button
                type="button"
                className="ErrorBoundary__Button"
                onClick={resetErrorBoundary}
              >
                Try again
              </button>
            </div>
          )}
        </>
      )
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment -- fragment converts to JSX.Element
    return <>{children}</>
  }
}

type AppFallbackProps = {
  resetErrorBoundary?: () => void
}
export const AppFallback = ({
  resetErrorBoundary,
}: AppFallbackProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(true)

  const tryAgain =
    resetErrorBoundary ||
    (() => {
      window.location.reload()
    })

  useEffect(() => {
    // redirect user after 5 seconds if page persists
    const DELAY = 5
    const timer1 = setTimeout(() => setShowSpinner(false), DELAY * 1000)

    return () => {
      clearTimeout(timer1)
    }
  }, [])

  if (showSpinner) {
    return (
      <div className="ErrorBoundary__Container">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="ErrorBoundary__Container">
      <h1 className="ErrorBoundary__Hero">Sorry...</h1>
      <h1 className="ErrorBoundary__Hero">It&apos;s not you.</h1>
      <h1 className="ErrorBoundary__Hero">It&apos;s us.</h1>
      <p className="ErrorBoundary__Text">
        We&apos;re experiencing an internal server problem. Please{" "}
        <button
          type="button"
          className="ErrorBoundary__ButtonLink"
          onClick={tryAgain}
        >
          try again
        </button>{" "}
        later or contact <EmailLink to={SUPPORT_EMAIL}>Support</EmailLink>.
      </p>
      <p className="ErrorBoundary__Text">
        {/* eslint-disable-next-line react/forbid-elements -- navigate and refresh (start React app) */}
        Go back to <a href={DASHBOARD}>home</a> and try again? (v
        {process.env.REACT_APP_BUNDLE_VERSION})
      </p>
      {isNativeApp() && (
        <Suspense fallback={null}>
          <p className="ErrorBoundary__Text">
            Clear account &{" "}
            <button
              type="button"
              className="ErrorBoundary__ButtonLink"
              onClick={() => {
                clearStorage().then(() => {
                  window.location.reload()
                })
              }}
            >
              reset secure storage
            </button>
            ?
          </p>
        </Suspense>
      )}
    </div>
  )
}

export const withErrorBoundary = <P extends Record<string, unknown>>(
  Component: React.FC<P>,
): React.FC<P> => {
  const ErrorBoundaryHOC = ({ ...props }) => {
    return Component ? (
      <ErrorBoundary>
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- pass through props to ErrorBoundary HOC */}
        <Component {...(props as P)} />
      </ErrorBoundary>
    ) : null
  }
  return ErrorBoundaryHOC
}

withErrorBoundary.displayName = "ErrorBoundaryHOC"

export default ErrorBoundary
