import React, { ReactElement } from "react"

import useRevelSession from "@hooks/useRevelSession"

import { useProfileLinkQuery } from "@graphql/codegen"

import Avatar from "@components/Avatar"
import { withErrorBoundary } from "@components/ErrorBoundary"
import { AnchorLink } from "@components/Link"

type ProfileLinkProps = {
  label: string
  href: string
  onClick: () => void
}

const ProfileLink = ({
  label,
  href,
  onClick,
}: ProfileLinkProps): ReactElement => {
  const { needsLogin } = useRevelSession()
  const { data } = useProfileLinkQuery({
    skip: needsLogin,
  })
  const currentUser = data?.currentUser
  return (
    <AnchorLink to={href} className="Header__Profile" onClick={onClick}>
      <div className="Header__ProfileLink">
        <Avatar
          alt="Click to open menu"
          classNames={{ image: "Header__Collapse_ProfilePic" }}
          src={currentUser?.profilePictureUrl ?? undefined}
          size={50}
        />

        <div className="Header__ProfileLinkContent">
          <strong className="Header__ProfileName">
            {currentUser?.fullName || "Loading User..."}
          </strong>
          <span className="Header__ProfileLinkCTA">{label}</span>
        </div>
      </div>
    </AnchorLink>
  )
}

export default withErrorBoundary(ProfileLink)
