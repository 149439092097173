import React, { CSSProperties, forwardRef, ReactNode } from "react"

import "./index.scss"

export type BannerProps = {
  children: ReactNode
  className?: string
  dataCy?: string
  show: boolean
  style?: CSSProperties | undefined
}
const Banner = forwardRef<HTMLDivElement, BannerProps>(
  ({ show, children, className, style, dataCy }, ref) => {
    return show ? (
      <div
        ref={ref}
        data-cy={dataCy}
        className={`Banner ${className || ""}`}
        style={style}
      >
        <div className="Banner_Inner">{children}</div>
      </div>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment -- fragment gives clean null with forwardRef
      <></>
    )
  },
)

Banner.displayName = "Banner"
export default Banner
