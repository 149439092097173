import startsWith from "lodash/startsWith"

import { CORE_DOMAINS, ROOT_URL } from "@constants/routeConstants"

export const makeRelative = ({ href }: { href: string }): string => {
  if (!href) {
    return href
  }

  if (startsWith(href, "/")) {
    // Already relative? Return verbatim
    return href
  }

  // If not already relative, make it relative
  return CORE_DOMAINS.reduce((acc, current) => {
    return acc.includes(current) ? acc.split(current)[1] : acc
  }, href)
}

export const makeAbsolute = ({ href }: { href: string }): string => {
  if (!href) {
    return href
  }

  if (!startsWith(href, "/")) {
    // Not relative? Return verbatim
    return href
  }

  // If relative link, make it absolute
  return `${ROOT_URL}${href}`
}
