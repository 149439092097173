import { useEffect } from "react"

import { Notifier } from "@airbrake/browser"

import { getDeviceInfo } from "@services/mobileHelpers"

import { CLIENT_HOST } from "@constants"
import { envIsDevOrTest, envIsProd } from "@constants/environment"
import { ANDROID_CLIENT_HOST, IOS_CLIENT_HOST } from "@constants/routeConstants"

export const useAirbrake = (): void => {
  useEffect(() => {
    if (process.env.REACT_APP_AIRBRAKE_PROJECT_ID) {
      const BRANCH = process.env.REACT_APP_BRANCH
      const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
      const environment =
        BRANCH === "main" || envIsProd() || envIsDevOrTest()
          ? ENVIRONMENT
          : BRANCH || "preview"
      const airbrake = new Notifier({
        projectId: parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID, 10) || 0,
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || "DEFAULT",
        environment,
      })

      getDeviceInfo().then((deviceInfo) => {
        airbrake.addFilter((notice) => {
          const filteredNotice = notice
          filteredNotice.context.version = process.env.REACT_APP_BUNDLE_VERSION
          filteredNotice.context.deviceInfo = deviceInfo
          if (deviceInfo.platform !== "web") {
            filteredNotice.url = filteredNotice.url
              ?.replace(ANDROID_CLIENT_HOST, CLIENT_HOST)
              ?.replace(IOS_CLIENT_HOST, CLIENT_HOST)
          }

          return filteredNotice
        })
      })
    }
  }, [])
}
