import states from "us-state-codes"

import { UNITED_STATES } from "@constants"

const prettyLocation = ({ city, state, country }) => {
  if (!city && !state && !country) {
    // Garbage In, Garbage Out
    return ""
  }

  if (country && !city && !state) {
    // We only have a country, might as well show it
    return country
  }

  if (city && !country && !state) {
    // We only have a city, might as well show it
    return city
  }

  if (state && !country && !city) {
    // We only have a state, might as well show it
    return state
  }

  if (country && city && !state) {
    // We don't have a state, so best we can do is city & country
    return `${city}, ${country}`
  }

  // Try to turn state into US state code if its in the US
  const prettyState =
    (country === UNITED_STATES && state
      ? states.getStateCodeByStateName(state)
      : state) ?? state

  if (country && prettyState && !city) {
    return `${prettyState}, ${country}`
  }

  if (country === UNITED_STATES) {
    // If we have all three of city, state, country, and it's in the US, don't show the country, just show the state
    return `${city}, ${prettyState}`
  }

  if (!country && city && state) {
    // If we don't know the country, then just display the state
    return `${city}, ${prettyState}`
  }

  // For non-US, just show the city and country, and no state, even if we have all three
  return `${city}, ${country}`
}

export const prettyStates = (state) => {
  return states.getStateCodeByStateName(state)
}

export default prettyLocation
