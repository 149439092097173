import React from "react"

import { withErrorBoundary } from "@components/ErrorBoundary"
import { SetShowHook } from "@components/Header/Header.types"

import NotificationBell from "./NotificationBell"
import NotificationDropdown from "./NotificationDropdown"

import "./index.scss"

const NotificationWidget = ({
  show,
  setShow,
}: {
  show: boolean
  setShow: SetShowHook
}): React.ReactElement => {
  return (
    <div className="Header__NotificationInner">
      <NotificationBell setShowHook={setShow} />
      <NotificationDropdown show={show} setShowHook={setShow} />
    </div>
  )
}

export default withErrorBoundary(NotificationWidget)
