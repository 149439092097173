import sample from "lodash/sample"

import { prettyStates } from "@services/prettyLocation"

import {
  VIRTUAL_EVENT_LOCATION_TYPE,
  ARCHIVED_EVENT_STATUS,
  PRIVACY,
  UNITED_STATES,
} from "@constants"

import { PRIME_NUMS } from "./primes"

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout
  return function debounced() {
    // This method stores `this` in order to provide correct scope to the callback, if necessary
    const context = this
    // eslint-disable-next-line prefer-rest-params -- util function
    const args = arguments

    const later = () => {
      timeout = null
      if (!immediate && func) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }
}

export const finalPathComponent = (path) => {
  const components = path.split("/").filter((a) => a)
  return components[components.length - 1]
}

export const isVirtualEvent = (event) => {
  return parseInt(event.eventLocationTypeId, 10) === VIRTUAL_EVENT_LOCATION_TYPE
}

export const isVirtualDraftEvent = (event) => {
  return (
    parseInt(event.eventLocationTypeIdDraft, 10) === VIRTUAL_EVENT_LOCATION_TYPE
  )
}

export const getRegionText = (event) => {
  const isVirtual = isVirtualEvent(event)

  if (isVirtual) {
    return "Phone/Online Event"
  }

  if (event.city && event.state) {
    return `${event.city}, ${event.state}`
  }

  return "TBD"
}

// Transform an array of tickets into an array of attendees
// Flattens ticket-specific fields onto the associated user
export const transformTickets = (event) => {
  const { tickets } = event
  let guestCount = 0
  const transformed =
    tickets
      ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .map((ticket) => {
        const { user } = ticket
        const guest = Boolean(ticket.guest) // Only interested in presence of guest, not details
        if (guest) {
          guestCount += 1
        }

        const ticketCopy = {
          ...ticket,
          ...user,
          guest,
        }
        delete ticketCopy.user

        return ticketCopy
      }) ?? []

  const attendees = transformed.filter((t) => !t.waitlisted)
  const waitlistAttendees = transformed.filter((t) => t.waitlisted)
  return {
    attendees,
    attendeeCount: attendees.length + guestCount,
    waitlistAttendees,
    waitlistCount: waitlistAttendees.length,
  }
}

export const calculateSpotsRemaining = (event) => {
  if (!event) return 0

  const tickets = event.tickets ?? []
  const attendeeCount = tickets.filter((t) => !t.waitlisted).length
  const guestCount = tickets.filter((t) => t.guest).length
  const externalGuestCount = event.externalGuests?.length ?? 0

  // Ensure we don't overflow
  return Math.max(
    0,
    event.ticketsTotal - attendeeCount - guestCount - externalGuestCount,
  )
}

export const isCancelledEvent = (event) => {
  return event.status === ARCHIVED_EVENT_STATUS
}

export const isPastEvent = (event) => {
  return new Date(event.end) < new Date()
}

export const userIsAttending = (userId, event) => {
  const userTicket = event?.tickets?.find(
    (t) => t.user?.id === userId && !t.waitlisted,
  )

  return Boolean(userId) && Boolean(userTicket)
}

export const userIsWaitlisted = (userId, event) => {
  const waitlistTicket = event?.tickets?.find(
    (t) => t.user?.id === userId && t.waitlisted,
  )

  return Boolean(userId) && Boolean(waitlistTicket)
}

export const isLoggedIn = (userId) => {
  return Boolean(userId)
}

export const userIsHost = (userId, event) => {
  if (!userId) return false

  return event.hosts?.map((h) => h.id).includes(userId)
}

export const buildPrivateLocation = (event) => {
  if (isVirtualEvent(event)) {
    return null
  }

  if (!event.city || !event.state) {
    return "Location TBD"
  }

  // Specific location won't be returned if the user isn't
  // hosting/attending/admin
  if (!event.location) {
    return `${event.city}, ${prettyStates(event.state)}`
  }
  return event.location
}

export const buildEventLink = (event) => {
  const identifier = event.slug ?? event.id
  return `/events/${identifier}`
}

// React Query pagination API expects server to return a response containing pagination info
// Unpacking this response to get the actual data must be done by the developer at the `useInfiniteQuery` call site
export const getFetchMore = (lastGroup) => {
  const { nextPage = false } = lastGroup
  return nextPage
}

// Revel API will return paginated responses in this format:
// {
//   data: [...],
//   nextPage: 2
// }
export const flattenInfiniteResult = (data) => {
  if (!data) {
    return []
  }

  // Extract the internal data array from each result, and flatten the resulting nested arrays
  return data.map((datum) => datum.data).flat()
}

// Transparently handles pagination so that API responses can be passed directly to the appropriate transformer
export const paginationDecorator = (transformer) => (response) => {
  // nextPage value can be falsy, so we will check a known truthy key for paginated responses
  const hasPagination = response.data ?? false

  if (!hasPagination) {
    return transformer(response)
  }

  // If response is paginated, apply transformer to the data key instead
  response.data = transformer(response.data)
  return response
}

export const buildAuthHeaders = (accessToken) => ({
  Authorization: `Bearer ${accessToken}`,
})

export const getRandomPrime = () => {
  return sample(PRIME_NUMS)
}

export const buildVersionHeader = (version) => ({
  "revel-endpoint-version": version,
})

// Translates from params in the url to an array of ids
export const getArrayParam = (arrayParam = [], integerValue = false) => {
  if (!Array.isArray(arrayParam)) {
    if (integerValue) {
      return [parseInt(arrayParam, 10)]
    }
    return [arrayParam]
  }
  return arrayParam.map((id) => {
    if (integerValue) {
      return parseInt(id, 10)
    }
    return id
  })
}

// Takes an array of selected ids and a query with data that has an id and name
// Returns an array of objects in the format react-select expects
export const getReactSelectFormat = (selectedIds, query) => {
  if (!query.isSuccess) {
    return []
  }
  const selectedData = query.data?.filter((interest) =>
    selectedIds.includes(interest.id),
  )
  const result = selectedData?.map((data) => {
    return { label: data.name, value: data.id }
  })

  return result
}

export const getPublicName = (user) => {
  // Requires a `privacy` object on the user
  if (!user.privacy) {
    return ""
  }

  let publicName = user.firstName

  if (user.privacy.lastName === PRIVACY.FULL) {
    publicName += ` ${user.lastName}`
  }

  if (user.privacy.lastName === PRIVACY.INITIAL && user.lastName[0]) {
    publicName += ` ${user.lastName[0]}.`
  }

  return publicName
}

export const getPublicLastName = (lastName, lastNamePrivacy) => {
  if (lastNamePrivacy === PRIVACY.FULL) {
    return lastName
  }
  if (lastNamePrivacy === PRIVACY.INITIAL) {
    return `${lastName[0]}.`
  }
  return ""
}

export const getPublicLocation = (city, state, country) => {
  // Handle users who have a very general location
  if (!state) {
    return country
  }
  if (!city) {
    return `${state}, ${country}`
  }

  // Handle fully specified users based on their origin country
  if (country !== UNITED_STATES) {
    return `${city}, ${country}`
  }

  return `${city}, ${state}`
}

// Required to disable the ghastly css-in-js bundled with `react-select`
// https://github.com/JedWatson/react-select/issues/2872#issuecomment-654251799
export const reactSelectProxy = new Proxy(
  {},
  {
    // This proxy serves to replace existing styles with no styles, and uses an empty arrow function to do so
    get: () => () => {},
  },
)
