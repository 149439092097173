import React, { ReactElement, ReactNode } from "react"

import isObject from "lodash/isObject"
import { Link, LinkProps } from "react-router-dom"

import useRevelSession from "@hooks/useRevelSession"

import {
  isNativeApp,
  makeUrlAnchorLinkable,
  openBrowser,
} from "@services/mobileHelpers"
import { track } from "@services/tracking"

import { isReactPage, isWebflowPage } from "@constants/routeConstants"

const question = (string: string) =>
  string.includes("?") ? string : `?${string}`

type AProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>
const A = ({
  href,
  onClick,
  children,
  ...rest
}: AProps): ReactElement<HTMLAnchorElement> => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading, react/forbid-elements -- pass through props to anchor tag
    <a href={href} onClick={onClick} {...rest}>
      {children}
    </a>
  )
}

type AnalyticsEventData = Record<string, never | unknown> | null
export type AnalyticsProps =
  | { event: string; data?: AnalyticsEventData }
  | string
type ToProps = {
  pathname?: string
  search?: string
  hash?: string
  // TODO: refactor to accept
  // state?: Record<string, unknown>
}
export type AnchorLinkProps = AProps & {
  to?: ToProps | string
  onClick?: (() => void) | undefined
  analytics?: AnalyticsProps
  enforceNoTargetBlank?: boolean
  children: ReactNode
}
export const AnchorLink = ({
  to,
  onClick,
  analytics,
  children,
  enforceNoTargetBlank,
  ...rest
}: AnchorLinkProps): ReactElement<HTMLAnchorElement | typeof Link> => {
  const { userId } = useRevelSession()
  const constructedPath =
    isObject(to) &&
    `${to.pathname ? to.pathname : window.location.pathname}${
      to.search ? question(to.search) : ""
    }${to.hash ? `#${to.hash}` : ""}`
  const path = isObject(to) ? constructedPath : to
  const isReact = !!path && isReactPage(path)
  const isWebflow = !!path && isWebflowPage(path)
  const isExternalToRevel = !(isReact || isWebflow)
  const isNative = isNativeApp()

  const includeOpenInApp = !!path && isExternalToRevel && isNative
  const includeOnClick = !!onClick || includeOpenInApp || analytics

  const includeHref = !!path && !isReact && !includeOpenInApp
  const includeTo = !!to && isReact

  const onClickWithTracking = async () => {
    if (analytics) {
      if (typeof analytics === "string") {
        track(analytics)
      } else {
        const { event, data } = analytics
        track(event, data ?? {})
      }
    }
    if (onClick) {
      onClick()
    }
    if (includeOpenInApp && !!path) {
      await openBrowser({ url: path })
    }
  }

  const linkableUrl = makeUrlAnchorLinkable({
    url: path || "",
    userId,
  })

  const params: AProps | LinkProps = {
    onClick: includeOnClick ? onClickWithTracking : undefined,
    href: includeHref ? linkableUrl : undefined,
    to: includeTo ? linkableUrl : undefined,
    rel: isExternalToRevel ? "noopener noreferrer nofollow" : undefined,
    target: !enforceNoTargetBlank && isExternalToRevel ? "_blank" : undefined,
  }

  const LinkComponent = includeTo ? Link : A

  return (
    /* eslint-disable @typescript-eslint/ban-ts-comment, react/jsx-props-no-spreading -- type mismatch between <a> and <Link> */
    // @ts-ignore
    <LinkComponent {...params} {...rest}>
      {children}
    </LinkComponent>
    /* eslint-enable @typescript-eslint/ban-ts-comment, react/jsx-props-no-spreading -- type mismatch between <a> and <Link> */
  )
}

export default AnchorLink
