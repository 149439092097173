import React from "react"

import { QUERY_STATUS } from "@constants"

import { ReactComponent as ButtonSpinner } from "@images/button-spinner.svg"

import "./index.scss"

const MutationButton = ({
  handleClick,
  status,
  children,
  className,
  dataCy = "",
  disabled = false,
}) => {
  const mutationClassName = `MutationButton ${className}`
  const loadingClassName = `MutationButton__Loading ${mutationClassName}`

  if (status === QUERY_STATUS.LOADING) {
    return (
      <button className={loadingClassName} disabled type="button">
        <ButtonSpinner className="MutationButton__ButtonSpinner" />
        {children}
      </button>
    )
  }

  return (
    <button
      className={mutationClassName}
      data-cy={dataCy}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      {children}
    </button>
  )
}

export default MutationButton
