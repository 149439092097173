import React from "react"

import useRevelSession from "@hooks/useRevelSession"

import LinkToLogin from "@components/LinkToLogin"

import styles from "./index.module.scss"

const PromptToLoginRoute = ({
  Component,
  message,
}: {
  Component: React.ComponentType
  message: string
}): React.ReactNode | null => {
  const { isLoggedIn, sessionReady } = useRevelSession()
  // Avoid flashing the login button if Auth0 hasn't loaded yet
  if (!sessionReady) {
    return null
  }
  if (!isLoggedIn) {
    return (
      <div className={styles.Container}>
        <div className={styles.Prompt}>
          You&apos;re currently logged out. Please login to {message}.
        </div>
        <LinkToLogin>
          <button className={styles.LoginButton} type="button">
            Login
          </button>
        </LinkToLogin>
      </div>
    )
  }
  return <Component />
}

export default PromptToLoginRoute
