import { useEffect, useMemo } from "react"

import qs from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

function useQueryParams() {
  const navigate = useNavigate()
  const location = useLocation()
  const hasSearch = location?.pathname?.includes("?")

  useEffect(() => {
    if (hasSearch) {
      navigate(location?.pathname, { replace: true })
    }
  }, [hasSearch, location?.pathname, navigate])
  const search = location?.search

  return useMemo(() => qs.parse(search), [search])
}

export default useQueryParams
