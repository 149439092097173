import React from "react"

const RevelSessionContext = React.createContext({
  userId: "",
  userGqlId: "",
  realUserId: "",
  isLoading: true,
  isAuthenticated: false,
  getAccessToken: null,
})

export default RevelSessionContext
