import axios from "axios"
import qs from "qs"

export const getData = (response) => response.data

export const revelApi = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_HOST}/api/`,
  paramsSerializer: qs.stringify, // Axios encodes params using `+` instead of `%20` and it's a headache
})

revelApi.interceptors.response.use(getData)
revelApi.defaults.withCredentials = true // Allow cookies to be sent with requests and set from responses
