import React, { ComponentType, FC, useMemo } from "react"

import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useLocation } from "react-router-dom"

import Spinner from "@components/Spinner"

interface ProtectedRouteProps {
  component: ComponentType
}
export const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  // Send unauthenticated users to Auth0, then back to this route
  const { pathname: returnTo } = useLocation()

  const options = useMemo(() => {
    const onRedirecting = () => <Spinner fullscreen />
    return { returnTo, onRedirecting }
  }, [returnTo])

  const WrappedComponent = useMemo(
    () => withAuthenticationRequired(component, options),
    [component, options],
  )

  return <WrappedComponent />
}
