import enUS from "date-fns/locale/en-US"

import { envIsDevOrTest, envIsStaging } from "./environment"

export const UNITED_STATES = "United States"

export const VIRTUAL_EVENT_LOCATION_TYPE = 3
export const IN_PERSON_EVENT_LOCATION_TYPE = 2
export const ARCHIVED_EVENT_STATUS = "deleted"
export const SUBMITTED_EVENT_STATUS = "submitted"
export const LIVE_EVENT_STATUS = "live"
export const DRAFT_EVENT_STATUS = "draft"
export const COMPLETED_EVENT_STATUS = "completed"
export const DELETED_EVENT_STATUS = "deleted"
export const REVEL_ZOOM = "REVEL ZOOM"
export const SUPPORT_EMAIL = "support@hellorevel.com"
export const POWERHOST_EMAIL = "powerhost@hellorevel.com"
export const SUPPORT_PHONE_NUMBER = `+1 (909) 323-0994`
export const CALL_SUPPORT = `tel:+19093230994`
export const COOKIE_MAX_AGE = 60 * 60 * 24 * 90 // 90 days
export const EVENT_FORMAT_TYPE = {
  SMALL_GROUP: 1,
  SPEAKER: 2,
}

// Constants used for member event actions - used in the BE as well
export const EVENT_ACTION_TYPES = {
  ATTENDED: "attended",
  ATTENDING: "attending",
  CRITICAL: "critical",
  HOSTED: "hosted",
  HOSTING: "hosting",
  NEW_MEMBER: "newMember",
  RETURNING_MEMBER: "returningMember",
  ALL_PAST_UNREVIEWED: "allPastUnreviewed",
}

export const DEFAULT_INTEREST_IMAGE =
  "https://cdn.filestackcontent.com/u4tjdykSRuTrrKqfGzaN"

export const DEFAULT_PROFILE_PICTURE =
  "https://cdn.filestackcontent.com/JrjP1hG9RsqIybmgFbKN"

export const REVEL_LOGO =
  "https://cdn.filestackcontent.com/hVpRrfHsQ5iWvDCGcPLT"

export const FORMAT_OPTIONS = {
  locale: enUS,
}

export const EVENT_CARD_FORMATS = {
  START: "eee, LLL d, h:mm a",
  END: "h:mm a zzz",
  DATE: "eee, LLL d",
  TIME_START: "h:mm a",
  TIME_START_W_TZ: "h:mm a zzz",
  TIME_END: "h:mm a zzz",
}

// Mapping from interest ID --> filestack url, sorted alphabetically by name
export const INTEREST_IMAGES = {
  // Activism
  149: "https://cdn.filestackcontent.com/dcj3okAQo6tyXVUPVIuG",
  // Adventure
  150: "https://cdn.filestackcontent.com/ac77abWHSxi24lxxPa8i",
  // Animals
  106: "https://cdn.filestackcontent.com/fst5VPXEQA6prcCq9bYl",
  // "Art"
  102: "https://cdn.filestackcontent.com/bRxIbEBmSCGe2JmWWwhq",
  // "Book Discussions"
  93: "https://cdn.filestackcontent.com/Sw29BiujSIuGQ3QWjPvR",
  // Camping
  156: "https://cdn.filestackcontent.com/in5WOj83RfuodClsZjBf",
  // "Career & Entrepreneurship"
  89: "https://cdn.filestackcontent.com/lqyHjwARTarbORzxFWGL",
  // "Community & Connection"
  90: "https://cdn.filestackcontent.com/pDo9fmMGSLyR4qk21I40",
  // Crafts
  148: "https://cdn.filestackcontent.com/44dd1RlTAiLgpfA6XsmZ",
  // "Cultural Events"
  98: "https://cdn.filestackcontent.com/8BEndoUGSkAxOkULyzYh",
  // "Diversity & Inclusion"
  108: "https://cdn.filestackcontent.com/viL6JJU3TLGvq0p4Fh5P",
  // Fashion & Beauty
  100: "https://cdn.filestackcontent.com/8j8JzfU8TDSauJSFDKJL",
  // Financial Health
  147: "https://cdn.filestackcontent.com/fGzcXsFHR8CXpwvgdMFy",
  // Fitness
  145: "https://cdn.filestackcontent.com/9tEJxhRWQA6sdUDfzD8A",
  // "Food & Drink"
  97: "https://cdn.filestackcontent.com/NWCiIQejT42Rbolv4tcd",
  // "Gardening"
  107: "https://cdn.filestackcontent.com/1mMsPBAXQZ6jsfH2naeH",
  // "Health & Wellness"
  88: "https://cdn.filestackcontent.com/OttgyDigSgS4EHzYVpCI",
  // Humor
  153: "https://cdn.filestackcontent.com/Lx2zgklRU6TdJ9STrqfC",
  // Just For Fun
  146: "https://cdn.filestackcontent.com/t8gQdiYQLqhNNsDkuUpt",
  // "Hiking & Outdoor Activities"
  95: "https://cdn.filestackcontent.com/PfqnzbRrSWenoXXas2Tr",
  // "Menopause & Positive Aging"
  91: "https://cdn.filestackcontent.com/NHerlv96SQy89ryWlq9h",
  // Mental Health
  152: "https://cdn.filestackcontent.com/zFda6CbFS26dsVJkTUll",
  // Parenting & Caregiving
  105: "https://cdn.filestackcontent.com/hR05wqDdRwe701vZBeBA",
  // Politics
  104: "https://cdn.filestackcontent.com/5H2iD5EiS1eVKfIfnafM",
  // "Self Care"
  144: "https://cdn.filestackcontent.com/VWHPZytdSlKZD2b93pMG",
  // Sex & Dating
  151: "https://cdn.filestackcontent.com/UXNxf2YNQVqhtMrCrc6M",
  // "Speakers & Learning"
  92: "https://cdn.filestackcontent.com/2qGBIXotROWwxwW3xxHY",
  // Spirituality
  155: "https://cdn.filestackcontent.com/H01jyFeR4K7l6xdUWhJQ",
  // "Technology"
  96: "https://cdn.filestackcontent.com/XOQGHvFXQnyzXWho4XMX",
  // "Travel"
  111: "https://cdn.filestackcontent.com/hC7LlshZS3qE9FuZ1Ol9",
  // "Volunteering"
  99: "https://cdn.filestackcontent.com/gHG00Ta5QgC7bWPsmxcH",
  // "Writing Groups"
  94: "https://cdn.filestackcontent.com/EW5Fy1XOTvqLXkn9Bj84",
  // Yoga
  154: "https://cdn.filestackcontent.com/ybuDEtHlShiO6PuMi98K",
}

export const EVENT_TYPES = {
  SINGLE: 0,
  SERIES: 1,
}

export const SERIES = "Series"
export const REVEL_HQ_EVENT = "Revel HQ Event"
export const EXPERTS_EVENT = "Power Host"

export const SUBSCRIPTION_TYPE_INCOMPLETE_SIGNUP = "Incomplete Signup"
export const SUBSCRIPTION_TYPE_TRIAL_ACTIVE = "paidTrialActive"
export const SUBSCRIPTION_TYPE_ACTIVE = "active"
export const SUBSCRIPTION_TYPE_CANCELLED = "cancelled"
export const SUBSCRIPTION_TYPE_EXPIRED = "expired"
export const SUBSCRIPTION_TYPE_FREE_ACTIVE = "trialActive"
export const SUBSCRIPTION_TYPE_FREE_EXPIRED = "freeExpired"
export const SUBSCRIPTION_TYPE_INAUGURAL = "inaugural"
export const SUBSCRIPTION_TYPE_PAID_LIFETIME = "paidLifetime"
export const SUBSCRIPTION_TYPE_PAST_DUE = "pastDue"

export const PRICE_INTERVALS = {
  year: {
    unit: "/month",
    calculateBilling: (price) => `$${price} billed annually`,
    discountPercentage: "25%",
    calculatePrice: (price) => (price / 12).toFixed(2),
  },
  month: {
    unit: "/month",
    calculateBilling: () => "billed monthly",
    calculatePrice: (price) => price.toFixed(2),
  },
  one_time: {
    unit: " lifetime access",
    calculateBilling: () => "billed once",
    calculatePrice: (price) => price.toFixed(0),
  },
}

export const queryKeys = {
  ACCOUNT_DETAIL: "account-details",
  ADMIN_EVENTS: "admin-events",
  ATTENDED_EVENTS: "past-events",
  CUSTOMER_PORTAL: "customer-portal",
  DISCUSSION: "discussion",
  EVENT_DETAIL: "event-detail",
  EVENT_DRAFT: "event-draft",
  EVENTS_BY_INTEREST: "events-by-interest",
  EVENTS: "filtered-events",
  FAVORITE_EVENTS: "favorite-events",
  FAVORITE_USERS: "favorite-users",
  FILTERED_USERS: "filtered-users",
  HOME_METRICS: "home-metrics",
  HOSTS: "hosts",
  INTERESTS: "interests",
  MANAGE_EVENT: "manage-event",
  MEMBER_LOOKUP: "member-lookup",
  MEMBERSHIP_DUES: "membership-dues",
  MESSAGES: "messages",
  PROFILE: "profile",
  POST_EDIT: "post-edit",
  TICKET: "ticket",
  USER: "user",
  USER_EVENTS: "user-events",
  USER_HOSTED_EVENTS: "user-hosted-events",
  USER_PROFILE_COMPLETE: "user-profile-complete", // Profile completion fetcher transforms data TODO don't transform results in fetcher, to allow better caching
  USER_PROFILE_DETAIL: "user-profile-detail", // Event details page fetcher transforms data TODO don't transform results in fetcher, to allow better caching
  USER_PROFILE: "user-profile",
  MEMBER_EVENT_ACTIONS: "member-event-actions",
  GEOCODE_ADDRESS: "geocodeAddress",
}

export const CLIENT_HOST =
  process.env.REACT_APP_CLIENT_HOST || "https://www.hellorevel.com"
export const CLIENT_HOST_STAGING_WHEN_DEV =
  envIsDevOrTest() || envIsStaging()
    ? `https://staging.hellorevel.com`
    : CLIENT_HOST
export const AUTH0_ALLOWED_RETURN_URLS = [
  `${CLIENT_HOST}/inbox`,
  `${CLIENT_HOST}/messages`,
  `${CLIENT_HOST}/video-library`,
]

// Used to read query context and display which filters are limiting results the most
export const FILTER_TYPES = {
  GEOSEARCH: "geocodesearch",
}
export const ALGOLIA_MATCH = {
  FULL: "full",
  NONE: "none",
}

export const PRIVACY = {
  FULL: "full",
  INITIAL: "initial",
}

export const MIN_REVEL_AGE = "40"
export const INBOX_POLLING_TIME = 1000 * 60 // every minute

export const BEFORE_ANY_EVENT = new Date("2018-01-1")

export const QUERY_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
}

export const STAR_RATING_COLOR = "#e5866b"
