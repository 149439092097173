import axios from "axios"

import { errorHandler } from "../errors"
import { sendFiltersInit } from "../filters/actions"
import { track } from "../tracking"

export const RSVP_CLICK = "RSVP Click"
export const EVENT_SHARED = "Event Social Share"

export const EVENTS_FETCH_SUCCESS = "events::fetched"
export const ASSOCIATED_EVENT_DATA_FETCH_SUCCESS = "events::fetchedAssociated"
export const INVALIDATE_EVENTS = "events::fetching"

const eventsUrl = `${process.env.REACT_APP_SERVER_HOST}/api/events`
const endpoints = {
  attendees: (id) => `${eventsUrl}/${id}/attendees`,
  associated: (id) => `${eventsUrl}/${id}/associated`,
}

const sendInvalidateEvents = {
  type: INVALIDATE_EVENTS,
}

const sendEventsFetchSuccess = (payload) => ({
  type: EVENTS_FETCH_SUCCESS,
  payload,
})

const sendAssociatedEventDataFetchSuccess = (payload) => ({
  type: ASSOCIATED_EVENT_DATA_FETCH_SUCCESS,
  payload,
})

const eventShared = (payload) => ({
  type: EVENT_SHARED,
  payload,
})

export const shareEvent = (data, dispatch) => {
  track(EVENT_SHARED, data)
  dispatch(eventShared(data))
}

export const fetchEvents = (dispatch) => {
  dispatch(sendInvalidateEvents)

  axios
    .get(eventsUrl)
    .then((response) => response.data)
    .then((data) => {
      dispatch(sendEventsFetchSuccess(data))
      dispatch(sendFiltersInit(data))
    })
    .catch(errorHandler("events::fetch", null, dispatch))
}

export const fetchAssociatedEventData = (id, dispatch) => {
  axios
    .get(endpoints.associated(id))
    .then((response) => response.data)
    .then((data) => dispatch(sendAssociatedEventDataFetchSuccess(data)))
    .catch(errorHandler("events::fetchAssociated", { id }, dispatch))
}
