import React, { forwardRef } from "react"

import useRevelSession from "@hooks/useRevelSession"

import { useImpersonateBannerQuery } from "@graphql/codegen"

import Banner from "@components/Banner"
import { withErrorBoundary } from "@components/ErrorBoundary"
import StopImpersonate from "@components/StopImpersonate"

import "./index.scss"

const ImpersonationBanner = forwardRef(({ style }, ref) => {
  const { userId, isImpersonating, needsLogin } = useRevelSession()

  const { data, loading, called } = useImpersonateBannerQuery({
    skip: needsLogin || !isImpersonating,
  })
  const currentUser = data?.currentUser

  if (loading && !called) {
    return null
  }

  return isImpersonating ? (
    <div data-cy="ImpersonateBanner" className="ImpersonateBanner__Wrapper">
      <Banner
        style={style}
        className="ImpersonateBanner"
        show={isImpersonating}
        ref={ref}
      >
        Careful! You are currently impersonating {currentUser?.fullName}.
        <span data-cy="ImpersonateBanner__StopImpersonateButton">
          <StopImpersonate
            className="ImpersonateBanner__StopImpersonateButton"
            userId={userId}
          >
            EXIT
          </StopImpersonate>
        </span>
      </Banner>
    </div>
  ) : undefined
})

ImpersonationBanner.displayName = "ImpersonationBanner"

export default withErrorBoundary(ImpersonationBanner)
