import React, { forwardRef, useEffect, useMemo, useState } from "react"

import qs from "query-string"
import { useLocation } from "react-router-dom"
import { animated, useSpring } from "react-spring"

import useRevelSession from "@hooks/useRevelSession"

import Banner from "@components/Banner"
import ErrorBoundary from "@components/ErrorBoundary"
import DropdownLinks from "@components/Header/DropdownLinks"
import { headerStyles, REVEL_APP_ICON } from "@components/Header/Header"
import { DelegatedHeaderProps, Ref } from "@components/Header/Header.types"
import NotificationBell from "@components/Header/NotificationWidget/NotificationBell"
import NotificationDropdown from "@components/Header/NotificationWidget/NotificationDropdown"
import { logoLink } from "@components/Header/data"
import Image from "@components/Image"
import { AnchorLink } from "@components/Link"
import LinkToLogin from "@components/LinkToLogin"

import { transformFileStackRoute } from "@services/filestack/transformRoute"
import {
  isAndroidBrowser,
  isiOSBrowser,
  shouldShowAppBanner,
} from "@services/mobileHelpers"
import { disableScroll, enableScroll } from "@services/toggleBodyScroll"

import { ANDROID_APP, IOS_APP } from "@constants/routeConstants"
import { JOIN_REVEL } from "@constants/tracking"

import { ReactComponent as Brand } from "@images/ribbons/brand.svg"
import { ReactComponent as Close } from "@images/ribbons/close.svg"
import { ReactComponent as Hamburger } from "@images/ribbons/hamburger.svg"

export const MobileHeader = forwardRef<Ref, DelegatedHeaderProps>(
  function MobileHeader({ setShowMenu, showMenu, toggleShowMenu }, ref) {
    const { isLoggedIn, sessionReady } = useRevelSession()
    const loggedIn = sessionReady && isLoggedIn
    const [showNotifications, setShowNotifications] = useState(false)

    const { pathname, search, hash } = useLocation()
    const isViewedOnMobileDevice =
      process.env.REACT_APP_FF_MOBILE_APP_ENABLED === "yes" &&
      shouldShowAppBanner()
    let appBannerUrl = `https://www.hellorevel.com/`
    let appBannerCta = "Open in App"
    let params = qs.parse(search)
    const shouldShowInstallLink = params.install && true

    if (isViewedOnMobileDevice) {
      if (!shouldShowInstallLink) {
        const { hostname } = window.location
        const subdomain = hostname.startsWith("www.") ? "m" : "www"
        appBannerUrl = `https://${subdomain}.hellorevel.com${pathname}`
        params = { ...params, install: "1" }
        const queryString = qs.stringify(params)
        if (queryString) {
          appBannerUrl = `${appBannerUrl}?${queryString}${hash}`
        }
      } else {
        appBannerCta = "Install App"
        if (isAndroidBrowser()) {
          appBannerUrl = ANDROID_APP
        } else if (isiOSBrowser()) {
          appBannerUrl = IOS_APP
        }
      }
    }

    const resetScrollAndMenus = useMemo(
      () => () => {
        enableScroll()
        if (setShowMenu) {
          setShowMenu(false)
        }
        setShowNotifications(false)
      },
      [setShowMenu, setShowNotifications],
    )

    // Close the dropdown menus if the url changes (e.g. by clicking
    // a push notification)
    useEffect(() => {
      resetScrollAndMenus()
    }, [pathname, search, resetScrollAndMenus])

    // Wrap our toggles so that each closes the other
    const setNotificationsHook = (
      showHook: (prevState: boolean) => boolean,
    ) => {
      const shouldShow = showHook(showNotifications)
      if (setShowMenu) {
        setShowMenu(false)
      }
      setShowNotifications(shouldShow)

      if (shouldShow) {
        disableScroll()
      } else {
        enableScroll()
      }
    }

    const toggleMenu = () => {
      if (toggleShowMenu) {
        toggleShowMenu()

        if (showMenu) {
          enableScroll()
        } else {
          disableScroll()
        }
      }

      setShowNotifications(false)
    }

    const sharedStyles = (toggle: boolean | undefined) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: refactor to not break rules of hooks
      useSpring({
        display: toggle ? "block" : "none",
        opacity: toggle ? 1 : 0,
      })

    const ToggleImage = showMenu ? Close : Hamburger
    const notifStyles = sharedStyles(showNotifications)
    const profileStyles = sharedStyles(showMenu)

    return (
      <ErrorBoundary>
        <header className="Header__Wrapper" ref={ref}>
          {isViewedOnMobileDevice && (
            <Banner show className="Header__OpenAppBanner">
              <Image
                width="30"
                height="30"
                className="Header__AppIcon"
                src={transformFileStackRoute({
                  src: REVEL_APP_ICON,
                  width: 30,
                  height: 30,
                })}
                alt="Revel App Icon"
              />
              {/* eslint-disable-next-line react/forbid-elements -- allows domain change to reattempt universal linking */}
              <a className="Header__AppLink" href={appBannerUrl}>
                {appBannerCta}
              </a>
            </Banner>
          )}
          <div className="Header__Mobile_Container" style={headerStyles()}>
            <div className="Header__Mobile_Toggle">
              <AnchorLink to={logoLink(loggedIn)} onClick={resetScrollAndMenus}>
                <Brand className="Header__Brand" />
              </AnchorLink>

              <div className="Header__MobileCTAWrapper">
                {loggedIn ? (
                  <NotificationBell setShowHook={setNotificationsHook} />
                ) : (
                  <LinkToLogin
                    isJoining
                    className="Header__MobileJoinBtn"
                    analytics={{
                      event: `Clicked Join`,
                      data: {
                        category: JOIN_REVEL.category,
                        uiLocation: "Header",
                      },
                    }}
                  >
                    <button
                      type="button"
                      onClick={resetScrollAndMenus}
                      className="Header__InnerMobileJoinBtn"
                    >
                      Join
                    </button>
                  </LinkToLogin>
                )}

                <button
                  type="button"
                  onClick={toggleMenu}
                  className="Header__Mobile_Hamburger"
                >
                  <ToggleImage />
                </button>
              </div>
            </div>

            <animated.div
              className={`Header__Mobile_Collapse${
                showNotifications ? "--open" : ""
              }`}
              style={useSpring({
                right: showNotifications ? "0%" : "100%",
              })}
            >
              <animated.div
                style={notifStyles}
                className="Header__Collapse_Relative"
              >
                <NotificationDropdown
                  show={showNotifications}
                  setShowHook={setNotificationsHook}
                  isMobile
                />
              </animated.div>
            </animated.div>

            <animated.div
              className={`Header__Mobile_Collapse${showMenu ? "--open" : ""}`}
              style={useSpring({
                right: showMenu ? "0%" : "100%",
              })}
            >
              <animated.div
                className="Header__Collapse_Relative"
                style={profileStyles}
              >
                <DropdownLinks toggleShow={resetScrollAndMenus} />
              </animated.div>
            </animated.div>
          </div>
        </header>
      </ErrorBoundary>
    )
  },
)
