import { useContext } from "react"

import LayoutContext, {
  LayoutValues,
} from "@providers/LayoutProvider/LayoutContext"

const useLayout = (): LayoutValues => {
  return useContext(LayoutContext)
}

export default useLayout
