import React, { ReactElement, ReactNode } from "react"

import queryString from "query-string"

import { AnchorLink } from "@components/Link"

import { isNativeApp, shareEmail } from "@services/mobileHelpers"

const mailto = ({
  subject,
  body,
  to,
  cc,
  bcc,
}: {
  subject?: string
  body?: string
  to: string[]
  cc: string[]
  bcc: string[]
}) => {
  const mailtoReceiver = encodeURIComponent(to.join(","))
  const mailtoQueryString = queryString.stringify({
    subject,
    body,
    cc,
    bcc,
  })
  return `mailto:${mailtoReceiver}?${mailtoQueryString}`
}

const EmailLink = ({
  subject = "",
  body = "",
  to = [],
  cc = [],
  bcc = [],
  isHtml = false,
  children,
  className = "",
  ...rest
}: {
  subject?: string
  body?: string
  className?: string
  to?: string[] | string
  cc?: string[] | string
  bcc?: string[] | string
  isHtml?: boolean
  children: ReactNode
}): ReactElement<HTMLAnchorElement> => {
  const toArray = Array.isArray(to) ? to : [to]
  const ccArray = Array.isArray(cc) ? cc : [cc]
  const bccArray = Array.isArray(bcc) ? bcc : [bcc]
  const href = mailto({
    subject,
    body,
    to: toArray,
    cc: ccArray,
    bcc: bccArray,
  })
  const onClick = async () => {
    await shareEmail({
      subject,
      body,
      to: toArray,
      cc: ccArray,
      bcc: bccArray,
      isHtml,
      fallbackUrl: href,
    })
  }

  return (
    <AnchorLink
      to={isNativeApp() ? undefined : href}
      onClick={isNativeApp() ? onClick : undefined}
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading -- pass through to `AnchorLink`
      {...rest}
    >
      {children}
    </AnchorLink>
  )
}

EmailLink.defaultProps = {
  bcc: [],
  className: "",
  body: "",
  cc: [],
  isHtml: false,
  subject: "",
  to: [],
}

export default EmailLink
