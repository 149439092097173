import {
  parseISO,
  formatDistanceToNow,
  parse,
  formatDistanceToNowStrict,
  isBefore,
  sub,
} from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"

import { FORMAT_OPTIONS } from "@constants"
import { formats } from "@constants/eventDetail"
import { simpleDate } from "@constants/timeDisplayFormats"

const transform = (date, formatString) => {
  if (!date) {
    return null
  }
  return format(parseISO(date), formatString)
}

export const dayOnly = (date) => {
  return transform(date, "MMM dd")
}

export const dayOfWeek = (date) => {
  return transform(date, "E, MMM dd")
}

export const shortDayOnly = (date) => {
  return transform(date, "M/d")
}

export const timeAgo = (date) => {
  return formatDistanceToNow(parseISO(date), { addSuffix: true })
}

export const timeAgoStrict = (date) => {
  return formatDistanceToNowStrict(parseISO(date), { addSuffix: true })
}

export const formatShortenedDistanceWithCatch = (date) => {
  if (isBefore(new Date(date), sub(new Date(), { days: 7 }))) {
    const formattedDate = format(new Date(date), simpleDate)
    const dateArray = formattedDate.split(" ")
    // Return something like "Oct 15"
    return `${dateArray[0]} ${dateArray[1].substring(
      0,
      dateArray[1].length - 1,
    )}`
  }

  const formattedDate = formatDistanceToNowStrict(new Date(date))
  const dateArray = formattedDate.split(" ")
  // Return something like "12 h"
  const formattedTime = `${dateArray[0]} ${dateArray[1].charAt(0)}`
  return formattedTime === "0 s" ? "now" : formattedTime
}

export const formatDistanceWithCatch = (date, addSuffix = false) => {
  if (!date) return null

  if (isBefore(new Date(date), sub(new Date(), { days: 7 }))) {
    return format(new Date(date), simpleDate)
  }

  const formattedTime = formatDistanceToNowStrict(new Date(date), { addSuffix })

  return formattedTime.includes("second") ? "now" : formattedTime
}

export const shortDate = (date) => {
  return transform(date, "MM/dd/yy")
}

export const standardDate = (date) => {
  return transform(date, "E, MMM d, y")
}

export const formalDate = (date) => {
  return transform(date, "EEEE, MMMM d, y")
}

export const shortTime = (date, tz = false) => {
  let timeFormat = "h:mm b"
  if (tz) {
    timeFormat += " zzz"
  }
  return transform(date, timeFormat)
}

export const year = (date) => {
  return transform(date, "y")
}

export const timeZone = (date) => {
  // date is in UTC time, translate it to being in the correct time zone and then format it
  // we display the long form of the timezone since the short form doesn't work globally
  const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zonedTime = utcToZonedTime(date, myTimeZone)
  return format(zonedTime, "zzzz", { timeZone: myTimeZone })
}

export const convertTimezone = (isoDate, timezone) => {
  const converted = utcToZonedTime(isoDate, timezone)
  return converted.toISOString()
}

export const isValidDate = (date) => {
  // eslint-disable-next-line no-restricted-globals -- util function, `isFinite` accomplishes goal
  return date instanceof Date && isFinite(date)
}

export const formatEventTime = (start, end) => {
  const formattedStart = format(new Date(start), formats.START, FORMAT_OPTIONS)
  const formattedEnd = format(new Date(end), formats.END, FORMAT_OPTIONS)
  return `${formattedStart} - ${formattedEnd}`
}

export const formatEventStickyDate = (
  start,
  dateFormat = formats.STICKY_DATE,
) => {
  const formattedDate = format(new Date(start), dateFormat, FORMAT_OPTIONS)
  return formattedDate
}

export const formatEventStickyTime = (start, end) => {
  const formattedStart = format(
    new Date(start),
    formats.STICKY_START,
    FORMAT_OPTIONS,
  )
  const formattedEnd = format(new Date(end), formats.END, FORMAT_OPTIONS)
  return `${formattedStart} - ${formattedEnd}`
}

export const getEventTimeData = (curStart, curEnd, newDate) => {
  // Get existing start & end times
  const start = format(new Date(curStart), "hh:mm a")
  const end = format(new Date(curEnd), "hh:mm a")

  // Use new date and existing times
  const startTime = parse(start, "hh:mm a", newDate)
  const endTime = parse(end, "hh:mm a", newDate)
  return {
    startTime,
    endTime,
  }
}

export const tzlist = [
  "America/New_York",
  "America/Chicago",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Los_Angeles",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Anchorage",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Phoenix",
  "America/Boise",
  "America/Indiana/Indianapolis",
  "America/Indiana/Marengo",
  "America/Indiana/Vincennes",
  "America/Indiana/Tell_City",
  "America/Indiana/Petersburg",
  "America/Indiana/Knox",
  "America/Indiana/Winamac",
  "America/Indiana/Vevay",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Detroit",
  "America/Menominee",
  "America/St_Johns",
  "America/Goose_Bay",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Thunder_Bay",
  "America/Nipigon",
  "America/Rainy_River",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Vancouver",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Creston",
  "America/Pangnirtung",
  "America/Iqaluit",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Whitehorse",
  "America/Dawson",
  "America/Cancun",
  "America/Merida",
  "America/Matamoros",
  "America/Monterrey",
  "America/Mexico_City",
  "America/Ojinaga",
  "America/Chihuahua",
  "America/Hermosillo",
  "America/Mazatlan",
  "America/Bahia_Banderas",
  "America/Tijuana",
  "America/Nassau",
  "America/Barbados",
  "America/Belize",
  "Atlantic/Bermuda",
  "America/Costa_Rica",
  "America/Havana",
  "America/Santo_Domingo",
  "America/El_Salvador",
  "America/Guatemala",
  "America/Port-au-Prince",
  "America/Tegucigalpa",
  "America/Jamaica",
  "America/Martinique",
  "America/Managua",
  "America/Panama",
  "America/Cayman",
  "America/Puerto_Rico",
  "America/Miquelon",
  "America/Grand_Turk",
]

export const timezones = tzlist
  .sort((a, b) => {
    const offsetA = Number(format(new Date(), "xx", { timeZone: a }))
    const offsetB = Number(format(new Date(), "xx", { timeZone: b }))
    return offsetA - offsetB
  })
  .reduce((tzs, tz) => {
    const shortTz = tz.split("/").slice(1).join(" - ")
    const label = shortTz.replace(/_/g, " ")

    const offset = format(new Date(), "zzz", { timeZone: tz })

    // eslint-disable-next-line no-param-reassign -- util function, most concise way to do this
    tzs[tz] = { label, offset }

    return tzs
  }, {})
