import { ICache } from "@auth0/auth0-react"
import { GetResult, Preferences } from "@capacitor/preferences"

const PREFERENCES_FCM_TOKEN_KEY = "__revel_fcmToken"

/**
 * Get fcmToken from app preferences storage
 */
export const getFcmToken = (): Promise<GetResult> =>
  Preferences.get({ key: PREFERENCES_FCM_TOKEN_KEY })

/**
 * Save fcmToken to app preferences storage upon login/update
 */
export const setFcmToken = (value: string): Promise<void> =>
  Preferences.set({ key: PREFERENCES_FCM_TOKEN_KEY, value })

/**
 * Remove fcmToken from app preferences storage upon logout
 */
export const removeFcmToken = (): Promise<void> =>
  Preferences.remove({ key: PREFERENCES_FCM_TOKEN_KEY })

/**
 * Clear all values in app preferences. Used by ErrorBoundary to try and
 * fix hard to solve issues on the mobile app.
 */
export const clearStorage = async (): Promise<void> => Preferences.clear()

/**
 * Cache interface for Auth0Provider, uses app preferences on mobile and
 * localstorage on web.
 */
export const auth0CacheInterface: ICache = {
  async get(key) {
    return Preferences.get({ key }).then(({ value }) => {
      return JSON.parse(value || "{}")
    })
  },
  async set(key, value) {
    await Preferences.set({
      key,
      value: JSON.stringify(value),
    })
  },
  async remove(key) {
    await Preferences.remove({ key })
  },
  async allKeys() {
    return Preferences.keys().then(({ keys }) => {
      return keys
    })
  },
}
