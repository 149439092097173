import { useEffect } from "react"

const useDocumentEvent = (events, dependencies = []) => {
  useEffect(() => {
    events.forEach((event) => {
      document.addEventListener(event.type, event.callback)
    })

    // Effect hooks can return either a cleanup function, or nothing
    return () =>
      events.forEach((event) => {
        document.removeEventListener(event.type, event.callback)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [events, ...dependencies])
}

export default useDocumentEvent
