import { useEffect } from "react"

import useAnalyticsSettings from "@hooks/useAnalyticsSettings"

import { SegmentAnalytics } from "@plugins/CapacitorSegmentPlugin"

import { isAndroid, isiOS } from "@services/mobileHelpers"
import { sendQueuedEvents } from "@services/tracking"

const segmentWriteKey = () => {
  if (isiOS()) {
    return process.env.REACT_APP_SEGMENT_IOS_WRITE_KEY
  }
  if (isAndroid()) {
    return process.env.REACT_APP_SEGMENT_ANDROID_WRITE_KEY
  }
  return process.env.REACT_APP_SEGMENT_WRITE_KEY
}

const setupAnalytics = (integrations) => {
  const writeKey = segmentWriteKey()
  if (writeKey) {
    const initialize = async () => {
      try {
        await SegmentAnalytics.initialize({
          writeKey,
          trackLifecycleEvents: true,
          recordScreenViews: false,
          options: {
            integrations,
          },
        })
        await sendQueuedEvents()
      } catch (e) {
        console.error(e)
      }
    }
    initialize()
  }
}

const useAnalytics = () => {
  const {
    analyticsActive,
    adPerformanceActive,
    bannerDismissed,
    hashedUserId,
    mobileTrackingEnabled,
    integrations,
  } = useAnalyticsSettings()
  window.mobileTrackingEnabled = mobileTrackingEnabled
  useEffect(() => {
    if (
      analyticsActive ||
      adPerformanceActive ||
      hashedUserId ||
      mobileTrackingEnabled
    ) {
      setupAnalytics(integrations)
    }
  }, [
    analyticsActive,
    adPerformanceActive,
    bannerDismissed,
    hashedUserId,
    mobileTrackingEnabled,
    integrations,
  ])
}

export default useAnalytics
