import React, { ReactElement, ReactNode } from "react"

import { useLocation } from "react-router-dom"

import AnchorLink, { AnalyticsProps } from "@components/Link/AnchorLink"
import LinkWithReturn from "@components/LinkWithReturn"

import {
  LOGIN,
  LOGIN_VALID_RETURN_PATHS,
  getPath,
  JOIN,
} from "@constants/routeConstants"

// Links the user to /login or /join if isJoining is true.
// Sets a returnTo value in the url to be used by the page it links to.
// Uses the provided returnTo value and otherwise defaults to the current
// route in most cases. Does not return to pages not meant for logged in users
// (/login, /join), or unknown routes.
// Everywhere that links to /login or /join should use this component!
const LinkToLogin = ({
  analytics,
  children,
  className = "",
  returnTo,
  isJoining = false,
  preserveSearch = true,
}: {
  analytics?: AnalyticsProps
  children: ReactNode
  className?: string
  returnTo?: string
  isJoining?: boolean
  preserveSearch?: boolean
}): ReactElement | null => {
  const location = useLocation()
  // If there are no children this link won't show up anyways
  if (!children) {
    return null
  }

  // Use the passed returnTo if provided, otherwise default to the current
  // path if it's a valid returnTo
  const to = isJoining ? JOIN : LOGIN
  let returnPath = null
  if (returnTo) {
    returnPath = returnTo
  } else if (
    LOGIN_VALID_RETURN_PATHS.find((path) => location.pathname.startsWith(path))
  ) {
    returnPath = getPath(preserveSearch)
  }

  if (returnPath) {
    return (
      <LinkWithReturn
        analytics={analytics}
        className={className}
        returnTo={returnPath}
        to={to}
      >
        {children}
      </LinkWithReturn>
    )
  }
  return (
    <AnchorLink analytics={analytics} to={to} className={className}>
      {children}
    </AnchorLink>
  )
}

export default LinkToLogin
