import React from "react"

import { Auth0Provider } from "@auth0/auth0-react"
import qs from "query-string"
import { useNavigate } from "react-router-dom"

import { isNativeApp } from "@services/mobileHelpers"
import { auth0CacheInterface } from "@services/storage"

import { AUTH0_ALLOWED_RETURN_URLS, CLIENT_HOST } from "@constants"
import { DASHBOARD, MOBILE_CALLBACK } from "@constants/routeConstants"

const isExternalUrlValid = (url) => {
  // Ignore query params for forums purposes
  const hostAndPathname = url.split("?")[0]
  const { hostname } = new URL(url)
  const isRevelDomain = CLIENT_HOST.includes(hostname)

  return isRevelDomain || AUTH0_ALLOWED_RETURN_URLS.includes(hostAndPathname)
}

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate()
  const isMobileApp = isNativeApp()

  // Handles auth0 redirect after authentication
  const onRedirectCallback = (appState) => {
    // Set aside references for the final redirect state
    const destination = appState.returnTo ?? ""
    let search = ""

    // The provided returnTo can include the search part of the url, so
    // pull it out because we need to pass it separately
    const splitDestination = destination.split("?")
    let pathname = splitDestination[0]
    const queryParams = qs.parse(splitDestination[1])

    const isExternal = destination.includes("http")

    // Check validity of redirect
    const isValid = !isExternal || isExternalUrlValid(destination)

    // Attach any forwarded params to the query
    // `origin` acts as an open redirect
    const forwardedParams = ["referralToken", "origin"]
    forwardedParams.forEach((param) => {
      if (!appState[param]) {
        return
      }

      queryParams[param] = appState[param]
    })

    // Populate the search object, if possible
    if (Object.keys(queryParams).length > 0) {
      search = qs.stringify(queryParams, { addQueryPrefix: true })
    }

    // Handle external paths
    if (isExternal && isValid) {
      window.location.href = destination
      window.location.search = search
    }

    // Handle invalid external urls
    if (!isValid) {
      pathname = window.location.pathname
    }

    navigate({ pathname, search }, { replace: true })
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={
        isMobileApp
          ? process.env.REACT_APP_AUTH0_MOBILE_CLIENT_ID
          : process.env.REACT_APP_AUTH0_CLIENT_ID
      }
      redirectUri={
        isMobileApp ? MOBILE_CALLBACK : window.location.origin + DASHBOARD
      } // CF worker url re-write on root path necessitates a specific callback path
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cache={auth0CacheInterface}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
