import { useEffect } from "react"

import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics"
import StackTrace from "stacktrace-js"

import useRevelSession from "@hooks/useRevelSession"

import { isiOS, isNativeApp } from "@services/mobileHelpers"
import snackbar from "@services/notifications/snackbar"
import {
  successSnack,
  warningSnack,
  errorSnack,
} from "@services/notifications/snacks"

import { envIsProd } from "@constants/environment"

/*
You must also set up these prices MANUALLY for the mobile apps:
  - You should also update the staging app equivalents of the following FIRST!
  - Apple App Store Connect (see script at revel-frontend/fastlane/ruby-scripts/add_apple_app_store_iap_products.rb):
    https://appstoreconnect.apple.com/apps/1605040973/appstore/addons/1610564754
  - Google Play Developer Console (manual process):
    1. Go to in-app products for the app, export current values, import into Google Sheets or somewhere to edit
    https://play.google.com/console/u/0/developers/5047504387140725262/app/4972571149428780840/managed-products
    2. Go to price templates for the account, add each price point with name matching current formatting (do not use
    cents, eg. 5, not 5.00 or 4.99) as a new price template. Auto-fill other currencies. Do not check any boxes.
    3. Copy price template ID after saving.
    4. Add new row for price point to Google Sheet, fill in rest of info based on info above. Download as csv,
    upload to app on using "import" button next to "export" button in step 1.
  ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️ ☝️
 */
export const EXPERT_PRICE_POINTS = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100, 110, 120, 125, 150, 175, 200, 250,
]

export const BUNDLE_PREFIX = envIsProd()
  ? "com.hellorevel.mobile"
  : "com.hellorevel.mobile.staging"

export const iapProduct = (pp) => {
  const price = parseInt(pp, 10)
  return {
    value: pp,
    label: `$${price}`,
    iap: {
      key: `${BUNDLE_PREFIX}.expert.${price}`,
      alias: `expert $${price}`,
    },
  }
}

const iapProducts = (pricePoints = EXPERT_PRICE_POINTS) => {
  return pricePoints.map((pp) => iapProduct(pp))
}

export const IAP_PRODUCTS = iapProducts()

export const startInAppPurchase = async (
  price,
  eventId,
  createEventCheckoutSessionWithVariables,
  close,
) => {
  const paymentIdPrefix = isiOS() ? "apple" : "google"
  const { store } = window
  const alias = iapProduct(price).iap.key
  store.log.debug(`📃 prepareInAppPurchase: ${alias}`)
  const approvedProduct = (p) => {
    // payment completed, id ready
    const paymentId = `${paymentIdPrefix}:${p.transaction.id}`
    snackbar.enqueue(successSnack("Validating your purchase..."))
    // update pending record with payment ID
    createEventCheckoutSessionWithVariables({
      paymentId,
    })
  }
  const verifiedProduct = (product) => {
    snackbar.enqueue(successSnack("Success! See you soon! 🎉"))
    store.log.debug(`🏁 FINISHED: ${JSON.stringify(product)}`)
    /* eslint-disable no-use-before-define -- TODO: try refactoring this to remove, maybe more stable? (less dupe snackbars) */
    store.off(cancelledProduct)
    store.off(erroredProduct)
    /* eslint-enable no-use-before-define -- TODO: try refactoring this to remove, maybe more stable? (less dupe snackbars) */
    close(true)
  }
  const cancelledProduct = (p) => {
    store.log.debug(`🙅 CANCELLED: ${JSON.stringify(p)}`)
    snackbar.enqueue(warningSnack("Your purchase has been cancelled"))
    store.off(approvedProduct)
    store.off(verifiedProduct)
    // eslint-disable-next-line no-use-before-define -- TODO: try refactoring this to remove, maybe more stable? (less dupe snackbars)
    store.off(erroredProduct)
    close(false)
  }
  const erroredProduct = async (error) => {
    const stacktrace = await StackTrace.fromError(error)
    await FirebaseCrashlytics.recordException({
      message: `${error.name}: ${error.message}`,
      stacktrace,
    })
    if (envIsProd()) {
      snackbar.enqueue(errorSnack(`There was a problem with your payment`))
    } else {
      const message = `ERROR ${error.code}: ${error.message}`
      console.error(`🪦 ${message}`)
      snackbar.enqueue(errorSnack(message))
    }
    store.off(approvedProduct)
    store.off(verifiedProduct)
    store.off(cancelledProduct)
  }
  store.once(alias).cancelled(cancelledProduct)
  store.once(alias).approved(approvedProduct)
  store.once(alias).verified(verifiedProduct)
  store.once(alias).error(erroredProduct)

  store.log.debug(`💳 startInAppPurchase: ${alias}`)
  // create pending record with no payment ID so that we can recover if needed
  return createEventCheckoutSessionWithVariables({
    paymentId: paymentIdPrefix,
  }).then(() => store.order(alias))
}

// Sets up in-app purchases listeners on initial load if the user is
// accessing Revel from the app
const useInAppPurchases = () => {
  const { isLoggedIn, userId } = useRevelSession()

  useEffect(() => {
    // Only do this if we're running the mobile app
    // and the user is logged in
    if (isLoggedIn && isNativeApp()) {
      const { store } = window
      store.applicationUsername = () => userId
      store.validator = process.env.REACT_APP_FOVEA_VALIDATOR_URL

      store
        .when("product")
        .valid((p) => {
          store.log.debug(`✅ VALID: ${JSON.stringify(p)}`)
        })
        .invalid((p) => {
          store.log.debug(`❌ INVALID: ${JSON.stringify(p)}`)
        })
        .approved((p) => {
          if (p.type === "consumable") {
            const paymentId = `${isiOS() ? "apple" : "google"}:${
              p.transaction.id
            }`
            store.log.debug(`💰 APPROVED (${paymentId}): ${JSON.stringify(p)}`)
            p.verify()
          }
        })
        .verified((p) => {
          if (p.type === "consumable") {
            store.log.debug(`🕵️‍ VERIFIED: ${JSON.stringify(p)}`)
            p.finish()
          }
        })
        .owned((p) => {
          store.log.debug(`🧸 OWNED: ${JSON.stringify(p)}`)
        })

      IAP_PRODUCTS.forEach(({ iap: { key, alias } }) => {
        store.register({
          id: key,
          alias,
          type: store.CONSUMABLE,
        })
        store.log.debug(`📋 REGISTERED: ${key}`)
      })

      store.refresh().finished((s) => {
        store.log.debug(`♻️ REFRESHED: ${s}`)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  -- TODO: update this message or remove and add deps
  }, [isLoggedIn])
}

export default useInAppPurchases
