export const POST_FOCUS = "posts::focusPost"
export const POST_SET_POSTING = "posts::setPosting"
export const POST_SET_REPLYING_TO = "posts::setReplyingTo"
export const POST_SET_EDIT_MODE = "posts::setEdit"

export const focusPost = (id) => ({
  type: POST_FOCUS,
  payload: id,
})

export const setPosting = (data) => ({
  type: POST_SET_POSTING,
  payload: data,
})

export const setReplyingTo = (data) => ({
  type: POST_SET_REPLYING_TO,
  payload: data,
})

export const setEditing = (data) => ({
  type: POST_SET_EDIT_MODE,
  payload: data,
})
