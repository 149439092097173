import React, { useState, useEffect, useMemo } from "react"

import { App } from "@capacitor/app"
import { Preferences } from "@capacitor/preferences"
import differenceInSeconds from "date-fns/differenceInSeconds"
import formatISO from "date-fns/formatISO"
import isValid from "date-fns/isValid"
import parseISO from "date-fns/parseISO"
import debounce from "lodash/debounce"
import { useLocation } from "react-router-dom"

import useRevelSession from "@hooks/useRevelSession"

import { VersionStatus, useVersionCheckMutation } from "@graphql/codegen"

import { withErrorBoundary } from "@components/ErrorBoundary"

import { isNativeApp } from "@services/mobileHelpers"
import { track } from "@services/tracking"

import UseVersionCheckContext, {
  useVersionCheck,
} from "./UseVersionCheckContext"

const generatePrettyVersion = ({
  appVersion,
  bundleVersion,
}: {
  appVersion?: string | null
  bundleVersion?: string | null
}) => {
  if (isNativeApp()) {
    if (appVersion && bundleVersion) {
      return `v${appVersion}~v${bundleVersion}`
    }
  } else if (bundleVersion) {
    return `v${bundleVersion}`
  }

  return null
}

// This is the key used to store results in app storage / local storage
const PREFERENCES_KEY = "RevelVersionCheck"

// Run this check every 15 minutes
const SECONDS_BETWEEN_CHECKS = 15 * 60

type UseVersionProviderProps = {
  children?: React.ReactNode | null
}
const UseVersionProvider = ({
  children,
}: UseVersionProviderProps): React.ReactElement => {
  const location = useLocation()
  const { userId } = useRevelSession()
  const [appVersion, setAppVersion] = useState<string | null>()
  const [bundleVersion, setBundleVersion] = useState<string | null>()
  const [checkedAt, setCheckedAt] = useState<Date>(new Date())
  const [appVersionStatus, setAppVersionStatus] =
    useState<VersionStatus | null>()
  const [bundleVersionStatus, setBundleVersionStatus] =
    useState<VersionStatus | null>()
  const [versionMessage, setVersionMessage] = useState<string | null>()
  const [executeVersionCheck, versionCheck] = useVersionCheckMutation({
    onCompleted: (data) => {
      const now = new Date()
      const check = data?.version?.check
      setAppVersionStatus(check?.app ?? null)
      setBundleVersionStatus(check?.bundle ?? null)
      setVersionMessage(check?.message ?? null)
      setCheckedAt(now)

      // Analytics to track rejected versions
      if (
        check?.app === VersionStatus.Rejected ||
        check?.bundle === VersionStatus.Rejected
      ) {
        track("App/Bundle Version is deprecated", {
          userId,
          appVersion,
          bundleVersion,
          appVersionStatus: check?.app,
          bundleVersionStatus: check?.bundle,
        })
      }

      // Store check results
      const serialized = JSON.stringify({
        checkedAt: formatISO(now),
        appVersion,
        bundleVersion,
        appVersionStatus: check?.app,
        bundleVersionStatus: check?.bundle,
        versionMessage: check?.message,
      })
      Preferences.set({ key: PREFERENCES_KEY, value: serialized })
    },
  })
  const doVersionCheck = debounce(executeVersionCheck, 10000, {
    leading: false,
    trailing: true,
  })
  const loadCachedResults = debounce(
    ({ currentAppVersion, currentBundleVersion }) => {
      // Load cached results from storage
      // Set in a debounce to delay fetching for a set time,
      // in the hopes that the page is fully loaded by the time we run this logic
      Preferences.get({ key: PREFERENCES_KEY }).then(({ value }) => {
        try {
          const stored = value ? JSON.parse(value) : {}
          const checked = stored?.checkedAt ? parseISO(stored?.checkedAt) : null

          // If the stored results are based on a different app version, we can't trust them
          // Otherwise we might still show the "app not supported page" when the user has already updated,
          // but the old status is still cached
          let shouldSetCheckedAt = false
          if (currentAppVersion && stored?.appVersion === currentAppVersion) {
            setAppVersionStatus(stored?.appVersionStatus)
            setVersionMessage(stored?.versionMessage)
            shouldSetCheckedAt = true
          }
          if (
            currentBundleVersion &&
            stored?.bundleVersion === currentBundleVersion
          ) {
            setBundleVersionStatus(stored?.bundleVersionStatus)
            setVersionMessage(stored?.versionMessage)
            shouldSetCheckedAt = true
          }

          if (shouldSetCheckedAt) {
            setCheckedAt((isValid(checked) && checked) || new Date(0))
          } else {
            setCheckedAt(new Date(0))
          }
        } catch {
          Preferences.remove({ key: PREFERENCES_KEY })
          setCheckedAt(new Date(0))
        }
      })
    },
    5000,
    { leading: false, trailing: true },
  )

  useEffect(() => {
    // Load current versions
    const currentBundleVersion = process.env.REACT_APP_BUNDLE_VERSION || null
    setBundleVersion(currentBundleVersion)

    if (isNativeApp()) {
      App.getInfo().then(({ version }) => {
        if (version) {
          setAppVersion(version)
          loadCachedResults({
            currentAppVersion: version,
            currentBundleVersion,
          })
        } else {
          setAppVersion(null)
          loadCachedResults({
            currentAppVersion: null,
            currentBundleVersion,
          })
        }
      })
    } else {
      loadCachedResults({
        currentAppVersion: null,
        currentBundleVersion,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [])

  useEffect(() => {
    // Check if we should make a new call to check the version

    if (versionCheck.loading) {
      // There's a check in flight
      return
    }

    const now = new Date()
    if (differenceInSeconds(now, checkedAt) < SECONDS_BETWEEN_CHECKS) {
      // The latest check was recent enough; don't recheck
      return
    }

    if (!bundleVersion) {
      // We don't have a bundle version, we can't run a check
      return
    }

    if (isNativeApp() && !appVersion) {
      // We expect to have an app version but don't have one yet
      return
    }

    doVersionCheck({
      variables: {
        app: appVersion || null,
        bundle: bundleVersion || null,
      },
    })
    setCheckedAt(now)
  }, [
    doVersionCheck,
    versionCheck,
    checkedAt,
    appVersion,
    bundleVersion,

    // When user navigates run this hook to see if we need to schedule a version check.
    // This is a convenient way to make sure the check happens regularly as she's browsing.
    location,
  ])

  const values = useMemo(
    () => ({
      versionReady: !!(appVersion || bundleVersion),
      appVersion: appVersion || null,
      bundleVersion: bundleVersion || null,
      appVersionStatus: appVersionStatus || null,
      bundleVersionStatus: bundleVersionStatus || null,
      versionMessage: versionMessage || null,
      bundleVersionRejected: bundleVersionStatus === VersionStatus.Rejected,
      appVersionRejected:
        isNativeApp() && appVersionStatus === VersionStatus.Rejected,
      prettyVersion: generatePrettyVersion({ appVersion, bundleVersion }),
    }),
    [
      appVersion,
      bundleVersion,
      appVersionStatus,
      bundleVersionStatus,
      versionMessage,
    ],
  )

  return (
    <UseVersionCheckContext.Provider value={values}>
      {children}
    </UseVersionCheckContext.Provider>
  )
}

export default withErrorBoundary(UseVersionProvider)
export { useVersionCheck }
