import React, { ReactElement } from "react"

import { useLocation } from "react-router-dom"

import useLayout from "@hooks/useLayout"
import useRevelSession from "@hooks/useRevelSession"

import { useInboxButtonQuery } from "@graphql/codegen"

import { withErrorBoundary } from "@components/ErrorBoundary"
import { AnchorLink } from "@components/Link"

import { INBOX_POLLING_TIME } from "@constants"
import { INBOX, JOIN } from "@constants/routeConstants"

import { ReactComponent as InboxIcon } from "../assets/inbox.svg"

import "../Header.scss"

const InboxButton = ({
  iconClass = "",
}: {
  iconClass?: string
}): ReactElement => {
  const { tabletDown } = useLayout()
  const { isLoggedIn, needsLogin } = useRevelSession()
  const { pathname } = useLocation()
  const isJoinPage = pathname.includes(JOIN)

  const { data } = useInboxButtonQuery({
    skip: needsLogin || isJoinPage,
    // Poll every 1s while the document is focused
    pollInterval: isLoggedIn && document.hasFocus() ? INBOX_POLLING_TIME : 0,
  })

  const count = data?.inbox?.unreadMessages?.totalCount ?? 0

  return (
    <AnchorLink to={INBOX} className="Header__NotificationInner">
      {tabletDown ? (
        <div className={iconClass}>
          <InboxIcon />
          <span className="Header__MobileFooterLabel">Messages</span>
        </div>
      ) : (
        <InboxIcon className="Header__InboxIcon" title="View your messages" />
      )}
      {count > 0 && (
        <div className="Header__InboxCount">
          <span>{count > 9 ? "9+" : count}</span>
        </div>
      )}
    </AnchorLink>
  )
}

export default withErrorBoundary(InboxButton)
