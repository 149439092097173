import { useEffect } from "react"

import { useLocation } from "react-router-dom"

import { pageView, track } from "@services/tracking"

import {
  ACCOUNT_SETTINGS,
  CREATE_EVENT,
  DASHBOARD,
  DIRECTORY,
  EVENT_DETAIL,
  EVENTS_CALENDAR,
  EVENTS,
  FAQ,
  JOIN,
  LOGIN,
  LOGOUT,
  MANAGE_EVENTS,
  POST_JOIN,
  PROFILE,
  REFERRAL,
  STYLE_GUIDE,
  INBOX,
  GROUPS,
  GROUP_DETAIL,
} from "@constants/routeConstants"

const PAGE_VIEW_CATEGORY = "Page view"
// Need a separate constant for profile view because otherwise it matches
// editing profile as well
const VIEW_PROFILE_PREFIX = `${PROFILE}auth0`

// This hook will only track page views on these pages
const PAGES_TO_TRACK = [
  ACCOUNT_SETTINGS,
  CREATE_EVENT,
  DASHBOARD,
  DIRECTORY,
  EVENT_DETAIL,
  EVENTS_CALENDAR,
  EVENTS,
  FAQ,
  JOIN,
  LOGIN,
  LOGOUT,
  MANAGE_EVENTS,
  POST_JOIN,
  VIEW_PROFILE_PREFIX,
  REFERRAL,
  STYLE_GUIDE,
  INBOX,
  GROUPS,
  GROUP_DETAIL,
]

// These pages should ignore any specificity in their url to a single
// event/person/etc and instead be treated as the same.  For example event
// details pages should all be treated as the same page.
const GROUPED_PAGES = [
  { url: CREATE_EVENT, label: CREATE_EVENT },
  { url: EVENT_DETAIL, label: "event detail" },
  { url: VIEW_PROFILE_PREFIX, label: "view profile" },
  { url: GROUP_DETAIL, label: "group detail" },
]

// Logs the page views of the given pathname on segment if it's in
// PAGES_TO_TRACK
const usePageAnalytics = () => {
  const { pathname } = useLocation()
  const pageGroup = GROUPED_PAGES.find(({ url: groupPrefix }) => {
    // Ensure the url isn't the base prefix url
    const pathnamePattern = new RegExp(`.*${groupPrefix}.+`)
    return pathname.match(pathnamePattern)
  })

  useEffect(() => {
    // Only track the event if we want to track the current url
    if (
      (!pageGroup && PAGES_TO_TRACK.includes(pathname)) ||
      (pageGroup && PAGES_TO_TRACK.includes(pageGroup.url))
    ) {
      const label = pageGroup ? pageGroup.label : pathname

      track(`Visited ${label} page`, {
        category: PAGE_VIEW_CATEGORY,
      })
      pageView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [pathname])
}

export default usePageAnalytics
