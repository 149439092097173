import React, { MutableRefObject, useCallback, useRef } from "react"

import { parseISO } from "date-fns"
import reverse from "lodash/reverse"
import sortBy from "lodash/sortBy"

import useDocumentEvent from "@hooks/useDocumentEvent"
import useLayout from "@hooks/useLayout"
import useNotifications from "@hooks/useNotifications"

import {
  useNotificationsMarkAllAsReadMutation,
  Notification as NotificationType,
} from "@graphql/codegen"

import { SetShowHook } from "@components/Header/Header.types"
import { AnchorLink } from "@components/Link"
import { NotificationsProvider } from "@providers/NotificationsProvider"

import cn from "@services/joinStyles"

import Notification from "./Notification"

import styles from "@views/Notifications/index.module.scss"

export interface NotificationDropdownProps {
  isMobile?: boolean
  setShowHook: SetShowHook
  show: boolean
}

const InnerNotificationDropdown = ({
  isMobile,
  setShowHook,
  show,
}: NotificationDropdownProps): JSX.Element => {
  const { tabletDown } = useLayout()
  const notifications = useNotifications()
  const MAX_NOTIFICATION_PER_SOURCE = tabletDown ? 3 : 2

  // Ensure that at most MAX_NOTIFICATION_PER_SOURCE x 2 notifications are shown in the dropdown
  // The notification sources can have variable lengths but would sum up to at most MAX_NOTIFICATION_PER_SOURCE x 2
  const computeNotificationsToShow = (nodes: {
    Events: NotificationType[]
    Groups: NotificationType[]
  }) => {
    const eventsNotificationsToShow: NotificationType[] = []
    const groupsNotificationsToShow: NotificationType[] = []
    for (let i = 0; i < MAX_NOTIFICATION_PER_SOURCE * 2; i++) {
      if (
        groupsNotificationsToShow.length + eventsNotificationsToShow.length ===
        MAX_NOTIFICATION_PER_SOURCE * 2
      ) {
        break
      }
      if (nodes.Groups[i]) {
        groupsNotificationsToShow.push(nodes.Groups[i])
      }
      if (nodes.Events[i]) {
        eventsNotificationsToShow.push(nodes.Events[i])
      }
    }
    return {
      Events: eventsNotificationsToShow,
      Groups: groupsNotificationsToShow,
    }
  }

  const notificationsToShow = {
    ...notifications,
    nodes: computeNotificationsToShow(notifications.nodes),
  }

  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const [markAllAsRead] = useNotificationsMarkAllAsReadMutation({
    update: (cache, { data: mutationData }) => {
      const notificationsData =
        mutationData?.notification?.markAllAsRead?.notifications
      if (!notificationsData) return

      cache.modify({
        id: cache.identify(notificationsData),
        fields: {
          unreadCount: () => 0,
        },
      })
    },
    optimisticResponse: {
      notification: {
        markAllAsRead: {
          notifications: {
            id: notifications.id,
            unreadCount: 0,
          },
        },
      },
    },
  })

  const closeNotificationWidget = useCallback(() => {
    if (show) {
      setShowHook(() => false)
    }
  }, [setShowHook, show])

  const handleOutsideClick = useCallback(
    (event: Event & { target: HTMLInputElement }) => {
      if (isMobile) return
      if (!ref.current) return
      if (ref.current.contains(event.target)) return

      closeNotificationWidget()
    },
    [closeNotificationWidget, isMobile],
  )

  const handleEscape = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== "Escape") return

      closeNotificationWidget()
    },
    [closeNotificationWidget],
  )

  useDocumentEvent([
    { type: "click", callback: handleOutsideClick },
    { type: "keydown", callback: handleEscape },
  ])

  if (!show) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- fragment gives clean empty dropdown
    return <></>
  }

  const EventsNotificationComponents =
    reverse(
      sortBy(notificationsToShow.nodes.Events, (node) => {
        return parseISO(node.updatedAt)
      }),
    ).map((node, index) => (
      <Notification
        index={index}
        key={node.id}
        notification={node}
        closeNotificationWidget={closeNotificationWidget}
      />
    )) ?? []

  const GroupsNotificationComponents =
    reverse(
      sortBy(notificationsToShow.nodes.Groups, (node) => {
        return parseISO(node.updatedAt)
      }),
    ).map((node, index) => (
      <Notification
        index={index}
        key={node.id}
        notification={node}
        closeNotificationWidget={closeNotificationWidget}
      />
    )) ?? []

  return (
    <div className={cn((!isMobile && "HeaderDropdown__Container") || "")}>
      <div
        className="HeaderDropdown__Inner"
        ref={ref}
        data-cy="notifications:dropdown"
      >
        <div className="HeaderDropdown__Header">
          <h4 className="HeaderDropdown__Title">Notifications</h4>
          <button
            type="button"
            className="HeaderDropdown__MarkAllAsRead"
            onClick={() => markAllAsRead()}
          >
            Mark all as read
          </button>
        </div>
        <div className="HeaderDropdown__Content">
          {!!EventsNotificationComponents.length && (
            <div className="HeaderDropdown__Section HeaderDropdown__Section__Events">
              <h5 className="HeaderDropdown__SectionTitle">
                Event Activity
                {!!notifications.unreadEventsCount && (
                  <span className={styles.Unread}>
                    {" "}
                    ({notifications.unreadEventsCount} unread)
                  </span>
                )}
              </h5>
              {EventsNotificationComponents}
            </div>
          )}
          {!!GroupsNotificationComponents.length && (
            <div className="HeaderDropdown__Section HeaderDropdown__Section__Groups">
              <h5 className="HeaderDropdown__SectionTitle">
                Group Activity
                {!!notifications.unreadGroupsCount && (
                  <span className={styles.Unread}>
                    {" "}
                    ({notifications.unreadGroupsCount} unread)
                  </span>
                )}
              </h5>
              {GroupsNotificationComponents}
            </div>
          )}
        </div>
        {!EventsNotificationComponents.length &&
          !GroupsNotificationComponents.length && (
            <span className="HeaderDropdown__NoResults">
              You have no notifications yet.
            </span>
          )}
        {(!!EventsNotificationComponents.length ||
          !!GroupsNotificationComponents.length) && (
          <div
            className="HeaderDropdown__Footer"
            data-cy="notifications:footer"
          >
            <AnchorLink
              to="/notifications"
              className="HeaderDropdown__FooterLink"
              onClick={closeNotificationWidget}
              data-cy="notifications:seeAll"
            >
              See all notifications
            </AnchorLink>
          </div>
        )}
      </div>
    </div>
  )
}

const NotificationDropdown = ({
  isMobile,
  setShowHook,
  show,
}: NotificationDropdownProps): JSX.Element => (
  <NotificationsProvider>
    <InnerNotificationDropdown
      isMobile={isMobile}
      setShowHook={setShowHook}
      show={show}
    />
  </NotificationsProvider>
)

export default NotificationDropdown
