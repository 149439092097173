import React, {
  useState,
  useMemo,
  ImgHTMLAttributes,
  CSSProperties,
  ReactElement,
  useCallback,
} from "react"

import { envIsTest } from "@constants/environment"

import { ReactComponent as UserIcon } from "@images/defaults/user.svg"
import { ReactComponent as HeartIcon } from "@images/reactions/heart.svg"
import { ReactComponent as LaughingIcon } from "@images/reactions/laughing.svg"
import { ReactComponent as LikeIcon } from "@images/reactions/like.svg"
import { ReactComponent as SparkleIcon } from "@images/reactions/sparkle.svg"

import styles from "./index.module.scss"

const DefaultEventImagePath = "../assets/images/defaults/event.png"

function getRandomInt(min: number, max: number) {
  const ceilMin = Math.ceil(min)
  const flooredMax = Math.floor(max)
  return Math.floor(Math.random() * (flooredMax - ceilMin + 1)) + ceilMin
}

const Icons = [HeartIcon, LaughingIcon, LikeIcon, SparkleIcon]

const Image = ({
  alt = "Loading for Revel",
  width = "auto",
  height = "auto",
  className,
  dataCy = "",
  defaultImagePath = "",
  loading = "lazy",
  loadingStyle = {},
  src,
  style = {},
  user = false,
}: ImgHTMLAttributes<HTMLImageElement> & {
  dataCy?: string
  defaultImagePath?: string
  loadingStyle?: CSSProperties | undefined
  user?: boolean
}): ReactElement => {
  const [loaded, setLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const isTestEnv = envIsTest()

  const RandomIcon = useMemo(
    () => Icons[isTestEnv ? 0 : getRandomInt(0, Icons.length - 1)],
    [isTestEnv],
  )

  // set to true until we can fix the state issue when loading
  const alwaysLoadImage = true

  const LoadedImage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- TODO: set alwaysLoadImage = false after fixing the state issue when loading images (see above)
    ({ loadedPic = false }) => (
      // eslint-disable-next-line react/forbid-elements -- TODO: use img here to avoid flickering whenever new data loads in, check if still needed?
      <img
        width={width}
        height={height}
        alt={alt}
        className={className}
        data-cy={dataCy}
        loading={loading}
        onError={() => setHasError(true)}
        onLoad={() => setLoaded(true)}
        src={src}
      />
    ),
    [alt, className, dataCy, height, loading, src, width],
  )

  const DefaultImage = useCallback(
    () => (
      <div className={styles.LoadingImage}>
        {user ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- add alt text to default image SVG
          // @ts-ignore
          <UserIcon className={className} style={style} alt="Loading..." />
        ) : (
          <>
            {/* eslint-disable-next-line react/forbid-elements -- TODO: use img here to avoid flickering whenever new data loads in, check if still needed? */}
            <img
              width={width}
              height={height}
              alt="Loading..."
              className={className}
              src={defaultImagePath || DefaultEventImagePath}
              style={loadingStyle || style}
            />
            {!defaultImagePath && (
              <div className={styles.IconWrapper}>
                <RandomIcon className={styles.Icon} />
              </div>
            )}
          </>
        )}
      </div>
    ),
    [
      RandomIcon,
      className,
      defaultImagePath,
      height,
      loadingStyle,
      style,
      user,
      width,
    ],
  )

  // if lazy loading pic, no default is necessary
  // if (loading === "lazy" || isTestEnv) return <LoadedImage loadedPic />
  if (alwaysLoadImage) return <LoadedImage loadedPic />

  return (
    <>
      {loaded ? null : <DefaultImage />}
      {!hasError && <LoadedImage loadedPic={loaded} />}
    </>
  )
}

export default React.memo(Image)
