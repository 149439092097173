import React, { useMemo } from "react"

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import has from "lodash/has"

import useRevelSession from "@hooks/useRevelSession"

// generated by Fragment Matcher plugin
import introspectionResult from "@graphql/graphql.schema"
import mergeConnectionResults from "@graphql/mergeConnectionResults"

const defaultPaginationKeyArgs = (additionalKeyArgs) => {
  // This function generates "keyArgs".
  // This is basically the cache key.
  // When two cache keys match, apollo will consider it the same collection and merge results, even across queries.
  // If there is some argument that marks it as a different collection, it should be provided as an "additionalKeyArgs"
  // For example, a filter such as
  // events (
  //   search: String
  //   states: [EventStatus!]
  //   connection: ConnectionInput
  // )
  // here, events(search: "Dior", connection: { first: 5 })
  // is different from
  // events(connection: { first: 5 })
  // that's why "search" should be part of "additionalKeyArgs"

  return (args, meta) => {
    const firstOrLast =
      (has(args, "connection.first") && "first") ||
      (has(args, "connection.last") && "last") ||
      "noDirectionSpecified"
    const beforeOrAfter =
      (has(args, "connection.before") && "before") ||
      (has(args, "connection.after") && "after") ||
      "noCursor"
    const additional = additionalKeyArgs
      ? additionalKeyArgs.map((name) => {
          const value = (args && args[name]) ?? (meta && meta[name]) ?? "null"
          if (typeof value === "object") {
            return JSON.stringify(value)
          }
          return value
        })
      : []

    return [
      meta.typename,
      meta.fieldName,
      firstOrLast,
      beforeOrAfter,
      ...additional,
    ].join(":")
  }
}

const revelPagination = (props) => {
  return {
    keyArgs:
      props?.keyArgs ?? defaultPaginationKeyArgs(props?.additionalKeyArgs),

    read(existing) {
      return existing
    },

    merge: (existing, incoming, meta) =>
      mergeConnectionResults(
        existing,
        incoming,
        meta,
        props?.mergeOptions ?? {},
      ),
  }
}

const revelMutation = () => {
  return {
    merge(existing, incoming) {
      if (incoming) {
        return incoming
      }

      return existing
    },
  }
}

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Inbox: {
      fields: {
        threads: revelPagination({
          additionalKeyArgs: ["search"],
        }),
      },
    },
    Thread: {
      fields: {
        messages: revelPagination(),
        participants: revelPagination(),
      },
    },
    Group: {
      fields: {
        discussionFeed: revelPagination({
          additionalKeyArgs: ["orders", "orderByColumn"],
        }),
        participants: revelPagination({
          additionalKeyArgs: ["roles"],
        }),
        events: revelPagination({
          additionalKeyArgs: ["states"],
        }),
      },
    },
    Query: {
      fields: {
        groups: revelPagination({
          additionalKeyArgs: ["isMember", "canPost"],
        }),
        groupDiscussionFeed: revelPagination({
          additionalKeyArgs: ["orders", "membershipStatus", "authoredById"],
        }),
        lifeTransitions: revelPagination({}),
        recommendedGroups: revelPagination({}),
      },
    },
    VersionMutation: revelMutation(),
    GroupMutation: revelMutation(),
    EventMutation: revelMutation(),
    Notifications: {
      fields: {
        notifications: revelPagination({ additionalKeyArgs: ["sources"] }),
      },
    },
    GroupPost: {
      fields: {
        posts: revelPagination({
          mergeOptions: {
            sort: {
              order: "DESC",
              fieldName: "createdAt",
            },
          },
        }),
        reactions: revelPagination({ additionalKeyArgs: ["content"] }),
      },
    },
    Event: {
      fields: {
        participants: revelPagination(),
        tickets: revelPagination({ additionalKeyArgs: ["status"] }),
        reviews: revelPagination(),
        discussionFeed: revelPagination({ additionalKeyArgs: ["orders"] }),
      },
    },
    EventPost: {
      fields: {
        posts: revelPagination({ additionalKeyArgs: ["orders"] }),
        likes: revelPagination({ additionalKeyArgs: ["orders"] }),
      },
    },
    User: {
      fields: {
        lifeTransitions: revelPagination(),
        interests: revelPagination(),
      },
    },
  },
})

export const RevelApolloProvider = ({ children }) => {
  const { getAccessToken } = useRevelSession()
  const httpLink = createHttpLink({
    // the 'credentials' var controls if cookies are sent.
    // possible values: "include" and "same-origin"
    // the reason it's called 'credentials' is because this feature was specifically built
    // for allowing cookies that manage user sessions, but it's for any cookie
    credentials: "include",
    uri: `${process.env.REACT_APP_SERVER_HOST}/graphql`,
  })

  const authLink = useMemo(
    () =>
      setContext(async (_, { headers }) => {
        const accessToken = await getAccessToken()
        if (!accessToken) return headers
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      }),
    [getAccessToken],
  )
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const unauthenticatedError = graphQLErrors.find(
        (err) => err.extensions.code === "UNAUTHENTICATED",
      )
      if (unauthenticatedError && getAccessToken) {
        getAccessToken().then((newAccessToken) => {
          // An access token wasn't issued, don't retry
          if (!newAccessToken) {
            return null
          }
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: newAccessToken,
            },
          })
          // Retry the request
          return forward(operation)
        })
      }
    }
    return null
  })

  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default RevelApolloProvider
