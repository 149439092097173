import React, { Suspense, useEffect, useState } from "react"

import { useLocation } from "react-router-dom"

import { lazzy } from "@services/lazy"
import { isNativeApp } from "@services/mobileHelpers"

import { INBOX, EVENTS } from "@constants/routeConstants"

// delay import of Zendesk to (hopefully) load the page before it starts downloading Zendesk's JS
const Zendesk = lazzy("LazyReactZendesk", () =>
  new Promise((resolve) => {
    setTimeout(resolve, 10000)
  }).then(() => import("react-zendesk")),
)

const pagesWithoutZendesk = [INBOX, EVENTS]

const Helpdesk = ({ isMobile }) => {
  const location = useLocation()
  const matchesPage = !!pagesWithoutZendesk.filter((page) => {
    return location.pathname.includes(page)
  }).length
  const shouldShowWidget = !(matchesPage || isMobile || isNativeApp())
  const [showWidget, setShowWidget] = useState(shouldShowWidget)

  const checkIfItShouldShow = () => {
    if (shouldShowWidget) {
      setShowWidget(true)

      if (document.getElementById("launcher")) {
        document.getElementById("launcher").style.display = "block"
      }
    } else {
      setShowWidget(false)

      if (document.getElementById("launcher")) {
        document.getElementById("launcher").style.display = "none"
      }
    }
  }

  // check if widget should show
  useEffect(() => {
    checkIfItShouldShow()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: update this message or remove and add deps
  }, [
    location.pathname, // check on page nagivation
    isMobile, // and when the page resizes
  ])

  return (
    (showWidget && process.env.REACT_APP_ZENDESK && (
      <Suspense fallback={null}>
        <Zendesk
          defer
          zendeskKey={process.env.REACT_APP_ZENDESK}
          onLoaded={() => checkIfItShouldShow()}
        />
      </Suspense>
    )) || <div />
  )
}

export default Helpdesk
