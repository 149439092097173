import concat from "lodash/concat"
import uniq from "lodash/uniq"

import { CLIENT_HOST_STAGING_WHEN_DEV, CLIENT_HOST } from "@constants"

export const ABOUT = "/about"
export const ACCOUNT_SETTINGS = "/account-settings"
export const ANDROID_APP =
  "https://play.google.com/store/apps/details?id=com.hellorevel.mobile"
export const APP_VERSION_REJECTED = "/app-version-too-low"
export const ACTIVITY_LOG = "/activity-log"
export const BLOG = "/blog"
export const CAREERS = "/careers"
export const CODE_OF_CONDUCT = "/code-of-conduct"
export const COMPLETE_PROFILE = "/join-complete?step=1"
export const COOKIE_POLICY = "/cookie-policy"
export const CREATE_EVENT = "/create-event"
export const CREATE_GROUP = "https://forms.gle/pLTTtxhwyqawFMAUA"
export const DASHBOARD = "/home"
export const DIRECTORY = "/directory"
export const EDIT_PROFILE = "/profile/contact-info"
export const EVENT_CATEGORY = "/events/category"
export const EVENT_DETAIL = "/events/"
export const EVENT_PAYMENT_COMPLETED = "/payment-completed"
export const EVENTS = "/events"
export const EVENTS_CALENDAR = "/events/calendar"
export const EVENTS_SEARCH_QUERY = `${EVENTS}/filtering`
export const FAQ = "/information"
export const GROUP_DETAIL = "/community/"
export const GROUP_GUIDELINES = "/group-guidelines"
export const GROUPS = "/community"
export const GROUPS_I_JOINED = "/community/filtering?myGroups=true"
export const GROUPS_SEARCH_QUERY = `${GROUPS}/filtering`
export const HOST_GUIDELINES = "/host-guidelines"
export const HOST_TOOLS_LIBRARY = "/host-tools-library"
export const INBOX = "/messages"
export const IOS_APP = "https://apps.apple.com/us/app/hellorevel/id1579196395"
export const JOIN = "/join"
export const JOIN_FORM = "/join/form"
export const LANDING = "https://www.hellorevel.com/"
export const LOGIN = "/login"
export const LOGOUT = "/logout"
export const MANAGE_EVENTS = "/manage-my-events"
export const MOBILE_DASHBOARD = "/"
export const NEW_POWER_HOST = "/new-power-host"
export const POST_JOIN = "/profile/contact-info"
export const PRESS = "/press"
export const PRIVACY_POLICY = "/privacy-policy"
export const PROFILE = "/profile/"
export const REFERRAL = "/referral"
export const STYLE_GUIDE = "/style-guide"
export const SUBSCRIPTION = "/subscription"
export const SWAG_STORE = "/swag-store"
export const TERMS_CONDITIONS = "/terms"
export const WHY_HOST = "/why-host"
export const NOTIFICATIONS = "/notifications"

export const ROOT_URL = CLIENT_HOST
export const CORE_DOMAINS = (() => {
  const prodUrls = ["www.hellorevel.com", "hellorevel.com", "m.hellorevel.com"]
  const clientHostURL = new URL(ROOT_URL)
  const clientHostStagingWhenDevURL = new URL(CLIENT_HOST_STAGING_WHEN_DEV)

  return uniq(
    concat(
      prodUrls,
      clientHostURL.hostname,
      clientHostStagingWhenDevURL.hostname,
    ),
  )
})()

export const ANDROID_CLIENT_HOST = `http://localhost`
export const IOS_CLIENT_HOST = `capacitor://localhost`

export const MOBILE_CALLBACK = `com.hellorevel.mobile://localhost/capacitor/com.hellorevel.mobile/callback`

/*
 * Webflow routes must be updated across the following files:
 * - android/app/src/main/java/com/hellorevel/mobile/utils/RevelUrl.java
 * - ios/App/App/Extensions/URL+Revel.swift
 * - cloudflare/worker/utils/isWebflow.js
 * - src/constants/routeConstants.js WEBFLOW_PATH_PREFIXES
 */
export const WEBFLOW_PATH_PREFIXES = [
  "/asheville",
  "/cities",
  "/guest-speaker-guidelines",
  "/revel-holiday-marketplace-2020",
  "/video-library",
  ABOUT,
  BLOG,
  CAREERS,
  CODE_OF_CONDUCT,
  HOST_GUIDELINES,
  GROUP_GUIDELINES,
  HOST_TOOLS_LIBRARY,
  PRESS,
  PRIVACY_POLICY,
  SWAG_STORE,
  TERMS_CONDITIONS,
]

export const getPath = (preserveSearch) => {
  const { pathname, search, hash } = window.location

  if (preserveSearch) {
    return encodeURIComponent(pathname + search + hash)
  }
  return encodeURIComponent(pathname + hash)
}

export const getPathWithReturn = (
  to = LOGIN,
  preserveSearch = true,
  returnPath = "",
) => {
  return `${to}?returnTo=${
    (returnPath && encodeURIComponent(returnPath)) || getPath(preserveSearch)
  }`
}

export const isRevelCoreDomain = (url) => {
  const isRelative = ["#", "/"].some((prefix) => url.startsWith(prefix))
  let isAbsoluteRevelDomain = false
  if (!isRelative) {
    try {
      const { hostname } = new URL(url)
      isAbsoluteRevelDomain = CORE_DOMAINS.some((host) => host === hostname)
    } catch (_) {
      return isRelative
    }
  }
  return isRelative || isAbsoluteRevelDomain
}

/**
 * Takes a url string and determines if page is from Webflow based on WEBFLOW_PATH_PREFIXES.
 * @param url - url string (accepts both absolute and relative urls), relies on ROOT_URL if absolute.
 * @returns {boolean}
 * @type {(url: string) => boolean}
 */
export const isWebflowPage = (url) => {
  const isRevelDomain = isRevelCoreDomain(url)
  return WEBFLOW_PATH_PREFIXES.some(
    (prefix) =>
      url.startsWith(prefix) || (isRevelDomain && url.includes(prefix)),
  )
}

/**
 * Takes a url string and determines if page is a react page based on ROOT_URL and not being a Webflow page.
 * @param url - url string (accepts both absolute and relative urls), relies on ROOT_URL if absolute.
 * @returns {boolean}
 * @type {(url: string) => boolean}
 */
export const isReactPage = (url) => {
  return isRevelCoreDomain(url) && !isWebflowPage(url)
}

export const SOCIAL_LOGIN_FAILURE_PARAM = `socialLoginFailure`

// We should not return to Webflow pages after login, any React pages that
// may cause an infinite loop, or an unknown path
export const LOGIN_VALID_RETURN_PATHS = [
  ACCOUNT_SETTINGS,
  APP_VERSION_REJECTED,
  COMPLETE_PROFILE,
  CREATE_EVENT,
  CREATE_GROUP,
  DASHBOARD,
  DIRECTORY,
  EDIT_PROFILE,
  EVENT_DETAIL,
  EVENT_PAYMENT_COMPLETED,
  EVENTS_SEARCH_QUERY,
  EVENTS,
  GROUP_DETAIL,
  GROUPS_I_JOINED,
  GROUPS_SEARCH_QUERY,
  GROUPS,
  INBOX,
  MANAGE_EVENTS,
  NEW_POWER_HOST,
  POST_JOIN,
  PROFILE,
  REFERRAL,
  SUBSCRIPTION,
]
