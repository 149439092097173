import React, { ReactElement, ReactNode } from "react"

import { AnchorLink } from "@components/Link"

import { isNativeApp, shareSms } from "@services/mobileHelpers"

const sms = ({ message }: { message: string }) =>
  `sms:?&body=${encodeURIComponent(message)}`

const SmsLink = ({
  children,
  message = "",
  ...rest
}: {
  className?: string
  message?: string
  children: ReactNode
}): ReactElement<HTMLAnchorElement> => {
  const href = sms({ message })
  const onClick = async () => {
    await shareSms({ message, fallbackUrl: href })
  }

  return (
    <AnchorLink
      to={isNativeApp() ? undefined : href}
      onClick={isNativeApp() ? onClick : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading -- pass through to `AnchorLink`
      {...rest}
    >
      {children}
    </AnchorLink>
  )
}

export default SmsLink
