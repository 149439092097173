import React from "react"

import { LinkData } from "@components/Header/Header.types"

import { isNativeApp } from "@services/mobileHelpers"

import {
  ABOUT,
  ACCOUNT_SETTINGS,
  GROUPS,
  DASHBOARD,
  DIRECTORY,
  EVENTS_SEARCH_QUERY,
  FAQ,
  MANAGE_EVENTS,
  INBOX,
  JOIN,
  LOGIN,
  LOGOUT,
  PROFILE,
  WHY_HOST,
  BLOG,
  REFERRAL,
} from "@constants/routeConstants"

import { ReactComponent as BlogIcon } from "./assets/blog.svg"
import { ReactComponent as EventsIcon } from "./assets/events.svg"
import { ReactComponent as GroupsIcon } from "./assets/groups.svg"
import { ReactComponent as HomeIcon } from "./assets/home.svg"
import { ReactComponent as InboxIcon } from "./assets/inbox.svg"

export const logoLink = (loggedIn: boolean): string =>
  loggedIn && !isNativeApp() ? DASHBOARD : "/"

const buildGuestLinks = (): LinkData[] => [
  { url: EVENTS_SEARCH_QUERY, label: "Events" },
  { url: GROUPS, label: "Communities" },
  { url: ABOUT, label: "About" },
  { url: BLOG, label: "Blog" },
  { url: LOGIN, label: "Login" },
  { url: JOIN, label: "Join", id: "join" },
]

export const buildDesktopLinks = (): {
  member: LinkData[]
  guest: LinkData[]
} => ({
  member: [
    { url: DASHBOARD, label: "Home" },
    { url: EVENTS_SEARCH_QUERY, label: "Events" },
    {
      url: GROUPS,
      label: "Communities",
    },
    {
      url: DIRECTORY,
      label: "Directory",
    },
    {
      url: WHY_HOST,
      label: "Hosting",
    },
    {
      url: BLOG,
      label: "Blog",
    },
  ],
  guest: buildGuestLinks(),
})

export const buildMobileLinks = (
  userId: string,
): { member: LinkData[]; guest: LinkData[] } => ({
  member: [
    {
      url: `${PROFILE}${userId}`,
      label: "Click to View / Edit Profile",
    },
    {
      url: DIRECTORY,
      label: "Member Directory",
    },
    {
      url: MANAGE_EVENTS,
      label: "Manage My Events",
    },
    { url: ACCOUNT_SETTINGS, label: "Account & Billing" },
    { url: FAQ, label: "FAQS" },
    { url: REFERRAL, label: "Invite a Friend" },
    { url: LOGOUT, label: "Logout" },
  ],
  guest: buildGuestLinks(),
})

export const buildDropdownLinks = (userId: string): LinkData[] => [
  {
    url: `${PROFILE}${userId}`,
    label: "Click to View / Edit Profile",
  },
  {
    url: MANAGE_EVENTS,
    label: "Manage My Events",
  },
  {
    url: ACCOUNT_SETTINGS,
    label: "Account & Billing",
  },
  { url: FAQ, label: "FAQS" },
  { url: REFERRAL, label: "Invite a Friend" },
  { url: LOGOUT, label: "Logout" },
]

export const buildMobileBottomLinks = (): LinkData[] => [
  {
    url: isNativeApp() ? "/" : DASHBOARD,
    label: "Home",
    icon: <HomeIcon />,
    isNew: true,
  },
  {
    url: EVENTS_SEARCH_QUERY,
    label: "Events",
    icon: <EventsIcon />,
  },
  {
    url: GROUPS,
    label: "Communities",
    icon: <GroupsIcon />,
  },
  {
    url: BLOG,
    label: "Blog",
    icon: <BlogIcon />,
    isExternalLink: true,
  },
  {
    url: INBOX,
    label: "Messages",
    icon: <InboxIcon />,
  },
]
