import React, { useEffect, useMemo, useState } from "react"

import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency"

import {
  ACCEPTS_ANALYTICS_COOKIES,
  ACCEPTS_PERFORMANCE_COOKIES,
  AJS_USER_ID,
  cookieExists,
  DISMISSED_COOKIE_BANNER,
} from "@services/cookies"
import { isAndroid, isiOS } from "@services/mobileHelpers"

export const AnalyticsSettingsContext = React.createContext({
  analyticsActive: false,
  adPerformanceActive: false,
  bannerDismissed: true,
  hashedUserId: "",
  mobileTrackingEnabled: false,
  androidTrackingEnabled: false,
  iOSAdTrackingStatus: false,
  iOSAdTrackingEnabled: false,
  integrations: {},
})

const AnalyticsSettingsProvider = ({ children }) => {
  const [iOSAdTrackingStatus, setiOSAdTrackingStatus] = useState("")
  const [iOSAdTrackingEnabled, setiOSAdTrackingEnabled] = useState(false)
  const androidTrackingEnabled = isAndroid()
  useEffect(() => {
    const apply = async () => {
      if (isiOS()) {
        let { status } = await AppTrackingTransparency.getStatus()
        if (status === "notDetermined") {
          status = (await AppTrackingTransparency.requestPermission()).status
        }
        setiOSAdTrackingStatus(status)
        setiOSAdTrackingEnabled(status === "authorized")
      }
    }
    apply()
  }, [])
  const bannerDismissed = cookieExists(DISMISSED_COOKIE_BANNER)
  const hashedUserId =
    cookieExists(AJS_USER_ID) && !cookieExists(AJS_USER_ID).startsWith("auth0|")
  const mobileTrackingEnabled =
    androidTrackingEnabled || (isiOS() && iOSAdTrackingStatus !== "")
  const analyticsActive =
    androidTrackingEnabled ||
    iOSAdTrackingEnabled ||
    cookieExists(ACCEPTS_ANALYTICS_COOKIES)
  const adPerformanceActive =
    androidTrackingEnabled ||
    iOSAdTrackingEnabled ||
    cookieExists(ACCEPTS_PERFORMANCE_COOKIES)

  const settings = useMemo(() => {
    const defaultIntegrations = {
      All: false,
      Postgres: true,
      "NextDoor Pixel": false,
      "Segment.io": true,
    }
    const analyticsIntegrations = analyticsActive
      ? {
          "Customer.io Actions": true,
          "Google Universal Analytics": true,
          Hotjar: true,
          Mixpanel: true,
          Zendesk: true,
        }
      : {}
    const adPerformanceIntegrations = adPerformanceActive
      ? {
          "Facebook Pixel": true,
          "NextDoor Pixel": true,
          "Google AdWords New": true,
        }
      : {}

    return {
      analyticsActive,
      adPerformanceActive,
      bannerDismissed,
      hashedUserId,
      mobileTrackingEnabled,
      androidTrackingEnabled,
      iOSAdTrackingStatus,
      iOSAdTrackingEnabled,
      integrations: {
        ...defaultIntegrations,
        ...analyticsIntegrations,
        ...adPerformanceIntegrations,
      },
    }
  }, [
    adPerformanceActive,
    analyticsActive,
    androidTrackingEnabled,
    bannerDismissed,
    hashedUserId,
    iOSAdTrackingEnabled,
    iOSAdTrackingStatus,
    mobileTrackingEnabled,
  ])

  return (
    <AnalyticsSettingsContext.Provider value={settings}>
      {children}
    </AnalyticsSettingsContext.Provider>
  )
}

export default AnalyticsSettingsProvider
