import React, { useEffect, useState, Suspense, ReactElement } from "react"

import { useNavigate } from "react-router-dom"

import { useAirbrake } from "@hooks/useAirbrake"
import useLayoutProvider from "@hooks/useLayout"
import { useMobileApp } from "@hooks/useMobileApp"
import usePageAnalytics from "@hooks/usePageAnalytics"
import useRevelSession from "@hooks/useRevelSession"
import { useScrollOnLoad } from "@hooks/useScrollOnLoad"

import { useLayoutQuery } from "@graphql/codegen"

import ErrorBoundary, { AppFallback } from "@components/ErrorBoundary"
import Header from "@components/Header"
import Helpdesk from "@components/Helpdesk"
import SeoTags from "@components/SeoTags"
import { LazyCookieConsent } from "@components/lazy"
import { useVersionCheck } from "@providers/UseVersionCheckProvider"

import { lazzy } from "@services/lazy"
import { identify } from "@services/tracking"

import { APP_VERSION_REJECTED } from "@constants/routeConstants"

import styles from "./index.module.scss"

const LazzyFooter = lazzy("Footer", () => {
  return import("@components/Footer").then(({ default: Component }) => {
    return {
      default: React.forwardRef<HTMLDivElement>(function LoadedFooter(
        props,
        ref,
      ) {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading -- pass through props to Footer
          <Component ref={ref} {...props} />
        )
      }),
    }
  })
})
const LazzyMobileNavBar = lazzy("MobileNavBar", () => {
  return import("@components/MobileNavBar").then(({ default: Component }) => {
    return {
      default: React.forwardRef<HTMLDivElement>(function LoadedMobileNavBar(
        props,
        ref,
      ) {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading -- pass through props to MobileNavBar
          <Component ref={ref} {...props} />
        )
      }),
    }
  })
})

type LayoutProps = {
  children: ReactElement
}

const Layout = ({ children }: LayoutProps): ReactElement => {
  const navigate = useNavigate()
  const { userId, hasProfile, isLoggedIn, needsLogin, sessionReady } =
    useRevelSession()
  const version = useVersionCheck()
  useMobileApp()
  useAirbrake()
  usePageAnalytics()
  useScrollOnLoad()

  const layoutQuery = useLayoutQuery({
    skip: needsLogin,
  })
  const user = layoutQuery.data?.currentUser

  const [hasIdentified, setHasIdentified] = useState(false)
  useEffect(() => {
    if (!hasIdentified && isLoggedIn && hasProfile && user) {
      setHasIdentified(true)
      identify(user)
    }
  }, [hasIdentified, isLoggedIn, user, hasProfile])

  useEffect(() => {
    if (version.appVersionRejected) {
      navigate(APP_VERSION_REJECTED)
    }
  }, [navigate, version.appVersionRejected])

  const { footerRef, headerRef, footerHidden, tabletDown, navBarRef } =
    useLayoutProvider()

  return (
    <ErrorBoundary fallBackRender={<AppFallback />}>
      <SeoTags />

      {/* Display a sentinel element that cypress can use to verify a user's logged-in status */}
      {userId && <span data-cy="userLoggedIn" style={{ display: "none" }} />}

      <div className={styles.Layout}>
        <Header ref={headerRef as unknown as React.Ref<HTMLDivElement>} />

        <main>
          <ErrorBoundary fallBackRender={<AppFallback />}>
            <section className={styles.MainSection}>{children}</section>
          </ErrorBoundary>

          {sessionReady && !footerHidden && (
            <Suspense fallback={null}>
              <LazzyFooter
                ref={footerRef as unknown as React.Ref<HTMLDivElement>}
              />
            </Suspense>
          )}
        </main>

        {tabletDown && (
          <Suspense fallback={null}>
            <LazzyMobileNavBar
              ref={navBarRef as unknown as React.Ref<HTMLDivElement>}
            />
          </Suspense>
        )}
      </div>

      {sessionReady && (
        <Suspense fallback={null}>
          <LazyCookieConsent />
        </Suspense>
      )}
      <Helpdesk isMobile={tabletDown} />
    </ErrorBoundary>
  )
}

export default Layout
