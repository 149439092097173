import { useContext } from "react"

import { AnalyticsSettingsContext } from "@providers/AnalyticsSettingsProvider"

const useAnalyticsSettings = () => {
  const analyticsSettings = useContext(AnalyticsSettingsContext)
  return analyticsSettings
}

export default useAnalyticsSettings
