const SEQUENCE_ID_REGEX = /[^\w]thread-(?<sequenceId>[\d]+)/
const nestedCommentSequenceIdFromLocation = ({ location }) => {
  // Extract the posts' sequence id from the fragment
  // This is used to indicate that the feed should be centered on this post

  const match = location.hash.match(SEQUENCE_ID_REGEX)
  if (match?.groups) {
    return parseInt(match.groups.sequenceId, 10)
  }
  return undefined
}

export default nestedCommentSequenceIdFromLocation
