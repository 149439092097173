import {
  COOKIE_POLICY,
  EVENT_CATEGORY,
  EVENTS,
  FAQ,
  GROUPS,
  JOIN,
  LOGIN,
  NEW_POWER_HOST,
  REFERRAL,
  STYLE_GUIDE,
  WHY_HOST,
} from "@constants/routeConstants"

import { MIN_REVEL_AGE } from "."

export const CANONICAL_URL = "https://www.hellorevel.com"
export const SITE_TITLE = `Revel, events and community for women ${MIN_REVEL_AGE}+.`

export const GlobalSeo = {
  title: SITE_TITLE,
  description:
    "Revel is a community where women build meaningful connections, accelerate personal growth, and have candid conversations with the support of like-minded women.",
  canonical: CANONICAL_URL,
  image:
    "https://revel-public-images.s3.us-east-2.amazonaws.com/revel-open-graph-may-2022.png",
}

export const EventsSeo = {
  title: `Our Events for Women ${MIN_REVEL_AGE}+ | Revel`,
  description:
    "Revel members host and attend intimate communities, expert-led talks, and lighthearted events just for fun, building the community from the ground up.",
  canonical: `${CANONICAL_URL}${EVENTS}`,
}

export const EventDetailsSeo = {
  canonical: `${CANONICAL_URL}${EVENTS}`,
  title: (title) => `${title || "Event Details"} - Revel`,
  description: (interest, description) =>
    `${interest} event for women over ${MIN_REVEL_AGE}: ${description}`,
}

export const EventsCategorySeo = (catData, eventCategory) => ({
  title: `${catData?.title} for Women ${MIN_REVEL_AGE}+ | Revel`,
  description: `Looking for ${catData?.title} for women 40+? At Revel, women can support each other in finding rich experiences & deep connections. See our events.`,
  canonical: `${CANONICAL_URL}${EVENT_CATEGORY}/${eventCategory}`,
})

export const StyleGuideSeo = {
  title: `Style Guide - ${SITE_TITLE}`,
  description: "See all of Revel's funky colors in our style guide.",
  canonical: `${CANONICAL_URL}${STYLE_GUIDE}`,
  image:
    "https://revel-public-images.s3.us-east-2.amazonaws.com/revel-logo-colors.png",
}

export const FaqSeo = {
  title: `Frequently Asked Questions | Revel`,
  description:
    "Read our Frequently Asked Questions to learn more about the Revel community, our memberships and how to host events with us.",
  canonical: `${CANONICAL_URL}${FAQ}`,
}

export const JoinSeo = {
  title: `Join Our Community for Women 40+ | Revel`,
  description:
    "Join Revel and become a member of the community where midlife women build meaningful connections, grow, and explore with the support of like minded women.",
  canonical: `${CANONICAL_URL}${JOIN}`,
}

export const LoginSeo = {
  title: `Account Log In | Revel`,
  description: "Revel members login to connect with the Revel community.",
  canonical: `${CANONICAL_URL}${LOGIN}`,
}

export const NotFoundSeo = {
  title: `Page Not Found - ${SITE_TITLE}`,
}

// Below pages are login gated, so not really necessary to put much text here beyond default.
export const YourEventsSeo = {
  title: `Manage Events - ${SITE_TITLE}`,
}

export const AccountSeo = {
  title: `Account Settings - ${SITE_TITLE}`,
}

export const EventCreationSeo = {
  title: `Create Event - ${SITE_TITLE}`,
}

export const DashboardSeo = {
  title: `Home Dashboard - ${SITE_TITLE}`,
}

export const JoinCompleteSeo = {
  title: `Complete Your Account - ${SITE_TITLE}`,
}

export const MemberDirectorySeo = {
  title: `Member Directory - ${SITE_TITLE}`,
}

export const SubscriptionSeo = {
  title: `Plan Selection - ${SITE_TITLE}`,
}

export const WhyHostSeo = {
  title: `Hosting - ${SITE_TITLE}`,
  description:
    "Revel members create and attend intimate communities, expert-led talks, and lighthearted events just for fun, building the community from the ground up.",
  canonical: `${CANONICAL_URL}${WHY_HOST}`,
}

export const ProfileSeo = {
  title: `Profile - ${SITE_TITLE}`,
}

export const AllGroupsSeo = {
  title: `Women's ${MIN_REVEL_AGE}+ Communities | Revel`,
  description: `At Revel, our communities are a forum where women ${MIN_REVEL_AGE}+ can have honest conversations in a safe space, and tap into the collective wisdom and wit of our sisterhood.`,
  canonical: `${CANONICAL_URL}${GROUPS}`,
}

export const GroupDetailsSeo = (groupName, headline, slug) => ({
  title: `${groupName} - Community for Women ${MIN_REVEL_AGE}+ | Revel`,
  description: `${headline} Let's discuss.`,
  canonical: `${CANONICAL_URL}${GROUPS}/${slug}`,
})

export const ReferralSeo = {
  title: `Refer a Friend | Revel`,
  description:
    "Learn how to invite your friends and family to easily join the Revel community.",
  canonical: `${CANONICAL_URL}${REFERRAL}`,
}

export const CookiePolicySeo = {
  title: `Cookie Policy - ${SITE_TITLE}`,
  description:
    "We use cookies to help improve our site and personalize your Revel experience.",
  canonical: `${CANONICAL_URL}${COOKIE_POLICY}`,
}

export const PowerHostSeo = {
  title: `New Power Hosts - ${SITE_TITLE}`,
  description:
    "We are so excited to share that because you have hosted more than five events on the Revel platform in the last year, you now qualify as a Revel Power Host.",
  canonical: `${CANONICAL_URL}${NEW_POWER_HOST}`,
}
