import axios from "axios"

import { errorHandler } from "./errors"

export const PROFILE_UPDATE_SUCCESS = "Profile update successful"
export const PROFILE_UPDATE_FAILURE = "Profile update failed"
export const PROFILE_FETCHING = "Profile fetch initiated"
export const PROFILE_SUCCESS = "Profile fetch successful"
export const PROFILE_FAILURE = "Profile fetch failed"
export const PROFILE_RESET = "Profile editing state reset"

const authUrl = `${process.env.REACT_APP_SERVER_HOST}/api/auth`
const endpoints = {
  profile: (id) => `${authUrl}/profile/${id}`,
}

export const sendProfileUpdateSuccess = (profile) => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload: profile,
})

export const sendProfileSuccess = (profile) => ({
  type: PROFILE_SUCCESS,
  payload: profile,
})

export const sendProfileReset = {
  type: PROFILE_RESET,
}

export const fetchProfile = (userId) => (dispatch) => {
  axios
    .get(endpoints.profile(userId))
    .then((response) => response.data)
    .then((profile) => dispatch(sendProfileSuccess(profile)))
    .catch(errorHandler("profile::fetch", { userId }, dispatch))
}

const initialState = {
  updateSuccessful: false,
}

// eslint-disable-next-line default-param-last -- redux code expects this
export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_FETCHING:
      return {
        ...state,
        [action.payload.id]: {
          name: "Loading...",
          picture: "",
        },
      }
    case PROFILE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccessful: true,
        [action.payload.id]: action.payload,
      }
    case PROFILE_FAILURE:
      return state
    case PROFILE_UPDATE_FAILURE:
    case PROFILE_RESET:
      return { ...state, updateSuccessful: false }
    default:
      return state
  }
}
