import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISODateTime: any
  SemanticVersion: any
  Upload: any
}

export type ApproveMemberRequestToJoinInput = {
  groupId: Scalars["ID"]
  userId: Scalars["ID"]
}

export type ApproveMemberRequestToJoinResponse = {
  __typename?: "ApproveMemberRequestToJoinResponse"
  groupParticipant?: Maybe<GroupParticipant>
}

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CancelEventInput = {
  id: Scalars["ID"]
}

export type CancelEventResponse = {
  __typename?: "CancelEventResponse"
  event?: Maybe<Event>
}

export type CancelTicketInput = {
  id: Scalars["ID"]
}

export type CancelTicketResponse = {
  __typename?: "CancelTicketResponse"
  ticket?: Maybe<Ticket>
}

/** Input for cursor based pagination. Also supports getting nodes updated `since` a time (useful for refreshing data). */
export type ConnectionInput = {
  /** Get nodes after this cursor */
  after?: InputMaybe<Scalars["String"]>
  /** Get nodes before this cursor */
  before?: InputMaybe<Scalars["String"]>
  /** Get the first `first` nodes */
  first?: InputMaybe<Scalars["Int"]>
  /** Get the last `last` nodes */
  last?: InputMaybe<Scalars["Int"]>
  /** Get nodes with `updatedAt` after this timestamp */
  since?: InputMaybe<Scalars["ISODateTime"]>
}

export enum ConnectionOrderDirection {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

export enum ConnectionOrderNulls {
  First = "FIRST",
  Last = "LAST",
}

export type ContentReport = {
  __typename?: "ContentReport"
  id: Scalars["ID"]
}

export type ContentReportMutation = {
  __typename?: "ContentReportMutation"
  createContentReport?: Maybe<CreateContentReportResponse>
}

export type ContentReportMutationCreateContentReportArgs = {
  input: CreateContentReportInput
}

export type CreateContentReportInput = {
  contentId: Scalars["ID"]
  reason?: InputMaybe<Scalars["String"]>
}

export type CreateContentReportResponse = {
  __typename?: "CreateContentReportResponse"
  contentReport?: Maybe<ContentReport>
}

export type CreatePostInput = {
  eventId: Scalars["ID"]
  parentPostId?: InputMaybe<Scalars["ID"]>
  textBody: Scalars["String"]
}

export type CreatePostResponse = {
  __typename?: "CreatePostResponse"
  createdPost?: Maybe<EventPost>
  event?: Maybe<Event>
  parentPost?: Maybe<EventPost>
}

export type DeletePostInput = {
  postId: Scalars["ID"]
}

export type DeletePostResponse = {
  __typename?: "DeletePostResponse"
  event?: Maybe<Event>
  parentPost?: Maybe<EventPost>
}

export type Device = {
  __typename?: "Device"
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Device token to be used with Firebase */
  fcmToken: Scalars["String"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
  /** Database id of the user whose device this is */
  userId: Scalars["String"]
}

export type DeviceAddInput = {
  fcmToken: Scalars["String"]
}

export type DeviceAddResponse = {
  __typename?: "DeviceAddResponse"
  createdDevice?: Maybe<Device>
}

export type DeviceMutation = {
  __typename?: "DeviceMutation"
  addDevice?: Maybe<DeviceAddResponse>
  removeDevice?: Maybe<DeviceRemoveResponse>
}

export type DeviceMutationAddDeviceArgs = {
  input: DeviceAddInput
}

export type DeviceMutationRemoveDeviceArgs = {
  input: DeviceRemoveInput
}

export type DeviceRemoveInput = {
  fcmToken: Scalars["String"]
}

export type DeviceRemoveResponse = {
  __typename?: "DeviceRemoveResponse"
  user?: Maybe<User>
}

export type Event = {
  __typename?: "Event"
  /** Are external guests allowed for this event? */
  allowExternalGuests?: Maybe<Scalars["Boolean"]>
  /** Details around the attendee threshold: if the threshold is not met, the event will be auto-canceled. */
  attendeeThreshold?: Maybe<EventAttendeeThreshold>
  /** Average rating of attendee reviews for an event */
  averageRating?: Maybe<Scalars["Float"]>
  /** Can the user cancel this event? */
  canCancel?: Maybe<Scalars["Boolean"]>
  /** Can the user edit this event? */
  canEdit?: Maybe<Scalars["Boolean"]>
  /** Can the current user make posts on this event? */
  canPost?: Maybe<Scalars["Boolean"]>
  /** This event's city if applicable */
  city?: Maybe<Scalars["String"]>
  /** UUID */
  databaseId?: Maybe<Scalars["String"]>
  /** Description provided by the host(s) */
  description?: Maybe<Scalars["String"]>
  discussionFeed?: Maybe<EventPostConnection>
  /** Group post that is automatically published when event is promoted */
  draftGroupPost?: Maybe<GroupPost>
  /** This event ends at */
  endsAt?: Maybe<Scalars["ISODateTime"]>
  /** The external guests for this event */
  externalGuests?: Maybe<ExternalGuestConnection>
  /** Is this event hosted by a power host? */
  hasPowerHost?: Maybe<Scalars["Boolean"]>
  /** The host zoom link */
  hostZoomLink?: Maybe<Scalars["String"]>
  /** All hosts and co-hosts for this event */
  hostships?: Maybe<HostshipConnection>
  /** Globally unique ID */
  id?: Maybe<Scalars["ID"]>
  /** The published image */
  image?: Maybe<Scalars["String"]>
  /** Primary interest type of the event */
  interest?: Maybe<Interest>
  /** Is the user attending this event? */
  isAttending?: Maybe<Scalars["Boolean"]>
  /** Is this event excluded from marketing? */
  isExcludedMarketing?: Maybe<Scalars["Boolean"]>
  /** Is this event featured for marketing? */
  isFeaturedMarketing?: Maybe<Scalars["Boolean"]>
  /** Is the user the hosting this event? */
  isHosting?: Maybe<Scalars["Boolean"]>
  /** Does the event cost money? */
  isPaid?: Maybe<Scalars["Boolean"]>
  /** Does this event require members to have a profile picture when RSVPing? */
  isPictureRequired?: Maybe<Scalars["Boolean"]>
  /** Is this event part of a series? */
  isSeries?: Maybe<Scalars["Boolean"]>
  /** Does this virtual event require members to enable their camera during the event? */
  isVideoRequired?: Maybe<Scalars["Boolean"]>
  /** Enable waitlist on this event? */
  isWaitlistEnabled?: Maybe<Scalars["Boolean"]>
  /** The event location */
  location?: Maybe<Scalars["String"]>
  /** This event's location type ID */
  locationTypeId?: Maybe<Scalars["Int"]>
  /** The published name */
  name?: Maybe<Scalars["String"]>
  /** Valid attendees, hosts, and co-hosts for this event */
  participants?: Maybe<UserConnection>
  /** The price for this event */
  price?: Maybe<Scalars["Float"]>
  /** Average rating of attendee reviews for an event */
  reviews?: Maybe<EventReviewConnection>
  /** Secondary interest type of the event */
  secondaryInterest?: Maybe<Interest>
  /** Unique slug used to construct URLs */
  slug: Scalars["String"]
  /** This event starts at */
  startsAt?: Maybe<Scalars["ISODateTime"]>
  /** This event's state if applicable */
  state?: Maybe<Scalars["String"]>
  /** The current status */
  status: EventStatus
  /** Current tickets for this event; when a user RSVPs, they receive a ticket */
  tickets?: Maybe<TicketConnection>
  /** The virtual local zip for this event */
  virtualLocalZip?: Maybe<Scalars["String"]>
  /** The attendee zoom link */
  zoomLink?: Maybe<Scalars["String"]>
}

export type EventDiscussionFeedArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventPostOrderInput>>
}

export type EventExternalGuestsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type EventHostshipsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type EventParticipantsArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<UserOrderInput>>
  role?: InputMaybe<ParticipantRole>
}

export type EventReviewsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type EventTicketsArgs = {
  connection?: InputMaybe<ConnectionInput>
  status?: InputMaybe<TicketStatus>
}

export type EventAddHostInput = {
  eventId: Scalars["String"]
  userId: Scalars["String"]
}

export type EventAddHostResponse = {
  __typename?: "EventAddHostResponse"
  hostship?: Maybe<Hostship>
}

export type EventAttendeeThreshold = {
  __typename?: "EventAttendeeThreshold"
  /**
   * The number of hours before event start when the threshold must be met."
   * If the threshold is not met this many hours before the event start, it will be canceled.
   */
  hoursBefore?: Maybe<Scalars["Int"]>
  /**
   * The minimum number of RSVPs required to meet the threshold.
   * If this number is not met in time, then the event will be canceled.
   */
  minimum?: Maybe<Scalars["Int"]>
}

export type EventCheckoutSessionInput = {
  eventIds: Array<Scalars["ID"]>
  paymentId?: InputMaybe<Scalars["String"]>
  paymentMethod?: PaymentMethod
  redirectUrl?: InputMaybe<Scalars["String"]>
}

export type EventCheckoutSessionResponse = {
  __typename?: "EventCheckoutSessionResponse"
  url?: Maybe<Scalars["String"]>
}

export type EventConnection = {
  __typename?: "EventConnection"
  edges?: Maybe<Array<Maybe<EventEdge>>>
  nodes?: Maybe<Array<Maybe<Event>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type EventEdge = {
  __typename?: "EventEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Event>
}

export type EventMutation = {
  __typename?: "EventMutation"
  RSVP?: Maybe<RsvpResponse>
  cancelEvent?: Maybe<CancelEventResponse>
  cancelTicket?: Maybe<CancelTicketResponse>
  createPost?: Maybe<CreatePostResponse>
  deletePost?: Maybe<DeletePostResponse>
  likePost?: Maybe<LikePostResponse>
  markFeedbackModalAsSeen?: Maybe<MarkFeedbackModalAsSeenResponse>
  setGroup?: Maybe<SetGroupResponse>
  submitAttendeeFeedback?: Maybe<SubmitAttendeeFeedbackResponse>
  submitHostFeedback?: Maybe<SubmitHostFeedbackResponse>
  unlikePost?: Maybe<UnlikePostResponse>
  updatePost?: Maybe<UpdatePostResponse>
}

export type EventMutationRsvpArgs = {
  input: RsvpInput
}

export type EventMutationCancelEventArgs = {
  input: CancelEventInput
}

export type EventMutationCancelTicketArgs = {
  input: CancelTicketInput
}

export type EventMutationCreatePostArgs = {
  input: CreatePostInput
}

export type EventMutationDeletePostArgs = {
  input: DeletePostInput
}

export type EventMutationLikePostArgs = {
  input: LikePostInput
}

export type EventMutationMarkFeedbackModalAsSeenArgs = {
  input: MarkFeedbackModalAsSeenInput
}

export type EventMutationSetGroupArgs = {
  input: SetGroupInput
}

export type EventMutationSubmitAttendeeFeedbackArgs = {
  input: SubmitAttendeeFeedbackInput
}

export type EventMutationSubmitHostFeedbackArgs = {
  input: SubmitHostFeedbackInput
}

export type EventMutationUnlikePostArgs = {
  input: UnlikePostInput
}

export type EventMutationUpdatePostArgs = {
  input: UpdatePostInput
}

export enum EventOrderBy {
  EndAt = "END_AT",
  StartAt = "START_AT",
}

export type EventOrderInput = {
  by: EventOrderBy
  direction: ConnectionOrderDirection
}

export type EventPost = {
  __typename?: "EventPost"
  /** Can the current user like/unlike this post? */
  canLike?: Maybe<Scalars["Boolean"]>
  /** Can the current user create a child post of this post? */
  canPost?: Maybe<Scalars["Boolean"]>
  /** Can the current user update or delete this post? */
  canUpdate?: Maybe<Scalars["Boolean"]>
  /** Created at */
  createdAt?: Maybe<Scalars["ISODateTime"]>
  /** The author of this post */
  createdBy?: Maybe<User>
  /** Globally unique ID */
  id?: Maybe<Scalars["ID"]>
  /** This post's likes. */
  likes?: Maybe<EventPostLikeConnection>
  /** The current user's EventPostLike on this post. */
  myLike?: Maybe<EventPostLike>
  /** This post's parent post. */
  parentPost?: Maybe<EventPost>
  /** This post's child posts. */
  posts?: Maybe<EventPostConnection>
  /** Text content associated with this post */
  textBody?: Maybe<Scalars["String"]>
  /** Updated at */
  updatedAt?: Maybe<Scalars["ISODateTime"]>
}

export type EventPostLikesArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventPostLikeOrderInput>>
}

export type EventPostPostsArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventPostOrderInput>>
}

export type EventPostConnection = {
  __typename?: "EventPostConnection"
  edges?: Maybe<Array<Maybe<EventPostEdge>>>
  nodes?: Maybe<Array<Maybe<EventPost>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type EventPostEdge = {
  __typename?: "EventPostEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<EventPost>
}

export type EventPostLike = {
  __typename?: "EventPostLike"
  /** Created at */
  createdAt?: Maybe<Scalars["ISODateTime"]>
  /** Globally unique ID */
  id: Scalars["ID"]
  /** The user who created the like */
  likedBy: User
  /** Updated at */
  updatedAt?: Maybe<Scalars["ISODateTime"]>
}

export type EventPostLikeConnection = {
  __typename?: "EventPostLikeConnection"
  edges?: Maybe<Array<Maybe<EventPostLikeEdge>>>
  nodes?: Maybe<Array<Maybe<EventPostLike>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type EventPostLikeEdge = {
  __typename?: "EventPostLikeEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<EventPostLike>
}

export enum EventPostLikeOrderBy {
  CreatedAt = "CREATED_AT",
}

export type EventPostLikeOrderInput = {
  by: EventPostLikeOrderBy
  direction: ConnectionOrderDirection
  nulls?: InputMaybe<ConnectionOrderNulls>
}

export enum EventPostOrderBy {
  CreatedAt = "CREATED_AT",
}

export type EventPostOrderInput = {
  by: EventPostOrderBy
  direction: ConnectionOrderDirection
  nulls?: InputMaybe<ConnectionOrderNulls>
}

export type EventRemoveHostInput = {
  eventId: Scalars["String"]
  userId: Scalars["String"]
}

export type EventRemoveHostResponse = {
  __typename?: "EventRemoveHostResponse"
  hostship?: Maybe<Hostship>
}

export type EventReview = {
  __typename?: "EventReview"
  feedbackForHost?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["ID"]>
  ratingForHost?: Maybe<Scalars["Int"]>
}

export type EventReviewConnection = {
  __typename?: "EventReviewConnection"
  edges?: Maybe<Array<Maybe<EventReviewEdge>>>
  nodes?: Maybe<Array<Maybe<EventReview>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type EventReviewEdge = {
  __typename?: "EventReviewEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<EventReview>
}

export enum EventStatus {
  Archived = "ARCHIVED",
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Live = "LIVE",
  OnHold = "ON_HOLD",
  Submitted = "SUBMITTED",
}

export type EventUpdateHostRoleInput = {
  eventId: Scalars["String"]
  role: Scalars["String"]
  userId: Scalars["String"]
}

export type EventUpdateHostRoleResponse = {
  __typename?: "EventUpdateHostRoleResponse"
  hostships?: Maybe<Array<Maybe<Hostship>>>
}

export type ExternalGuest = {
  __typename?: "ExternalGuest"
  email: Scalars["String"]
  firstName: Scalars["String"]
  id: Scalars["ID"]
  lastName: Scalars["String"]
}

export type ExternalGuestConnection = {
  __typename?: "ExternalGuestConnection"
  edges?: Maybe<Array<Maybe<ExternalGuestEdge>>>
  nodes?: Maybe<Array<Maybe<ExternalGuest>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type ExternalGuestEdge = {
  __typename?: "ExternalGuestEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<ExternalGuest>
}

export type Features = {
  __typename?: "Features"
  /** Is the inbox search feature enabled? */
  inboxSearch: Scalars["Boolean"]
}

export type File = {
  __typename?: "File"
  encoding: Scalars["String"]
  filename: Scalars["String"]
  id?: Maybe<Scalars["ID"]>
  mimetype: Scalars["String"]
}

export type GeocodeAddressResponse = {
  __typename?: "GeocodeAddressResponse"
  latitude?: Maybe<Scalars["Float"]>
  longitude?: Maybe<Scalars["Float"]>
}

export type Group = {
  __typename?: "Group"
  /** Description of what this group is about */
  about: Scalars["String"]
  /** Can the current user author a post in this group? */
  canPost: Scalars["Boolean"]
  /** Database ID */
  databaseId: Scalars["Int"]
  /** Posts made within this group, orderByColumn can be `lastActivityAt` or `createdAt` */
  discussionFeed?: Maybe<GroupPostConnection>
  /** Events shared within this group */
  events?: Maybe<EventConnection>
  /** Short version of the about field that introduces the group */
  headline: Scalars["String"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** Is the current user a member of this group? */
  isMember: Scalars["Boolean"]
  /** Is the current user a moderator of this group? */
  isModerator: Scalars["Boolean"]
  /** Is the current user a pending member of this group? */
  isPendingMember: Scalars["Boolean"]
  /** Is the group private? */
  isPrivate: Scalars["Boolean"]
  /** Unique name. Do not use as a slug! */
  name: Scalars["String"]
  /**
   * All participants of this group
   *
   * roles: When supplied, only return participants that match this role. When not provided, no filtering is applied.
   */
  participants?: Maybe<GroupParticipantConnection>
  /** URL for the header picture of the group */
  pictureUrl: Scalars["String"]
  /** Questions specified by moderators */
  questions?: Maybe<GroupQuestionConnection>
  /** Group rules provided by the curator */
  rules?: Maybe<Scalars["String"]>
  /** Unique slug used to construct URLs */
  slug: Scalars["String"]
}

export type GroupDiscussionFeedArgs = {
  connection?: InputMaybe<ConnectionInput>
  orderByColumn?: InputMaybe<Scalars["String"]>
  orders?: InputMaybe<Array<GroupPostOrderInput>>
}

export type GroupEventsArgs = {
  connection?: InputMaybe<ConnectionInput>
  states?: InputMaybe<Array<EventStatus>>
}

export type GroupParticipantsArgs = {
  connection?: InputMaybe<ConnectionInput>
  roles?: InputMaybe<Array<GroupParticipantRole>>
}

export type GroupQuestionsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type GroupAnswer = {
  __typename?: "GroupAnswer"
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** Group Question */
  question: GroupQuestion
  /** Text content associated with this answer */
  textBody: Scalars["String"]
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
}

export type GroupAnswerConnection = {
  __typename?: "GroupAnswerConnection"
  edges?: Maybe<Array<Maybe<GroupAnswerEdge>>>
  nodes?: Maybe<Array<Maybe<GroupAnswer>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupAnswerEdge = {
  __typename?: "GroupAnswerEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<GroupQuestion>
}

export type GroupConnection = {
  __typename?: "GroupConnection"
  edges?: Maybe<Array<Maybe<GroupEdge>>>
  nodes?: Maybe<Array<Maybe<Group>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupContentModerationReason = {
  category?: InputMaybe<GroupContentModerationReasonCategory>
  context?: InputMaybe<Scalars["String"]>
  contextForPostAuthor?: InputMaybe<Scalars["String"]>
}

export enum GroupContentModerationReasonCategory {
  DisruptiveContent = "DISRUPTIVE_CONTENT",
  DuplicateContent = "DUPLICATE_CONTENT",
  Other = "OTHER",
  UnwantedProductPromotion = "UNWANTED_PRODUCT_PROMOTION",
  ViolatesGroupSpecificGuidelines = "VIOLATES_GROUP_SPECIFIC_GUIDELINES",
  ViolatesPlatformGuidelines = "VIOLATES_PLATFORM_GUIDELINES",
}

export type GroupCreatePostInput = {
  attachedDatabaseEventIds?: InputMaybe<Array<Scalars["String"]>>
  attachedEventIds?: InputMaybe<Array<Scalars["ID"]>>
  attachedFiles?: InputMaybe<Array<RevelFileUpload>>
  groupId: Scalars["ID"]
  mentionedUserIds?: InputMaybe<Array<Scalars["ID"]>>
  parentPostId?: InputMaybe<Scalars["ID"]>
  status?: Scalars["String"]
  textBody: Scalars["String"]
}

export type GroupCreatePostReactionInput = {
  content?: InputMaybe<ReactionContent>
  postId: Scalars["ID"]
}

export type GroupCreatePostReactionResponse = {
  __typename?: "GroupCreatePostReactionResponse"
  createdReaction?: Maybe<Reaction>
  groupPost?: Maybe<GroupPost>
}

export type GroupCreatePostResponse = {
  __typename?: "GroupCreatePostResponse"
  createdGroupPost?: Maybe<GroupPost>
  group?: Maybe<Group>
}

export type GroupDeletePostInput = {
  postId: Scalars["ID"]
  reason?: InputMaybe<GroupContentModerationReason>
}

export type GroupDeletePostReactionInput = {
  postId: Scalars["ID"]
}

export type GroupDeletePostReactionResponse = {
  __typename?: "GroupDeletePostReactionResponse"
  groupPost?: Maybe<GroupPost>
}

export type GroupDeletePostResponse = {
  __typename?: "GroupDeletePostResponse"
  group?: Maybe<Group>
  parentPost?: Maybe<GroupPost>
}

export type GroupEdge = {
  __typename?: "GroupEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Group>
}

export type GroupFeaturePostInput = {
  postId: Scalars["ID"]
}

export type GroupFeaturePostResponse = {
  __typename?: "GroupFeaturePostResponse"
  groupPost?: Maybe<GroupPost>
}

export type GroupJoinInput = {
  answers?: InputMaybe<Array<GroupQuestionAnswerInput>>
  groupId: Scalars["ID"]
}

export type GroupJoinResponse = {
  __typename?: "GroupJoinResponse"
  createdGroupParticipant?: Maybe<GroupParticipant>
  group?: Maybe<Group>
}

export type GroupLeaveInput = {
  groupId: Scalars["ID"]
}

export type GroupLeaveResponse = {
  __typename?: "GroupLeaveResponse"
  group?: Maybe<Group>
  user?: Maybe<User>
}

export type GroupLockPostInput = {
  postId: Scalars["ID"]
  reason?: InputMaybe<GroupContentModerationReason>
}

export type GroupLockPostResponse = {
  __typename?: "GroupLockPostResponse"
  groupPost?: Maybe<GroupPost>
}

export enum GroupMembershipStatus {
  /** Match both joined and not joined */
  Any = "ANY",
  /** Must be joined, but don't consider the role */
  Joined = "JOINED",
  /** Only match when role is member */
  MemberRole = "MEMBER_ROLE",
  /** Only match when role is moderator */
  ModeratorRole = "MODERATOR_ROLE",
  /** Must be not joined */
  NotJoined = "NOT_JOINED",
}

export type GroupMutation = {
  __typename?: "GroupMutation"
  /** Approve a member request to join a group */
  approveMemberRequestToJoin?: Maybe<ApproveMemberRequestToJoinResponse>
  createPost?: Maybe<GroupCreatePostResponse>
  createPostReaction?: Maybe<GroupCreatePostReactionResponse>
  deletePost?: Maybe<GroupDeletePostResponse>
  deletePostReaction?: Maybe<GroupDeletePostReactionResponse>
  featurePost?: Maybe<GroupFeaturePostResponse>
  join?: Maybe<GroupJoinResponse>
  leave?: Maybe<GroupLeaveResponse>
  /** Lock a post and all of its descendant posts. */
  lockPost?: Maybe<GroupLockPostResponse>
  pinPost?: Maybe<GroupPinPostResponse>
  /** Reject a member request to join a group */
  rejectMemberRequestToJoin?: Maybe<RejectMemberRequestToJoinResponse>
  /** Unlock a post and all of its descendant posts. */
  unlockPost?: Maybe<GroupUnlockPostResponse>
  unpinPost?: Maybe<GroupUnpinPostResponse>
  updatePost?: Maybe<GroupUpdatePostResponse>
}

export type GroupMutationApproveMemberRequestToJoinArgs = {
  input: ApproveMemberRequestToJoinInput
}

export type GroupMutationCreatePostArgs = {
  input: GroupCreatePostInput
}

export type GroupMutationCreatePostReactionArgs = {
  input: GroupCreatePostReactionInput
}

export type GroupMutationDeletePostArgs = {
  input: GroupDeletePostInput
}

export type GroupMutationDeletePostReactionArgs = {
  input: GroupDeletePostReactionInput
}

export type GroupMutationFeaturePostArgs = {
  input: GroupFeaturePostInput
}

export type GroupMutationJoinArgs = {
  input: GroupJoinInput
}

export type GroupMutationLeaveArgs = {
  input: GroupLeaveInput
}

export type GroupMutationLockPostArgs = {
  input: GroupLockPostInput
}

export type GroupMutationPinPostArgs = {
  input: GroupPinPostInput
}

export type GroupMutationRejectMemberRequestToJoinArgs = {
  input: RejectMemberRequestToJoinInput
}

export type GroupMutationUnlockPostArgs = {
  input: GroupUnlockPostInput
}

export type GroupMutationUnpinPostArgs = {
  input: GroupUnpinPostInput
}

export type GroupMutationUpdatePostArgs = {
  input: GroupUpdatePostInput
}

export enum GroupOrderBy {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  PinOrder = "PIN_ORDER",
}

export type GroupOrderInput = {
  by: GroupOrderBy
  direction: ConnectionOrderDirection
  nulls?: InputMaybe<ConnectionOrderNulls>
}

export type GroupParticipant = {
  __typename?: "GroupParticipant"
  /** Answers to group questions */
  answers: GroupAnswerConnection
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Group object of this participant entry */
  group: Group
  /** Globally unique ID */
  id: Scalars["ID"]
  /** The role of this participant */
  role: GroupParticipantRole
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
  /** User object of this participant entry */
  user: User
}

export type GroupParticipantAnswersArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type GroupParticipantConnection = {
  __typename?: "GroupParticipantConnection"
  edges?: Maybe<Array<Maybe<GroupParticipantEdge>>>
  nodes?: Maybe<Array<Maybe<GroupParticipant>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupParticipantEdge = {
  __typename?: "GroupParticipantEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<GroupParticipant>
}

export enum GroupParticipantRole {
  DeclinedMember = "DECLINED_MEMBER",
  Member = "MEMBER",
  Moderator = "MODERATOR",
  PendingMember = "PENDING_MEMBER",
}

export type GroupPinPostInput = {
  postId: Scalars["ID"]
}

export type GroupPinPostResponse = {
  __typename?: "GroupPinPostResponse"
  groupPost?: Maybe<GroupPost>
  unpinnedPosts?: Maybe<Array<GroupPost>>
}

export type GroupPost = {
  __typename?: "GroupPost"
  /** The attachments that are part of this post */
  attachments?: Maybe<GroupPostAttachmentConnection>
  /**
   * Can this post be deleted?
   *
   * To delete a post, call the deletePost mutation.
   */
  canDelete: Scalars["Boolean"]
  /**
   * Is this post OR any of its children lockable?
   *
   * To lock a post and all of its children, call the lockPost mutation.
   */
  canLock: Scalars["Boolean"]
  /**
   * Can the current user pin this post?
   *
   * To pin a post, call the pinPost mutation.
   */
  canPin: Scalars["Boolean"]
  /** Can a child post be created on this post? */
  canPost: Scalars["Boolean"]
  /**
   * Is this post OR any of its children unlockable?
   *
   * To unlock a post and all of its children, call the unlockPost mutation.
   */
  canUnlock: Scalars["Boolean"]
  /**
   * Can the current user unpin this post?
   *
   * To unpin a post, call the unpinPost mutation.
   */
  canUnpin: Scalars["Boolean"]
  /** Can the user update the content of this post? */
  canUpdate: Scalars["Boolean"]
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** The author of this post */
  createdBy: User
  /** Database ID */
  databaseId: Scalars["Int"]
  /** The group in which this post was shared */
  group: Group
  /** Globally unique ID */
  id: Scalars["ID"]
  /** Is this a locked post? */
  isLocked: Scalars["Boolean"]
  /** Is the current user the creator of this post? */
  isMyPost: Scalars["Boolean"]
  /** Is this a pinned post? */
  isPinned: Scalars["Boolean"]
  /** Was the content of this post ever updated in its lifetime? */
  isUpdated: Scalars["Boolean"]
  /** Last activity at (including activity on any sub-comments, or reaction) */
  lastActivityAt: Scalars["ISODateTime"]
  /** The reaction of current user to this post */
  myReaction?: Maybe<Reaction>
  /** This posts' parent post; when present, it means this post was a reply on the parent post */
  parentPost?: Maybe<GroupPost>
  /** Posts made directly on this post (e.g. comments) */
  posts: GroupPostConnection
  /** Published at (differs from createdAt if a draft post is created first) */
  publishedAt?: Maybe<Scalars["ISODateTime"]>
  /** Reactions to this post */
  reactions: ReactionConnection
  /** Sequential ID for posts within this group; unique in combination with group */
  sequenceId: Scalars["Int"]
  /** The group post status */
  status: GroupPostStatus
  /** Text content associated with this post */
  textBody: Scalars["String"]
  /** The count of all children comments under a post or a comment */
  totalCommentCount: Scalars["Int"]
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
}

export type GroupPostAttachmentsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type GroupPostPostsArgs = {
  connection?: InputMaybe<ConnectionInput>
  orderByColumn?: InputMaybe<Scalars["String"]>
  orders?: InputMaybe<Array<GroupPostOrderInput>>
}

export type GroupPostReactionsArgs = {
  connection?: InputMaybe<ConnectionInput>
  content?: InputMaybe<ReactionContent>
}

export type GroupPostAttachment =
  | GroupPostAttachmentEvent
  | GroupPostAttachmentFile

export type GroupPostAttachmentConnection = {
  __typename?: "GroupPostAttachmentConnection"
  edges?: Maybe<Array<Maybe<GroupPostAttachmentEdge>>>
  nodes?: Maybe<Array<Maybe<GroupPostAttachment>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupPostAttachmentEdge = {
  __typename?: "GroupPostAttachmentEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<GroupPostAttachment>
}

export type GroupPostAttachmentEvent = {
  __typename?: "GroupPostAttachmentEvent"
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** This attachment's event */
  event: Event
  /** Globally unique ID */
  id: Scalars["ID"]
  /** This attachment's post */
  post: GroupPost
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
}

export type GroupPostAttachmentFile = {
  __typename?: "GroupPostAttachmentFile"
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** MIME type of the file */
  mimeType: Scalars["String"]
  /** This attachment's post */
  post: GroupPost
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
  /** External URL where the file is stored */
  url: Scalars["String"]
}

export type GroupPostConnection = {
  __typename?: "GroupPostConnection"
  /**
   * Retrieve a specific edge in the connection
   *
   * - groupSlug: Group.slug to scope the discussion feed. Only required if the query does not specify a group
   * - postSequenceId: GroupPost.sequenceId. Always required.
   */
  edge?: Maybe<GroupPostEdge>
  edges?: Maybe<Array<Maybe<GroupPostEdge>>>
  nodes?: Maybe<Array<Maybe<GroupPost>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupPostConnectionEdgeArgs = {
  groupSlug?: InputMaybe<Scalars["String"]>
  postSequenceId?: InputMaybe<Scalars["Int"]>
}

export type GroupPostEdge = {
  __typename?: "GroupPostEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<GroupPost>
}

export enum GroupPostOrderBy {
  CreatedAt = "CREATED_AT",
  LastActivityAt = "LAST_ACTIVITY_AT",
  PinOrder = "PIN_ORDER",
}

export type GroupPostOrderInput = {
  by: GroupPostOrderBy
  direction: ConnectionOrderDirection
  nulls?: InputMaybe<ConnectionOrderNulls>
}

export type GroupPostSequenceInput = {
  groupSlug: Scalars["String"]
  sequenceId: Scalars["Int"]
}

export enum GroupPostStatus {
  Deleted = "DELETED",
  Draft = "DRAFT",
  Locked = "LOCKED",
  Published = "PUBLISHED",
}

export type GroupQuestion = {
  __typename?: "GroupQuestion"
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** Text content associated with this question */
  textBody: Scalars["String"]
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
}

export type GroupQuestionAnswerInput = {
  questionId: Scalars["ID"]
  textBody: Scalars["String"]
}

export type GroupQuestionConnection = {
  __typename?: "GroupQuestionConnection"
  edges?: Maybe<Array<Maybe<GroupQuestionEdge>>>
  nodes?: Maybe<Array<Maybe<GroupQuestion>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type GroupQuestionEdge = {
  __typename?: "GroupQuestionEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<GroupQuestion>
}

export enum GroupStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Submitted = "SUBMITTED",
}

export type GroupUnlockPostInput = {
  postId: Scalars["ID"]
}

export type GroupUnlockPostResponse = {
  __typename?: "GroupUnlockPostResponse"
  groupPost?: Maybe<GroupPost>
}

export type GroupUnpinPostInput = {
  postId: Scalars["ID"]
}

export type GroupUnpinPostResponse = {
  __typename?: "GroupUnpinPostResponse"
  groupPost?: Maybe<GroupPost>
}

export type GroupUpdatePostInput = {
  mentionedUserIds?: InputMaybe<Array<Scalars["ID"]>>
  postId: Scalars["ID"]
  textBody?: InputMaybe<Scalars["String"]>
}

export type GroupUpdatePostResponse = {
  __typename?: "GroupUpdatePostResponse"
  updatedGroupPost?: Maybe<GroupPost>
}

export type Hostship = {
  __typename?: "Hostship"
  easyToCreateAndSubmitEvent?: Maybe<Scalars["Boolean"]>
  event?: Maybe<Event>
  feedback?: Maybe<Scalars["String"]>
  feltSupportedByRevel?: Maybe<Scalars["Boolean"]>
  hostRole?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["ID"]>
  metOtherRevelers?: Maybe<Scalars["Boolean"]>
  rating?: Maybe<Scalars["Int"]>
  user?: Maybe<User>
}

export type HostshipConnection = {
  __typename?: "HostshipConnection"
  edges?: Maybe<Array<Maybe<HostshipEdge>>>
  nodes?: Maybe<Array<Maybe<Hostship>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type HostshipEdge = {
  __typename?: "HostshipEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Hostship>
}

export type Inbox = {
  __typename?: "Inbox"
  id?: Maybe<Scalars["ID"]>
  messages?: Maybe<ThreadMessageConnection>
  thread?: Maybe<Thread>
  threads?: Maybe<ThreadConnection>
}

export type InboxMessagesArgs = {
  connection?: InputMaybe<ConnectionInput>
  read?: InputMaybe<Scalars["Boolean"]>
}

export type InboxThreadArgs = {
  id?: InputMaybe<Scalars["ID"]>
}

export type InboxThreadsArgs = {
  connection?: InputMaybe<ConnectionInput>
  read?: InputMaybe<Scalars["Boolean"]>
  search?: InputMaybe<Scalars["String"]>
}

export type InboxCreateMessageInput = {
  textBody?: InputMaybe<Scalars["String"]>
  threadId?: InputMaybe<Scalars["ID"]>
}

export type InboxCreateMessageResponse = {
  __typename?: "InboxCreateMessageResponse"
  message?: Maybe<ThreadMessage>
}

export type InboxCreateThreadInput = {
  message: Scalars["String"]
  participantIds: Array<Scalars["ID"]>
  subject: Scalars["String"]
}

export type InboxCreateThreadResponse = {
  __typename?: "InboxCreateThreadResponse"
  thread?: Maybe<Thread>
}

export type InboxDeleteMessageInput = {
  messageId?: InputMaybe<Scalars["ID"]>
}

export type InboxDeleteMessageResponse = {
  __typename?: "InboxDeleteMessageResponse"
  message?: Maybe<ThreadMessage>
}

export type InboxLeaveThreadInput = {
  threadId: Scalars["ID"]
}

export type InboxLeaveThreadResponse = {
  __typename?: "InboxLeaveThreadResponse"
  thread?: Maybe<Thread>
}

export type InboxMarkThreadAsReadInput = {
  threadId: Scalars["ID"]
}

export type InboxMarkThreadAsReadResponse = {
  __typename?: "InboxMarkThreadAsReadResponse"
  thread?: Maybe<Thread>
}

export type Interest = {
  __typename?: "Interest"
  icon: Scalars["String"]
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type InterestConnection = {
  __typename?: "InterestConnection"
  edges?: Maybe<Array<Maybe<InterestEdge>>>
  nodes?: Maybe<Array<Maybe<Interest>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type InterestEdge = {
  __typename?: "InterestEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Interest>
}

export enum InterestOrderBy {
  Name = "NAME",
  OrderKey = "ORDER_KEY",
}

export type InterestOrderInput = {
  by: InterestOrderBy
  direction: ConnectionOrderDirection
}

export type LifeTransition = {
  __typename?: "LifeTransition"
  id?: Maybe<Scalars["ID"]>
  name?: Maybe<Scalars["String"]>
}

export type LifeTransitionConnection = {
  __typename?: "LifeTransitionConnection"
  edges?: Maybe<Array<Maybe<LifeTransitionEdge>>>
  nodes?: Maybe<Array<Maybe<LifeTransition>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type LifeTransitionEdge = {
  __typename?: "LifeTransitionEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<LifeTransition>
}

export type LikePostInput = {
  postId: Scalars["ID"]
}

export type LikePostResponse = {
  __typename?: "LikePostResponse"
  eventPost?: Maybe<EventPost>
}

export type Location = {
  __typename?: "Location"
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
}

export type MarkAsReadInput = {
  id?: InputMaybe<Scalars["ID"]>
  pushNotification?: InputMaybe<MarkAsReadPushNotificationInput>
}

export type MarkAsReadPushNotificationInput = {
  link: Scalars["String"]
  type: Scalars["Int"]
}

export type MarkAsReadResponse = {
  __typename?: "MarkAsReadResponse"
  notification?: Maybe<Notification>
}

export type MarkFeedbackModalAsSeenInput = {
  dbEventId: Scalars["ID"]
  markRestOfUserTicketsAsSeen?: InputMaybe<Scalars["Boolean"]>
}

export type MarkFeedbackModalAsSeenResponse = {
  __typename?: "MarkFeedbackModalAsSeenResponse"
  event?: Maybe<Event>
}

export type MarkPostJoinModalAsSeenResponse = {
  __typename?: "MarkPostJoinModalAsSeenResponse"
  user?: Maybe<User>
}

export type MemberEventActions = {
  __typename?: "MemberEventActions"
  attending?: Maybe<EventConnection>
  critical?: Maybe<EventConnection>
  hosting?: Maybe<EventConnection>
  unreviewed?: Maybe<EventConnection>
}

export type MemberEventActionsAttendingArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventOrderInput>>
}

export type MemberEventActionsCriticalArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventOrderInput>>
}

export type MemberEventActionsHostingArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventOrderInput>>
}

export type MemberEventActionsUnreviewedArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<EventOrderInput>>
}

export type Mutation = {
  __typename?: "Mutation"
  contentReport?: Maybe<ContentReportMutation>
  device?: Maybe<DeviceMutation>
  event?: Maybe<EventMutation>
  eventAddHost?: Maybe<EventAddHostResponse>
  eventRemoveHost?: Maybe<EventRemoveHostResponse>
  eventUpdateHostRole?: Maybe<EventUpdateHostRoleResponse>
  group?: Maybe<GroupMutation>
  inboxCreateMessage?: Maybe<InboxCreateMessageResponse>
  inboxCreateThread?: Maybe<InboxCreateThreadResponse>
  inboxDeleteMessage?: Maybe<InboxDeleteMessageResponse>
  inboxLeaveThread?: Maybe<InboxLeaveThreadResponse>
  inboxMarkThreadAsRead?: Maybe<InboxMarkThreadAsReadResponse>
  notification?: Maybe<NotificationMutation>
  /** @deprecated Use `notification.markAllAsSeen`. */
  notificationsUpdateLastViewedAt?: Maybe<NotificationMarkAllAsSeenResponse>
  payment?: Maybe<PaymentMutation>
  restrictUser?: Maybe<RestrictUserMutation>
  user?: Maybe<UserMutation>
  version?: Maybe<VersionMutation>
}

export type MutationEventAddHostArgs = {
  input: EventAddHostInput
}

export type MutationEventRemoveHostArgs = {
  input: EventRemoveHostInput
}

export type MutationEventUpdateHostRoleArgs = {
  input: EventUpdateHostRoleInput
}

export type MutationInboxCreateMessageArgs = {
  input: InboxCreateMessageInput
}

export type MutationInboxCreateThreadArgs = {
  input?: InputMaybe<InboxCreateThreadInput>
}

export type MutationInboxDeleteMessageArgs = {
  input: InboxDeleteMessageInput
}

export type MutationInboxLeaveThreadArgs = {
  input?: InputMaybe<InboxLeaveThreadInput>
}

export type MutationInboxMarkThreadAsReadArgs = {
  input?: InputMaybe<InboxMarkThreadAsReadInput>
}

export type Notification = {
  __typename?: "Notification"
  category: Scalars["String"]
  createdAt: Scalars["ISODateTime"]
  id: Scalars["ID"]
  link: Scalars["String"]
  message: Scalars["String"]
  pictureUrl?: Maybe<Scalars["String"]>
  read: Scalars["Boolean"]
  seen: Scalars["Boolean"]
  source?: Maybe<NotificationSource>
  type: NotificationType
  updatedAt: Scalars["ISODateTime"]
  user: User
}

export type NotificationConnection = {
  __typename?: "NotificationConnection"
  edges?: Maybe<Array<Maybe<NotificationEdge>>>
  nodes?: Maybe<Array<Maybe<Notification>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type NotificationEdge = {
  __typename?: "NotificationEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Notification>
}

export type NotificationMarkAllAsReadResponse = {
  __typename?: "NotificationMarkAllAsReadResponse"
  notifications?: Maybe<Notifications>
}

export type NotificationMarkAllAsSeenResponse = {
  __typename?: "NotificationMarkAllAsSeenResponse"
  lastViewedNotificationsAt?: Maybe<Scalars["ISODateTime"]>
  notifications?: Maybe<Notifications>
}

export type NotificationMutation = {
  __typename?: "NotificationMutation"
  markAllAsRead?: Maybe<NotificationMarkAllAsReadResponse>
  markAllAsSeen?: Maybe<NotificationMarkAllAsSeenResponse>
  markAsRead?: Maybe<MarkAsReadResponse>
}

export type NotificationMutationMarkAsReadArgs = {
  input: MarkAsReadInput
}

export enum NotificationSource {
  Events = "EVENTS",
  Groups = "GROUPS",
}

export enum NotificationType {
  AttendeeCancellation = "ATTENDEE_CANCELLATION",
  AttendeeSignup = "ATTENDEE_SIGNUP",
  CommentReply = "COMMENT_REPLY",
  EventAttendeeFeedback = "EVENT_ATTENDEE_FEEDBACK",
  EventHostFeedback = "EVENT_HOST_FEEDBACK",
  GroupNewMember = "GROUP_NEW_MEMBER",
  GroupNewPost = "GROUP_NEW_POST",
  GroupNewSharedEvent = "GROUP_NEW_SHARED_EVENT",
  GroupPostComment = "GROUP_POST_COMMENT",
  GroupPostMention = "GROUP_POST_MENTION",
  GroupPostReact = "GROUP_POST_REACT",
  GroupSubsequentComment = "GROUP_SUBSEQUENT_COMMENT",
  JustPostedEvent = "JUST_POSTED_EVENT",
  NewMessage = "NEW_MESSAGE",
  RevelExpertsPromotion = "REVEL_EXPERTS_PROMOTION",
}

export type Notifications = {
  __typename?: "Notifications"
  id?: Maybe<Scalars["ID"]>
  notifications?: Maybe<NotificationConnection>
  unreadCount?: Maybe<Scalars["Int"]>
  unseenCount?: Maybe<Scalars["Int"]>
}

export type NotificationsNotificationsArgs = {
  connection?: InputMaybe<ConnectionInput>
  sources?: InputMaybe<Array<NotificationSource>>
}

export type NotificationsUnreadCountArgs = {
  source?: InputMaybe<NotificationSource>
}

export type NotificationsUnseenCountArgs = {
  source?: InputMaybe<NotificationSource>
}

export type PageInfo = {
  __typename?: "PageInfo"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  hasPreviousPage: Scalars["Boolean"]
  startCursor?: Maybe<Scalars["String"]>
}

export enum ParticipantRole {
  Attendee = "ATTENDEE",
  HostOrCohost = "HOST_OR_COHOST",
}

export enum PaymentMethod {
  AppleAppStore = "APPLE_APP_STORE",
  GooglePlayStore = "GOOGLE_PLAY_STORE",
  Stripe = "STRIPE",
}

export type PaymentMutation = {
  __typename?: "PaymentMutation"
  cancelSubscriptionCheckoutSession?: Maybe<SubscriptionCheckoutSessionResponse>
  createEventCheckoutSession?: Maybe<EventCheckoutSessionResponse>
  createSubscriptionCheckoutSession?: Maybe<SubscriptionCheckoutSessionResponse>
}

export type PaymentMutationCancelSubscriptionCheckoutSessionArgs = {
  input: SubscriptionCheckoutSessionInput
}

export type PaymentMutationCreateEventCheckoutSessionArgs = {
  input: EventCheckoutSessionInput
}

export type PaymentMutationCreateSubscriptionCheckoutSessionArgs = {
  input: SubscriptionCheckoutSessionInput
}

export type PromoteToPowerHostInput = {
  id: Scalars["ID"]
  notify: Scalars["Boolean"]
}

export type PromoteToPowerHostResponse = {
  __typename?: "PromoteToPowerHostResponse"
  user?: Maybe<User>
}

export type Query = {
  __typename?: "Query"
  currentUser?: Maybe<User>
  event?: Maybe<Event>
  eventPost?: Maybe<EventPost>
  events?: Maybe<EventConnection>
  geocodeAddress?: Maybe<GeocodeAddressResponse>
  group?: Maybe<Group>
  /**
   * Returns a collection of all posts across all groups.
   *
   * membershipStatus: only show posts that match the specific membership status
   */
  groupDiscussionFeed?: Maybe<GroupPostConnection>
  groupParticipant: GroupParticipant
  groupPost?: Maybe<GroupPost>
  groups?: Maybe<GroupConnection>
  inbox?: Maybe<Inbox>
  interests?: Maybe<InterestConnection>
  lifeTransitions?: Maybe<LifeTransitionConnection>
  notifications?: Maybe<Notifications>
  recommendedEvents?: Maybe<Array<Maybe<Event>>>
  recommendedGroups?: Maybe<Array<Maybe<Group>>>
  requiresSubscriptionOnRSVP: RequiresSubscriptionResponse
  searchKey?: Maybe<SearchKey>
  user?: Maybe<User>
  users?: Maybe<UserConnection>
}

export type QueryEventArgs = {
  databaseId?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  slug?: InputMaybe<Scalars["String"]>
}

export type QueryEventPostArgs = {
  id?: InputMaybe<Scalars["ID"]>
}

export type QueryEventsArgs = {
  connection?: InputMaybe<ConnectionInput>
  search?: InputMaybe<Scalars["String"]>
  states?: InputMaybe<Array<EventStatus>>
}

export type QueryGeocodeAddressArgs = {
  address: Scalars["String"]
}

export type QueryGroupArgs = {
  id?: InputMaybe<Scalars["ID"]>
  slug?: InputMaybe<Scalars["String"]>
}

export type QueryGroupDiscussionFeedArgs = {
  authoredById?: InputMaybe<Scalars["ID"]>
  connection?: InputMaybe<ConnectionInput>
  membershipStatus: GroupMembershipStatus
  orders?: InputMaybe<Array<GroupPostOrderInput>>
}

export type QueryGroupParticipantArgs = {
  id: Scalars["ID"]
}

export type QueryGroupPostArgs = {
  id?: InputMaybe<Scalars["ID"]>
  input?: InputMaybe<QueryGroupPostInput>
}

export type QueryGroupsArgs = {
  canPost?: InputMaybe<Scalars["Boolean"]>
  connection?: InputMaybe<ConnectionInput>
  isMember?: InputMaybe<Scalars["Boolean"]>
  orders?: InputMaybe<Array<GroupOrderInput>>
  statuses?: InputMaybe<Array<InputMaybe<GroupStatus>>>
}

export type QueryInterestsArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<InterestOrderInput>>
}

export type QueryLifeTransitionsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type QueryRecommendedEventsArgs = {
  isFree?: InputMaybe<Scalars["Boolean"]>
}

export type QueryRequiresSubscriptionOnRsvpArgs = {
  id: Scalars["ID"]
}

export type QueryUserArgs = {
  auth0id?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
}

export type QueryUsersArgs = {
  auth0ids?: InputMaybe<Array<Scalars["String"]>>
  connection?: InputMaybe<ConnectionInput>
  ids?: InputMaybe<Array<Scalars["ID"]>>
  search?: InputMaybe<Scalars["String"]>
}

export type QueryGroupPostInput = {
  id?: InputMaybe<Scalars["ID"]>
  sequence?: InputMaybe<GroupPostSequenceInput>
}

export type RsvpInput = {
  eventId: Scalars["ID"]
}

export type RsvpResponse = {
  __typename?: "RSVPResponse"
  ticket?: Maybe<Ticket>
}

export type Reaction = {
  __typename?: "Reaction"
  /** The content (emoji or icon) of this reaction */
  content: ReactionContent
  /** Created at */
  createdAt: Scalars["ISODateTime"]
  /** Globally unique ID */
  id: Scalars["ID"]
  /** The user who created the reaction */
  reactedBy: User
  /** Last updated at */
  updatedAt: Scalars["ISODateTime"]
}

export type ReactionConnection = {
  __typename?: "ReactionConnection"
  edges?: Maybe<Array<Maybe<ReactionEdge>>>
  nodes?: Maybe<Array<Maybe<Reaction>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export enum ReactionContent {
  Angry = "ANGRY",
  Clap = "CLAP",
  Heart = "HEART",
  Inspired = "INSPIRED",
  Laughing = "LAUGHING",
  Like = "LIKE",
  Sad = "SAD",
  Sparkle = "SPARKLE",
  Support = "SUPPORT",
}

export type ReactionEdge = {
  __typename?: "ReactionEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Reaction>
}

export type RejectMemberRequestToJoinInput = {
  groupId: Scalars["ID"]
  userId: Scalars["ID"]
}

export type RejectMemberRequestToJoinResponse = {
  __typename?: "RejectMemberRequestToJoinResponse"
  groupParticipant?: Maybe<GroupParticipant>
}

export type RequiresSubscriptionResponse = {
  __typename?: "RequiresSubscriptionResponse"
  numOtherTickets?: Maybe<Scalars["Int"]>
  requiresSubscription: Scalars["Boolean"]
}

/**
 * - Will promote to higher level when adding restrictions
 *   eg. updates to block if block called and currently muted,
 *   but does not update to mute if mute called and currently blocked
 * - Will remove lower level when removing restrictions
 *   eg. unmutes if unblock called and was only currently muted,
 *   but does not unblock if unmute called and currently blocked)
 * - removes restriction if restrictionType false, and existing
 *   restriction is same or lower level
 */
export type RestrictUserInput = {
  addRestriction?: InputMaybe<Scalars["Boolean"]>
  restrictionType?: InputMaybe<RestrictionType>
  userId: Scalars["String"]
}

export type RestrictUserMutation = {
  __typename?: "RestrictUserMutation"
  restrictUser?: Maybe<RestrictUserResponse>
}

export type RestrictUserMutationRestrictUserArgs = {
  input: RestrictUserInput
}

export type RestrictUserResponse = {
  __typename?: "RestrictUserResponse"
  message: Scalars["String"]
  restrictedUser: User
}

export enum RestrictionType {
  Block = "BLOCK",
  Mute = "MUTE",
}

export type RevelFileUpload = {
  mimeType: Scalars["String"]
  url: Scalars["String"]
}

export type SearchKey = {
  __typename?: "SearchKey"
  events?: Maybe<Scalars["String"]>
  groups?: Maybe<Scalars["String"]>
  members?: Maybe<Scalars["String"]>
  threads?: Maybe<Scalars["String"]>
}

export type SearchKeyGroupsArgs = {
  isMember?: InputMaybe<Scalars["Boolean"]>
}

export type SetGroupInput = {
  eventId: Scalars["ID"]
  groupId?: InputMaybe<Scalars["ID"]>
  textBody?: InputMaybe<Scalars["String"]>
}

export type SetGroupResponse = {
  __typename?: "SetGroupResponse"
  group?: Maybe<Group>
  groupPost?: Maybe<GroupPost>
}

export type SubmitAttendeeFeedbackInput = {
  attended: Scalars["Boolean"]
  eventId: Scalars["ID"]
  eventMatchedDescription?: InputMaybe<Scalars["Boolean"]>
  feedback?: InputMaybe<Scalars["String"]>
  feedbackForHost?: InputMaybe<Scalars["String"]>
  feedbackForRevel?: InputMaybe<Scalars["String"]>
  hostWelcomingAndOrganized?: InputMaybe<Scalars["Boolean"]>
  metOtherRevelers?: InputMaybe<Scalars["Boolean"]>
  rating?: InputMaybe<Scalars["Int"]>
  ratingForEvent?: InputMaybe<Scalars["Int"]>
  ratingForHost?: InputMaybe<Scalars["Int"]>
}

export type SubmitAttendeeFeedbackResponse = {
  __typename?: "SubmitAttendeeFeedbackResponse"
  ticket?: Maybe<Ticket>
}

export type SubmitHostFeedbackInput = {
  easyToCreateAndSubmitEvent?: InputMaybe<Scalars["Boolean"]>
  eventId: Scalars["ID"]
  feedback?: InputMaybe<Scalars["String"]>
  feltSupportedByRevel?: InputMaybe<Scalars["Boolean"]>
  metOtherRevelers?: InputMaybe<Scalars["Boolean"]>
  rating: Scalars["Int"]
}

export type SubmitHostFeedbackResponse = {
  __typename?: "SubmitHostFeedbackResponse"
  hostship?: Maybe<Hostship>
}

export type SubscriptionCheckoutSessionInput = {
  eventIds: Array<Scalars["ID"]>
}

export type SubscriptionCheckoutSessionResponse = {
  __typename?: "SubscriptionCheckoutSessionResponse"
  user: User
}

export type Thread = {
  __typename?: "Thread"
  createdAt?: Maybe<Scalars["ISODateTime"]>
  createdBy?: Maybe<User>
  databaseId?: Maybe<Scalars["Int"]>
  id?: Maybe<Scalars["ID"]>
  lastActivityAt?: Maybe<Scalars["ISODateTime"]>
  messages?: Maybe<ThreadMessageConnection>
  participants?: Maybe<UserConnection>
  subject?: Maybe<Scalars["String"]>
  updatedAt?: Maybe<Scalars["ISODateTime"]>
}

export type ThreadMessagesArgs = {
  connection?: InputMaybe<ConnectionInput>
  read?: InputMaybe<Scalars["Boolean"]>
}

export type ThreadParticipantsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type ThreadConnection = {
  __typename?: "ThreadConnection"
  edges?: Maybe<Array<Maybe<ThreadEdge>>>
  nodes?: Maybe<Array<Maybe<Thread>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type ThreadEdge = {
  __typename?: "ThreadEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Thread>
}

export type ThreadMessage = {
  __typename?: "ThreadMessage"
  createdAt?: Maybe<Scalars["ISODateTime"]>
  createdBy?: Maybe<User>
  databaseId?: Maybe<Scalars["Int"]>
  id?: Maybe<Scalars["ID"]>
  textBody?: Maybe<Scalars["String"]>
  thread?: Maybe<Thread>
  updatedAt?: Maybe<Scalars["ISODateTime"]>
}

export type ThreadMessageConnection = {
  __typename?: "ThreadMessageConnection"
  edges?: Maybe<Array<Maybe<ThreadMessageEdge>>>
  nodes?: Maybe<Array<Maybe<ThreadMessage>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type ThreadMessageEdge = {
  __typename?: "ThreadMessageEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<ThreadMessage>
}

export type Ticket = {
  __typename?: "Ticket"
  attended?: Maybe<Scalars["Boolean"]>
  cancelled?: Maybe<Scalars["Boolean"]>
  databaseId?: Maybe<Scalars["ID"]>
  event?: Maybe<Event>
  eventMatchedDescription?: Maybe<Scalars["Boolean"]>
  feedback?: Maybe<Scalars["String"]>
  feedbackForHost?: Maybe<Scalars["String"]>
  feedbackForRevel?: Maybe<Scalars["String"]>
  hostWelcomingAndOrganized?: Maybe<Scalars["Boolean"]>
  id?: Maybe<Scalars["ID"]>
  metOtherRevelers?: Maybe<Scalars["Boolean"]>
  rating?: Maybe<Scalars["Int"]>
  ratingForEvent?: Maybe<Scalars["Int"]>
  ratingForHost?: Maybe<Scalars["Int"]>
  user?: Maybe<User>
  waitlisted?: Maybe<Scalars["Boolean"]>
}

export type TicketConnection = {
  __typename?: "TicketConnection"
  edges?: Maybe<Array<Maybe<TicketEdge>>>
  nodes?: Maybe<Array<Maybe<Ticket>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type TicketEdge = {
  __typename?: "TicketEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<Ticket>
}

export enum TicketStatus {
  Attending = "ATTENDING",
  Cancelled = "CANCELLED",
  Waitlisted = "WAITLISTED",
}

export type ToggleHostFollowInput = {
  userDatabaseId: Scalars["ID"]
}

export type ToggleHostFollowResponse = {
  __typename?: "ToggleHostFollowResponse"
  user?: Maybe<User>
}

export type ToggleUserFavoriteInput = {
  userDatabaseId: Scalars["ID"]
}

export type ToggleUserFavoriteResponse = {
  __typename?: "ToggleUserFavoriteResponse"
  user?: Maybe<User>
}

export type UnlikePostInput = {
  postId: Scalars["ID"]
}

export type UnlikePostResponse = {
  __typename?: "UnlikePostResponse"
  eventPost?: Maybe<EventPost>
}

export type UpdatePostInput = {
  postId: Scalars["ID"]
  textBody?: InputMaybe<Scalars["String"]>
}

export type UpdatePostResponse = {
  __typename?: "UpdatePostResponse"
  updatedPost?: Maybe<EventPost>
}

export type User = {
  __typename?: "User"
  auth0Id?: Maybe<Scalars["String"]>
  /** The average rating for this user */
  averageRating?: Maybe<Scalars["Float"]>
  databaseId?: Maybe<Scalars["Int"]>
  /** Feature flags that are enabled for this user. */
  features?: Maybe<Features>
  /**
   * The value that the user has specified to be their first name (their given name).
   * This is only available for the current user! Chiefly useful for settings page where users can configure their firstName.
   *
   * If you want to show arbitrary user's names, consider using `shortName` or `fullName` instead.
   */
  firstName?: Maybe<Scalars["String"]>
  /** Returns full name while respecting privacy settings */
  fullName?: Maybe<Scalars["String"]>
  /** Did this user complete the signup flow? */
  hasCompletedSignup?: Maybe<Scalars["Boolean"]>
  id?: Maybe<Scalars["ID"]>
  interests?: Maybe<InterestConnection>
  /** Restriction status fields */
  isBlocked?: Maybe<Scalars["Boolean"]>
  /** Is the current user following this user's events? */
  isFollowingEvents?: Maybe<Scalars["Boolean"]>
  /** Is this user a host or higher role? */
  isHost?: Maybe<Scalars["Boolean"]>
  /** Is this user in the current user's address book? */
  isInAddressBook?: Maybe<Scalars["Boolean"]>
  isMuted?: Maybe<Scalars["Boolean"]>
  /** URL path where the user clicked /join */
  joinedFromPath?: Maybe<Scalars["String"]>
  /**
   * The value that the user has specified to be their last name (their family name).
   * This is only available for the current user! Chiefly useful for settings page where users can configure their lastName.
   *
   * If you want to show arbitrary user's names, consider using `shortName` or `fullName` instead.
   */
  lastName?: Maybe<Scalars["String"]>
  lifeTransitions?: Maybe<LifeTransitionConnection>
  location?: Maybe<Location>
  /** The member event actions for this user */
  memberEventActions?: Maybe<MemberEventActions>
  /** Moderation status fields */
  moderationStatus?: Maybe<Scalars["String"]>
  moderationStatusNotes?: Maybe<Scalars["String"]>
  moderationStatusUpdatedAt?: Maybe<Scalars["ISODateTime"]>
  moderationStatusUpdatedBy?: Maybe<Scalars["ID"]>
  /** Has this user seen the post join modal? */
  postJoinModalSeenAt?: Maybe<Scalars["ISODateTime"]>
  /** Short blurb about this user. Null if user is not a power host. */
  powerHostBio?: Maybe<Scalars["String"]>
  profilePictureUrl?: Maybe<Scalars["String"]>
  role?: Maybe<UserRole>
  /** Returns short name while respecting privacy settings */
  shortName?: Maybe<Scalars["String"]>
  slug?: Maybe<Scalars["String"]>
}

export type UserInterestsArgs = {
  connection?: InputMaybe<ConnectionInput>
  orders?: InputMaybe<Array<InterestOrderInput>>
}

export type UserLifeTransitionsArgs = {
  connection?: InputMaybe<ConnectionInput>
}

export type UserConnection = {
  __typename?: "UserConnection"
  edges?: Maybe<Array<Maybe<UserEdge>>>
  nodes?: Maybe<Array<Maybe<User>>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type UserEdge = {
  __typename?: "UserEdge"
  cursor?: Maybe<Scalars["String"]>
  node?: Maybe<User>
}

export enum UserModerationStatus {
  Active = "ACTIVE",
  DeletionAndBlockInProgress = "DELETION_AND_BLOCK_IN_PROGRESS",
  DeletionAndBlockRequested = "DELETION_AND_BLOCK_REQUESTED",
  DeletionInProgress = "DELETION_IN_PROGRESS",
  DeletionRequested = "DELETION_REQUESTED",
  Frozen = "FROZEN",
}

export type UserMutation = {
  __typename?: "UserMutation"
  markPostJoinModalAsSeen?: Maybe<MarkPostJoinModalAsSeenResponse>
  promoteToPowerHost?: Maybe<PromoteToPowerHostResponse>
  requestDeletion?: Maybe<UserRequestDeletionResponse>
  setLifeTransitions: UserSetLifeTransitionsResponse
  toggleHostFollow?: Maybe<ToggleHostFollowResponse>
  toggleUserFavorite?: Maybe<ToggleUserFavoriteResponse>
  updateEmail?: Maybe<UserUpdateEmailResponse>
  updateModerationStatus?: Maybe<UserUpdateModerationStatusResponse>
}

export type UserMutationPromoteToPowerHostArgs = {
  input?: InputMaybe<PromoteToPowerHostInput>
}

export type UserMutationRequestDeletionArgs = {
  input?: InputMaybe<UserRequestDeletionInput>
}

export type UserMutationSetLifeTransitionsArgs = {
  input: UserSetLifeTransitionsInput
}

export type UserMutationToggleHostFollowArgs = {
  input?: InputMaybe<ToggleHostFollowInput>
}

export type UserMutationToggleUserFavoriteArgs = {
  input?: InputMaybe<ToggleUserFavoriteInput>
}

export type UserMutationUpdateEmailArgs = {
  input?: InputMaybe<UserUpdateEmailInput>
}

export type UserMutationUpdateModerationStatusArgs = {
  input: UserUpdateModerationStatusInput
}

export enum UserOrderBy {
  CreatedAt = "CREATED_AT",
}

export type UserOrderInput = {
  by: UserOrderBy
  direction: ConnectionOrderDirection
  nulls?: InputMaybe<ConnectionOrderNulls>
}

export type UserRequestDeletionInput = {
  id: Scalars["ID"]
  shouldBlock?: InputMaybe<Scalars["Boolean"]>
}

export type UserRequestDeletionResponse = {
  __typename?: "UserRequestDeletionResponse"
  user: User
}

/** User roles, ordered by most permissive to least */
export enum UserRole {
  Admin = "ADMIN",
  Cl = "CL",
  Host = "HOST",
  PowerHost = "POWER_HOST",
  Staff = "STAFF",
  User = "USER",
}

export type UserSetLifeTransitionsInput = {
  lifeTransitionIds?: InputMaybe<Array<Scalars["ID"]>>
}

export type UserSetLifeTransitionsResponse = {
  __typename?: "UserSetLifeTransitionsResponse"
  user?: Maybe<User>
}

export type UserUpdateEmailInput = {
  email: Scalars["String"]
  id: Scalars["ID"]
}

export type UserUpdateEmailResponse = {
  __typename?: "UserUpdateEmailResponse"
  user: User
}

export type UserUpdateModerationStatusInput = {
  id: Scalars["ID"]
  moderationStatus: UserModerationStatus
  moderationStatusNotes: Scalars["String"]
}

export type UserUpdateModerationStatusResponse = {
  __typename?: "UserUpdateModerationStatusResponse"
  updatedStatus: UserModerationStatus
}

export type VersionCheckInput = {
  app?: InputMaybe<Scalars["SemanticVersion"]>
  bundle?: InputMaybe<Scalars["SemanticVersion"]>
}

export type VersionCheckResponse = {
  __typename?: "VersionCheckResponse"
  app?: Maybe<VersionStatus>
  bundle?: Maybe<VersionStatus>
  /** Message to show to the user. May be null regardless of version status. */
  message?: Maybe<Scalars["String"]>
}

export type VersionMutation = {
  __typename?: "VersionMutation"
  check?: Maybe<VersionCheckResponse>
}

export type VersionMutationCheckArgs = {
  input: VersionCheckInput
}

export enum VersionStatus {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
}

export type User__DeleteUserMutationMutationVariables = Exact<{
  id: Scalars["ID"]
  shouldBlock?: InputMaybe<Scalars["Boolean"]>
}>

export type User__DeleteUserMutationMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    requestDeletion?: {
      __typename?: "UserRequestDeletionResponse"
      user: { __typename?: "User"; moderationStatus?: string | null }
    } | null
  } | null
}

export type CreateEventCheckoutSessionMutationVariables = Exact<{
  eventIds: Array<Scalars["ID"]> | Scalars["ID"]
  paymentId?: InputMaybe<Scalars["String"]>
  paymentMethod?: InputMaybe<PaymentMethod>
  redirectUrl: Scalars["String"]
}>

export type CreateEventCheckoutSessionMutation = {
  __typename?: "Mutation"
  payment?: {
    __typename?: "PaymentMutation"
    createEventCheckoutSession?: {
      __typename?: "EventCheckoutSessionResponse"
      url?: string | null
    } | null
  } | null
}

export type RequiresSubscriptionOnRsvpQueryVariables = Exact<{
  eventId: Scalars["ID"]
}>

export type RequiresSubscriptionOnRsvpQuery = {
  __typename?: "Query"
  requiresSubscriptionOnRSVP: {
    __typename?: "RequiresSubscriptionResponse"
    numOtherTickets?: number | null
    requiresSubscription: boolean
  }
}

export type EventLookupQueryVariables = Exact<{
  search: Scalars["String"]
}>

export type EventLookupQuery = {
  __typename?: "Query"
  events?: {
    __typename?: "EventConnection"
    nodes?: Array<{
      __typename?: "Event"
      id?: string | null
      name?: string | null
      startsAt?: any | null
    } | null> | null
  } | null
}

export type EventsQueryVariables = Exact<{
  isFree?: InputMaybe<Scalars["Boolean"]>
}>

export type EventsQuery = {
  __typename?: "Query"
  recommendedEvents?: Array<{
    __typename?: "Event"
    image?: string | null
    name?: string | null
    slug: string
    startsAt?: any | null
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        user?: { __typename?: "User"; fullName?: string | null } | null
      } | null> | null
    } | null
  } | null> | null
}

export type AddToAddressBookButtonQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type AddToAddressBookButtonQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: string | null
    isInAddressBook?: boolean | null
    shortName?: string | null
  } | null
}

export type ToggleAddressBookMutationVariables = Exact<{
  userDatabaseId: Scalars["ID"]
}>

export type ToggleAddressBookMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    toggleUserFavorite?: {
      __typename?: "ToggleUserFavoriteResponse"
      user?: {
        __typename?: "User"
        id?: string | null
        isInAddressBook?: boolean | null
      } | null
    } | null
  } | null
}

export type BlockUser__RestrictUserMutationVariables = Exact<{
  addRestriction: Scalars["Boolean"]
  restrictionType: RestrictionType
  userId: Scalars["String"]
}>

export type BlockUser__RestrictUserMutation = {
  __typename?: "Mutation"
  restrictUser?: {
    __typename?: "RestrictUserMutation"
    restrictUser?: {
      __typename?: "RestrictUserResponse"
      message: string
      restrictedUser: {
        __typename?: "User"
        id?: string | null
        isBlocked?: boolean | null
      }
    } | null
  } | null
}

export type ContentReport__CreateContentReportMutationVariables = Exact<{
  contentId: Scalars["ID"]
  reason: Scalars["String"]
}>

export type ContentReport__CreateContentReportMutation = {
  __typename?: "Mutation"
  contentReport?: {
    __typename?: "ContentReportMutation"
    createContentReport?: {
      __typename?: "CreateContentReportResponse"
      contentReport?: { __typename?: "ContentReport"; id: string } | null
    } | null
  } | null
}

export type FollowHostEventsButtonQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type FollowHostEventsButtonQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: string | null
    isFollowingEvents?: boolean | null
    shortName?: string | null
  } | null
}

export type ToggleFollowHostMutationVariables = Exact<{
  userDatabaseId: Scalars["ID"]
}>

export type ToggleFollowHostMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    toggleHostFollow?: {
      __typename?: "ToggleHostFollowResponse"
      user?: {
        __typename?: "User"
        id?: string | null
        isFollowingEvents?: boolean | null
      } | null
    } | null
  } | null
}

export type FollowMenuQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type FollowMenuQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: string | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
  } | null
}

export type Group__DeletePostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
  reasonCategory?: InputMaybe<GroupContentModerationReasonCategory>
  reasonContext?: InputMaybe<Scalars["String"]>
  reasonContextForPostAuthor?: InputMaybe<Scalars["String"]>
  topLevelPostSequenceId: Scalars["Int"]
}>

export type Group__DeletePostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    deletePost?: {
      __typename?: "GroupDeletePostResponse"
      group?: {
        __typename?: "Group"
        id: string
        discussionFeed?: {
          __typename?: "GroupPostConnection"
          totalCount: number
          edge?: {
            __typename?: "GroupPostEdge"
            node?: {
              __typename?: "GroupPost"
              id: string
              totalCommentCount: number
            } | null
          } | null
        } | null
      } | null
      parentPost?: {
        __typename?: "GroupPost"
        id: string
        totalCommentCount: number
        posts: { __typename?: "GroupPostConnection"; totalCount: number }
      } | null
    } | null
  } | null
}

export type Group__LockPostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
  reasonCategory?: InputMaybe<GroupContentModerationReasonCategory>
  reasonContext?: InputMaybe<Scalars["String"]>
  reasonContextForPostAuthor?: InputMaybe<Scalars["String"]>
}>

export type Group__LockPostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    lockPost?: {
      __typename?: "GroupLockPostResponse"
      groupPost?: {
        __typename?: "GroupPost"
        canLock: boolean
        canPost: boolean
        canUnlock: boolean
        id: string
        isLocked: boolean
      } | null
    } | null
  } | null
}

export type Group__PinPostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type Group__PinPostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    pinPost?: {
      __typename?: "GroupPinPostResponse"
      groupPost?: {
        __typename?: "GroupPost"
        canPin: boolean
        canUnpin: boolean
        id: string
        isPinned: boolean
      } | null
      unpinnedPosts?: Array<{
        __typename?: "GroupPost"
        canPin: boolean
        canUnpin: boolean
        id: string
        isPinned: boolean
      }> | null
    } | null
  } | null
}

export type Group__UnlockPostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type Group__UnlockPostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    unlockPost?: {
      __typename?: "GroupUnlockPostResponse"
      groupPost?: {
        __typename?: "GroupPost"
        canLock: boolean
        canPost: boolean
        canUnlock: boolean
        id: string
        isLocked: boolean
      } | null
    } | null
  } | null
}

export type Group__UnpinPostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type Group__UnpinPostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    unpinPost?: {
      __typename?: "GroupUnpinPostResponse"
      groupPost?: {
        __typename?: "GroupPost"
        canPin: boolean
        canUnpin: boolean
        id: string
        isPinned: boolean
      } | null
    } | null
  } | null
}

export type User__ModerationStatusMutationMutationVariables = Exact<{
  input: UserUpdateModerationStatusInput
}>

export type User__ModerationStatusMutationMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    updateModerationStatus?: {
      __typename?: "UserUpdateModerationStatusResponse"
      updatedStatus: UserModerationStatus
    } | null
  } | null
}

export type GroupCardJoinGroupMutationVariables = Exact<{
  groupId: Scalars["ID"]
}>

export type GroupCardJoinGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    join?: {
      __typename?: "GroupJoinResponse"
      group?: {
        __typename?: "Group"
        id: string
        canPost: boolean
        name: string
        slug: string
      } | null
    } | null
  } | null
}

export type Group__CreatePostMutationMutationVariables = Exact<{
  attachedDatabaseEventIds?: InputMaybe<
    Array<Scalars["String"]> | Scalars["String"]
  >
  attachedEventIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>
  attachedFiles?: InputMaybe<Array<RevelFileUpload> | RevelFileUpload>
  groupId: Scalars["ID"]
  mentionedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>
  textBody: Scalars["String"]
}>

export type Group__CreatePostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    createPost?: {
      __typename?: "GroupCreatePostResponse"
      createdGroupPost?: {
        __typename?: "GroupPost"
        id: string
        canDelete: boolean
        canLock: boolean
        canPin: boolean
        canPost: boolean
        canUnlock: boolean
        canUnpin: boolean
        canUpdate: boolean
        createdAt: any
        databaseId: number
        isLocked: boolean
        isMyPost: boolean
        isPinned: boolean
        isUpdated: boolean
        lastActivityAt: any
        publishedAt?: any | null
        sequenceId: number
        textBody: string
        totalCommentCount: number
        parentPost?: {
          __typename?: "GroupPost"
          id: string
          comments: { __typename?: "GroupPostConnection"; totalCount: number }
        } | null
        group: {
          __typename?: "Group"
          id: string
          databaseId: number
          isModerator: boolean
          name: string
          slug: string
        }
        attachments?: {
          __typename?: "GroupPostAttachmentConnection"
          nodes?: Array<
            | {
                __typename?: "GroupPostAttachmentEvent"
                id: string
                event: {
                  __typename?: "Event"
                  endsAt?: any | null
                  image?: string | null
                  name?: string | null
                  slug: string
                  startsAt?: any | null
                  hostships?: {
                    __typename?: "HostshipConnection"
                    nodes?: Array<{
                      __typename?: "Hostship"
                      user?: {
                        __typename?: "User"
                        fullName?: string | null
                      } | null
                    } | null> | null
                  } | null
                }
              }
            | {
                __typename?: "GroupPostAttachmentFile"
                id: string
                mimeType: string
                url: string
              }
            | null
          > | null
        } | null
        comments: { __typename?: "GroupPostConnection"; totalCount: number }
        createdBy: {
          __typename?: "User"
          auth0Id?: string | null
          firstName?: string | null
          fullName?: string | null
          id?: string | null
          isBlocked?: boolean | null
          isFollowingEvents?: boolean | null
          isInAddressBook?: boolean | null
          profilePictureUrl?: string | null
          slug?: string | null
        }
      } | null
      group?: {
        __typename?: "Group"
        id: string
        discussionFeed?: {
          __typename?: "GroupPostConnection"
          totalCount: number
        } | null
      } | null
    } | null
  } | null
}

export type Group__CreatePostMutation__GroupPostFragmentFragment = {
  __typename?: "GroupPost"
  id: string
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  lastActivityAt: any
  publishedAt?: any | null
  sequenceId: number
  textBody: string
  totalCommentCount: number
  group: {
    __typename?: "Group"
    id: string
    databaseId: number
    isModerator: boolean
    name: string
    slug: string
  }
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | {
          __typename?: "GroupPostAttachmentEvent"
          id: string
          event: {
            __typename?: "Event"
            endsAt?: any | null
            image?: string | null
            name?: string | null
            slug: string
            startsAt?: any | null
            hostships?: {
              __typename?: "HostshipConnection"
              nodes?: Array<{
                __typename?: "Hostship"
                user?: { __typename?: "User"; fullName?: string | null } | null
              } | null> | null
            } | null
          }
        }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  comments: { __typename?: "GroupPostConnection"; totalCount: number }
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
}

export type GroupPostQueryVariables = Exact<{
  commentsConnection: ConnectionInput
  id: Scalars["ID"]
}>

export type GroupPostQuery = {
  __typename?: "Query"
  groupPost?: {
    __typename?: "GroupPost"
    databaseId: number
    id: string
    comments: {
      __typename?: "GroupPostConnection"
      totalCount: number
      edges?: Array<{
        __typename?: "GroupPostEdge"
        cursor?: string | null
        node?: {
          __typename?: "GroupPost"
          id: string
          canDelete: boolean
          canLock: boolean
          canPin: boolean
          canPost: boolean
          canUnlock: boolean
          canUnpin: boolean
          canUpdate: boolean
          createdAt: any
          databaseId: number
          isLocked: boolean
          isMyPost: boolean
          isPinned: boolean
          isUpdated: boolean
          sequenceId: number
          textBody: string
          comments: { __typename?: "GroupPostConnection"; totalCount: number }
          attachments?: {
            __typename?: "GroupPostAttachmentConnection"
            nodes?: Array<
              | { __typename?: "GroupPostAttachmentEvent" }
              | {
                  __typename?: "GroupPostAttachmentFile"
                  id: string
                  mimeType: string
                  url: string
                }
              | null
            > | null
          } | null
          createdBy: {
            __typename?: "User"
            auth0Id?: string | null
            firstName?: string | null
            fullName?: string | null
            id?: string | null
            isBlocked?: boolean | null
            isFollowingEvents?: boolean | null
            isInAddressBook?: boolean | null
            profilePictureUrl?: string | null
            slug?: string | null
          }
          group: {
            __typename?: "Group"
            databaseId: number
            id: string
            isModerator: boolean
            name: string
          }
          myReaction?: {
            __typename?: "Reaction"
            content: ReactionContent
            id: string
          } | null
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    }
    createdBy: {
      __typename?: "User"
      auth0Id?: string | null
      id?: string | null
    }
  } | null
}

export type Group__FeaturePostMutationMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type Group__FeaturePostMutationMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    featurePost?: {
      __typename?: "GroupFeaturePostResponse"
      groupPost?: { __typename?: "GroupPost"; id: string } | null
    } | null
  } | null
}

export type CreatePostCommentMutationVariables = Exact<{
  attachedFiles?: InputMaybe<Array<RevelFileUpload> | RevelFileUpload>
  groupId: Scalars["ID"]
  mentionedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>
  parentPostId: Scalars["ID"]
  textBody: Scalars["String"]
}>

export type CreatePostCommentMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    createPost?: {
      __typename?: "GroupCreatePostResponse"
      createdGroupPost?: {
        __typename?: "GroupPost"
        id: string
        canDelete: boolean
        canLock: boolean
        canPin: boolean
        canPost: boolean
        canUnlock: boolean
        canUnpin: boolean
        canUpdate: boolean
        createdAt: any
        databaseId: number
        isLocked: boolean
        isMyPost: boolean
        isPinned: boolean
        isUpdated: boolean
        sequenceId: number
        textBody: string
        parentPost?: { __typename?: "GroupPost"; id: string } | null
        attachments?: {
          __typename?: "GroupPostAttachmentConnection"
          nodes?: Array<
            | { __typename?: "GroupPostAttachmentEvent" }
            | {
                __typename?: "GroupPostAttachmentFile"
                id: string
                mimeType: string
                url: string
              }
            | null
          > | null
        } | null
        createdBy: {
          __typename?: "User"
          auth0Id?: string | null
          firstName?: string | null
          fullName?: string | null
          id?: string | null
          isBlocked?: boolean | null
          isFollowingEvents?: boolean | null
          isInAddressBook?: boolean | null
          profilePictureUrl?: string | null
          slug?: string | null
        }
        group: {
          __typename?: "Group"
          databaseId: number
          id: string
          isModerator: boolean
          name: string
        }
        myReaction?: {
          __typename?: "Reaction"
          content: ReactionContent
          id: string
        } | null
      } | null
    } | null
  } | null
}

export type CreatePostCommentMutationGroupPostFragment = {
  __typename?: "GroupPost"
  id: string
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  sequenceId: number
  textBody: string
  parentPost?: { __typename?: "GroupPost"; id: string } | null
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | { __typename?: "GroupPostAttachmentEvent" }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
  }
  myReaction?: {
    __typename?: "Reaction"
    content: ReactionContent
    id: string
  } | null
}

export type CreatePostReactionMutationVariables = Exact<{
  content: ReactionContent
  postId: Scalars["ID"]
}>

export type CreatePostReactionMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    createPostReaction?: {
      __typename?: "GroupCreatePostReactionResponse"
      groupPost?: {
        __typename?: "GroupPost"
        databaseId: number
        id: string
        angryReaction: { __typename?: "ReactionConnection"; totalCount: number }
        clapReaction: { __typename?: "ReactionConnection"; totalCount: number }
        createdBy: {
          __typename?: "User"
          auth0Id?: string | null
          id?: string | null
        }
        group: {
          __typename?: "Group"
          databaseId: number
          id: string
          name: string
        }
        heartReaction: { __typename?: "ReactionConnection"; totalCount: number }
        inspiredReaction: {
          __typename?: "ReactionConnection"
          totalCount: number
        }
        laughingReaction: {
          __typename?: "ReactionConnection"
          totalCount: number
        }
        likeReaction: { __typename?: "ReactionConnection"; totalCount: number }
        myReaction?: {
          __typename?: "Reaction"
          content: ReactionContent
          id: string
          reactedBy: {
            __typename?: "User"
            fullName?: string | null
            id?: string | null
          }
        } | null
        reactions: { __typename?: "ReactionConnection"; totalCount: number }
        sadReaction: { __typename?: "ReactionConnection"; totalCount: number }
        sparkleReaction: {
          __typename?: "ReactionConnection"
          totalCount: number
        }
        supportReaction: {
          __typename?: "ReactionConnection"
          totalCount: number
        }
      } | null
    } | null
  } | null
}

export type DeletePostReactionMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type DeletePostReactionMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    deletePostReaction?: {
      __typename?: "GroupDeletePostReactionResponse"
      groupPost?: {
        __typename?: "GroupPost"
        id: string
        myReaction?: {
          __typename?: "Reaction"
          id: string
          reactedBy: {
            __typename?: "User"
            fullName?: string | null
            id?: string | null
          }
        } | null
      } | null
    } | null
  } | null
}

export type GroupPostReactionQueryVariables = Exact<{
  postId: Scalars["ID"]
}>

export type GroupPostReactionQuery = {
  __typename?: "Query"
  groupPost?: {
    __typename?: "GroupPost"
    id: string
    angryReaction: { __typename?: "ReactionConnection"; totalCount: number }
    clapReaction: { __typename?: "ReactionConnection"; totalCount: number }
    heartReaction: { __typename?: "ReactionConnection"; totalCount: number }
    inspiredReaction: { __typename?: "ReactionConnection"; totalCount: number }
    laughingReaction: { __typename?: "ReactionConnection"; totalCount: number }
    likeReaction: { __typename?: "ReactionConnection"; totalCount: number }
    myReaction?: {
      __typename?: "Reaction"
      content: ReactionContent
      id: string
      reactedBy: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
      }
    } | null
    reactions: {
      __typename?: "ReactionConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "Reaction"
        id: string
        reactedBy: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
        }
      } | null> | null
    }
    sadReaction: { __typename?: "ReactionConnection"; totalCount: number }
    sparkleReaction: { __typename?: "ReactionConnection"; totalCount: number }
    supportReaction: { __typename?: "ReactionConnection"; totalCount: number }
  } | null
}

export type GroupUpdatePostCommentMutationVariables = Exact<{
  mentionedUserIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>
  postId: Scalars["ID"]
  textBody?: InputMaybe<Scalars["String"]>
}>

export type GroupUpdatePostCommentMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    updatePost?: {
      __typename?: "GroupUpdatePostResponse"
      updatedGroupPost?: {
        __typename?: "GroupPost"
        id: string
        isUpdated: boolean
        textBody: string
      } | null
    } | null
  } | null
}

export type DiscussionFeedPost__CreatedByFragmentFragment = {
  __typename?: "User"
  auth0Id?: string | null
  firstName?: string | null
  fullName?: string | null
  id?: string | null
  isBlocked?: boolean | null
  isFollowingEvents?: boolean | null
  isInAddressBook?: boolean | null
  profilePictureUrl?: string | null
  slug?: string | null
}

export type DiscussionFeedPost__GroupPostFragmentFragment = {
  __typename?: "GroupPost"
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  id: string
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  lastActivityAt: any
  publishedAt?: any | null
  sequenceId: number
  textBody: string
  totalCommentCount: number
  comments: { __typename?: "GroupPostConnection"; totalCount: number }
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
    slug: string
  }
}

export type DiscussionFeedPost__GroupPostAttachmentFileFragmentFragment = {
  __typename?: "GroupPostAttachmentFile"
  id: string
  mimeType: string
  url: string
}

export type DiscussionFeedPost__GroupPostAttachmentEventFragmentFragment = {
  __typename?: "GroupPostAttachmentEvent"
  id: string
  event: {
    __typename?: "Event"
    endsAt?: any | null
    image?: string | null
    name?: string | null
    slug: string
    startsAt?: any | null
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        user?: { __typename?: "User"; fullName?: string | null } | null
      } | null> | null
    } | null
  }
}

export type DiscussionFeedPostFragmentFragment = {
  __typename?: "GroupPost"
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  id: string
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  lastActivityAt: any
  publishedAt?: any | null
  sequenceId: number
  textBody: string
  totalCommentCount: number
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | {
          __typename?: "GroupPostAttachmentEvent"
          id: string
          event: {
            __typename?: "Event"
            endsAt?: any | null
            image?: string | null
            name?: string | null
            slug: string
            startsAt?: any | null
            hostships?: {
              __typename?: "HostshipConnection"
              nodes?: Array<{
                __typename?: "Hostship"
                user?: { __typename?: "User"; fullName?: string | null } | null
              } | null> | null
            } | null
          }
        }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  comments: { __typename?: "GroupPostConnection"; totalCount: number }
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
    slug: string
  }
}

export type EventBySlugOrIdQueryVariables = Exact<{
  databaseId?: InputMaybe<Scalars["String"]>
  slug?: InputMaybe<Scalars["String"]>
}>

export type EventBySlugOrIdQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    endsAt?: any | null
    id?: string | null
    image?: string | null
    name?: string | null
    slug: string
    startsAt?: any | null
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        user?: { __typename?: "User"; fullName?: string | null } | null
      } | null> | null
    } | null
  } | null
}

export type GroupPostComment__GroupPostAttachmentFileFragmentFragment = {
  __typename?: "GroupPostAttachmentFile"
  id: string
  mimeType: string
  url: string
}

export type GroupPostCommentFragmentFragment = {
  __typename?: "GroupPost"
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  id: string
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  sequenceId: number
  textBody: string
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | { __typename?: "GroupPostAttachmentEvent" }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
  }
  myReaction?: {
    __typename?: "Reaction"
    content: ReactionContent
    id: string
  } | null
}

export type DiscussionFeedFragmentFragment = {
  __typename?: "GroupPostConnection"
  totalCount: number
  edges?: Array<{
    __typename?: "GroupPostEdge"
    cursor?: string | null
    node?: {
      __typename?: "GroupPost"
      canDelete: boolean
      canLock: boolean
      canPin: boolean
      canPost: boolean
      canUnlock: boolean
      canUnpin: boolean
      canUpdate: boolean
      createdAt: any
      databaseId: number
      id: string
      isLocked: boolean
      isMyPost: boolean
      isPinned: boolean
      isUpdated: boolean
      lastActivityAt: any
      publishedAt?: any | null
      sequenceId: number
      textBody: string
      totalCommentCount: number
      attachments?: {
        __typename?: "GroupPostAttachmentConnection"
        nodes?: Array<
          | {
              __typename?: "GroupPostAttachmentEvent"
              id: string
              event: {
                __typename?: "Event"
                endsAt?: any | null
                image?: string | null
                name?: string | null
                slug: string
                startsAt?: any | null
                hostships?: {
                  __typename?: "HostshipConnection"
                  nodes?: Array<{
                    __typename?: "Hostship"
                    user?: {
                      __typename?: "User"
                      fullName?: string | null
                    } | null
                  } | null> | null
                } | null
              }
            }
          | {
              __typename?: "GroupPostAttachmentFile"
              id: string
              mimeType: string
              url: string
            }
          | null
        > | null
      } | null
      comments: { __typename?: "GroupPostConnection"; totalCount: number }
      createdBy: {
        __typename?: "User"
        auth0Id?: string | null
        firstName?: string | null
        fullName?: string | null
        id?: string | null
        isBlocked?: boolean | null
        isFollowingEvents?: boolean | null
        isInAddressBook?: boolean | null
        profilePictureUrl?: string | null
        slug?: string | null
      }
      group: {
        __typename?: "Group"
        databaseId: number
        id: string
        isModerator: boolean
        name: string
        slug: string
      }
    } | null
  } | null> | null
  pageInfo: {
    __typename?: "PageInfo"
    endCursor?: string | null
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string | null
  }
}

export type DiscussionFeed__UserFragmentFragment = {
  __typename?: "User"
  auth0Id?: string | null
  fullName?: string | null
  id?: string | null
  profilePictureUrl?: string | null
  shortName?: string | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    auth0Id?: string | null
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
    shortName?: string | null
  } | null
}

export type DesktopHeaderCurrentUserFragment = {
  __typename?: "User"
  id?: string | null
  profilePictureUrl?: string | null
}

export type DesktopHeaderQueryVariables = Exact<{ [key: string]: never }>

export type DesktopHeaderQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    id?: string | null
    profilePictureUrl?: string | null
    fullName?: string | null
  } | null
}

export type InboxButtonUnreadMessagesFragment = {
  __typename?: "ThreadMessageConnection"
  totalCount: number
}

export type InboxButtonInboxFragment = {
  __typename?: "Inbox"
  id?: string | null
  unreadMessages?: {
    __typename?: "ThreadMessageConnection"
    totalCount: number
  } | null
}

export type InboxButtonQueryVariables = Exact<{ [key: string]: never }>

export type InboxButtonQuery = {
  __typename?: "Query"
  inbox?: {
    __typename?: "Inbox"
    id?: string | null
    unreadMessages?: {
      __typename?: "ThreadMessageConnection"
      totalCount: number
    } | null
  } | null
}

export type NotificationsMarkAllAsReadMutationVariables = Exact<{
  [key: string]: never
}>

export type NotificationsMarkAllAsReadMutation = {
  __typename?: "Mutation"
  notification?: {
    __typename?: "NotificationMutation"
    markAllAsRead?: {
      __typename?: "NotificationMarkAllAsReadResponse"
      notifications?: {
        __typename?: "Notifications"
        id?: string | null
        unreadCount?: number | null
      } | null
    } | null
  } | null
}

export type NotificationsMarkAllAsSeenMutationVariables = Exact<{
  [key: string]: never
}>

export type NotificationsMarkAllAsSeenMutation = {
  __typename?: "Mutation"
  notification?: {
    __typename?: "NotificationMutation"
    markAllAsSeen?: {
      __typename?: "NotificationMarkAllAsSeenResponse"
      lastViewedNotificationsAt?: any | null
      notifications?: {
        __typename?: "Notifications"
        id?: string | null
        unseenCount?: number | null
      } | null
    } | null
  } | null
}

export type NotificationMarkAsReadMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type NotificationMarkAsReadMutation = {
  __typename?: "Mutation"
  notification?: {
    __typename?: "NotificationMutation"
    markAsRead?: {
      __typename?: "MarkAsReadResponse"
      notification?: {
        __typename?: "Notification"
        id: string
        read: boolean
      } | null
    } | null
  } | null
}

export type ProfileLinkCurrentUserFragment = {
  __typename?: "User"
  fullName?: string | null
  id?: string | null
  profilePictureUrl?: string | null
}

export type ProfileLinkQueryVariables = Exact<{ [key: string]: never }>

export type ProfileLinkQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
  } | null
}

export type ImpersonateBannerCurrentUserFragment = {
  __typename?: "User"
  fullName?: string | null
  id?: string | null
}

export type ImpersonateBannerQueryVariables = Exact<{ [key: string]: never }>

export type ImpersonateBannerQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
  } | null
}

export type LayoutCurrentUserFragment = {
  __typename?: "User"
  auth0Id?: string | null
  firstName?: string | null
  id?: string | null
  lastName?: string | null
  profilePictureUrl?: string | null
  role?: UserRole | null
  interests?: {
    __typename?: "InterestConnection"
    nodes?: Array<{
      __typename?: "Interest"
      id: string
      name: string
    } | null> | null
  } | null
  location?: {
    __typename?: "Location"
    city?: string | null
    country?: string | null
    state?: string | null
  } | null
}

export type LayoutQueryVariables = Exact<{ [key: string]: never }>

export type LayoutQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    id?: string | null
    lastName?: string | null
    profilePictureUrl?: string | null
    role?: UserRole | null
    interests?: {
      __typename?: "InterestConnection"
      nodes?: Array<{
        __typename?: "Interest"
        id: string
        name: string
      } | null> | null
    } | null
    location?: {
      __typename?: "Location"
      city?: string | null
      country?: string | null
      state?: string | null
    } | null
  } | null
}

export type MemberLookupQueryVariables = Exact<{
  search: Scalars["String"]
}>

export type MemberLookupQuery = {
  __typename?: "Query"
  users?: {
    __typename?: "UserConnection"
    nodes?: Array<{
      __typename?: "User"
      auth0Id?: string | null
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
    } | null> | null
  } | null
}

export type MessageMemberQueryVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type MessageMemberQuery = {
  __typename?: "Query"
  users?: {
    __typename?: "UserConnection"
    nodes?: Array<{
      __typename?: "User"
      fullName?: string | null
      id?: string | null
    } | null> | null
  } | null
}

export type PromoteToPowerHostUserFragmentFragment = {
  __typename?: "User"
  id?: string | null
  role?: UserRole | null
}

export type PromoteToPowerHostMutationVariables = Exact<{
  input: PromoteToPowerHostInput
}>

export type PromoteToPowerHostMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    promoteToPowerHost?: {
      __typename?: "PromoteToPowerHostResponse"
      user?: {
        __typename?: "User"
        id?: string | null
        role?: UserRole | null
      } | null
    } | null
  } | null
}

export type UserForPostRatingModalFragment = {
  __typename?: "User"
  auth0Id?: string | null
  firstName?: string | null
  fullName?: string | null
  id?: string | null
  profilePictureUrl?: string | null
  location?: {
    __typename?: "Location"
    city?: string | null
    country?: string | null
    state?: string | null
  } | null
}

export type EventForRatingDataFragment = {
  __typename?: "Event"
  endsAt?: any | null
  id?: string | null
  name?: string | null
  startsAt?: any | null
  attendees?: {
    __typename?: "UserConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "User"
      auth0Id?: string | null
      firstName?: string | null
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
      location?: {
        __typename?: "Location"
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
  hostships?: {
    __typename?: "HostshipConnection"
    nodes?: Array<{
      __typename?: "Hostship"
      id?: string | null
      user?: {
        __typename?: "User"
        auth0Id?: string | null
        firstName?: string | null
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
        location?: {
          __typename?: "Location"
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type EventForRatingQueryVariables = Exact<{
  attendeesConnection: ConnectionInput
  slug: Scalars["String"]
}>

export type EventForRatingQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    endsAt?: any | null
    id?: string | null
    name?: string | null
    startsAt?: any | null
    attendees?: {
      __typename?: "UserConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "User"
        auth0Id?: string | null
        firstName?: string | null
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
        location?: {
          __typename?: "Location"
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        id?: string | null
        user?: {
          __typename?: "User"
          auth0Id?: string | null
          firstName?: string | null
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
          location?: {
            __typename?: "Location"
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type SubmitAttendeeFeedbackMutationVariables = Exact<{
  attended: Scalars["Boolean"]
  eventId: Scalars["ID"]
  feedbackForHost?: InputMaybe<Scalars["String"]>
  feedbackForRevel?: InputMaybe<Scalars["String"]>
  ratingForEvent?: InputMaybe<Scalars["Int"]>
  ratingForHost?: InputMaybe<Scalars["Int"]>
}>

export type SubmitAttendeeFeedbackMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    submitAttendeeFeedback?: {
      __typename?: "SubmitAttendeeFeedbackResponse"
      ticket?: {
        __typename?: "Ticket"
        attended?: boolean | null
        feedbackForHost?: string | null
        feedbackForRevel?: string | null
        id?: string | null
        ratingForEvent?: number | null
        ratingForHost?: number | null
      } | null
    } | null
  } | null
}

export type EventRateAsHostMutationVariables = Exact<{
  easyToCreateAndSubmitEvent?: InputMaybe<Scalars["Boolean"]>
  eventId: Scalars["ID"]
  feedback?: InputMaybe<Scalars["String"]>
  feltSupportedByRevel?: InputMaybe<Scalars["Boolean"]>
  metOtherRevelers?: InputMaybe<Scalars["Boolean"]>
  rating: Scalars["Int"]
}>

export type EventRateAsHostMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    submitHostFeedback?: {
      __typename?: "SubmitHostFeedbackResponse"
      hostship?: {
        __typename?: "Hostship"
        easyToCreateAndSubmitEvent?: boolean | null
        feedback?: string | null
        feltSupportedByRevel?: boolean | null
        id?: string | null
        metOtherRevelers?: boolean | null
        rating?: number | null
      } | null
    } | null
  } | null
}

export type Device__RemoveDeviceMutationMutationVariables = Exact<{
  fcmToken: Scalars["String"]
}>

export type Device__RemoveDeviceMutationMutation = {
  __typename?: "Mutation"
  device?: {
    __typename?: "DeviceMutation"
    removeDevice?: {
      __typename?: "DeviceRemoveResponse"
      user?: { __typename?: "User"; id?: string | null } | null
    } | null
  } | null
}

export type Mentions__UsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>
}>

export type Mentions__UsersQuery = {
  __typename?: "Query"
  users?: {
    __typename?: "UserConnection"
    nodes?: Array<{
      __typename?: "User"
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
      slug?: string | null
      location?: {
        __typename?: "Location"
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
    } | null> | null
  } | null
}

export type Device__AddDeviceMutationMutationVariables = Exact<{
  fcmToken: Scalars["String"]
}>

export type Device__AddDeviceMutationMutation = {
  __typename?: "Mutation"
  device?: {
    __typename?: "DeviceMutation"
    addDevice?: {
      __typename?: "DeviceAddResponse"
      createdDevice?: {
        __typename?: "Device"
        fcmToken: string
        userId: string
      } | null
    } | null
  } | null
}

export type PushNotificationMarkAsReadMutationVariables = Exact<{
  link: Scalars["String"]
  type: Scalars["Int"]
}>

export type PushNotificationMarkAsReadMutation = {
  __typename?: "Mutation"
  notification?: {
    __typename?: "NotificationMutation"
    markAsRead?: {
      __typename?: "MarkAsReadResponse"
      notification?: {
        __typename?: "Notification"
        id: string
        read: boolean
        seen: boolean
      } | null
    } | null
  } | null
}

export type UseUserRoleCurrentUserFragment = {
  __typename?: "User"
  id?: string | null
  role?: UserRole | null
}

export type UseUserRoleQueryVariables = Exact<{ [key: string]: never }>

export type UseUserRoleQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    id?: string | null
    role?: UserRole | null
  } | null
}

export type EventDetailsFeedEventPostFragment = {
  __typename?: "EventPost"
  canLike?: boolean | null
  canPost?: boolean | null
  canUpdate?: boolean | null
  createdAt?: any | null
  id?: string | null
  textBody?: string | null
  comments?: { __typename?: "EventPostConnection"; totalCount: number } | null
  createdBy?: {
    __typename?: "User"
    id?: string | null
    isBlocked?: boolean | null
    profilePictureUrl?: string | null
    shortName?: string | null
    slug?: string | null
  } | null
  likes?: { __typename?: "EventPostLikeConnection"; totalCount: number } | null
  myLike?: { __typename?: "EventPostLike"; id: string } | null
}

export type EventDetailsAttendeeUserFragment = {
  __typename?: "User"
  fullName?: string | null
  id?: string | null
  profilePictureUrl?: string | null
  slug?: string | null
}

export type EventDetailsAttendeeEventFragment = {
  __typename?: "Event"
  id?: string | null
  attendees?: {
    __typename?: "UserConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "User"
      id?: string | null
      fullName?: string | null
      profilePictureUrl?: string | null
      slug?: string | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type EventDetailsAttendeeQueryVariables = Exact<{
  attendeesConnection: ConnectionInput
  slug: Scalars["String"]
}>

export type EventDetailsAttendeeQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    id?: string | null
    attendees?: {
      __typename?: "UserConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "User"
        id?: string | null
        fullName?: string | null
        profilePictureUrl?: string | null
        slug?: string | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type EventDetailsCreatePostMutationVariables = Exact<{
  eventId: Scalars["ID"]
  parentPostId?: InputMaybe<Scalars["ID"]>
  textBody: Scalars["String"]
}>

export type EventDetailsCreatePostMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    createPost?: {
      __typename?: "CreatePostResponse"
      createdPost?: {
        __typename?: "EventPost"
        id?: string | null
        canLike?: boolean | null
        canPost?: boolean | null
        canUpdate?: boolean | null
        createdAt?: any | null
        textBody?: string | null
        comments?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
        createdBy?: {
          __typename?: "User"
          id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        } | null
        likes?: {
          __typename?: "EventPostLikeConnection"
          totalCount: number
        } | null
        myLike?: { __typename?: "EventPostLike"; id: string } | null
      } | null
      event?: {
        __typename?: "Event"
        discussionFeed?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
      } | null
      parentPost?: {
        __typename?: "EventPost"
        id?: string | null
        comments?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
      } | null
    } | null
  } | null
}

export type EventDetailsEssentialEventFragment = {
  __typename?: "Event"
  allowExternalGuests?: boolean | null
  canCancel?: boolean | null
  canEdit?: boolean | null
  city?: string | null
  databaseId?: string | null
  description?: string | null
  endsAt?: any | null
  hasPowerHost?: boolean | null
  hostZoomLink?: string | null
  id?: string | null
  image?: string | null
  isAttending?: boolean | null
  isExcludedMarketing?: boolean | null
  isFeaturedMarketing?: boolean | null
  isHosting?: boolean | null
  isPaid?: boolean | null
  isPictureRequired?: boolean | null
  isSeries?: boolean | null
  isVideoRequired?: boolean | null
  location?: string | null
  locationTypeId?: number | null
  name?: string | null
  price?: number | null
  slug: string
  startsAt?: any | null
  state?: string | null
  status: EventStatus
  virtualLocalZip?: string | null
  zoomLink?: string | null
  isWaitlistEnabled?: boolean | null
  attendeeThreshold?: {
    __typename?: "EventAttendeeThreshold"
    hoursBefore?: number | null
    minimum?: number | null
  } | null
  externalGuests?: {
    __typename?: "ExternalGuestConnection"
    totalCount: number
  } | null
  interest?: { __typename?: "Interest"; id: string; name: string } | null
  secondaryInterest?: {
    __typename?: "Interest"
    id: string
    name: string
  } | null
}

export type EventDetailsEssentialQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type EventDetailsEssentialQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    allowExternalGuests?: boolean | null
    canCancel?: boolean | null
    canEdit?: boolean | null
    city?: string | null
    databaseId?: string | null
    description?: string | null
    endsAt?: any | null
    hasPowerHost?: boolean | null
    hostZoomLink?: string | null
    id?: string | null
    image?: string | null
    isAttending?: boolean | null
    isExcludedMarketing?: boolean | null
    isFeaturedMarketing?: boolean | null
    isHosting?: boolean | null
    isPaid?: boolean | null
    isPictureRequired?: boolean | null
    isSeries?: boolean | null
    isVideoRequired?: boolean | null
    location?: string | null
    locationTypeId?: number | null
    name?: string | null
    price?: number | null
    slug: string
    startsAt?: any | null
    state?: string | null
    status: EventStatus
    virtualLocalZip?: string | null
    zoomLink?: string | null
    isWaitlistEnabled?: boolean | null
    attendeeThreshold?: {
      __typename?: "EventAttendeeThreshold"
      hoursBefore?: number | null
      minimum?: number | null
    } | null
    externalGuests?: {
      __typename?: "ExternalGuestConnection"
      totalCount: number
    } | null
    interest?: { __typename?: "Interest"; id: string; name: string } | null
    secondaryInterest?: {
      __typename?: "Interest"
      id: string
      name: string
    } | null
  } | null
}

export type EventDetailsFeedEventFragment = {
  __typename?: "Event"
  discussionFeed?: {
    __typename?: "EventPostConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "EventPost"
      id?: string | null
      canLike?: boolean | null
      canPost?: boolean | null
      canUpdate?: boolean | null
      createdAt?: any | null
      textBody?: string | null
      comments?: {
        __typename?: "EventPostConnection"
        totalCount: number
      } | null
      createdBy?: {
        __typename?: "User"
        id?: string | null
        isBlocked?: boolean | null
        profilePictureUrl?: string | null
        shortName?: string | null
        slug?: string | null
      } | null
      likes?: {
        __typename?: "EventPostLikeConnection"
        totalCount: number
      } | null
      myLike?: { __typename?: "EventPostLike"; id: string } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type EventDetailsFeedQueryVariables = Exact<{
  feedConnection: ConnectionInput
  slug: Scalars["String"]
}>

export type EventDetailsFeedQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    id?: string | null
    discussionFeed?: {
      __typename?: "EventPostConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "EventPost"
        id?: string | null
        canLike?: boolean | null
        canPost?: boolean | null
        canUpdate?: boolean | null
        createdAt?: any | null
        textBody?: string | null
        comments?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
        createdBy?: {
          __typename?: "User"
          id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        } | null
        likes?: {
          __typename?: "EventPostLikeConnection"
          totalCount: number
        } | null
        myLike?: { __typename?: "EventPostLike"; id: string } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type EventDetailsHostUserFragment = {
  __typename?: "User"
  fullName?: string | null
  id?: string | null
  powerHostBio?: string | null
  profilePictureUrl?: string | null
}

export type EventDetailsHostEventFragment = {
  __typename?: "Event"
  id?: string | null
  hosts?: {
    __typename?: "UserConnection"
    nodes?: Array<{
      __typename?: "User"
      id?: string | null
      fullName?: string | null
      powerHostBio?: string | null
      profilePictureUrl?: string | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type EventDetailsHostQueryVariables = Exact<{
  hostsConnection: ConnectionInput
  slug: Scalars["String"]
}>

export type EventDetailsHostQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    id?: string | null
    hosts?: {
      __typename?: "UserConnection"
      nodes?: Array<{
        __typename?: "User"
        id?: string | null
        fullName?: string | null
        powerHostBio?: string | null
        profilePictureUrl?: string | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type EventDetailsFeedSinglePostEventPostFragment = {
  __typename?: "EventPost"
  id?: string | null
  comments?: {
    __typename?: "EventPostConnection"
    nodes?: Array<{
      __typename?: "EventPost"
      id?: string | null
      canLike?: boolean | null
      canPost?: boolean | null
      canUpdate?: boolean | null
      createdAt?: any | null
      textBody?: string | null
      comments?: {
        __typename?: "EventPostConnection"
        totalCount: number
      } | null
      createdBy?: {
        __typename?: "User"
        id?: string | null
        isBlocked?: boolean | null
        profilePictureUrl?: string | null
        shortName?: string | null
        slug?: string | null
      } | null
      likes?: {
        __typename?: "EventPostLikeConnection"
        totalCount: number
      } | null
      myLike?: { __typename?: "EventPostLike"; id: string } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type EventDetailsFeedSinglePostQueryVariables = Exact<{
  commentsConnection: ConnectionInput
  postId: Scalars["ID"]
}>

export type EventDetailsFeedSinglePostQuery = {
  __typename?: "Query"
  eventPost?: {
    __typename?: "EventPost"
    id?: string | null
    comments?: {
      __typename?: "EventPostConnection"
      nodes?: Array<{
        __typename?: "EventPost"
        id?: string | null
        canLike?: boolean | null
        canPost?: boolean | null
        canUpdate?: boolean | null
        createdAt?: any | null
        textBody?: string | null
        comments?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
        createdBy?: {
          __typename?: "User"
          id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        } | null
        likes?: {
          __typename?: "EventPostLikeConnection"
          totalCount: number
        } | null
        myLike?: { __typename?: "EventPostLike"; id: string } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type EventPostDeleteMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type EventPostDeleteMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    deletePost?: {
      __typename?: "DeletePostResponse"
      event?: {
        __typename?: "Event"
        id?: string | null
        discussionFeed?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
      } | null
      parentPost?: {
        __typename?: "EventPost"
        id?: string | null
        comments?: {
          __typename?: "EventPostConnection"
          totalCount: number
        } | null
      } | null
    } | null
  } | null
}

export type EventPostLikeMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type EventPostLikeMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    likePost?: {
      __typename?: "LikePostResponse"
      eventPost?: {
        __typename?: "EventPost"
        id?: string | null
        likes?: {
          __typename?: "EventPostLikeConnection"
          totalCount: number
        } | null
        myLike?: { __typename?: "EventPostLike"; id: string } | null
      } | null
    } | null
  } | null
}

export type EventPostUnlikeMutationVariables = Exact<{
  postId: Scalars["ID"]
}>

export type EventPostUnlikeMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    unlikePost?: {
      __typename?: "UnlikePostResponse"
      eventPost?: {
        __typename?: "EventPost"
        id?: string | null
        likes?: {
          __typename?: "EventPostLikeConnection"
          totalCount: number
        } | null
        myLike?: { __typename?: "EventPostLike"; id: string } | null
      } | null
    } | null
  } | null
}

export type EventPostUpdateMutationVariables = Exact<{
  postId: Scalars["ID"]
  textBody: Scalars["String"]
}>

export type EventPostUpdateMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    updatePost?: {
      __typename?: "UpdatePostResponse"
      updatedPost?: {
        __typename?: "EventPost"
        id?: string | null
        textBody?: string | null
      } | null
    } | null
  } | null
}

export type InboxCreateThreadMutationVariables = Exact<{
  message: Scalars["String"]
  participantIds: Array<Scalars["ID"]> | Scalars["ID"]
  subject: Scalars["String"]
}>

export type InboxCreateThreadMutation = {
  __typename?: "Mutation"
  inboxCreateThread?: {
    __typename?: "InboxCreateThreadResponse"
    thread?: {
      __typename?: "Thread"
      id?: string | null
      participants?: {
        __typename?: "UserConnection"
        nodes?: Array<{
          __typename?: "User"
          auth0Id?: string | null
          fullName?: string | null
          id?: string | null
          isBlocked?: boolean | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type InboxCurrentUserDataFragment = {
  __typename?: "User"
  auth0Id?: string | null
  id?: string | null
  isBlocked?: boolean | null
  profilePictureUrl?: string | null
  shortName?: string | null
  features?: { __typename?: "Features"; inboxSearch: boolean } | null
}

export type InboxCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type InboxCurrentUserQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    auth0Id?: string | null
    id?: string | null
    isBlocked?: boolean | null
    profilePictureUrl?: string | null
    shortName?: string | null
    features?: { __typename?: "Features"; inboxSearch: boolean } | null
  } | null
}

export type InboxAttendeeFragment = {
  __typename?: "User"
  auth0Id?: string | null
  fullName?: string | null
  id?: string | null
  isBlocked?: boolean | null
}

export type InboxFetchEventAttendeesQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>
  eventDatabaseId: Scalars["String"]
}>

export type InboxFetchEventAttendeesQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    participants?: {
      __typename?: "UserConnection"
      nodes?: Array<{
        __typename?: "User"
        auth0Id?: string | null
        fullName?: string | null
        id?: string | null
        isBlocked?: boolean | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type InboxTicketFragment = {
  __typename?: "Ticket"
  id?: string | null
  user?: {
    __typename?: "User"
    auth0Id?: string | null
    fullName?: string | null
    id?: string | null
  } | null
}

export type InboxFetchEventWaitlistQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>
  eventDatabaseId: Scalars["String"]
  status?: InputMaybe<TicketStatus>
}>

export type InboxFetchEventWaitlistQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    tickets?: {
      __typename?: "TicketConnection"
      nodes?: Array<{
        __typename?: "Ticket"
        id?: string | null
        user?: {
          __typename?: "User"
          auth0Id?: string | null
          fullName?: string | null
          id?: string | null
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type InboxFetchUserFromAuth0IdQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type InboxFetchUserFromAuth0IdQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
  } | null
}

export type InboxParticipantConnectionFragment = {
  __typename?: "UserConnection"
  totalCount: number
  nodes?: Array<{
    __typename?: "User"
    auth0Id?: string | null
    id?: string | null
    isBlocked?: boolean | null
    profilePictureUrl?: string | null
    shortName?: string | null
  } | null> | null
  pageInfo: {
    __typename?: "PageInfo"
    endCursor?: string | null
    hasNextPage: boolean
  }
}

export type InboxThreadFragment = {
  __typename?: "Thread"
  databaseId?: number | null
  id?: string | null
  lastActivityAt?: any | null
  subject?: string | null
  messages?: {
    __typename?: "ThreadMessageConnection"
    nodes?: Array<{
      __typename?: "ThreadMessage"
      id?: string | null
      createdAt?: any | null
      databaseId?: number | null
      textBody?: string | null
      createdBy?: {
        __typename?: "User"
        id?: string | null
        auth0Id?: string | null
        isBlocked?: boolean | null
        profilePictureUrl?: string | null
        shortName?: string | null
      } | null
    } | null> | null
  } | null
  participants?: {
    __typename?: "UserConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "User"
      auth0Id?: string | null
      id?: string | null
      isBlocked?: boolean | null
      profilePictureUrl?: string | null
      shortName?: string | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
  unreadMessages?: {
    __typename?: "ThreadMessageConnection"
    totalCount: number
  } | null
}

export type InboxDataFragment = {
  __typename?: "Inbox"
  id?: string | null
  thread?: {
    __typename?: "Thread"
    databaseId?: number | null
    id?: string | null
    lastActivityAt?: any | null
    subject?: string | null
    messages?: {
      __typename?: "ThreadMessageConnection"
      nodes?: Array<{
        __typename?: "ThreadMessage"
        id?: string | null
        createdAt?: any | null
        databaseId?: number | null
        textBody?: string | null
        createdBy?: {
          __typename?: "User"
          id?: string | null
          auth0Id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    } | null
    participants?: {
      __typename?: "UserConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "User"
        auth0Id?: string | null
        id?: string | null
        isBlocked?: boolean | null
        profilePictureUrl?: string | null
        shortName?: string | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
  threads?: {
    __typename?: "ThreadConnection"
    nodes?: Array<{
      __typename?: "Thread"
      databaseId?: number | null
      id?: string | null
      lastActivityAt?: any | null
      subject?: string | null
      messages?: {
        __typename?: "ThreadMessageConnection"
        nodes?: Array<{
          __typename?: "ThreadMessage"
          id?: string | null
          createdAt?: any | null
          databaseId?: number | null
          textBody?: string | null
          createdBy?: {
            __typename?: "User"
            id?: string | null
            auth0Id?: string | null
            isBlocked?: boolean | null
            profilePictureUrl?: string | null
            shortName?: string | null
          } | null
        } | null> | null
      } | null
      participants?: {
        __typename?: "UserConnection"
        totalCount: number
        nodes?: Array<{
          __typename?: "User"
          auth0Id?: string | null
          id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
        } | null> | null
        pageInfo: {
          __typename?: "PageInfo"
          endCursor?: string | null
          hasNextPage: boolean
        }
      } | null
      unreadMessages?: {
        __typename?: "ThreadMessageConnection"
        totalCount: number
      } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
  unreadMessages?: {
    __typename?: "ThreadMessageConnection"
    totalCount: number
  } | null
}

export type InboxQueryVariables = Exact<{
  messageBeforeCursor?: InputMaybe<Scalars["String"]>
  read?: InputMaybe<Scalars["Boolean"]>
  shouldFetchThread: Scalars["Boolean"]
  shouldFetchThreads: Scalars["Boolean"]
  threadBeforeCursor?: InputMaybe<Scalars["String"]>
  threadId?: InputMaybe<Scalars["ID"]>
  threadParticipantsBeforeCursor?: InputMaybe<Scalars["String"]>
  threadParticipantsLast?: InputMaybe<Scalars["Int"]>
  threadSearch?: InputMaybe<Scalars["String"]>
  threadsMessagesLast?: InputMaybe<Scalars["Int"]>
  threadsParticipantsLast?: InputMaybe<Scalars["Int"]>
}>

export type InboxQuery = {
  __typename?: "Query"
  inbox?: {
    __typename?: "Inbox"
    id?: string | null
    thread?: {
      __typename?: "Thread"
      databaseId?: number | null
      id?: string | null
      lastActivityAt?: any | null
      subject?: string | null
      messages?: {
        __typename?: "ThreadMessageConnection"
        nodes?: Array<{
          __typename?: "ThreadMessage"
          id?: string | null
          createdAt?: any | null
          databaseId?: number | null
          textBody?: string | null
          createdBy?: {
            __typename?: "User"
            id?: string | null
            auth0Id?: string | null
            isBlocked?: boolean | null
            profilePictureUrl?: string | null
            shortName?: string | null
          } | null
        } | null> | null
        pageInfo: {
          __typename?: "PageInfo"
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      } | null
      participants?: {
        __typename?: "UserConnection"
        totalCount: number
        nodes?: Array<{
          __typename?: "User"
          auth0Id?: string | null
          id?: string | null
          isBlocked?: boolean | null
          profilePictureUrl?: string | null
          shortName?: string | null
        } | null> | null
        pageInfo: {
          __typename?: "PageInfo"
          endCursor?: string | null
          hasNextPage: boolean
        }
      } | null
    } | null
    threads?: {
      __typename?: "ThreadConnection"
      nodes?: Array<{
        __typename?: "Thread"
        databaseId?: number | null
        id?: string | null
        lastActivityAt?: any | null
        subject?: string | null
        messages?: {
          __typename?: "ThreadMessageConnection"
          nodes?: Array<{
            __typename?: "ThreadMessage"
            id?: string | null
            createdAt?: any | null
            databaseId?: number | null
            textBody?: string | null
            createdBy?: {
              __typename?: "User"
              id?: string | null
              auth0Id?: string | null
              isBlocked?: boolean | null
              profilePictureUrl?: string | null
              shortName?: string | null
            } | null
          } | null> | null
        } | null
        participants?: {
          __typename?: "UserConnection"
          totalCount: number
          nodes?: Array<{
            __typename?: "User"
            auth0Id?: string | null
            id?: string | null
            isBlocked?: boolean | null
            profilePictureUrl?: string | null
            shortName?: string | null
          } | null> | null
          pageInfo: {
            __typename?: "PageInfo"
            endCursor?: string | null
            hasNextPage: boolean
          }
        } | null
        unreadMessages?: {
          __typename?: "ThreadMessageConnection"
          totalCount: number
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
    unreadMessages?: {
      __typename?: "ThreadMessageConnection"
      totalCount: number
    } | null
  } | null
}

export type InboxLeaveThreadMutationVariables = Exact<{
  threadId: Scalars["ID"]
}>

export type InboxLeaveThreadMutation = {
  __typename?: "Mutation"
  inboxLeaveThread?: {
    __typename?: "InboxLeaveThreadResponse"
    thread?: { __typename?: "Thread"; id?: string | null } | null
  } | null
}

export type InboxMarkThreadAsReadMutationVariables = Exact<{
  threadId: Scalars["ID"]
}>

export type InboxMarkThreadAsReadMutation = {
  __typename?: "Mutation"
  inboxMarkThreadAsRead?: {
    __typename?: "InboxMarkThreadAsReadResponse"
    thread?: { __typename?: "Thread"; id?: string | null } | null
  } | null
}

export type InboxParticipantFragment = {
  __typename?: "User"
  auth0Id?: string | null
  id?: string | null
  isBlocked?: boolean | null
  profilePictureUrl?: string | null
  shortName?: string | null
}

export type InboxSubmitReplyMutationVariables = Exact<{
  textBody: Scalars["String"]
  threadId: Scalars["ID"]
}>

export type InboxSubmitReplyMutation = {
  __typename?: "Mutation"
  inboxCreateMessage?: {
    __typename?: "InboxCreateMessageResponse"
    message?: {
      __typename?: "ThreadMessage"
      id?: string | null
      createdAt?: any | null
      databaseId?: number | null
      textBody?: string | null
      createdBy?: {
        __typename?: "User"
        id?: string | null
        auth0Id?: string | null
        isBlocked?: boolean | null
        profilePictureUrl?: string | null
        shortName?: string | null
      } | null
    } | null
  } | null
}

export type InboxThreadMessageFragment = {
  __typename?: "ThreadMessage"
  createdAt?: any | null
  databaseId?: number | null
  id?: string | null
  textBody?: string | null
  createdBy?: {
    __typename?: "User"
    id?: string | null
    auth0Id?: string | null
    isBlocked?: boolean | null
    profilePictureUrl?: string | null
    shortName?: string | null
  } | null
}

export type MyGroupsProviderGroupFragment = {
  __typename?: "Group"
  canPost: boolean
  id: string
  name: string
  slug: string
}

export type MyGroupsProviderQueryVariables = Exact<{
  afterCursor?: InputMaybe<Scalars["String"]>
}>

export type MyGroupsProviderQuery = {
  __typename?: "Query"
  groups?: {
    __typename?: "GroupConnection"
    totalCount: number
    edges?: Array<{
      __typename?: "GroupEdge"
      cursor?: string | null
      node?: {
        __typename?: "Group"
        canPost: boolean
        id: string
        name: string
        slug: string
      } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type NotificationsQueryVariables = Exact<{
  sources?: InputMaybe<Array<NotificationSource> | NotificationSource>
  connection?: InputMaybe<ConnectionInput>
  includePageInfo: Scalars["Boolean"]
}>

export type NotificationsQuery = {
  __typename?: "Query"
  notifications?: {
    __typename?: "Notifications"
    id?: string | null
    notifications?: {
      __typename?: "NotificationConnection"
      pageInfo?: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasPreviousPage: boolean
        hasNextPage: boolean
      }
      nodes?: Array<{
        __typename?: "Notification"
        id: string
        category: string
        source?: NotificationSource | null
        link: string
        message: string
        read: boolean
        seen: boolean
        type: NotificationType
        pictureUrl?: string | null
        updatedAt: any
      } | null> | null
    } | null
  } | null
}

export type NotificationsUnreadCountQueryVariables = Exact<{
  source?: InputMaybe<NotificationSource>
}>

export type NotificationsUnreadCountQuery = {
  __typename?: "Query"
  notifications?: {
    __typename?: "Notifications"
    id?: string | null
    unreadCount?: number | null
  } | null
}

export type NotificationsUnseenCountQueryVariables = Exact<{
  source?: InputMaybe<NotificationSource>
}>

export type NotificationsUnseenCountQuery = {
  __typename?: "Query"
  notifications?: {
    __typename?: "Notifications"
    id?: string | null
    unseenCount?: number | null
  } | null
}

export type SearchKeysQueryVariables = Exact<{ [key: string]: never }>

export type SearchKeysQuery = {
  __typename?: "Query"
  searchKey?: {
    __typename?: "SearchKey"
    events?: string | null
    groups?: string | null
    members?: string | null
    threads?: string | null
    myGroups?: string | null
  } | null
}

export type VersionCheckVersionStatusFragment = {
  __typename?: "VersionCheckResponse"
  app?: VersionStatus | null
  bundle?: VersionStatus | null
  message?: string | null
}

export type VersionCheckMutationVariables = Exact<{
  app?: InputMaybe<Scalars["SemanticVersion"]>
  bundle?: InputMaybe<Scalars["SemanticVersion"]>
}>

export type VersionCheckMutation = {
  __typename?: "Mutation"
  version?: {
    __typename?: "VersionMutation"
    check?: {
      __typename?: "VersionCheckResponse"
      app?: VersionStatus | null
      bundle?: VersionStatus | null
      message?: string | null
    } | null
  } | null
}

export type User__DeleteAccountMutationMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type User__DeleteAccountMutationMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    requestDeletion?: {
      __typename?: "UserRequestDeletionResponse"
      user: {
        __typename?: "User"
        id?: string | null
        moderationStatus?: string | null
      }
    } | null
  } | null
}

export type User__AccountPageQueryQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type User__AccountPageQueryQuery = {
  __typename?: "Query"
  user?: { __typename?: "User"; id?: string | null } | null
}

export type SetEventGroupMutationVariables = Exact<{
  eventId: Scalars["ID"]
  groupId?: InputMaybe<Scalars["ID"]>
  textBody?: InputMaybe<Scalars["String"]>
}>

export type SetEventGroupMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    setGroup?: {
      __typename?: "SetGroupResponse"
      groupPost?: {
        __typename?: "GroupPost"
        id: string
        textBody: string
      } | null
    } | null
  } | null
}

export type FetchEventGroupQueryVariables = Exact<{
  databaseId?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<TicketStatus>
}>

export type FetchEventGroupQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    id?: string | null
    draftGroupPost?: {
      __typename?: "GroupPost"
      id: string
      textBody: string
      group: { __typename?: "Group"; id: string; name: string }
    } | null
    tickets?: { __typename?: "TicketConnection"; totalCount: number } | null
  } | null
}

export type EventDetailsCancelEventMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type EventDetailsCancelEventMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    cancelEvent?: {
      __typename?: "CancelEventResponse"
      event?: {
        __typename?: "Event"
        allowExternalGuests?: boolean | null
        canCancel?: boolean | null
        canEdit?: boolean | null
        city?: string | null
        databaseId?: string | null
        description?: string | null
        endsAt?: any | null
        hasPowerHost?: boolean | null
        hostZoomLink?: string | null
        id?: string | null
        image?: string | null
        isAttending?: boolean | null
        isExcludedMarketing?: boolean | null
        isFeaturedMarketing?: boolean | null
        isHosting?: boolean | null
        isPaid?: boolean | null
        isPictureRequired?: boolean | null
        isSeries?: boolean | null
        isVideoRequired?: boolean | null
        location?: string | null
        locationTypeId?: number | null
        name?: string | null
        price?: number | null
        slug: string
        startsAt?: any | null
        state?: string | null
        status: EventStatus
        virtualLocalZip?: string | null
        zoomLink?: string | null
        isWaitlistEnabled?: boolean | null
        attendeeThreshold?: {
          __typename?: "EventAttendeeThreshold"
          hoursBefore?: number | null
          minimum?: number | null
        } | null
        externalGuests?: {
          __typename?: "ExternalGuestConnection"
          totalCount: number
        } | null
        interest?: { __typename?: "Interest"; id: string; name: string } | null
        secondaryInterest?: {
          __typename?: "Interest"
          id: string
          name: string
        } | null
      } | null
    } | null
  } | null
}

export type GeocodeAddressQueryVariables = Exact<{
  address: Scalars["String"]
}>

export type GeocodeAddressQuery = {
  __typename?: "Query"
  geocodeAddress?: {
    __typename?: "GeocodeAddressResponse"
    latitude?: number | null
    longitude?: number | null
  } | null
}

export type ModeratorsFragment = {
  __typename?: "GroupParticipantConnection"
  totalCount: number
  nodes?: Array<{
    __typename?: "GroupParticipant"
    createdAt: any
    id: string
    user: {
      __typename?: "User"
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
      shortName?: string | null
      slug?: string | null
    }
  } | null> | null
  pageInfo: {
    __typename?: "PageInfo"
    endCursor?: string | null
    hasNextPage: boolean
  }
}

export type MembersFragment = {
  __typename?: "GroupParticipantConnection"
  totalCount: number
  nodes?: Array<{
    __typename?: "GroupParticipant"
    createdAt: any
    id: string
    user: {
      __typename?: "User"
      id?: string | null
      profilePictureUrl?: string | null
      shortName?: string | null
      slug?: string | null
    }
  } | null> | null
  pageInfo: {
    __typename?: "PageInfo"
    endCursor?: string | null
    hasNextPage: boolean
  }
}

export type GroupAboutQueryVariables = Exact<{
  membersBeforeCursor?: InputMaybe<Scalars["String"]>
  moderatorsAfterCursor?: InputMaybe<Scalars["String"]>
  slug: Scalars["String"]
}>

export type GroupAboutQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    about: string
    id: string
    isMember: boolean
    isModerator: boolean
    name: string
    members?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "GroupParticipant"
        createdAt: any
        id: string
        user: {
          __typename?: "User"
          id?: string | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        }
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
    moderators?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "GroupParticipant"
        createdAt: any
        id: string
        user: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        }
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type GroupDetailLeaveGroupMutationVariables = Exact<{
  groupId: Scalars["ID"]
}>

export type GroupDetailLeaveGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    leave?: {
      __typename?: "GroupLeaveResponse"
      group?: {
        __typename?: "Group"
        databaseId: number
        id: string
        isMember: boolean
        isModerator: boolean
        name: string
        canPost: boolean
        slug: string
      } | null
      user?: {
        __typename?: "User"
        auth0Id?: string | null
        id?: string | null
      } | null
    } | null
  } | null
}

export type GroupDiscussionAnchoredPostPostFragment = {
  __typename?: "GroupPost"
  id: string
  sequenceId: number
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  lastActivityAt: any
  publishedAt?: any | null
  textBody: string
  totalCommentCount: number
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | {
          __typename?: "GroupPostAttachmentEvent"
          id: string
          event: {
            __typename?: "Event"
            endsAt?: any | null
            image?: string | null
            name?: string | null
            slug: string
            startsAt?: any | null
            hostships?: {
              __typename?: "HostshipConnection"
              nodes?: Array<{
                __typename?: "Hostship"
                user?: { __typename?: "User"; fullName?: string | null } | null
              } | null> | null
            } | null
          }
        }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  comments: { __typename?: "GroupPostConnection"; totalCount: number }
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
    slug: string
  }
}

export type GroupDiscussionAnchoredPostCommentFragment = {
  __typename?: "GroupPost"
  id: string
  sequenceId: number
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  textBody: string
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | { __typename?: "GroupPostAttachmentEvent" }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
  }
  myReaction?: {
    __typename?: "Reaction"
    content: ReactionContent
    id: string
  } | null
}

export type GroupDiscussionAnchoredPostNestedCommentFragment = {
  __typename?: "GroupPost"
  id: string
  sequenceId: number
  canDelete: boolean
  canLock: boolean
  canPin: boolean
  canPost: boolean
  canUnlock: boolean
  canUnpin: boolean
  canUpdate: boolean
  createdAt: any
  databaseId: number
  isLocked: boolean
  isMyPost: boolean
  isPinned: boolean
  isUpdated: boolean
  textBody: string
  attachments?: {
    __typename?: "GroupPostAttachmentConnection"
    nodes?: Array<
      | { __typename?: "GroupPostAttachmentEvent" }
      | {
          __typename?: "GroupPostAttachmentFile"
          id: string
          mimeType: string
          url: string
        }
      | null
    > | null
  } | null
  createdBy: {
    __typename?: "User"
    auth0Id?: string | null
    firstName?: string | null
    fullName?: string | null
    id?: string | null
    isBlocked?: boolean | null
    isFollowingEvents?: boolean | null
    isInAddressBook?: boolean | null
    profilePictureUrl?: string | null
    slug?: string | null
  }
  group: {
    __typename?: "Group"
    databaseId: number
    id: string
    isModerator: boolean
    name: string
  }
  myReaction?: {
    __typename?: "Reaction"
    content: ReactionContent
    id: string
  } | null
}

export type GroupDiscussionAnchoredPostGroupFragment = {
  __typename?: "Group"
  databaseId: number
  id: string
  isMember: boolean
  isModerator: boolean
  name: string
  canPost: boolean
  slug: string
}

export type GroupDiscussionAnchoredPostQueryVariables = Exact<{
  anchoredCommentSequenceId: Scalars["Int"]
  anchoredNestedCommentSequenceId: Scalars["Int"]
  anchoredPostSequenceId: Scalars["Int"]
  fetchAnchoredComment: Scalars["Boolean"]
  fetchAnchoredNestedComment: Scalars["Boolean"]
  slug: Scalars["String"]
}>

export type GroupDiscussionAnchoredPostQuery = {
  __typename?: "Query"
  comment?: {
    __typename?: "GroupPost"
    id: string
    sequenceId: number
    canDelete: boolean
    canLock: boolean
    canPin: boolean
    canPost: boolean
    canUnlock: boolean
    canUnpin: boolean
    canUpdate: boolean
    createdAt: any
    databaseId: number
    isLocked: boolean
    isMyPost: boolean
    isPinned: boolean
    isUpdated: boolean
    textBody: string
    attachments?: {
      __typename?: "GroupPostAttachmentConnection"
      nodes?: Array<
        | { __typename?: "GroupPostAttachmentEvent" }
        | {
            __typename?: "GroupPostAttachmentFile"
            id: string
            mimeType: string
            url: string
          }
        | null
      > | null
    } | null
    createdBy: {
      __typename?: "User"
      auth0Id?: string | null
      firstName?: string | null
      fullName?: string | null
      id?: string | null
      isBlocked?: boolean | null
      isFollowingEvents?: boolean | null
      isInAddressBook?: boolean | null
      profilePictureUrl?: string | null
      slug?: string | null
    }
    group: {
      __typename?: "Group"
      databaseId: number
      id: string
      isModerator: boolean
      name: string
    }
    myReaction?: {
      __typename?: "Reaction"
      content: ReactionContent
      id: string
    } | null
  } | null
  group?: {
    __typename?: "Group"
    databaseId: number
    id: string
    isMember: boolean
    isModerator: boolean
    name: string
    canPost: boolean
    slug: string
  } | null
  nestedComment?: {
    __typename?: "GroupPost"
    id: string
    sequenceId: number
    canDelete: boolean
    canLock: boolean
    canPin: boolean
    canPost: boolean
    canUnlock: boolean
    canUnpin: boolean
    canUpdate: boolean
    createdAt: any
    databaseId: number
    isLocked: boolean
    isMyPost: boolean
    isPinned: boolean
    isUpdated: boolean
    textBody: string
    attachments?: {
      __typename?: "GroupPostAttachmentConnection"
      nodes?: Array<
        | { __typename?: "GroupPostAttachmentEvent" }
        | {
            __typename?: "GroupPostAttachmentFile"
            id: string
            mimeType: string
            url: string
          }
        | null
      > | null
    } | null
    createdBy: {
      __typename?: "User"
      auth0Id?: string | null
      firstName?: string | null
      fullName?: string | null
      id?: string | null
      isBlocked?: boolean | null
      isFollowingEvents?: boolean | null
      isInAddressBook?: boolean | null
      profilePictureUrl?: string | null
      slug?: string | null
    }
    group: {
      __typename?: "Group"
      databaseId: number
      id: string
      isModerator: boolean
      name: string
    }
    myReaction?: {
      __typename?: "Reaction"
      content: ReactionContent
      id: string
    } | null
  } | null
  post?: {
    __typename?: "GroupPost"
    id: string
    sequenceId: number
    canDelete: boolean
    canLock: boolean
    canPin: boolean
    canPost: boolean
    canUnlock: boolean
    canUnpin: boolean
    canUpdate: boolean
    createdAt: any
    databaseId: number
    isLocked: boolean
    isMyPost: boolean
    isPinned: boolean
    isUpdated: boolean
    lastActivityAt: any
    publishedAt?: any | null
    textBody: string
    totalCommentCount: number
    attachments?: {
      __typename?: "GroupPostAttachmentConnection"
      nodes?: Array<
        | {
            __typename?: "GroupPostAttachmentEvent"
            id: string
            event: {
              __typename?: "Event"
              endsAt?: any | null
              image?: string | null
              name?: string | null
              slug: string
              startsAt?: any | null
              hostships?: {
                __typename?: "HostshipConnection"
                nodes?: Array<{
                  __typename?: "Hostship"
                  user?: {
                    __typename?: "User"
                    fullName?: string | null
                  } | null
                } | null> | null
              } | null
            }
          }
        | {
            __typename?: "GroupPostAttachmentFile"
            id: string
            mimeType: string
            url: string
          }
        | null
      > | null
    } | null
    comments: { __typename?: "GroupPostConnection"; totalCount: number }
    createdBy: {
      __typename?: "User"
      auth0Id?: string | null
      firstName?: string | null
      fullName?: string | null
      id?: string | null
      isBlocked?: boolean | null
      isFollowingEvents?: boolean | null
      isInAddressBook?: boolean | null
      profilePictureUrl?: string | null
      slug?: string | null
    }
    group: {
      __typename?: "Group"
      databaseId: number
      id: string
      isModerator: boolean
      name: string
      slug: string
    }
  } | null
}

export type GroupDiscussion__FeedQueryVariables = Exact<{
  connection: ConnectionInput
  orders?: InputMaybe<Array<GroupPostOrderInput> | GroupPostOrderInput>
  slug: Scalars["String"]
}>

export type GroupDiscussion__FeedQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: string
    discussionFeed?: {
      __typename?: "GroupPostConnection"
      totalCount: number
      edges?: Array<{
        __typename?: "GroupPostEdge"
        cursor?: string | null
        node?: {
          __typename?: "GroupPost"
          createdAt: any
          id: string
          lastActivityAt: any
          canDelete: boolean
          canLock: boolean
          canPin: boolean
          canPost: boolean
          canUnlock: boolean
          canUnpin: boolean
          canUpdate: boolean
          databaseId: number
          isLocked: boolean
          isMyPost: boolean
          isPinned: boolean
          isUpdated: boolean
          publishedAt?: any | null
          sequenceId: number
          textBody: string
          totalCommentCount: number
          attachments?: {
            __typename?: "GroupPostAttachmentConnection"
            nodes?: Array<
              | {
                  __typename?: "GroupPostAttachmentEvent"
                  id: string
                  event: {
                    __typename?: "Event"
                    endsAt?: any | null
                    image?: string | null
                    name?: string | null
                    slug: string
                    startsAt?: any | null
                    hostships?: {
                      __typename?: "HostshipConnection"
                      nodes?: Array<{
                        __typename?: "Hostship"
                        user?: {
                          __typename?: "User"
                          fullName?: string | null
                        } | null
                      } | null> | null
                    } | null
                  }
                }
              | {
                  __typename?: "GroupPostAttachmentFile"
                  id: string
                  mimeType: string
                  url: string
                }
              | null
            > | null
          } | null
          comments: { __typename?: "GroupPostConnection"; totalCount: number }
          createdBy: {
            __typename?: "User"
            auth0Id?: string | null
            firstName?: string | null
            fullName?: string | null
            id?: string | null
            isBlocked?: boolean | null
            isFollowingEvents?: boolean | null
            isInAddressBook?: boolean | null
            profilePictureUrl?: string | null
            slug?: string | null
          }
          group: {
            __typename?: "Group"
            databaseId: number
            id: string
            isModerator: boolean
            name: string
            slug: string
          }
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    } | null
  } | null
}

export type Group__DiscussionQueryVariables = Exact<{
  postBeforeCursor?: InputMaybe<Scalars["String"]>
  slug: Scalars["String"]
}>

export type Group__DiscussionQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: string
    isPrivate: boolean
    discussionFeed?: {
      __typename?: "GroupPostConnection"
      totalCount: number
      nodes?: Array<{ __typename?: "GroupPost"; id: string } | null> | null
      edges?: Array<{
        __typename?: "GroupPostEdge"
        cursor?: string | null
        node?: {
          __typename?: "GroupPost"
          canDelete: boolean
          canLock: boolean
          canPin: boolean
          canPost: boolean
          canUnlock: boolean
          canUnpin: boolean
          canUpdate: boolean
          createdAt: any
          databaseId: number
          id: string
          isLocked: boolean
          isMyPost: boolean
          isPinned: boolean
          isUpdated: boolean
          lastActivityAt: any
          publishedAt?: any | null
          sequenceId: number
          textBody: string
          totalCommentCount: number
          attachments?: {
            __typename?: "GroupPostAttachmentConnection"
            nodes?: Array<
              | {
                  __typename?: "GroupPostAttachmentEvent"
                  id: string
                  event: {
                    __typename?: "Event"
                    endsAt?: any | null
                    image?: string | null
                    name?: string | null
                    slug: string
                    startsAt?: any | null
                    hostships?: {
                      __typename?: "HostshipConnection"
                      nodes?: Array<{
                        __typename?: "Hostship"
                        user?: {
                          __typename?: "User"
                          fullName?: string | null
                        } | null
                      } | null> | null
                    } | null
                  }
                }
              | {
                  __typename?: "GroupPostAttachmentFile"
                  id: string
                  mimeType: string
                  url: string
                }
              | null
            > | null
          } | null
          comments: { __typename?: "GroupPostConnection"; totalCount: number }
          createdBy: {
            __typename?: "User"
            auth0Id?: string | null
            firstName?: string | null
            fullName?: string | null
            id?: string | null
            isBlocked?: boolean | null
            isFollowingEvents?: boolean | null
            isInAddressBook?: boolean | null
            profilePictureUrl?: string | null
            slug?: string | null
          }
          group: {
            __typename?: "Group"
            databaseId: number
            id: string
            isModerator: boolean
            name: string
            slug: string
          }
        } | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    } | null
  } | null
}

export type GroupEventsQueryVariables = Exact<{
  previousEventsCursor?: InputMaybe<Scalars["String"]>
  slug: Scalars["String"]
  upcomingEventsCursor?: InputMaybe<Scalars["String"]>
}>

export type GroupEventsQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: string
    isMember: boolean
    isPrivate: boolean
    previousEvents?: {
      __typename?: "EventConnection"
      nodes?: Array<{
        __typename?: "Event"
        id?: string | null
        name?: string | null
        slug: string
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
    upcomingEvents?: {
      __typename?: "EventConnection"
      nodes?: Array<{
        __typename?: "Event"
        id?: string | null
        name?: string | null
        slug: string
        startsAt?: any | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type GroupDetailHeaderJoinGroupMutationVariables = Exact<{
  groupId: Scalars["ID"]
}>

export type GroupDetailHeaderJoinGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    join?: {
      __typename?: "GroupJoinResponse"
      createdGroupParticipant?: {
        __typename?: "GroupParticipant"
        id: string
        user: {
          __typename?: "User"
          auth0Id?: string | null
          id?: string | null
        }
      } | null
      group?: {
        __typename?: "Group"
        databaseId: number
        id: string
        isMember: boolean
        isModerator: boolean
        name: string
        canPost: boolean
        slug: string
        discussionFeed?: {
          __typename?: "GroupPostConnection"
          edges?: Array<{
            __typename?: "GroupPostEdge"
            cursor?: string | null
            node?: {
              __typename?: "GroupPost"
              id: string
              totalCommentCount: number
            } | null
          } | null> | null
        } | null
      } | null
    } | null
  } | null
}

export type GroupQuestionsCompleteGroupFragment = {
  __typename?: "Group"
  about: string
  id: string
  isMember: boolean
  isModerator: boolean
  isPrivate: boolean
  name: string
  questions?: {
    __typename?: "GroupQuestionConnection"
    nodes?: Array<{
      __typename?: "GroupQuestion"
      id: string
      textBody: string
    } | null> | null
  } | null
}

export type GroupQuestionsQueryVariables = Exact<{
  id: Scalars["ID"]
  questionsAfterCursor?: InputMaybe<Scalars["String"]>
}>

export type GroupQuestionsQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    about: string
    id: string
    isMember: boolean
    isModerator: boolean
    isPrivate: boolean
    name: string
    questions?: {
      __typename?: "GroupQuestionConnection"
      nodes?: Array<{
        __typename?: "GroupQuestion"
        id: string
        textBody: string
      } | null> | null
    } | null
  } | null
}

export type GroupDetailRequestToJoinGroupMutationVariables = Exact<{
  answers?: InputMaybe<
    Array<GroupQuestionAnswerInput> | GroupQuestionAnswerInput
  >
  groupId: Scalars["ID"]
}>

export type GroupDetailRequestToJoinGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    join?: {
      __typename?: "GroupJoinResponse"
      createdGroupParticipant?: {
        __typename?: "GroupParticipant"
        id: string
        user: {
          __typename?: "User"
          auth0Id?: string | null
          id?: string | null
        }
      } | null
      group?: {
        __typename?: "Group"
        databaseId: number
        id: string
        isMember: boolean
        isModerator: boolean
        name: string
        canPost: boolean
        slug: string
        discussionFeed?: {
          __typename?: "GroupPostConnection"
          edges?: Array<{
            __typename?: "GroupPostEdge"
            cursor?: string | null
            node?: {
              __typename?: "GroupPost"
              id: string
              totalCommentCount: number
            } | null
          } | null> | null
        } | null
      } | null
    } | null
  } | null
}

export type GroupDetailApproveRequestToJoinGroupMutationVariables = Exact<{
  groupId: Scalars["ID"]
  userId: Scalars["ID"]
}>

export type GroupDetailApproveRequestToJoinGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    approveMemberRequestToJoin?: {
      __typename?: "ApproveMemberRequestToJoinResponse"
      groupParticipant?: { __typename?: "GroupParticipant"; id: string } | null
    } | null
  } | null
}

export type GroupDetailPendingParticipantAnswerDetailFragment = {
  __typename?: "GroupAnswer"
  id: string
  textBody: string
  question: { __typename?: "GroupQuestion"; id: string; textBody: string }
}

export type GroupDetailPendingParticipantAnswersFragment = {
  __typename?: "GroupParticipant"
  createdAt: any
  id: string
  answers: {
    __typename?: "GroupAnswerConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "GroupAnswer"
      id: string
      textBody: string
      question: { __typename?: "GroupQuestion"; id: string; textBody: string }
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  }
  user: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
    shortName?: string | null
    slug?: string | null
  }
}

export type GroupDetailMemberQuestionAnswersQueryVariables = Exact<{
  answersAfterCursor?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
}>

export type GroupDetailMemberQuestionAnswersQuery = {
  __typename?: "Query"
  groupParticipant: {
    __typename?: "GroupParticipant"
    createdAt: any
    id: string
    answers: {
      __typename?: "GroupAnswerConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "GroupAnswer"
        id: string
        textBody: string
        question: { __typename?: "GroupQuestion"; id: string; textBody: string }
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    }
    user: {
      __typename?: "User"
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
      shortName?: string | null
      slug?: string | null
    }
  }
}

export type GroupDetailRejectRequestToJoinGroupMutationVariables = Exact<{
  groupId: Scalars["ID"]
  userId: Scalars["ID"]
}>

export type GroupDetailRejectRequestToJoinGroupMutation = {
  __typename?: "Mutation"
  group?: {
    __typename?: "GroupMutation"
    rejectMemberRequestToJoin?: {
      __typename?: "RejectMemberRequestToJoinResponse"
      groupParticipant?: { __typename?: "GroupParticipant"; id: string } | null
    } | null
  } | null
}

export type GroupDetailPendingParticipantFragment = {
  __typename?: "GroupParticipant"
  createdAt: any
  id: string
  user: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
    shortName?: string | null
    slug?: string | null
  }
}

export type GroupDetailPendingParticipantsFragment = {
  __typename?: "Group"
  id: string
  pendingParticipants?: {
    __typename?: "GroupParticipantConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "GroupParticipant"
      createdAt: any
      id: string
      user: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
        shortName?: string | null
        slug?: string | null
      }
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type GroupDetailMembersTabQueryVariables = Exact<{
  pendingParticipantsAfterCursor?: InputMaybe<Scalars["String"]>
  slug: Scalars["String"]
}>

export type GroupDetailMembersTabQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    id: string
    pendingParticipants?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "GroupParticipant"
        createdAt: any
        id: string
        user: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        }
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type GroupDetailModeratorsFragment = {
  __typename?: "GroupParticipantConnection"
  nodes?: Array<{
    __typename?: "GroupParticipant"
    id: string
    user: {
      __typename?: "User"
      fullName?: string | null
      id?: string | null
      profilePictureUrl?: string | null
      shortName?: string | null
      slug?: string | null
    }
  } | null> | null
}

export type GroupDetailParticipantFragment = {
  __typename?: "GroupParticipant"
  createdAt: any
  id: string
  user: {
    __typename?: "User"
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
    shortName?: string | null
    slug?: string | null
  }
}

export type GroupDetailGroupFragment = {
  __typename?: "Group"
  canPost: boolean
  headline: string
  id: string
  isMember: boolean
  isModerator: boolean
  isPendingMember: boolean
  isPrivate: boolean
  name: string
  pictureUrl: string
  slug: string
}

export type GroupDetailCompleteGroupFragment = {
  __typename?: "Group"
  canPost: boolean
  headline: string
  id: string
  isMember: boolean
  isModerator: boolean
  isPendingMember: boolean
  isPrivate: boolean
  name: string
  pictureUrl: string
  slug: string
  moderators?: {
    __typename?: "GroupParticipantConnection"
    nodes?: Array<{
      __typename?: "GroupParticipant"
      id: string
      user: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
        shortName?: string | null
        slug?: string | null
      }
    } | null> | null
  } | null
  participants?: {
    __typename?: "GroupParticipantConnection"
    totalCount: number
    nodes?: Array<{
      __typename?: "GroupParticipant"
      createdAt: any
      id: string
      user: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
        shortName?: string | null
        slug?: string | null
      }
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
  pendingParticipants?: {
    __typename?: "GroupParticipantConnection"
    totalCount: number
  } | null
}

export type GroupDetailQueryVariables = Exact<{
  moderatorsAfterCursor?: InputMaybe<Scalars["String"]>
  participantsAfterCursor?: InputMaybe<Scalars["String"]>
  pendingParticipantsAfterCursor?: InputMaybe<Scalars["String"]>
  slug: Scalars["String"]
}>

export type GroupDetailQuery = {
  __typename?: "Query"
  group?: {
    __typename?: "Group"
    canPost: boolean
    headline: string
    id: string
    isMember: boolean
    isModerator: boolean
    isPendingMember: boolean
    isPrivate: boolean
    name: string
    pictureUrl: string
    slug: string
    moderators?: {
      __typename?: "GroupParticipantConnection"
      nodes?: Array<{
        __typename?: "GroupParticipant"
        id: string
        user: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        }
      } | null> | null
    } | null
    participants?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
      nodes?: Array<{
        __typename?: "GroupParticipant"
        createdAt: any
        id: string
        user: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
          shortName?: string | null
          slug?: string | null
        }
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
    pendingParticipants?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
    } | null
  } | null
}

export type GroupFragmentFragment = {
  __typename?: "Group"
  headline: string
  id: string
  isMember: boolean
  name: string
  slug: string
}

export type GroupsQueryVariables = Exact<{
  groupAfterCursor?: InputMaybe<Scalars["String"]>
}>

export type GroupsQuery = {
  __typename?: "Query"
  groups?: {
    __typename?: "GroupConnection"
    nodes?: Array<{
      __typename?: "Group"
      headline: string
      id: string
      isMember: boolean
      name: string
      slug: string
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type HomeLeftPanelEventFragment = {
  __typename?: "Event"
  databaseId?: string | null
  endsAt?: any | null
  hostZoomLink?: string | null
  id?: string | null
  isHosting?: boolean | null
  location?: string | null
  locationTypeId?: number | null
  name?: string | null
  slug: string
  startsAt?: any | null
  zoomLink?: string | null
}

export type HomeLeftPanelCurrentUserFragment = {
  __typename?: "User"
  id?: string | null
  memberEventActions?: {
    __typename?: "MemberEventActions"
    critical?: {
      __typename?: "EventConnection"
      nodes?: Array<{
        __typename?: "Event"
        databaseId?: string | null
        endsAt?: any | null
        hostZoomLink?: string | null
        id?: string | null
        isHosting?: boolean | null
        location?: string | null
        locationTypeId?: number | null
        name?: string | null
        slug: string
        startsAt?: any | null
        zoomLink?: string | null
      } | null> | null
    } | null
    unreviewed?: {
      __typename?: "EventConnection"
      nodes?: Array<{
        __typename?: "Event"
        databaseId?: string | null
        endsAt?: any | null
        hostZoomLink?: string | null
        id?: string | null
        isHosting?: boolean | null
        location?: string | null
        locationTypeId?: number | null
        name?: string | null
        slug: string
        startsAt?: any | null
        zoomLink?: string | null
      } | null> | null
    } | null
  } | null
}

export type HomeLeftPanelQueryVariables = Exact<{ [key: string]: never }>

export type HomeLeftPanelQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    id?: string | null
    memberEventActions?: {
      __typename?: "MemberEventActions"
      critical?: {
        __typename?: "EventConnection"
        nodes?: Array<{
          __typename?: "Event"
          databaseId?: string | null
          endsAt?: any | null
          hostZoomLink?: string | null
          id?: string | null
          isHosting?: boolean | null
          location?: string | null
          locationTypeId?: number | null
          name?: string | null
          slug: string
          startsAt?: any | null
          zoomLink?: string | null
        } | null> | null
      } | null
      unreviewed?: {
        __typename?: "EventConnection"
        nodes?: Array<{
          __typename?: "Event"
          databaseId?: string | null
          endsAt?: any | null
          hostZoomLink?: string | null
          id?: string | null
          isHosting?: boolean | null
          location?: string | null
          locationTypeId?: number | null
          name?: string | null
          slug: string
          startsAt?: any | null
          zoomLink?: string | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type MyGroupsDiscussionFeedQueryVariables = Exact<{
  authoredById?: InputMaybe<Scalars["ID"]>
  connection: ConnectionInput
  membershipStatus: GroupMembershipStatus
  orders?: InputMaybe<Array<GroupPostOrderInput> | GroupPostOrderInput>
}>

export type MyGroupsDiscussionFeedQuery = {
  __typename?: "Query"
  groupDiscussionFeed?: {
    __typename?: "GroupPostConnection"
    totalCount: number
    edges?: Array<{
      __typename?: "GroupPostEdge"
      cursor?: string | null
      node?: {
        __typename?: "GroupPost"
        canDelete: boolean
        canLock: boolean
        canPin: boolean
        canPost: boolean
        canUnlock: boolean
        canUnpin: boolean
        canUpdate: boolean
        createdAt: any
        databaseId: number
        id: string
        isLocked: boolean
        isMyPost: boolean
        isPinned: boolean
        isUpdated: boolean
        lastActivityAt: any
        publishedAt?: any | null
        sequenceId: number
        textBody: string
        totalCommentCount: number
        attachments?: {
          __typename?: "GroupPostAttachmentConnection"
          nodes?: Array<
            | {
                __typename?: "GroupPostAttachmentEvent"
                id: string
                event: {
                  __typename?: "Event"
                  endsAt?: any | null
                  image?: string | null
                  name?: string | null
                  slug: string
                  startsAt?: any | null
                  hostships?: {
                    __typename?: "HostshipConnection"
                    nodes?: Array<{
                      __typename?: "Hostship"
                      user?: {
                        __typename?: "User"
                        fullName?: string | null
                      } | null
                    } | null> | null
                  } | null
                }
              }
            | {
                __typename?: "GroupPostAttachmentFile"
                id: string
                mimeType: string
                url: string
              }
            | null
          > | null
        } | null
        comments: { __typename?: "GroupPostConnection"; totalCount: number }
        createdBy: {
          __typename?: "User"
          auth0Id?: string | null
          firstName?: string | null
          fullName?: string | null
          id?: string | null
          isBlocked?: boolean | null
          isFollowingEvents?: boolean | null
          isInAddressBook?: boolean | null
          profilePictureUrl?: string | null
          slug?: string | null
        }
        group: {
          __typename?: "Group"
          databaseId: number
          id: string
          isModerator: boolean
          name: string
          slug: string
        }
      } | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  } | null
}

export type RsvpMutationVariables = Exact<{
  eventId: Scalars["ID"]
}>

export type RsvpMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    RSVP?: {
      __typename?: "RSVPResponse"
      ticket?: { __typename?: "Ticket"; id?: string | null } | null
    } | null
  } | null
}

export type FetchLifeTransitionsQueryVariables = Exact<{
  lifeTransitionAfterCursor?: InputMaybe<Scalars["String"]>
}>

export type FetchLifeTransitionsQuery = {
  __typename?: "Query"
  lifeTransitions?: {
    __typename?: "LifeTransitionConnection"
    nodes?: Array<{
      __typename?: "LifeTransition"
      id?: string | null
      name?: string | null
    } | null> | null
    pageInfo: {
      __typename?: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type FetchMyLifeTransitionsQueryVariables = Exact<{
  lifeTransitionAfterCursor?: InputMaybe<Scalars["String"]>
}>

export type FetchMyLifeTransitionsQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    lifeTransitions?: {
      __typename?: "LifeTransitionConnection"
      nodes?: Array<{
        __typename?: "LifeTransition"
        id?: string | null
        name?: string | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type FetchRecommendedEventsQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchRecommendedEventsQuery = {
  __typename?: "Query"
  recommendedEvents?: Array<{
    __typename?: "Event"
    city?: string | null
    endsAt?: any | null
    id?: string | null
    image?: string | null
    name?: string | null
    startsAt?: any | null
    state?: string | null
    eventLocationTypeId?: number | null
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        id?: string | null
        user?: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          shortName?: string | null
          picture?: string | null
        } | null
      } | null> | null
    } | null
  } | null> | null
}

export type FetchRecommendedGroupsQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchRecommendedGroupsQuery = {
  __typename?: "Query"
  recommendedGroups?: Array<{
    __typename?: "Group"
    databaseId: number
    id: string
    isMember: boolean
    name: string
    pictureUrl: string
    slug: string
    curators?: {
      __typename?: "GroupParticipantConnection"
      nodes?: Array<{
        __typename?: "GroupParticipant"
        id: string
        user: {
          __typename?: "User"
          fullName?: string | null
          id?: string | null
          shortName?: string | null
          picture?: string | null
        }
      } | null> | null
    } | null
    participants?: {
      __typename?: "GroupParticipantConnection"
      totalCount: number
    } | null
  } | null> | null
}

export type FetchUserJoinedFromQueryVariables = Exact<{ [key: string]: never }>

export type FetchUserJoinedFromQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    id?: string | null
    joinedFromPath?: string | null
  } | null
}

export type PostJoinModalCurrentUserFragment = {
  __typename?: "User"
  id?: string | null
  interests?: { __typename?: "InterestConnection"; totalCount: number } | null
  lifeTransitions?: {
    __typename?: "LifeTransitionConnection"
    totalCount: number
  } | null
}

export type PostJoinModalQueryVariables = Exact<{ [key: string]: never }>

export type PostJoinModalQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    id?: string | null
    interests?: { __typename?: "InterestConnection"; totalCount: number } | null
    lifeTransitions?: {
      __typename?: "LifeTransitionConnection"
      totalCount: number
    } | null
  } | null
}

export type MarkPostJoinModalAsSeenMutationVariables = Exact<{
  [key: string]: never
}>

export type MarkPostJoinModalAsSeenMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    markPostJoinModalAsSeen?: {
      __typename?: "MarkPostJoinModalAsSeenResponse"
      user?: {
        __typename?: "User"
        id?: string | null
        postJoinModalSeenAt?: any | null
      } | null
    } | null
  } | null
}

export type SetLifeTransitionsMutationVariables = Exact<{
  lifeTransitionIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>
}>

export type SetLifeTransitionsMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    setLifeTransitions: {
      __typename?: "UserSetLifeTransitionsResponse"
      user?: { __typename?: "User"; id?: string | null } | null
    }
  } | null
}

export type HomeCurrentUserFragment = {
  __typename?: "User"
  hasCompletedSignup?: boolean | null
  id?: string | null
  moderationStatus?: string | null
  postJoinModalSeenAt?: any | null
}

export type HomeQueryVariables = Exact<{ [key: string]: never }>

export type HomeQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    hasCompletedSignup?: boolean | null
    id?: string | null
    moderationStatus?: string | null
    postJoinModalSeenAt?: any | null
  } | null
}

export type MarkFeedbackModalAsSeenMutationMutationVariables = Exact<{
  dbEventId: Scalars["ID"]
  markRestOfUserTicketsAsSeen?: InputMaybe<Scalars["Boolean"]>
}>

export type MarkFeedbackModalAsSeenMutationMutation = {
  __typename?: "Mutation"
  event?: {
    __typename?: "EventMutation"
    markFeedbackModalAsSeen?: {
      __typename?: "MarkFeedbackModalAsSeenResponse"
      event?: { __typename?: "Event"; id?: string | null } | null
    } | null
  } | null
}

export type JoinForm__CurrentUserQueryVariables = Exact<{
  [key: string]: never
}>

export type JoinForm__CurrentUserQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    firstName?: string | null
    hasCompletedSignup?: boolean | null
    id?: string | null
    lastName?: string | null
  } | null
}

export type EventAddHostMutationMutationVariables = Exact<{
  eventId: Scalars["String"]
  userId: Scalars["String"]
}>

export type EventAddHostMutationMutation = {
  __typename?: "Mutation"
  eventAddHost?: {
    __typename?: "EventAddHostResponse"
    hostship?: {
      __typename?: "Hostship"
      hostRole?: string | null
      id?: string | null
      user?: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
      } | null
    } | null
  } | null
}

export type EventRemoveHostMutationMutationVariables = Exact<{
  eventId: Scalars["String"]
  userId: Scalars["String"]
}>

export type EventRemoveHostMutationMutation = {
  __typename?: "Mutation"
  eventRemoveHost?: {
    __typename?: "EventRemoveHostResponse"
    hostship?: { __typename?: "Hostship"; id?: string | null } | null
  } | null
}

export type EventUpdateHostRoleMutationMutationVariables = Exact<{
  eventId: Scalars["String"]
  role: Scalars["String"]
  userId: Scalars["String"]
}>

export type EventUpdateHostRoleMutationMutation = {
  __typename?: "Mutation"
  eventUpdateHostRole?: {
    __typename?: "EventUpdateHostRoleResponse"
    hostships?: Array<{
      __typename?: "Hostship"
      hostRole?: string | null
      id?: string | null
      user?: {
        __typename?: "User"
        fullName?: string | null
        id?: string | null
        profilePictureUrl?: string | null
      } | null
    } | null> | null
  } | null
}

export type NewHostshipFragment = {
  __typename?: "Hostship"
  hostRole?: string | null
  id?: string | null
  user?: {
    __typename?: "User"
    auth0Id?: string | null
    fullName?: string | null
    id?: string | null
    profilePictureUrl?: string | null
  } | null
}

export type HostManagementQueryVariables = Exact<{
  eventId: Scalars["String"]
}>

export type HostManagementQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    hostships?: {
      __typename?: "HostshipConnection"
      nodes?: Array<{
        __typename?: "Hostship"
        hostRole?: string | null
        id?: string | null
        user?: {
          __typename?: "User"
          auth0Id?: string | null
          fullName?: string | null
          id?: string | null
          profilePictureUrl?: string | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type HostRatingQueryVariables = Exact<{ [key: string]: never }>

export type HostRatingQuery = {
  __typename?: "Query"
  currentUser?: {
    __typename?: "User"
    averageRating?: number | null
    id?: string | null
  } | null
}

export type EventCellQueryVariables = Exact<{
  databaseId: Scalars["String"]
}>

export type EventCellQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    averageRating?: number | null
    id?: string | null
    reviews?: {
      __typename?: "EventReviewConnection"
      totalCount: number
    } | null
  } | null
}

export type EventReviewsQueryVariables = Exact<{
  databaseId: Scalars["String"]
  reviewsAfterCursor?: InputMaybe<Scalars["String"]>
}>

export type EventReviewsQuery = {
  __typename?: "Query"
  event?: {
    __typename?: "Event"
    averageRating?: number | null
    id?: string | null
    name?: string | null
    reviews?: {
      __typename?: "EventReviewConnection"
      nodes?: Array<{
        __typename?: "EventReview"
        feedbackForHost?: string | null
        id?: string | null
        ratingForHost?: number | null
      } | null> | null
      pageInfo: {
        __typename?: "PageInfo"
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type User__ChangeEmailMutationMutationVariables = Exact<{
  email: Scalars["String"]
  id: Scalars["ID"]
}>

export type User__ChangeEmailMutationMutation = {
  __typename?: "Mutation"
  user?: {
    __typename?: "UserMutation"
    updateEmail?: {
      __typename?: "UserUpdateEmailResponse"
      user: { __typename?: "User"; id?: string | null }
    } | null
  } | null
}

export type ProfileQueryVariables = Exact<{
  auth0id?: InputMaybe<Scalars["String"]>
}>

export type ProfileQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: string | null
    moderationStatus?: string | null
  } | null
}

export const DiscussionFeedPost__GroupPostAttachmentEventFragmentFragmentDoc = gql`
  fragment DiscussionFeedPost__GroupPostAttachmentEventFragment on GroupPostAttachmentEvent {
    event {
      endsAt
      hostships {
        nodes {
          user {
            fullName
          }
        }
      }
      image
      name
      slug
      startsAt
    }
    id
  }
`
export const DiscussionFeedPost__GroupPostAttachmentFileFragmentFragmentDoc = gql`
  fragment DiscussionFeedPost__GroupPostAttachmentFileFragment on GroupPostAttachmentFile {
    id
    mimeType
    url
  }
`
export const DiscussionFeedPost__CreatedByFragmentFragmentDoc = gql`
  fragment DiscussionFeedPost__CreatedByFragment on User {
    auth0Id
    firstName
    fullName
    id
    isBlocked
    isFollowingEvents
    isInAddressBook
    profilePictureUrl
    slug
  }
`
export const DiscussionFeedPost__GroupPostFragmentFragmentDoc = gql`
  fragment DiscussionFeedPost__GroupPostFragment on GroupPost {
    canDelete
    canLock
    canPin
    canPost
    canUnlock
    canUnpin
    canUpdate
    comments: posts(connection: { first: 0 }) {
      totalCount
    }
    createdAt
    createdBy {
      ...DiscussionFeedPost__CreatedByFragment
    }
    databaseId
    group {
      databaseId
      id
      isModerator
      name
      slug
    }
    id
    isLocked
    isMyPost
    isPinned
    isUpdated
    lastActivityAt
    publishedAt
    sequenceId
    textBody
    totalCommentCount
  }
  ${DiscussionFeedPost__CreatedByFragmentFragmentDoc}
`
export const DiscussionFeedPostFragmentFragmentDoc = gql`
  fragment DiscussionFeedPostFragment on GroupPost {
    attachments(connection: { first: 10 }) {
      nodes {
        ... on GroupPostAttachmentEvent {
          ...DiscussionFeedPost__GroupPostAttachmentEventFragment
        }
        ... on GroupPostAttachmentFile {
          ...DiscussionFeedPost__GroupPostAttachmentFileFragment
        }
      }
    }
    ...DiscussionFeedPost__GroupPostFragment
  }
  ${DiscussionFeedPost__GroupPostAttachmentEventFragmentFragmentDoc}
  ${DiscussionFeedPost__GroupPostAttachmentFileFragmentFragmentDoc}
  ${DiscussionFeedPost__GroupPostFragmentFragmentDoc}
`
export const Group__CreatePostMutation__GroupPostFragmentFragmentDoc = gql`
  fragment Group__CreatePostMutation__GroupPostFragment on GroupPost {
    ...DiscussionFeedPostFragment
    group {
      id
    }
    id
  }
  ${DiscussionFeedPostFragmentFragmentDoc}
`
export const GroupPostComment__GroupPostAttachmentFileFragmentFragmentDoc = gql`
  fragment GroupPostComment__GroupPostAttachmentFileFragment on GroupPostAttachmentFile {
    id
    mimeType
    url
  }
`
export const GroupPostCommentFragmentFragmentDoc = gql`
  fragment GroupPostCommentFragment on GroupPost {
    attachments(connection: { first: 2 }) {
      nodes {
        ... on GroupPostAttachmentFile {
          ...GroupPostComment__GroupPostAttachmentFileFragment
        }
      }
    }
    canDelete
    canLock
    canPin
    canPost
    canUnlock
    canUnpin
    canUpdate
    createdAt
    createdBy {
      auth0Id
      firstName
      fullName
      id
      isBlocked
      isFollowingEvents
      isInAddressBook
      profilePictureUrl
      slug
    }
    databaseId
    group {
      databaseId
      id
      isModerator
      name
    }
    id
    isLocked
    isMyPost
    isPinned
    isUpdated
    myReaction {
      content
      id
    }
    sequenceId
    textBody
  }
  ${GroupPostComment__GroupPostAttachmentFileFragmentFragmentDoc}
`
export const CreatePostCommentMutationGroupPostFragmentDoc = gql`
  fragment CreatePostCommentMutationGroupPost on GroupPost {
    ...GroupPostCommentFragment
    id
    parentPost {
      id
    }
  }
  ${GroupPostCommentFragmentFragmentDoc}
`
export const DiscussionFeedFragmentFragmentDoc = gql`
  fragment DiscussionFeedFragment on GroupPostConnection {
    edges {
      cursor
      node {
        ...DiscussionFeedPostFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
  ${DiscussionFeedPostFragmentFragmentDoc}
`
export const DiscussionFeed__UserFragmentFragmentDoc = gql`
  fragment DiscussionFeed__UserFragment on User {
    auth0Id
    fullName
    id
    profilePictureUrl
    shortName
  }
`
export const DesktopHeaderCurrentUserFragmentDoc = gql`
  fragment DesktopHeaderCurrentUser on User {
    id
    profilePictureUrl
  }
`
export const InboxButtonUnreadMessagesFragmentDoc = gql`
  fragment InboxButtonUnreadMessages on ThreadMessageConnection {
    totalCount
  }
`
export const InboxButtonInboxFragmentDoc = gql`
  fragment InboxButtonInbox on Inbox {
    id
    unreadMessages: messages(read: false) {
      ...InboxButtonUnreadMessages
    }
  }
  ${InboxButtonUnreadMessagesFragmentDoc}
`
export const ProfileLinkCurrentUserFragmentDoc = gql`
  fragment ProfileLinkCurrentUser on User {
    fullName
    id
    profilePictureUrl
  }
`
export const ImpersonateBannerCurrentUserFragmentDoc = gql`
  fragment ImpersonateBannerCurrentUser on User {
    fullName
    id
  }
`
export const LayoutCurrentUserFragmentDoc = gql`
  fragment LayoutCurrentUser on User {
    auth0Id
    firstName
    id
    interests {
      nodes {
        id
        name
      }
    }
    lastName
    location {
      city
      country
      state
    }
    profilePictureUrl
    role
  }
`
export const PromoteToPowerHostUserFragmentFragmentDoc = gql`
  fragment PromoteToPowerHostUserFragment on User {
    id
    role
  }
`
export const UserForPostRatingModalFragmentDoc = gql`
  fragment UserForPostRatingModal on User {
    auth0Id
    firstName
    fullName
    id
    location {
      city
      country
      state
    }
    profilePictureUrl
  }
`
export const EventForRatingDataFragmentDoc = gql`
  fragment EventForRatingData on Event {
    attendees: participants(
      connection: $attendeesConnection
      orders: [{ by: CREATED_AT, direction: ASCENDING }]
      role: ATTENDEE
    ) {
      nodes {
        ...UserForPostRatingModal
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    endsAt
    hostships {
      nodes {
        id
        user {
          ...UserForPostRatingModal
        }
      }
    }
    id
    name
    startsAt
  }
  ${UserForPostRatingModalFragmentDoc}
`
export const UseUserRoleCurrentUserFragmentDoc = gql`
  fragment UseUserRoleCurrentUser on User {
    id
    role
  }
`
export const EventDetailsAttendeeUserFragmentDoc = gql`
  fragment EventDetailsAttendeeUser on User {
    fullName
    id
    profilePictureUrl
    slug
  }
`
export const EventDetailsAttendeeEventFragmentDoc = gql`
  fragment EventDetailsAttendeeEvent on Event {
    attendees: participants(
      connection: $attendeesConnection
      orders: [{ by: CREATED_AT, direction: ASCENDING }]
      role: ATTENDEE
    ) {
      nodes {
        ...EventDetailsAttendeeUser
        id
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    id
  }
  ${EventDetailsAttendeeUserFragmentDoc}
`
export const EventDetailsEssentialEventFragmentDoc = gql`
  fragment EventDetailsEssentialEvent on Event {
    allowExternalGuests
    attendeeThreshold {
      hoursBefore
      minimum
    }
    canCancel
    canEdit
    city
    databaseId
    description
    description
    endsAt
    externalGuests {
      totalCount
    }
    hasPowerHost
    hostZoomLink
    id
    image
    interest {
      id
      name
    }
    isAttending
    isExcludedMarketing
    isFeaturedMarketing
    isHosting
    isPaid
    isPictureRequired
    isSeries
    isVideoRequired
    location
    locationTypeId
    name
    price
    secondaryInterest {
      id
      name
    }
    slug
    startsAt
    state
    status
    virtualLocalZip
    zoomLink
    isWaitlistEnabled
  }
`
export const EventDetailsFeedEventPostFragmentDoc = gql`
  fragment EventDetailsFeedEventPost on EventPost {
    canLike
    canPost
    canUpdate
    comments: posts {
      totalCount
    }
    createdAt
    createdBy {
      id
      isBlocked
      profilePictureUrl
      shortName
      slug
    }
    id
    likes {
      totalCount
    }
    myLike {
      id
    }
    textBody
  }
`
export const EventDetailsFeedEventFragmentDoc = gql`
  fragment EventDetailsFeedEvent on Event {
    discussionFeed(
      connection: $feedConnection
      orders: [{ by: CREATED_AT, direction: DESCENDING }]
    ) {
      nodes {
        ...EventDetailsFeedEventPost
        id
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${EventDetailsFeedEventPostFragmentDoc}
`
export const EventDetailsHostUserFragmentDoc = gql`
  fragment EventDetailsHostUser on User {
    fullName
    id
    powerHostBio
    profilePictureUrl
  }
`
export const EventDetailsHostEventFragmentDoc = gql`
  fragment EventDetailsHostEvent on Event {
    hosts: participants(
      connection: $hostsConnection
      orders: [{ by: CREATED_AT, direction: ASCENDING }]
      role: HOST_OR_COHOST
    ) {
      nodes {
        ...EventDetailsHostUser
        id
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
  ${EventDetailsHostUserFragmentDoc}
`
export const EventDetailsFeedSinglePostEventPostFragmentDoc = gql`
  fragment EventDetailsFeedSinglePostEventPost on EventPost {
    comments: posts(
      connection: $commentsConnection
      orders: [{ by: CREATED_AT, direction: DESCENDING }]
    ) {
      nodes {
        ...EventDetailsFeedEventPost
        id
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
  ${EventDetailsFeedEventPostFragmentDoc}
`
export const InboxCurrentUserDataFragmentDoc = gql`
  fragment InboxCurrentUserData on User {
    auth0Id
    id
    isBlocked
    profilePictureUrl
    shortName
    features {
      inboxSearch
    }
  }
`
export const InboxAttendeeFragmentDoc = gql`
  fragment InboxAttendee on User {
    auth0Id
    fullName
    id
    isBlocked
  }
`
export const InboxTicketFragmentDoc = gql`
  fragment InboxTicket on Ticket {
    id
    user {
      auth0Id
      fullName
      id
    }
  }
`
export const InboxParticipantFragmentDoc = gql`
  fragment InboxParticipant on User {
    auth0Id
    id
    isBlocked
    profilePictureUrl
    shortName
  }
`
export const InboxThreadMessageFragmentDoc = gql`
  fragment InboxThreadMessage on ThreadMessage {
    createdAt
    createdBy {
      id
      ...InboxParticipant
    }
    databaseId
    id
    textBody
  }
  ${InboxParticipantFragmentDoc}
`
export const InboxParticipantConnectionFragmentDoc = gql`
  fragment InboxParticipantConnection on UserConnection {
    nodes {
      ...InboxParticipant
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
  ${InboxParticipantFragmentDoc}
`
export const InboxThreadFragmentDoc = gql`
  fragment InboxThread on Thread {
    databaseId
    id
    lastActivityAt
    messages(connection: { last: $threadsMessagesLast }) {
      nodes {
        id
        ...InboxThreadMessage
      }
    }
    participants(connection: { last: $threadsParticipantsLast }) {
      ...InboxParticipantConnection
    }
    subject
    unreadMessages: messages(read: false) {
      totalCount
    }
  }
  ${InboxThreadMessageFragmentDoc}
  ${InboxParticipantConnectionFragmentDoc}
`
export const InboxDataFragmentDoc = gql`
  fragment InboxData on Inbox {
    id
    thread(id: $threadId) @include(if: $shouldFetchThread) {
      databaseId
      id
      lastActivityAt
      messages(connection: { last: 25, before: $messageBeforeCursor }) {
        nodes {
          id
          ...InboxThreadMessage
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      participants(
        connection: {
          last: $threadParticipantsLast
          before: $threadParticipantsBeforeCursor
        }
      ) {
        ...InboxParticipantConnection
      }
      subject
    }
    threads(
      connection: { last: 10, before: $threadBeforeCursor }
      read: $read
      search: $threadSearch
    ) @include(if: $shouldFetchThreads) {
      nodes {
        ...InboxThread
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    unreadMessages: messages(read: false) {
      totalCount
    }
  }
  ${InboxThreadMessageFragmentDoc}
  ${InboxParticipantConnectionFragmentDoc}
  ${InboxThreadFragmentDoc}
`
export const VersionCheckVersionStatusFragmentDoc = gql`
  fragment VersionCheckVersionStatus on VersionCheckResponse {
    app
    bundle
    message
  }
`
export const ModeratorsFragmentDoc = gql`
  fragment Moderators on GroupParticipantConnection {
    nodes {
      createdAt
      id
      user {
        fullName
        id
        profilePictureUrl
        shortName
        slug
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
`
export const MembersFragmentDoc = gql`
  fragment Members on GroupParticipantConnection {
    nodes {
      createdAt
      id
      user {
        id
        profilePictureUrl
        shortName
        slug
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
`
export const GroupDiscussionAnchoredPostPostFragmentDoc = gql`
  fragment GroupDiscussionAnchoredPostPost on GroupPost {
    ...DiscussionFeedPostFragment
    id
    sequenceId
  }
  ${DiscussionFeedPostFragmentFragmentDoc}
`
export const GroupDiscussionAnchoredPostCommentFragmentDoc = gql`
  fragment GroupDiscussionAnchoredPostComment on GroupPost {
    ...GroupPostCommentFragment
    id
    sequenceId
  }
  ${GroupPostCommentFragmentFragmentDoc}
`
export const GroupDiscussionAnchoredPostNestedCommentFragmentDoc = gql`
  fragment GroupDiscussionAnchoredPostNestedComment on GroupPost {
    ...GroupPostCommentFragment
    id
    sequenceId
  }
  ${GroupPostCommentFragmentFragmentDoc}
`
export const MyGroupsProviderGroupFragmentDoc = gql`
  fragment MyGroupsProviderGroup on Group {
    canPost
    id
    name
    slug
  }
`
export const GroupDiscussionAnchoredPostGroupFragmentDoc = gql`
  fragment GroupDiscussionAnchoredPostGroup on Group {
    databaseId
    id
    isMember
    isModerator
    ...MyGroupsProviderGroup
    name
  }
  ${MyGroupsProviderGroupFragmentDoc}
`
export const GroupQuestionsCompleteGroupFragmentDoc = gql`
  fragment GroupQuestionsCompleteGroup on Group {
    about
    id
    isMember
    isModerator
    isPrivate
    name
    questions(connection: { first: 100, after: $questionsAfterCursor }) {
      nodes {
        id
        textBody
      }
    }
  }
`
export const GroupDetailPendingParticipantAnswerDetailFragmentDoc = gql`
  fragment GroupDetailPendingParticipantAnswerDetail on GroupAnswer {
    id
    question {
      id
      textBody
    }
    textBody
  }
`
export const GroupDetailPendingParticipantAnswersFragmentDoc = gql`
  fragment GroupDetailPendingParticipantAnswers on GroupParticipant {
    answers(connection: { first: 100, after: $answersAfterCursor }) {
      nodes {
        ...GroupDetailPendingParticipantAnswerDetail
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    createdAt
    id
    user {
      fullName
      id
      profilePictureUrl
      shortName
      slug
    }
  }
  ${GroupDetailPendingParticipantAnswerDetailFragmentDoc}
`
export const GroupDetailPendingParticipantFragmentDoc = gql`
  fragment GroupDetailPendingParticipant on GroupParticipant {
    createdAt
    id
    user {
      fullName
      id
      profilePictureUrl
      shortName
      slug
    }
  }
`
export const GroupDetailPendingParticipantsFragmentDoc = gql`
  fragment GroupDetailPendingParticipants on Group {
    id
    pendingParticipants: participants(
      connection: { first: 100, after: $pendingParticipantsAfterCursor }
      roles: [PENDING_MEMBER]
    ) {
      nodes {
        ...GroupDetailPendingParticipant
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${GroupDetailPendingParticipantFragmentDoc}
`
export const GroupDetailGroupFragmentDoc = gql`
  fragment GroupDetailGroup on Group {
    canPost
    headline
    id
    isMember
    isModerator
    isPendingMember
    isPrivate
    name
    pictureUrl
    slug
  }
`
export const GroupDetailModeratorsFragmentDoc = gql`
  fragment GroupDetailModerators on GroupParticipantConnection {
    nodes {
      id
      user {
        fullName
        id
        profilePictureUrl
        shortName
        slug
      }
    }
  }
`
export const GroupDetailParticipantFragmentDoc = gql`
  fragment GroupDetailParticipant on GroupParticipant {
    createdAt
    id
    user {
      fullName
      id
      profilePictureUrl
      shortName
      slug
    }
  }
`
export const GroupDetailCompleteGroupFragmentDoc = gql`
  fragment GroupDetailCompleteGroup on Group {
    ...GroupDetailGroup
    moderators: participants(
      connection: { first: 10, after: $moderatorsAfterCursor }
      roles: [MODERATOR]
    ) {
      ...GroupDetailModerators
    }
    participants(
      connection: { first: 10, after: $participantsAfterCursor }
      roles: [MEMBER]
    ) {
      nodes {
        ...GroupDetailParticipant
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    pendingParticipants: participants(
      connection: { first: 100, after: $pendingParticipantsAfterCursor }
      roles: [PENDING_MEMBER]
    ) {
      totalCount
    }
  }
  ${GroupDetailGroupFragmentDoc}
  ${GroupDetailModeratorsFragmentDoc}
  ${GroupDetailParticipantFragmentDoc}
`
export const GroupFragmentFragmentDoc = gql`
  fragment GroupFragment on Group {
    headline
    id
    isMember
    name
    slug
  }
`
export const HomeLeftPanelEventFragmentDoc = gql`
  fragment HomeLeftPanelEvent on Event {
    databaseId
    endsAt
    hostZoomLink
    id
    isHosting
    location
    locationTypeId
    name
    name
    slug
    startsAt
    zoomLink
  }
`
export const HomeLeftPanelCurrentUserFragmentDoc = gql`
  fragment HomeLeftPanelCurrentUser on User {
    id
    memberEventActions {
      critical(connection: { first: 1 }) {
        nodes {
          ...HomeLeftPanelEvent
        }
      }
      unreviewed(connection: { first: 3 }) {
        nodes {
          ...HomeLeftPanelEvent
        }
      }
    }
  }
  ${HomeLeftPanelEventFragmentDoc}
`
export const PostJoinModalCurrentUserFragmentDoc = gql`
  fragment PostJoinModalCurrentUser on User {
    id
    interests {
      totalCount
    }
    lifeTransitions {
      totalCount
    }
  }
`
export const HomeCurrentUserFragmentDoc = gql`
  fragment HomeCurrentUser on User {
    hasCompletedSignup
    id
    moderationStatus
    postJoinModalSeenAt
  }
`
export const NewHostshipFragmentDoc = gql`
  fragment NewHostship on Hostship {
    hostRole
    id
    user {
      auth0Id
      fullName
      id
      profilePictureUrl
    }
  }
`
export const User__DeleteUserMutationDocument = gql`
  mutation User__DeleteUserMutation($id: ID!, $shouldBlock: Boolean) {
    user {
      requestDeletion(input: { id: $id, shouldBlock: $shouldBlock }) {
        user {
          moderationStatus
        }
      }
    }
  }
`
export type User__DeleteUserMutationMutationFn = Apollo.MutationFunction<
  User__DeleteUserMutationMutation,
  User__DeleteUserMutationMutationVariables
>

/**
 * __useUser__DeleteUserMutationMutation__
 *
 * To run a mutation, you first call `useUser__DeleteUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser__DeleteUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteUserMutationMutation, { data, loading, error }] = useUser__DeleteUserMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shouldBlock: // value for 'shouldBlock'
 *   },
 * });
 */
export function useUser__DeleteUserMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    User__DeleteUserMutationMutation,
    User__DeleteUserMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    User__DeleteUserMutationMutation,
    User__DeleteUserMutationMutationVariables
  >(User__DeleteUserMutationDocument, options)
}
export type User__DeleteUserMutationMutationHookResult = ReturnType<
  typeof useUser__DeleteUserMutationMutation
>
export type User__DeleteUserMutationMutationResult =
  Apollo.MutationResult<User__DeleteUserMutationMutation>
export type User__DeleteUserMutationMutationOptions =
  Apollo.BaseMutationOptions<
    User__DeleteUserMutationMutation,
    User__DeleteUserMutationMutationVariables
  >
export const CreateEventCheckoutSessionDocument = gql`
  mutation CreateEventCheckoutSession(
    $eventIds: [ID!]!
    $paymentId: String
    $paymentMethod: PaymentMethod
    $redirectUrl: String!
  ) {
    payment {
      createEventCheckoutSession(
        input: {
          eventIds: $eventIds
          redirectUrl: $redirectUrl
          paymentMethod: $paymentMethod
          paymentId: $paymentId
        }
      ) {
        url
      }
    }
  }
`
export type CreateEventCheckoutSessionMutationFn = Apollo.MutationFunction<
  CreateEventCheckoutSessionMutation,
  CreateEventCheckoutSessionMutationVariables
>

/**
 * __useCreateEventCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateEventCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventCheckoutSessionMutation, { data, loading, error }] = useCreateEventCheckoutSessionMutation({
 *   variables: {
 *      eventIds: // value for 'eventIds'
 *      paymentId: // value for 'paymentId'
 *      paymentMethod: // value for 'paymentMethod'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateEventCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventCheckoutSessionMutation,
    CreateEventCheckoutSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateEventCheckoutSessionMutation,
    CreateEventCheckoutSessionMutationVariables
  >(CreateEventCheckoutSessionDocument, options)
}
export type CreateEventCheckoutSessionMutationHookResult = ReturnType<
  typeof useCreateEventCheckoutSessionMutation
>
export type CreateEventCheckoutSessionMutationResult =
  Apollo.MutationResult<CreateEventCheckoutSessionMutation>
export type CreateEventCheckoutSessionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEventCheckoutSessionMutation,
    CreateEventCheckoutSessionMutationVariables
  >
export const RequiresSubscriptionOnRsvpDocument = gql`
  query RequiresSubscriptionOnRSVP($eventId: ID!) {
    requiresSubscriptionOnRSVP(id: $eventId) {
      numOtherTickets
      requiresSubscription
    }
  }
`

/**
 * __useRequiresSubscriptionOnRsvpQuery__
 *
 * To run a query within a React component, call `useRequiresSubscriptionOnRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiresSubscriptionOnRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiresSubscriptionOnRsvpQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRequiresSubscriptionOnRsvpQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequiresSubscriptionOnRsvpQuery,
    RequiresSubscriptionOnRsvpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RequiresSubscriptionOnRsvpQuery,
    RequiresSubscriptionOnRsvpQueryVariables
  >(RequiresSubscriptionOnRsvpDocument, options)
}
export function useRequiresSubscriptionOnRsvpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequiresSubscriptionOnRsvpQuery,
    RequiresSubscriptionOnRsvpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RequiresSubscriptionOnRsvpQuery,
    RequiresSubscriptionOnRsvpQueryVariables
  >(RequiresSubscriptionOnRsvpDocument, options)
}
export type RequiresSubscriptionOnRsvpQueryHookResult = ReturnType<
  typeof useRequiresSubscriptionOnRsvpQuery
>
export type RequiresSubscriptionOnRsvpLazyQueryHookResult = ReturnType<
  typeof useRequiresSubscriptionOnRsvpLazyQuery
>
export type RequiresSubscriptionOnRsvpQueryResult = Apollo.QueryResult<
  RequiresSubscriptionOnRsvpQuery,
  RequiresSubscriptionOnRsvpQueryVariables
>
export const EventLookupDocument = gql`
  query EventLookup($search: String!) {
    events(connection: { first: 10 }, search: $search, states: [LIVE]) {
      nodes {
        id
        name
        startsAt
      }
    }
  }
`

/**
 * __useEventLookupQuery__
 *
 * To run a query within a React component, call `useEventLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useEventLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventLookupQuery,
    EventLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventLookupQuery, EventLookupQueryVariables>(
    EventLookupDocument,
    options,
  )
}
export function useEventLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventLookupQuery,
    EventLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventLookupQuery, EventLookupQueryVariables>(
    EventLookupDocument,
    options,
  )
}
export type EventLookupQueryHookResult = ReturnType<typeof useEventLookupQuery>
export type EventLookupLazyQueryHookResult = ReturnType<
  typeof useEventLookupLazyQuery
>
export type EventLookupQueryResult = Apollo.QueryResult<
  EventLookupQuery,
  EventLookupQueryVariables
>
export const EventsDocument = gql`
  query Events($isFree: Boolean) {
    recommendedEvents(isFree: $isFree) {
      hostships {
        nodes {
          user {
            fullName
          }
        }
      }
      image
      name
      slug
      startsAt
    }
  }
`

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      isFree: // value for 'isFree'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  )
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  )
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>
export const AddToAddressBookButtonDocument = gql`
  query AddToAddressBookButton($auth0id: String) {
    user(auth0id: $auth0id) {
      id
      isInAddressBook
      shortName
    }
  }
`

/**
 * __useAddToAddressBookButtonQuery__
 *
 * To run a query within a React component, call `useAddToAddressBookButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddToAddressBookButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddToAddressBookButtonQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useAddToAddressBookButtonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddToAddressBookButtonQuery,
    AddToAddressBookButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AddToAddressBookButtonQuery,
    AddToAddressBookButtonQueryVariables
  >(AddToAddressBookButtonDocument, options)
}
export function useAddToAddressBookButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddToAddressBookButtonQuery,
    AddToAddressBookButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AddToAddressBookButtonQuery,
    AddToAddressBookButtonQueryVariables
  >(AddToAddressBookButtonDocument, options)
}
export type AddToAddressBookButtonQueryHookResult = ReturnType<
  typeof useAddToAddressBookButtonQuery
>
export type AddToAddressBookButtonLazyQueryHookResult = ReturnType<
  typeof useAddToAddressBookButtonLazyQuery
>
export type AddToAddressBookButtonQueryResult = Apollo.QueryResult<
  AddToAddressBookButtonQuery,
  AddToAddressBookButtonQueryVariables
>
export const ToggleAddressBookDocument = gql`
  mutation ToggleAddressBook($userDatabaseId: ID!) {
    user {
      toggleUserFavorite(input: { userDatabaseId: $userDatabaseId }) {
        user {
          id
          isInAddressBook
        }
      }
    }
  }
`
export type ToggleAddressBookMutationFn = Apollo.MutationFunction<
  ToggleAddressBookMutation,
  ToggleAddressBookMutationVariables
>

/**
 * __useToggleAddressBookMutation__
 *
 * To run a mutation, you first call `useToggleAddressBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAddressBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAddressBookMutation, { data, loading, error }] = useToggleAddressBookMutation({
 *   variables: {
 *      userDatabaseId: // value for 'userDatabaseId'
 *   },
 * });
 */
export function useToggleAddressBookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleAddressBookMutation,
    ToggleAddressBookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleAddressBookMutation,
    ToggleAddressBookMutationVariables
  >(ToggleAddressBookDocument, options)
}
export type ToggleAddressBookMutationHookResult = ReturnType<
  typeof useToggleAddressBookMutation
>
export type ToggleAddressBookMutationResult =
  Apollo.MutationResult<ToggleAddressBookMutation>
export type ToggleAddressBookMutationOptions = Apollo.BaseMutationOptions<
  ToggleAddressBookMutation,
  ToggleAddressBookMutationVariables
>
export const BlockUser__RestrictUserDocument = gql`
  mutation BlockUser__RestrictUser(
    $addRestriction: Boolean!
    $restrictionType: RestrictionType!
    $userId: String!
  ) {
    restrictUser {
      restrictUser(
        input: {
          userId: $userId
          restrictionType: $restrictionType
          addRestriction: $addRestriction
        }
      ) {
        message
        restrictedUser {
          id
          isBlocked
        }
      }
    }
  }
`
export type BlockUser__RestrictUserMutationFn = Apollo.MutationFunction<
  BlockUser__RestrictUserMutation,
  BlockUser__RestrictUserMutationVariables
>

/**
 * __useBlockUser__RestrictUserMutation__
 *
 * To run a mutation, you first call `useBlockUser__RestrictUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUser__RestrictUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserRestrictUserMutation, { data, loading, error }] = useBlockUser__RestrictUserMutation({
 *   variables: {
 *      addRestriction: // value for 'addRestriction'
 *      restrictionType: // value for 'restrictionType'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBlockUser__RestrictUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUser__RestrictUserMutation,
    BlockUser__RestrictUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BlockUser__RestrictUserMutation,
    BlockUser__RestrictUserMutationVariables
  >(BlockUser__RestrictUserDocument, options)
}
export type BlockUser__RestrictUserMutationHookResult = ReturnType<
  typeof useBlockUser__RestrictUserMutation
>
export type BlockUser__RestrictUserMutationResult =
  Apollo.MutationResult<BlockUser__RestrictUserMutation>
export type BlockUser__RestrictUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUser__RestrictUserMutation,
  BlockUser__RestrictUserMutationVariables
>
export const ContentReport__CreateContentReportDocument = gql`
  mutation ContentReport__CreateContentReport(
    $contentId: ID!
    $reason: String!
  ) {
    contentReport {
      createContentReport(input: { contentId: $contentId, reason: $reason }) {
        contentReport {
          id
        }
      }
    }
  }
`
export type ContentReport__CreateContentReportMutationFn =
  Apollo.MutationFunction<
    ContentReport__CreateContentReportMutation,
    ContentReport__CreateContentReportMutationVariables
  >

/**
 * __useContentReport__CreateContentReportMutation__
 *
 * To run a mutation, you first call `useContentReport__CreateContentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentReport__CreateContentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentReportCreateContentReportMutation, { data, loading, error }] = useContentReport__CreateContentReportMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useContentReport__CreateContentReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentReport__CreateContentReportMutation,
    ContentReport__CreateContentReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ContentReport__CreateContentReportMutation,
    ContentReport__CreateContentReportMutationVariables
  >(ContentReport__CreateContentReportDocument, options)
}
export type ContentReport__CreateContentReportMutationHookResult = ReturnType<
  typeof useContentReport__CreateContentReportMutation
>
export type ContentReport__CreateContentReportMutationResult =
  Apollo.MutationResult<ContentReport__CreateContentReportMutation>
export type ContentReport__CreateContentReportMutationOptions =
  Apollo.BaseMutationOptions<
    ContentReport__CreateContentReportMutation,
    ContentReport__CreateContentReportMutationVariables
  >
export const FollowHostEventsButtonDocument = gql`
  query FollowHostEventsButton($auth0id: String) {
    user(auth0id: $auth0id) {
      id
      isFollowingEvents
      shortName
    }
  }
`

/**
 * __useFollowHostEventsButtonQuery__
 *
 * To run a query within a React component, call `useFollowHostEventsButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowHostEventsButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowHostEventsButtonQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useFollowHostEventsButtonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FollowHostEventsButtonQuery,
    FollowHostEventsButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FollowHostEventsButtonQuery,
    FollowHostEventsButtonQueryVariables
  >(FollowHostEventsButtonDocument, options)
}
export function useFollowHostEventsButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FollowHostEventsButtonQuery,
    FollowHostEventsButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FollowHostEventsButtonQuery,
    FollowHostEventsButtonQueryVariables
  >(FollowHostEventsButtonDocument, options)
}
export type FollowHostEventsButtonQueryHookResult = ReturnType<
  typeof useFollowHostEventsButtonQuery
>
export type FollowHostEventsButtonLazyQueryHookResult = ReturnType<
  typeof useFollowHostEventsButtonLazyQuery
>
export type FollowHostEventsButtonQueryResult = Apollo.QueryResult<
  FollowHostEventsButtonQuery,
  FollowHostEventsButtonQueryVariables
>
export const ToggleFollowHostDocument = gql`
  mutation ToggleFollowHost($userDatabaseId: ID!) {
    user {
      toggleHostFollow(input: { userDatabaseId: $userDatabaseId }) {
        user {
          id
          isFollowingEvents
        }
      }
    }
  }
`
export type ToggleFollowHostMutationFn = Apollo.MutationFunction<
  ToggleFollowHostMutation,
  ToggleFollowHostMutationVariables
>

/**
 * __useToggleFollowHostMutation__
 *
 * To run a mutation, you first call `useToggleFollowHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFollowHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFollowHostMutation, { data, loading, error }] = useToggleFollowHostMutation({
 *   variables: {
 *      userDatabaseId: // value for 'userDatabaseId'
 *   },
 * });
 */
export function useToggleFollowHostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleFollowHostMutation,
    ToggleFollowHostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleFollowHostMutation,
    ToggleFollowHostMutationVariables
  >(ToggleFollowHostDocument, options)
}
export type ToggleFollowHostMutationHookResult = ReturnType<
  typeof useToggleFollowHostMutation
>
export type ToggleFollowHostMutationResult =
  Apollo.MutationResult<ToggleFollowHostMutation>
export type ToggleFollowHostMutationOptions = Apollo.BaseMutationOptions<
  ToggleFollowHostMutation,
  ToggleFollowHostMutationVariables
>
export const FollowMenuDocument = gql`
  query FollowMenu($auth0id: String) {
    user(auth0id: $auth0id) {
      id
      isFollowingEvents
      isInAddressBook
    }
  }
`

/**
 * __useFollowMenuQuery__
 *
 * To run a query within a React component, call `useFollowMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowMenuQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useFollowMenuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FollowMenuQuery,
    FollowMenuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FollowMenuQuery, FollowMenuQueryVariables>(
    FollowMenuDocument,
    options,
  )
}
export function useFollowMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FollowMenuQuery,
    FollowMenuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FollowMenuQuery, FollowMenuQueryVariables>(
    FollowMenuDocument,
    options,
  )
}
export type FollowMenuQueryHookResult = ReturnType<typeof useFollowMenuQuery>
export type FollowMenuLazyQueryHookResult = ReturnType<
  typeof useFollowMenuLazyQuery
>
export type FollowMenuQueryResult = Apollo.QueryResult<
  FollowMenuQuery,
  FollowMenuQueryVariables
>
export const Group__DeletePostMutationDocument = gql`
  mutation Group__DeletePostMutation(
    $postId: ID!
    $reasonCategory: GroupContentModerationReasonCategory
    $reasonContext: String
    $reasonContextForPostAuthor: String
    $topLevelPostSequenceId: Int!
  ) {
    group {
      deletePost(
        input: {
          postId: $postId
          reason: {
            category: $reasonCategory
            context: $reasonContext
            contextForPostAuthor: $reasonContextForPostAuthor
          }
        }
      ) {
        group {
          discussionFeed(connection: { last: 0, before: null }) {
            edge(postSequenceId: $topLevelPostSequenceId) {
              node {
                id
                totalCommentCount
              }
            }
            totalCount
          }
          id
        }
        parentPost {
          id
          posts(connection: { last: 0, before: null }) {
            totalCount
          }
          totalCommentCount
        }
      }
    }
  }
`
export type Group__DeletePostMutationMutationFn = Apollo.MutationFunction<
  Group__DeletePostMutationMutation,
  Group__DeletePostMutationMutationVariables
>

/**
 * __useGroup__DeletePostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__DeletePostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__DeletePostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDeletePostMutationMutation, { data, loading, error }] = useGroup__DeletePostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      reasonCategory: // value for 'reasonCategory'
 *      reasonContext: // value for 'reasonContext'
 *      reasonContextForPostAuthor: // value for 'reasonContextForPostAuthor'
 *      topLevelPostSequenceId: // value for 'topLevelPostSequenceId'
 *   },
 * });
 */
export function useGroup__DeletePostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__DeletePostMutationMutation,
    Group__DeletePostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__DeletePostMutationMutation,
    Group__DeletePostMutationMutationVariables
  >(Group__DeletePostMutationDocument, options)
}
export type Group__DeletePostMutationMutationHookResult = ReturnType<
  typeof useGroup__DeletePostMutationMutation
>
export type Group__DeletePostMutationMutationResult =
  Apollo.MutationResult<Group__DeletePostMutationMutation>
export type Group__DeletePostMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Group__DeletePostMutationMutation,
    Group__DeletePostMutationMutationVariables
  >
export const Group__LockPostMutationDocument = gql`
  mutation Group__LockPostMutation(
    $postId: ID!
    $reasonCategory: GroupContentModerationReasonCategory
    $reasonContext: String
    $reasonContextForPostAuthor: String
  ) {
    group {
      lockPost(
        input: {
          postId: $postId
          reason: {
            category: $reasonCategory
            context: $reasonContext
            contextForPostAuthor: $reasonContextForPostAuthor
          }
        }
      ) {
        groupPost {
          canLock
          canPost
          canUnlock
          id
          isLocked
        }
      }
    }
  }
`
export type Group__LockPostMutationMutationFn = Apollo.MutationFunction<
  Group__LockPostMutationMutation,
  Group__LockPostMutationMutationVariables
>

/**
 * __useGroup__LockPostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__LockPostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__LockPostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupLockPostMutationMutation, { data, loading, error }] = useGroup__LockPostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      reasonCategory: // value for 'reasonCategory'
 *      reasonContext: // value for 'reasonContext'
 *      reasonContextForPostAuthor: // value for 'reasonContextForPostAuthor'
 *   },
 * });
 */
export function useGroup__LockPostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__LockPostMutationMutation,
    Group__LockPostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__LockPostMutationMutation,
    Group__LockPostMutationMutationVariables
  >(Group__LockPostMutationDocument, options)
}
export type Group__LockPostMutationMutationHookResult = ReturnType<
  typeof useGroup__LockPostMutationMutation
>
export type Group__LockPostMutationMutationResult =
  Apollo.MutationResult<Group__LockPostMutationMutation>
export type Group__LockPostMutationMutationOptions = Apollo.BaseMutationOptions<
  Group__LockPostMutationMutation,
  Group__LockPostMutationMutationVariables
>
export const Group__PinPostMutationDocument = gql`
  mutation Group__PinPostMutation($postId: ID!) {
    group {
      pinPost(input: { postId: $postId }) {
        groupPost {
          canPin
          canUnpin
          id
          isPinned
        }
        unpinnedPosts {
          canPin
          canUnpin
          id
          isPinned
        }
      }
    }
  }
`
export type Group__PinPostMutationMutationFn = Apollo.MutationFunction<
  Group__PinPostMutationMutation,
  Group__PinPostMutationMutationVariables
>

/**
 * __useGroup__PinPostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__PinPostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__PinPostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupPinPostMutationMutation, { data, loading, error }] = useGroup__PinPostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGroup__PinPostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__PinPostMutationMutation,
    Group__PinPostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__PinPostMutationMutation,
    Group__PinPostMutationMutationVariables
  >(Group__PinPostMutationDocument, options)
}
export type Group__PinPostMutationMutationHookResult = ReturnType<
  typeof useGroup__PinPostMutationMutation
>
export type Group__PinPostMutationMutationResult =
  Apollo.MutationResult<Group__PinPostMutationMutation>
export type Group__PinPostMutationMutationOptions = Apollo.BaseMutationOptions<
  Group__PinPostMutationMutation,
  Group__PinPostMutationMutationVariables
>
export const Group__UnlockPostMutationDocument = gql`
  mutation Group__UnlockPostMutation($postId: ID!) {
    group {
      unlockPost(input: { postId: $postId }) {
        groupPost {
          canLock
          canPost
          canUnlock
          id
          isLocked
        }
      }
    }
  }
`
export type Group__UnlockPostMutationMutationFn = Apollo.MutationFunction<
  Group__UnlockPostMutationMutation,
  Group__UnlockPostMutationMutationVariables
>

/**
 * __useGroup__UnlockPostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__UnlockPostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__UnlockPostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupUnlockPostMutationMutation, { data, loading, error }] = useGroup__UnlockPostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGroup__UnlockPostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__UnlockPostMutationMutation,
    Group__UnlockPostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__UnlockPostMutationMutation,
    Group__UnlockPostMutationMutationVariables
  >(Group__UnlockPostMutationDocument, options)
}
export type Group__UnlockPostMutationMutationHookResult = ReturnType<
  typeof useGroup__UnlockPostMutationMutation
>
export type Group__UnlockPostMutationMutationResult =
  Apollo.MutationResult<Group__UnlockPostMutationMutation>
export type Group__UnlockPostMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Group__UnlockPostMutationMutation,
    Group__UnlockPostMutationMutationVariables
  >
export const Group__UnpinPostMutationDocument = gql`
  mutation Group__UnpinPostMutation($postId: ID!) {
    group {
      unpinPost(input: { postId: $postId }) {
        groupPost {
          canPin
          canUnpin
          id
          isPinned
        }
      }
    }
  }
`
export type Group__UnpinPostMutationMutationFn = Apollo.MutationFunction<
  Group__UnpinPostMutationMutation,
  Group__UnpinPostMutationMutationVariables
>

/**
 * __useGroup__UnpinPostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__UnpinPostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__UnpinPostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupUnpinPostMutationMutation, { data, loading, error }] = useGroup__UnpinPostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGroup__UnpinPostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__UnpinPostMutationMutation,
    Group__UnpinPostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__UnpinPostMutationMutation,
    Group__UnpinPostMutationMutationVariables
  >(Group__UnpinPostMutationDocument, options)
}
export type Group__UnpinPostMutationMutationHookResult = ReturnType<
  typeof useGroup__UnpinPostMutationMutation
>
export type Group__UnpinPostMutationMutationResult =
  Apollo.MutationResult<Group__UnpinPostMutationMutation>
export type Group__UnpinPostMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Group__UnpinPostMutationMutation,
    Group__UnpinPostMutationMutationVariables
  >
export const User__ModerationStatusMutationDocument = gql`
  mutation User__ModerationStatusMutation(
    $input: UserUpdateModerationStatusInput!
  ) {
    user {
      updateModerationStatus(input: $input) {
        updatedStatus
      }
    }
  }
`
export type User__ModerationStatusMutationMutationFn = Apollo.MutationFunction<
  User__ModerationStatusMutationMutation,
  User__ModerationStatusMutationMutationVariables
>

/**
 * __useUser__ModerationStatusMutationMutation__
 *
 * To run a mutation, you first call `useUser__ModerationStatusMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser__ModerationStatusMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModerationStatusMutationMutation, { data, loading, error }] = useUser__ModerationStatusMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUser__ModerationStatusMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    User__ModerationStatusMutationMutation,
    User__ModerationStatusMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    User__ModerationStatusMutationMutation,
    User__ModerationStatusMutationMutationVariables
  >(User__ModerationStatusMutationDocument, options)
}
export type User__ModerationStatusMutationMutationHookResult = ReturnType<
  typeof useUser__ModerationStatusMutationMutation
>
export type User__ModerationStatusMutationMutationResult =
  Apollo.MutationResult<User__ModerationStatusMutationMutation>
export type User__ModerationStatusMutationMutationOptions =
  Apollo.BaseMutationOptions<
    User__ModerationStatusMutationMutation,
    User__ModerationStatusMutationMutationVariables
  >
export const GroupCardJoinGroupDocument = gql`
  mutation GroupCardJoinGroup($groupId: ID!) {
    group {
      join(input: { groupId: $groupId }) {
        group {
          id
          ...MyGroupsProviderGroup
        }
      }
    }
  }
  ${MyGroupsProviderGroupFragmentDoc}
`
export type GroupCardJoinGroupMutationFn = Apollo.MutationFunction<
  GroupCardJoinGroupMutation,
  GroupCardJoinGroupMutationVariables
>

/**
 * __useGroupCardJoinGroupMutation__
 *
 * To run a mutation, you first call `useGroupCardJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCardJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCardJoinGroupMutation, { data, loading, error }] = useGroupCardJoinGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupCardJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupCardJoinGroupMutation,
    GroupCardJoinGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupCardJoinGroupMutation,
    GroupCardJoinGroupMutationVariables
  >(GroupCardJoinGroupDocument, options)
}
export type GroupCardJoinGroupMutationHookResult = ReturnType<
  typeof useGroupCardJoinGroupMutation
>
export type GroupCardJoinGroupMutationResult =
  Apollo.MutationResult<GroupCardJoinGroupMutation>
export type GroupCardJoinGroupMutationOptions = Apollo.BaseMutationOptions<
  GroupCardJoinGroupMutation,
  GroupCardJoinGroupMutationVariables
>
export const Group__CreatePostMutationDocument = gql`
  mutation Group__CreatePostMutation(
    $attachedDatabaseEventIds: [String!]
    $attachedEventIds: [ID!]
    $attachedFiles: [RevelFileUpload!]
    $groupId: ID!
    $mentionedUserIds: [ID!]
    $textBody: String!
  ) {
    group {
      createPost(
        input: {
          groupId: $groupId
          textBody: $textBody
          attachedFiles: $attachedFiles
          attachedEventIds: $attachedEventIds
          attachedDatabaseEventIds: $attachedDatabaseEventIds
          mentionedUserIds: $mentionedUserIds
        }
      ) {
        createdGroupPost {
          ...Group__CreatePostMutation__GroupPostFragment
          id
          parentPost {
            comments: posts(connection: { last: 1, before: null }) {
              totalCount
            }
            id
          }
        }
        group {
          discussionFeed(connection: { last: 1, before: null }) {
            totalCount
          }
          id
        }
      }
    }
  }
  ${Group__CreatePostMutation__GroupPostFragmentFragmentDoc}
`
export type Group__CreatePostMutationMutationFn = Apollo.MutationFunction<
  Group__CreatePostMutationMutation,
  Group__CreatePostMutationMutationVariables
>

/**
 * __useGroup__CreatePostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__CreatePostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__CreatePostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreatePostMutationMutation, { data, loading, error }] = useGroup__CreatePostMutationMutation({
 *   variables: {
 *      attachedDatabaseEventIds: // value for 'attachedDatabaseEventIds'
 *      attachedEventIds: // value for 'attachedEventIds'
 *      attachedFiles: // value for 'attachedFiles'
 *      groupId: // value for 'groupId'
 *      mentionedUserIds: // value for 'mentionedUserIds'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useGroup__CreatePostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__CreatePostMutationMutation,
    Group__CreatePostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__CreatePostMutationMutation,
    Group__CreatePostMutationMutationVariables
  >(Group__CreatePostMutationDocument, options)
}
export type Group__CreatePostMutationMutationHookResult = ReturnType<
  typeof useGroup__CreatePostMutationMutation
>
export type Group__CreatePostMutationMutationResult =
  Apollo.MutationResult<Group__CreatePostMutationMutation>
export type Group__CreatePostMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Group__CreatePostMutationMutation,
    Group__CreatePostMutationMutationVariables
  >
export const GroupPostDocument = gql`
  query GroupPost($commentsConnection: ConnectionInput!, $id: ID!) {
    groupPost(input: { id: $id }) {
      comments: posts(
        connection: $commentsConnection
        orderByColumn: "createdAt"
      ) {
        edges {
          cursor
          node {
            comments: posts(connection: { first: 0 }) {
              totalCount
            }
            ...GroupPostCommentFragment
            id
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
      createdBy {
        auth0Id
        id
      }
      databaseId
      id
    }
  }
  ${GroupPostCommentFragmentFragmentDoc}
`

/**
 * __useGroupPostQuery__
 *
 * To run a query within a React component, call `useGroupPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPostQuery({
 *   variables: {
 *      commentsConnection: // value for 'commentsConnection'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupPostQuery(
  baseOptions: Apollo.QueryHookOptions<GroupPostQuery, GroupPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupPostQuery, GroupPostQueryVariables>(
    GroupPostDocument,
    options,
  )
}
export function useGroupPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupPostQuery,
    GroupPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupPostQuery, GroupPostQueryVariables>(
    GroupPostDocument,
    options,
  )
}
export type GroupPostQueryHookResult = ReturnType<typeof useGroupPostQuery>
export type GroupPostLazyQueryHookResult = ReturnType<
  typeof useGroupPostLazyQuery
>
export type GroupPostQueryResult = Apollo.QueryResult<
  GroupPostQuery,
  GroupPostQueryVariables
>
export const Group__FeaturePostMutationDocument = gql`
  mutation Group__FeaturePostMutation($postId: ID!) {
    group {
      featurePost(input: { postId: $postId }) {
        groupPost {
          id
        }
      }
    }
  }
`
export type Group__FeaturePostMutationMutationFn = Apollo.MutationFunction<
  Group__FeaturePostMutationMutation,
  Group__FeaturePostMutationMutationVariables
>

/**
 * __useGroup__FeaturePostMutationMutation__
 *
 * To run a mutation, you first call `useGroup__FeaturePostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroup__FeaturePostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupFeaturePostMutationMutation, { data, loading, error }] = useGroup__FeaturePostMutationMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGroup__FeaturePostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Group__FeaturePostMutationMutation,
    Group__FeaturePostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Group__FeaturePostMutationMutation,
    Group__FeaturePostMutationMutationVariables
  >(Group__FeaturePostMutationDocument, options)
}
export type Group__FeaturePostMutationMutationHookResult = ReturnType<
  typeof useGroup__FeaturePostMutationMutation
>
export type Group__FeaturePostMutationMutationResult =
  Apollo.MutationResult<Group__FeaturePostMutationMutation>
export type Group__FeaturePostMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Group__FeaturePostMutationMutation,
    Group__FeaturePostMutationMutationVariables
  >
export const CreatePostCommentDocument = gql`
  mutation CreatePostComment(
    $attachedFiles: [RevelFileUpload!]
    $groupId: ID!
    $mentionedUserIds: [ID!]
    $parentPostId: ID!
    $textBody: String!
  ) {
    group {
      createPost(
        input: {
          groupId: $groupId
          textBody: $textBody
          attachedFiles: $attachedFiles
          parentPostId: $parentPostId
          mentionedUserIds: $mentionedUserIds
        }
      ) {
        createdGroupPost {
          ...CreatePostCommentMutationGroupPost
          id
        }
      }
    }
  }
  ${CreatePostCommentMutationGroupPostFragmentDoc}
`
export type CreatePostCommentMutationFn = Apollo.MutationFunction<
  CreatePostCommentMutation,
  CreatePostCommentMutationVariables
>

/**
 * __useCreatePostCommentMutation__
 *
 * To run a mutation, you first call `useCreatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCommentMutation, { data, loading, error }] = useCreatePostCommentMutation({
 *   variables: {
 *      attachedFiles: // value for 'attachedFiles'
 *      groupId: // value for 'groupId'
 *      mentionedUserIds: // value for 'mentionedUserIds'
 *      parentPostId: // value for 'parentPostId'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useCreatePostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
  >(CreatePostCommentDocument, options)
}
export type CreatePostCommentMutationHookResult = ReturnType<
  typeof useCreatePostCommentMutation
>
export type CreatePostCommentMutationResult =
  Apollo.MutationResult<CreatePostCommentMutation>
export type CreatePostCommentMutationOptions = Apollo.BaseMutationOptions<
  CreatePostCommentMutation,
  CreatePostCommentMutationVariables
>
export const CreatePostReactionDocument = gql`
  mutation CreatePostReaction($content: ReactionContent!, $postId: ID!) {
    group {
      createPostReaction(input: { postId: $postId, content: $content }) {
        groupPost {
          angryReaction: reactions(content: ANGRY) {
            totalCount
          }
          clapReaction: reactions(content: CLAP) {
            totalCount
          }
          createdBy {
            auth0Id
            id
          }
          databaseId
          group {
            databaseId
            id
            name
          }
          heartReaction: reactions(content: HEART) {
            totalCount
          }
          id
          inspiredReaction: reactions(content: INSPIRED) {
            totalCount
          }
          laughingReaction: reactions(content: LAUGHING) {
            totalCount
          }
          likeReaction: reactions(content: LIKE) {
            totalCount
          }
          myReaction {
            content
            id
            reactedBy {
              fullName
              id
            }
          }
          reactions(connection: { last: 10 }) {
            totalCount
          }
          sadReaction: reactions(content: SAD) {
            totalCount
          }
          sparkleReaction: reactions(content: SPARKLE) {
            totalCount
          }
          supportReaction: reactions(content: SUPPORT) {
            totalCount
          }
        }
      }
    }
  }
`
export type CreatePostReactionMutationFn = Apollo.MutationFunction<
  CreatePostReactionMutation,
  CreatePostReactionMutationVariables
>

/**
 * __useCreatePostReactionMutation__
 *
 * To run a mutation, you first call `useCreatePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostReactionMutation, { data, loading, error }] = useCreatePostReactionMutation({
 *   variables: {
 *      content: // value for 'content'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreatePostReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostReactionMutation,
    CreatePostReactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePostReactionMutation,
    CreatePostReactionMutationVariables
  >(CreatePostReactionDocument, options)
}
export type CreatePostReactionMutationHookResult = ReturnType<
  typeof useCreatePostReactionMutation
>
export type CreatePostReactionMutationResult =
  Apollo.MutationResult<CreatePostReactionMutation>
export type CreatePostReactionMutationOptions = Apollo.BaseMutationOptions<
  CreatePostReactionMutation,
  CreatePostReactionMutationVariables
>
export const DeletePostReactionDocument = gql`
  mutation DeletePostReaction($postId: ID!) {
    group {
      deletePostReaction(input: { postId: $postId }) {
        groupPost {
          id
          myReaction {
            id
            reactedBy {
              fullName
              id
            }
          }
        }
      }
    }
  }
`
export type DeletePostReactionMutationFn = Apollo.MutationFunction<
  DeletePostReactionMutation,
  DeletePostReactionMutationVariables
>

/**
 * __useDeletePostReactionMutation__
 *
 * To run a mutation, you first call `useDeletePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostReactionMutation, { data, loading, error }] = useDeletePostReactionMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDeletePostReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostReactionMutation,
    DeletePostReactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePostReactionMutation,
    DeletePostReactionMutationVariables
  >(DeletePostReactionDocument, options)
}
export type DeletePostReactionMutationHookResult = ReturnType<
  typeof useDeletePostReactionMutation
>
export type DeletePostReactionMutationResult =
  Apollo.MutationResult<DeletePostReactionMutation>
export type DeletePostReactionMutationOptions = Apollo.BaseMutationOptions<
  DeletePostReactionMutation,
  DeletePostReactionMutationVariables
>
export const GroupPostReactionDocument = gql`
  query GroupPostReaction($postId: ID!) {
    groupPost(input: { id: $postId }) {
      angryReaction: reactions(content: ANGRY) {
        totalCount
      }
      clapReaction: reactions(content: CLAP) {
        totalCount
      }
      heartReaction: reactions(content: HEART) {
        totalCount
      }
      id
      inspiredReaction: reactions(content: INSPIRED) {
        totalCount
      }
      laughingReaction: reactions(content: LAUGHING) {
        totalCount
      }
      likeReaction: reactions(content: LIKE) {
        totalCount
      }
      myReaction {
        content
        id
        reactedBy {
          fullName
          id
        }
      }
      reactions(connection: { last: 10 }) {
        nodes {
          id
          reactedBy {
            fullName
            id
          }
        }
        totalCount
      }
      sadReaction: reactions(content: SAD) {
        totalCount
      }
      sparkleReaction: reactions(content: SPARKLE) {
        totalCount
      }
      supportReaction: reactions(content: SUPPORT) {
        totalCount
      }
    }
  }
`

/**
 * __useGroupPostReactionQuery__
 *
 * To run a query within a React component, call `useGroupPostReactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPostReactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPostReactionQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGroupPostReactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupPostReactionQuery,
    GroupPostReactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GroupPostReactionQuery,
    GroupPostReactionQueryVariables
  >(GroupPostReactionDocument, options)
}
export function useGroupPostReactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupPostReactionQuery,
    GroupPostReactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GroupPostReactionQuery,
    GroupPostReactionQueryVariables
  >(GroupPostReactionDocument, options)
}
export type GroupPostReactionQueryHookResult = ReturnType<
  typeof useGroupPostReactionQuery
>
export type GroupPostReactionLazyQueryHookResult = ReturnType<
  typeof useGroupPostReactionLazyQuery
>
export type GroupPostReactionQueryResult = Apollo.QueryResult<
  GroupPostReactionQuery,
  GroupPostReactionQueryVariables
>
export const GroupUpdatePostCommentDocument = gql`
  mutation GroupUpdatePostComment(
    $mentionedUserIds: [ID!]
    $postId: ID!
    $textBody: String
  ) {
    group {
      updatePost(
        input: {
          postId: $postId
          textBody: $textBody
          mentionedUserIds: $mentionedUserIds
        }
      ) {
        updatedGroupPost {
          id
          isUpdated
          textBody
        }
      }
    }
  }
`
export type GroupUpdatePostCommentMutationFn = Apollo.MutationFunction<
  GroupUpdatePostCommentMutation,
  GroupUpdatePostCommentMutationVariables
>

/**
 * __useGroupUpdatePostCommentMutation__
 *
 * To run a mutation, you first call `useGroupUpdatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupUpdatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupUpdatePostCommentMutation, { data, loading, error }] = useGroupUpdatePostCommentMutation({
 *   variables: {
 *      mentionedUserIds: // value for 'mentionedUserIds'
 *      postId: // value for 'postId'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useGroupUpdatePostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupUpdatePostCommentMutation,
    GroupUpdatePostCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupUpdatePostCommentMutation,
    GroupUpdatePostCommentMutationVariables
  >(GroupUpdatePostCommentDocument, options)
}
export type GroupUpdatePostCommentMutationHookResult = ReturnType<
  typeof useGroupUpdatePostCommentMutation
>
export type GroupUpdatePostCommentMutationResult =
  Apollo.MutationResult<GroupUpdatePostCommentMutation>
export type GroupUpdatePostCommentMutationOptions = Apollo.BaseMutationOptions<
  GroupUpdatePostCommentMutation,
  GroupUpdatePostCommentMutationVariables
>
export const EventBySlugOrIdDocument = gql`
  query EventBySlugOrId($databaseId: String, $slug: String) {
    event(databaseId: $databaseId, slug: $slug) {
      endsAt
      hostships {
        nodes {
          user {
            fullName
          }
        }
      }
      id
      image
      name
      slug
      startsAt
    }
  }
`

/**
 * __useEventBySlugOrIdQuery__
 *
 * To run a query within a React component, call `useEventBySlugOrIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventBySlugOrIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventBySlugOrIdQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventBySlugOrIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventBySlugOrIdQuery,
    EventBySlugOrIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventBySlugOrIdQuery, EventBySlugOrIdQueryVariables>(
    EventBySlugOrIdDocument,
    options,
  )
}
export function useEventBySlugOrIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventBySlugOrIdQuery,
    EventBySlugOrIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventBySlugOrIdQuery,
    EventBySlugOrIdQueryVariables
  >(EventBySlugOrIdDocument, options)
}
export type EventBySlugOrIdQueryHookResult = ReturnType<
  typeof useEventBySlugOrIdQuery
>
export type EventBySlugOrIdLazyQueryHookResult = ReturnType<
  typeof useEventBySlugOrIdLazyQuery
>
export type EventBySlugOrIdQueryResult = Apollo.QueryResult<
  EventBySlugOrIdQuery,
  EventBySlugOrIdQueryVariables
>
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...DiscussionFeed__UserFragment
    }
  }
  ${DiscussionFeed__UserFragmentFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>
export const DesktopHeaderDocument = gql`
  query DesktopHeader {
    currentUser {
      ...DesktopHeaderCurrentUser
      ...ProfileLinkCurrentUser
    }
  }
  ${DesktopHeaderCurrentUserFragmentDoc}
  ${ProfileLinkCurrentUserFragmentDoc}
`

/**
 * __useDesktopHeaderQuery__
 *
 * To run a query within a React component, call `useDesktopHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesktopHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesktopHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useDesktopHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DesktopHeaderQuery,
    DesktopHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DesktopHeaderQuery, DesktopHeaderQueryVariables>(
    DesktopHeaderDocument,
    options,
  )
}
export function useDesktopHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DesktopHeaderQuery,
    DesktopHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DesktopHeaderQuery, DesktopHeaderQueryVariables>(
    DesktopHeaderDocument,
    options,
  )
}
export type DesktopHeaderQueryHookResult = ReturnType<
  typeof useDesktopHeaderQuery
>
export type DesktopHeaderLazyQueryHookResult = ReturnType<
  typeof useDesktopHeaderLazyQuery
>
export type DesktopHeaderQueryResult = Apollo.QueryResult<
  DesktopHeaderQuery,
  DesktopHeaderQueryVariables
>
export const InboxButtonDocument = gql`
  query InboxButton {
    inbox {
      ...InboxButtonInbox
    }
  }
  ${InboxButtonInboxFragmentDoc}
`

/**
 * __useInboxButtonQuery__
 *
 * To run a query within a React component, call `useInboxButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useInboxButtonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InboxButtonQuery,
    InboxButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InboxButtonQuery, InboxButtonQueryVariables>(
    InboxButtonDocument,
    options,
  )
}
export function useInboxButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxButtonQuery,
    InboxButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InboxButtonQuery, InboxButtonQueryVariables>(
    InboxButtonDocument,
    options,
  )
}
export type InboxButtonQueryHookResult = ReturnType<typeof useInboxButtonQuery>
export type InboxButtonLazyQueryHookResult = ReturnType<
  typeof useInboxButtonLazyQuery
>
export type InboxButtonQueryResult = Apollo.QueryResult<
  InboxButtonQuery,
  InboxButtonQueryVariables
>
export const NotificationsMarkAllAsReadDocument = gql`
  mutation NotificationsMarkAllAsRead {
    notification {
      markAllAsRead {
        notifications {
          id
          unreadCount
        }
      }
    }
  }
`
export type NotificationsMarkAllAsReadMutationFn = Apollo.MutationFunction<
  NotificationsMarkAllAsReadMutation,
  NotificationsMarkAllAsReadMutationVariables
>

/**
 * __useNotificationsMarkAllAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationsMarkAllAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsMarkAllAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsMarkAllAsReadMutation, { data, loading, error }] = useNotificationsMarkAllAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsMarkAllAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationsMarkAllAsReadMutation,
    NotificationsMarkAllAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationsMarkAllAsReadMutation,
    NotificationsMarkAllAsReadMutationVariables
  >(NotificationsMarkAllAsReadDocument, options)
}
export type NotificationsMarkAllAsReadMutationHookResult = ReturnType<
  typeof useNotificationsMarkAllAsReadMutation
>
export type NotificationsMarkAllAsReadMutationResult =
  Apollo.MutationResult<NotificationsMarkAllAsReadMutation>
export type NotificationsMarkAllAsReadMutationOptions =
  Apollo.BaseMutationOptions<
    NotificationsMarkAllAsReadMutation,
    NotificationsMarkAllAsReadMutationVariables
  >
export const NotificationsMarkAllAsSeenDocument = gql`
  mutation NotificationsMarkAllAsSeen {
    notification {
      markAllAsSeen {
        lastViewedNotificationsAt
        notifications {
          id
          unseenCount
        }
      }
    }
  }
`
export type NotificationsMarkAllAsSeenMutationFn = Apollo.MutationFunction<
  NotificationsMarkAllAsSeenMutation,
  NotificationsMarkAllAsSeenMutationVariables
>

/**
 * __useNotificationsMarkAllAsSeenMutation__
 *
 * To run a mutation, you first call `useNotificationsMarkAllAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsMarkAllAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsMarkAllAsSeenMutation, { data, loading, error }] = useNotificationsMarkAllAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsMarkAllAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationsMarkAllAsSeenMutation,
    NotificationsMarkAllAsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationsMarkAllAsSeenMutation,
    NotificationsMarkAllAsSeenMutationVariables
  >(NotificationsMarkAllAsSeenDocument, options)
}
export type NotificationsMarkAllAsSeenMutationHookResult = ReturnType<
  typeof useNotificationsMarkAllAsSeenMutation
>
export type NotificationsMarkAllAsSeenMutationResult =
  Apollo.MutationResult<NotificationsMarkAllAsSeenMutation>
export type NotificationsMarkAllAsSeenMutationOptions =
  Apollo.BaseMutationOptions<
    NotificationsMarkAllAsSeenMutation,
    NotificationsMarkAllAsSeenMutationVariables
  >
export const NotificationMarkAsReadDocument = gql`
  mutation NotificationMarkAsRead($id: ID!) {
    notification {
      markAsRead(input: { id: $id }) {
        notification {
          id
          read
        }
      }
    }
  }
`
export type NotificationMarkAsReadMutationFn = Apollo.MutationFunction<
  NotificationMarkAsReadMutation,
  NotificationMarkAsReadMutationVariables
>

/**
 * __useNotificationMarkAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAsReadMutation, { data, loading, error }] = useNotificationMarkAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationMarkAsReadMutation,
    NotificationMarkAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationMarkAsReadMutation,
    NotificationMarkAsReadMutationVariables
  >(NotificationMarkAsReadDocument, options)
}
export type NotificationMarkAsReadMutationHookResult = ReturnType<
  typeof useNotificationMarkAsReadMutation
>
export type NotificationMarkAsReadMutationResult =
  Apollo.MutationResult<NotificationMarkAsReadMutation>
export type NotificationMarkAsReadMutationOptions = Apollo.BaseMutationOptions<
  NotificationMarkAsReadMutation,
  NotificationMarkAsReadMutationVariables
>
export const ProfileLinkDocument = gql`
  query ProfileLink {
    currentUser {
      ...ProfileLinkCurrentUser
    }
  }
  ${ProfileLinkCurrentUserFragmentDoc}
`

/**
 * __useProfileLinkQuery__
 *
 * To run a query within a React component, call `useProfileLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileLinkQuery,
    ProfileLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProfileLinkQuery, ProfileLinkQueryVariables>(
    ProfileLinkDocument,
    options,
  )
}
export function useProfileLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileLinkQuery,
    ProfileLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProfileLinkQuery, ProfileLinkQueryVariables>(
    ProfileLinkDocument,
    options,
  )
}
export type ProfileLinkQueryHookResult = ReturnType<typeof useProfileLinkQuery>
export type ProfileLinkLazyQueryHookResult = ReturnType<
  typeof useProfileLinkLazyQuery
>
export type ProfileLinkQueryResult = Apollo.QueryResult<
  ProfileLinkQuery,
  ProfileLinkQueryVariables
>
export const ImpersonateBannerDocument = gql`
  query ImpersonateBanner {
    currentUser {
      ...ImpersonateBannerCurrentUser
    }
  }
  ${ImpersonateBannerCurrentUserFragmentDoc}
`

/**
 * __useImpersonateBannerQuery__
 *
 * To run a query within a React component, call `useImpersonateBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpersonateBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpersonateBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpersonateBannerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImpersonateBannerQuery,
    ImpersonateBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ImpersonateBannerQuery,
    ImpersonateBannerQueryVariables
  >(ImpersonateBannerDocument, options)
}
export function useImpersonateBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpersonateBannerQuery,
    ImpersonateBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ImpersonateBannerQuery,
    ImpersonateBannerQueryVariables
  >(ImpersonateBannerDocument, options)
}
export type ImpersonateBannerQueryHookResult = ReturnType<
  typeof useImpersonateBannerQuery
>
export type ImpersonateBannerLazyQueryHookResult = ReturnType<
  typeof useImpersonateBannerLazyQuery
>
export type ImpersonateBannerQueryResult = Apollo.QueryResult<
  ImpersonateBannerQuery,
  ImpersonateBannerQueryVariables
>
export const LayoutDocument = gql`
  query Layout {
    currentUser {
      ...LayoutCurrentUser
    }
  }
  ${LayoutCurrentUserFragmentDoc}
`

/**
 * __useLayoutQuery__
 *
 * To run a query within a React component, call `useLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutQuery(
  baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(
    LayoutDocument,
    options,
  )
}
export function useLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(
    LayoutDocument,
    options,
  )
}
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>
export type LayoutQueryResult = Apollo.QueryResult<
  LayoutQuery,
  LayoutQueryVariables
>
export const MemberLookupDocument = gql`
  query MemberLookup($search: String!) {
    users(connection: { first: 10 }, search: $search) {
      nodes {
        auth0Id
        fullName
        id
        profilePictureUrl
      }
    }
  }
`

/**
 * __useMemberLookupQuery__
 *
 * To run a query within a React component, call `useMemberLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMemberLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberLookupQuery,
    MemberLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MemberLookupQuery, MemberLookupQueryVariables>(
    MemberLookupDocument,
    options,
  )
}
export function useMemberLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberLookupQuery,
    MemberLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MemberLookupQuery, MemberLookupQueryVariables>(
    MemberLookupDocument,
    options,
  )
}
export type MemberLookupQueryHookResult = ReturnType<
  typeof useMemberLookupQuery
>
export type MemberLookupLazyQueryHookResult = ReturnType<
  typeof useMemberLookupLazyQuery
>
export type MemberLookupQueryResult = Apollo.QueryResult<
  MemberLookupQuery,
  MemberLookupQueryVariables
>
export const MessageMemberDocument = gql`
  query MessageMember($userIds: [String!]) {
    users(auth0ids: $userIds) {
      nodes {
        fullName
        id
      }
    }
  }
`

/**
 * __useMessageMemberQuery__
 *
 * To run a query within a React component, call `useMessageMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageMemberQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useMessageMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MessageMemberQuery,
    MessageMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MessageMemberQuery, MessageMemberQueryVariables>(
    MessageMemberDocument,
    options,
  )
}
export function useMessageMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageMemberQuery,
    MessageMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MessageMemberQuery, MessageMemberQueryVariables>(
    MessageMemberDocument,
    options,
  )
}
export type MessageMemberQueryHookResult = ReturnType<
  typeof useMessageMemberQuery
>
export type MessageMemberLazyQueryHookResult = ReturnType<
  typeof useMessageMemberLazyQuery
>
export type MessageMemberQueryResult = Apollo.QueryResult<
  MessageMemberQuery,
  MessageMemberQueryVariables
>
export const PromoteToPowerHostDocument = gql`
  mutation PromoteToPowerHost($input: PromoteToPowerHostInput!) {
    user {
      promoteToPowerHost(input: $input) {
        user {
          id
          ...PromoteToPowerHostUserFragment
        }
      }
    }
  }
  ${PromoteToPowerHostUserFragmentFragmentDoc}
`
export type PromoteToPowerHostMutationFn = Apollo.MutationFunction<
  PromoteToPowerHostMutation,
  PromoteToPowerHostMutationVariables
>

/**
 * __usePromoteToPowerHostMutation__
 *
 * To run a mutation, you first call `usePromoteToPowerHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteToPowerHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteToPowerHostMutation, { data, loading, error }] = usePromoteToPowerHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteToPowerHostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteToPowerHostMutation,
    PromoteToPowerHostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PromoteToPowerHostMutation,
    PromoteToPowerHostMutationVariables
  >(PromoteToPowerHostDocument, options)
}
export type PromoteToPowerHostMutationHookResult = ReturnType<
  typeof usePromoteToPowerHostMutation
>
export type PromoteToPowerHostMutationResult =
  Apollo.MutationResult<PromoteToPowerHostMutation>
export type PromoteToPowerHostMutationOptions = Apollo.BaseMutationOptions<
  PromoteToPowerHostMutation,
  PromoteToPowerHostMutationVariables
>
export const EventForRatingDocument = gql`
  query EventForRating($attendeesConnection: ConnectionInput!, $slug: String!) {
    event(slug: $slug) {
      ...EventForRatingData
    }
  }
  ${EventForRatingDataFragmentDoc}
`

/**
 * __useEventForRatingQuery__
 *
 * To run a query within a React component, call `useEventForRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventForRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventForRatingQuery({
 *   variables: {
 *      attendeesConnection: // value for 'attendeesConnection'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventForRatingQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventForRatingQuery,
    EventForRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventForRatingQuery, EventForRatingQueryVariables>(
    EventForRatingDocument,
    options,
  )
}
export function useEventForRatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventForRatingQuery,
    EventForRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventForRatingQuery, EventForRatingQueryVariables>(
    EventForRatingDocument,
    options,
  )
}
export type EventForRatingQueryHookResult = ReturnType<
  typeof useEventForRatingQuery
>
export type EventForRatingLazyQueryHookResult = ReturnType<
  typeof useEventForRatingLazyQuery
>
export type EventForRatingQueryResult = Apollo.QueryResult<
  EventForRatingQuery,
  EventForRatingQueryVariables
>
export const SubmitAttendeeFeedbackDocument = gql`
  mutation SubmitAttendeeFeedback(
    $attended: Boolean!
    $eventId: ID!
    $feedbackForHost: String
    $feedbackForRevel: String
    $ratingForEvent: Int
    $ratingForHost: Int
  ) {
    event {
      submitAttendeeFeedback(
        input: {
          eventId: $eventId
          attended: $attended
          ratingForHost: $ratingForHost
          ratingForEvent: $ratingForEvent
          feedbackForHost: $feedbackForHost
          feedbackForRevel: $feedbackForRevel
        }
      ) {
        ticket {
          attended
          feedbackForHost
          feedbackForRevel
          id
          ratingForEvent
          ratingForHost
        }
      }
    }
  }
`
export type SubmitAttendeeFeedbackMutationFn = Apollo.MutationFunction<
  SubmitAttendeeFeedbackMutation,
  SubmitAttendeeFeedbackMutationVariables
>

/**
 * __useSubmitAttendeeFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitAttendeeFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAttendeeFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAttendeeFeedbackMutation, { data, loading, error }] = useSubmitAttendeeFeedbackMutation({
 *   variables: {
 *      attended: // value for 'attended'
 *      eventId: // value for 'eventId'
 *      feedbackForHost: // value for 'feedbackForHost'
 *      feedbackForRevel: // value for 'feedbackForRevel'
 *      ratingForEvent: // value for 'ratingForEvent'
 *      ratingForHost: // value for 'ratingForHost'
 *   },
 * });
 */
export function useSubmitAttendeeFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitAttendeeFeedbackMutation,
    SubmitAttendeeFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitAttendeeFeedbackMutation,
    SubmitAttendeeFeedbackMutationVariables
  >(SubmitAttendeeFeedbackDocument, options)
}
export type SubmitAttendeeFeedbackMutationHookResult = ReturnType<
  typeof useSubmitAttendeeFeedbackMutation
>
export type SubmitAttendeeFeedbackMutationResult =
  Apollo.MutationResult<SubmitAttendeeFeedbackMutation>
export type SubmitAttendeeFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SubmitAttendeeFeedbackMutation,
  SubmitAttendeeFeedbackMutationVariables
>
export const EventRateAsHostDocument = gql`
  mutation EventRateAsHost(
    $easyToCreateAndSubmitEvent: Boolean
    $eventId: ID!
    $feedback: String
    $feltSupportedByRevel: Boolean
    $metOtherRevelers: Boolean
    $rating: Int!
  ) {
    event {
      submitHostFeedback(
        input: {
          eventId: $eventId
          rating: $rating
          feedback: $feedback
          metOtherRevelers: $metOtherRevelers
          easyToCreateAndSubmitEvent: $easyToCreateAndSubmitEvent
          feltSupportedByRevel: $feltSupportedByRevel
        }
      ) {
        hostship {
          easyToCreateAndSubmitEvent
          feedback
          feltSupportedByRevel
          id
          metOtherRevelers
          rating
        }
      }
    }
  }
`
export type EventRateAsHostMutationFn = Apollo.MutationFunction<
  EventRateAsHostMutation,
  EventRateAsHostMutationVariables
>

/**
 * __useEventRateAsHostMutation__
 *
 * To run a mutation, you first call `useEventRateAsHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRateAsHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRateAsHostMutation, { data, loading, error }] = useEventRateAsHostMutation({
 *   variables: {
 *      easyToCreateAndSubmitEvent: // value for 'easyToCreateAndSubmitEvent'
 *      eventId: // value for 'eventId'
 *      feedback: // value for 'feedback'
 *      feltSupportedByRevel: // value for 'feltSupportedByRevel'
 *      metOtherRevelers: // value for 'metOtherRevelers'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useEventRateAsHostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventRateAsHostMutation,
    EventRateAsHostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventRateAsHostMutation,
    EventRateAsHostMutationVariables
  >(EventRateAsHostDocument, options)
}
export type EventRateAsHostMutationHookResult = ReturnType<
  typeof useEventRateAsHostMutation
>
export type EventRateAsHostMutationResult =
  Apollo.MutationResult<EventRateAsHostMutation>
export type EventRateAsHostMutationOptions = Apollo.BaseMutationOptions<
  EventRateAsHostMutation,
  EventRateAsHostMutationVariables
>
export const Device__RemoveDeviceMutationDocument = gql`
  mutation Device__RemoveDeviceMutation($fcmToken: String!) {
    device {
      removeDevice(input: { fcmToken: $fcmToken }) {
        user {
          id
        }
      }
    }
  }
`
export type Device__RemoveDeviceMutationMutationFn = Apollo.MutationFunction<
  Device__RemoveDeviceMutationMutation,
  Device__RemoveDeviceMutationMutationVariables
>

/**
 * __useDevice__RemoveDeviceMutationMutation__
 *
 * To run a mutation, you first call `useDevice__RemoveDeviceMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevice__RemoveDeviceMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceRemoveDeviceMutationMutation, { data, loading, error }] = useDevice__RemoveDeviceMutationMutation({
 *   variables: {
 *      fcmToken: // value for 'fcmToken'
 *   },
 * });
 */
export function useDevice__RemoveDeviceMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Device__RemoveDeviceMutationMutation,
    Device__RemoveDeviceMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Device__RemoveDeviceMutationMutation,
    Device__RemoveDeviceMutationMutationVariables
  >(Device__RemoveDeviceMutationDocument, options)
}
export type Device__RemoveDeviceMutationMutationHookResult = ReturnType<
  typeof useDevice__RemoveDeviceMutationMutation
>
export type Device__RemoveDeviceMutationMutationResult =
  Apollo.MutationResult<Device__RemoveDeviceMutationMutation>
export type Device__RemoveDeviceMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Device__RemoveDeviceMutationMutation,
    Device__RemoveDeviceMutationMutationVariables
  >
export const Mentions__UsersDocument = gql`
  query Mentions__Users($search: String) {
    users(connection: { first: 25 }, search: $search) {
      nodes {
        fullName
        id
        location {
          city
          country
          state
        }
        profilePictureUrl
        slug
      }
    }
  }
`

/**
 * __useMentions__UsersQuery__
 *
 * To run a query within a React component, call `useMentions__UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentions__UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentions__UsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMentions__UsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Mentions__UsersQuery,
    Mentions__UsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Mentions__UsersQuery, Mentions__UsersQueryVariables>(
    Mentions__UsersDocument,
    options,
  )
}
export function useMentions__UsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Mentions__UsersQuery,
    Mentions__UsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Mentions__UsersQuery,
    Mentions__UsersQueryVariables
  >(Mentions__UsersDocument, options)
}
export type Mentions__UsersQueryHookResult = ReturnType<
  typeof useMentions__UsersQuery
>
export type Mentions__UsersLazyQueryHookResult = ReturnType<
  typeof useMentions__UsersLazyQuery
>
export type Mentions__UsersQueryResult = Apollo.QueryResult<
  Mentions__UsersQuery,
  Mentions__UsersQueryVariables
>
export const Device__AddDeviceMutationDocument = gql`
  mutation Device__AddDeviceMutation($fcmToken: String!) {
    device {
      addDevice(input: { fcmToken: $fcmToken }) {
        createdDevice {
          fcmToken
          userId
        }
      }
    }
  }
`
export type Device__AddDeviceMutationMutationFn = Apollo.MutationFunction<
  Device__AddDeviceMutationMutation,
  Device__AddDeviceMutationMutationVariables
>

/**
 * __useDevice__AddDeviceMutationMutation__
 *
 * To run a mutation, you first call `useDevice__AddDeviceMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevice__AddDeviceMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceAddDeviceMutationMutation, { data, loading, error }] = useDevice__AddDeviceMutationMutation({
 *   variables: {
 *      fcmToken: // value for 'fcmToken'
 *   },
 * });
 */
export function useDevice__AddDeviceMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Device__AddDeviceMutationMutation,
    Device__AddDeviceMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Device__AddDeviceMutationMutation,
    Device__AddDeviceMutationMutationVariables
  >(Device__AddDeviceMutationDocument, options)
}
export type Device__AddDeviceMutationMutationHookResult = ReturnType<
  typeof useDevice__AddDeviceMutationMutation
>
export type Device__AddDeviceMutationMutationResult =
  Apollo.MutationResult<Device__AddDeviceMutationMutation>
export type Device__AddDeviceMutationMutationOptions =
  Apollo.BaseMutationOptions<
    Device__AddDeviceMutationMutation,
    Device__AddDeviceMutationMutationVariables
  >
export const PushNotificationMarkAsReadDocument = gql`
  mutation PushNotificationMarkAsRead($link: String!, $type: Int!) {
    notification {
      markAsRead(input: { pushNotification: { link: $link, type: $type } }) {
        notification {
          id
          read
          seen
        }
      }
    }
  }
`
export type PushNotificationMarkAsReadMutationFn = Apollo.MutationFunction<
  PushNotificationMarkAsReadMutation,
  PushNotificationMarkAsReadMutationVariables
>

/**
 * __usePushNotificationMarkAsReadMutation__
 *
 * To run a mutation, you first call `usePushNotificationMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushNotificationMarkAsReadMutation, { data, loading, error }] = usePushNotificationMarkAsReadMutation({
 *   variables: {
 *      link: // value for 'link'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePushNotificationMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PushNotificationMarkAsReadMutation,
    PushNotificationMarkAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PushNotificationMarkAsReadMutation,
    PushNotificationMarkAsReadMutationVariables
  >(PushNotificationMarkAsReadDocument, options)
}
export type PushNotificationMarkAsReadMutationHookResult = ReturnType<
  typeof usePushNotificationMarkAsReadMutation
>
export type PushNotificationMarkAsReadMutationResult =
  Apollo.MutationResult<PushNotificationMarkAsReadMutation>
export type PushNotificationMarkAsReadMutationOptions =
  Apollo.BaseMutationOptions<
    PushNotificationMarkAsReadMutation,
    PushNotificationMarkAsReadMutationVariables
  >
export const UseUserRoleDocument = gql`
  query UseUserRole {
    currentUser {
      ...UseUserRoleCurrentUser
    }
  }
  ${UseUserRoleCurrentUserFragmentDoc}
`

/**
 * __useUseUserRoleQuery__
 *
 * To run a query within a React component, call `useUseUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseUserRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UseUserRoleQuery,
    UseUserRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UseUserRoleQuery, UseUserRoleQueryVariables>(
    UseUserRoleDocument,
    options,
  )
}
export function useUseUserRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UseUserRoleQuery,
    UseUserRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UseUserRoleQuery, UseUserRoleQueryVariables>(
    UseUserRoleDocument,
    options,
  )
}
export type UseUserRoleQueryHookResult = ReturnType<typeof useUseUserRoleQuery>
export type UseUserRoleLazyQueryHookResult = ReturnType<
  typeof useUseUserRoleLazyQuery
>
export type UseUserRoleQueryResult = Apollo.QueryResult<
  UseUserRoleQuery,
  UseUserRoleQueryVariables
>
export const EventDetailsAttendeeDocument = gql`
  query EventDetailsAttendee(
    $attendeesConnection: ConnectionInput!
    $slug: String!
  ) {
    event(slug: $slug) {
      ...EventDetailsAttendeeEvent
      id
    }
  }
  ${EventDetailsAttendeeEventFragmentDoc}
`

/**
 * __useEventDetailsAttendeeQuery__
 *
 * To run a query within a React component, call `useEventDetailsAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsAttendeeQuery({
 *   variables: {
 *      attendeesConnection: // value for 'attendeesConnection'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventDetailsAttendeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsAttendeeQuery,
    EventDetailsAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventDetailsAttendeeQuery,
    EventDetailsAttendeeQueryVariables
  >(EventDetailsAttendeeDocument, options)
}
export function useEventDetailsAttendeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsAttendeeQuery,
    EventDetailsAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventDetailsAttendeeQuery,
    EventDetailsAttendeeQueryVariables
  >(EventDetailsAttendeeDocument, options)
}
export type EventDetailsAttendeeQueryHookResult = ReturnType<
  typeof useEventDetailsAttendeeQuery
>
export type EventDetailsAttendeeLazyQueryHookResult = ReturnType<
  typeof useEventDetailsAttendeeLazyQuery
>
export type EventDetailsAttendeeQueryResult = Apollo.QueryResult<
  EventDetailsAttendeeQuery,
  EventDetailsAttendeeQueryVariables
>
export const EventDetailsCreatePostDocument = gql`
  mutation EventDetailsCreatePost(
    $eventId: ID!
    $parentPostId: ID
    $textBody: String!
  ) {
    event {
      createPost(
        input: {
          eventId: $eventId
          parentPostId: $parentPostId
          textBody: $textBody
        }
      ) {
        createdPost {
          ...EventDetailsFeedEventPost
          id
        }
        event {
          discussionFeed {
            totalCount
          }
        }
        parentPost {
          comments: posts {
            totalCount
          }
          id
        }
      }
    }
  }
  ${EventDetailsFeedEventPostFragmentDoc}
`
export type EventDetailsCreatePostMutationFn = Apollo.MutationFunction<
  EventDetailsCreatePostMutation,
  EventDetailsCreatePostMutationVariables
>

/**
 * __useEventDetailsCreatePostMutation__
 *
 * To run a mutation, you first call `useEventDetailsCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventDetailsCreatePostMutation, { data, loading, error }] = useEventDetailsCreatePostMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      parentPostId: // value for 'parentPostId'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useEventDetailsCreatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventDetailsCreatePostMutation,
    EventDetailsCreatePostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventDetailsCreatePostMutation,
    EventDetailsCreatePostMutationVariables
  >(EventDetailsCreatePostDocument, options)
}
export type EventDetailsCreatePostMutationHookResult = ReturnType<
  typeof useEventDetailsCreatePostMutation
>
export type EventDetailsCreatePostMutationResult =
  Apollo.MutationResult<EventDetailsCreatePostMutation>
export type EventDetailsCreatePostMutationOptions = Apollo.BaseMutationOptions<
  EventDetailsCreatePostMutation,
  EventDetailsCreatePostMutationVariables
>
export const EventDetailsEssentialDocument = gql`
  query EventDetailsEssential($slug: String!) {
    event(slug: $slug) {
      ...EventDetailsEssentialEvent
    }
  }
  ${EventDetailsEssentialEventFragmentDoc}
`

/**
 * __useEventDetailsEssentialQuery__
 *
 * To run a query within a React component, call `useEventDetailsEssentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsEssentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsEssentialQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventDetailsEssentialQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsEssentialQuery,
    EventDetailsEssentialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventDetailsEssentialQuery,
    EventDetailsEssentialQueryVariables
  >(EventDetailsEssentialDocument, options)
}
export function useEventDetailsEssentialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsEssentialQuery,
    EventDetailsEssentialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventDetailsEssentialQuery,
    EventDetailsEssentialQueryVariables
  >(EventDetailsEssentialDocument, options)
}
export type EventDetailsEssentialQueryHookResult = ReturnType<
  typeof useEventDetailsEssentialQuery
>
export type EventDetailsEssentialLazyQueryHookResult = ReturnType<
  typeof useEventDetailsEssentialLazyQuery
>
export type EventDetailsEssentialQueryResult = Apollo.QueryResult<
  EventDetailsEssentialQuery,
  EventDetailsEssentialQueryVariables
>
export const EventDetailsFeedDocument = gql`
  query EventDetailsFeed($feedConnection: ConnectionInput!, $slug: String!) {
    event(slug: $slug) {
      ...EventDetailsFeedEvent
      id
    }
  }
  ${EventDetailsFeedEventFragmentDoc}
`

/**
 * __useEventDetailsFeedQuery__
 *
 * To run a query within a React component, call `useEventDetailsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsFeedQuery({
 *   variables: {
 *      feedConnection: // value for 'feedConnection'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventDetailsFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsFeedQuery,
    EventDetailsFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventDetailsFeedQuery, EventDetailsFeedQueryVariables>(
    EventDetailsFeedDocument,
    options,
  )
}
export function useEventDetailsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsFeedQuery,
    EventDetailsFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventDetailsFeedQuery,
    EventDetailsFeedQueryVariables
  >(EventDetailsFeedDocument, options)
}
export type EventDetailsFeedQueryHookResult = ReturnType<
  typeof useEventDetailsFeedQuery
>
export type EventDetailsFeedLazyQueryHookResult = ReturnType<
  typeof useEventDetailsFeedLazyQuery
>
export type EventDetailsFeedQueryResult = Apollo.QueryResult<
  EventDetailsFeedQuery,
  EventDetailsFeedQueryVariables
>
export const EventDetailsHostDocument = gql`
  query EventDetailsHost($hostsConnection: ConnectionInput!, $slug: String!) {
    event(slug: $slug) {
      ...EventDetailsHostEvent
      id
    }
  }
  ${EventDetailsHostEventFragmentDoc}
`

/**
 * __useEventDetailsHostQuery__
 *
 * To run a query within a React component, call `useEventDetailsHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsHostQuery({
 *   variables: {
 *      hostsConnection: // value for 'hostsConnection'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventDetailsHostQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsHostQuery,
    EventDetailsHostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventDetailsHostQuery, EventDetailsHostQueryVariables>(
    EventDetailsHostDocument,
    options,
  )
}
export function useEventDetailsHostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsHostQuery,
    EventDetailsHostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventDetailsHostQuery,
    EventDetailsHostQueryVariables
  >(EventDetailsHostDocument, options)
}
export type EventDetailsHostQueryHookResult = ReturnType<
  typeof useEventDetailsHostQuery
>
export type EventDetailsHostLazyQueryHookResult = ReturnType<
  typeof useEventDetailsHostLazyQuery
>
export type EventDetailsHostQueryResult = Apollo.QueryResult<
  EventDetailsHostQuery,
  EventDetailsHostQueryVariables
>
export const EventDetailsFeedSinglePostDocument = gql`
  query EventDetailsFeedSinglePost(
    $commentsConnection: ConnectionInput!
    $postId: ID!
  ) {
    eventPost(id: $postId) {
      ...EventDetailsFeedSinglePostEventPost
      id
    }
  }
  ${EventDetailsFeedSinglePostEventPostFragmentDoc}
`

/**
 * __useEventDetailsFeedSinglePostQuery__
 *
 * To run a query within a React component, call `useEventDetailsFeedSinglePostQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsFeedSinglePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsFeedSinglePostQuery({
 *   variables: {
 *      commentsConnection: // value for 'commentsConnection'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useEventDetailsFeedSinglePostQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsFeedSinglePostQuery,
    EventDetailsFeedSinglePostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventDetailsFeedSinglePostQuery,
    EventDetailsFeedSinglePostQueryVariables
  >(EventDetailsFeedSinglePostDocument, options)
}
export function useEventDetailsFeedSinglePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsFeedSinglePostQuery,
    EventDetailsFeedSinglePostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventDetailsFeedSinglePostQuery,
    EventDetailsFeedSinglePostQueryVariables
  >(EventDetailsFeedSinglePostDocument, options)
}
export type EventDetailsFeedSinglePostQueryHookResult = ReturnType<
  typeof useEventDetailsFeedSinglePostQuery
>
export type EventDetailsFeedSinglePostLazyQueryHookResult = ReturnType<
  typeof useEventDetailsFeedSinglePostLazyQuery
>
export type EventDetailsFeedSinglePostQueryResult = Apollo.QueryResult<
  EventDetailsFeedSinglePostQuery,
  EventDetailsFeedSinglePostQueryVariables
>
export const EventPostDeleteDocument = gql`
  mutation EventPostDelete($postId: ID!) {
    event {
      deletePost(input: { postId: $postId }) {
        event {
          discussionFeed {
            totalCount
          }
          id
        }
        parentPost {
          comments: posts {
            totalCount
          }
          id
        }
      }
    }
  }
`
export type EventPostDeleteMutationFn = Apollo.MutationFunction<
  EventPostDeleteMutation,
  EventPostDeleteMutationVariables
>

/**
 * __useEventPostDeleteMutation__
 *
 * To run a mutation, you first call `useEventPostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPostDeleteMutation, { data, loading, error }] = useEventPostDeleteMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useEventPostDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventPostDeleteMutation,
    EventPostDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventPostDeleteMutation,
    EventPostDeleteMutationVariables
  >(EventPostDeleteDocument, options)
}
export type EventPostDeleteMutationHookResult = ReturnType<
  typeof useEventPostDeleteMutation
>
export type EventPostDeleteMutationResult =
  Apollo.MutationResult<EventPostDeleteMutation>
export type EventPostDeleteMutationOptions = Apollo.BaseMutationOptions<
  EventPostDeleteMutation,
  EventPostDeleteMutationVariables
>
export const EventPostLikeDocument = gql`
  mutation EventPostLike($postId: ID!) {
    event {
      likePost(input: { postId: $postId }) {
        eventPost {
          id
          likes {
            totalCount
          }
          myLike {
            id
          }
        }
      }
    }
  }
`
export type EventPostLikeMutationFn = Apollo.MutationFunction<
  EventPostLikeMutation,
  EventPostLikeMutationVariables
>

/**
 * __useEventPostLikeMutation__
 *
 * To run a mutation, you first call `useEventPostLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPostLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPostLikeMutation, { data, loading, error }] = useEventPostLikeMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useEventPostLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventPostLikeMutation,
    EventPostLikeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventPostLikeMutation,
    EventPostLikeMutationVariables
  >(EventPostLikeDocument, options)
}
export type EventPostLikeMutationHookResult = ReturnType<
  typeof useEventPostLikeMutation
>
export type EventPostLikeMutationResult =
  Apollo.MutationResult<EventPostLikeMutation>
export type EventPostLikeMutationOptions = Apollo.BaseMutationOptions<
  EventPostLikeMutation,
  EventPostLikeMutationVariables
>
export const EventPostUnlikeDocument = gql`
  mutation EventPostUnlike($postId: ID!) {
    event {
      unlikePost(input: { postId: $postId }) {
        eventPost {
          id
          likes {
            totalCount
          }
          myLike {
            id
          }
        }
      }
    }
  }
`
export type EventPostUnlikeMutationFn = Apollo.MutationFunction<
  EventPostUnlikeMutation,
  EventPostUnlikeMutationVariables
>

/**
 * __useEventPostUnlikeMutation__
 *
 * To run a mutation, you first call `useEventPostUnlikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPostUnlikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPostUnlikeMutation, { data, loading, error }] = useEventPostUnlikeMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useEventPostUnlikeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventPostUnlikeMutation,
    EventPostUnlikeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventPostUnlikeMutation,
    EventPostUnlikeMutationVariables
  >(EventPostUnlikeDocument, options)
}
export type EventPostUnlikeMutationHookResult = ReturnType<
  typeof useEventPostUnlikeMutation
>
export type EventPostUnlikeMutationResult =
  Apollo.MutationResult<EventPostUnlikeMutation>
export type EventPostUnlikeMutationOptions = Apollo.BaseMutationOptions<
  EventPostUnlikeMutation,
  EventPostUnlikeMutationVariables
>
export const EventPostUpdateDocument = gql`
  mutation EventPostUpdate($postId: ID!, $textBody: String!) {
    event {
      updatePost(input: { postId: $postId, textBody: $textBody }) {
        updatedPost {
          id
          textBody
        }
      }
    }
  }
`
export type EventPostUpdateMutationFn = Apollo.MutationFunction<
  EventPostUpdateMutation,
  EventPostUpdateMutationVariables
>

/**
 * __useEventPostUpdateMutation__
 *
 * To run a mutation, you first call `useEventPostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPostUpdateMutation, { data, loading, error }] = useEventPostUpdateMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useEventPostUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventPostUpdateMutation,
    EventPostUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventPostUpdateMutation,
    EventPostUpdateMutationVariables
  >(EventPostUpdateDocument, options)
}
export type EventPostUpdateMutationHookResult = ReturnType<
  typeof useEventPostUpdateMutation
>
export type EventPostUpdateMutationResult =
  Apollo.MutationResult<EventPostUpdateMutation>
export type EventPostUpdateMutationOptions = Apollo.BaseMutationOptions<
  EventPostUpdateMutation,
  EventPostUpdateMutationVariables
>
export const InboxCreateThreadDocument = gql`
  mutation InboxCreateThread(
    $message: String!
    $participantIds: [ID!]!
    $subject: String!
  ) {
    inboxCreateThread(
      input: {
        message: $message
        participantIds: $participantIds
        subject: $subject
      }
    ) {
      thread {
        id
        participants {
          nodes {
            auth0Id
            fullName
            id
            isBlocked
          }
        }
      }
    }
  }
`
export type InboxCreateThreadMutationFn = Apollo.MutationFunction<
  InboxCreateThreadMutation,
  InboxCreateThreadMutationVariables
>

/**
 * __useInboxCreateThreadMutation__
 *
 * To run a mutation, you first call `useInboxCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxCreateThreadMutation, { data, loading, error }] = useInboxCreateThreadMutation({
 *   variables: {
 *      message: // value for 'message'
 *      participantIds: // value for 'participantIds'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useInboxCreateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxCreateThreadMutation,
    InboxCreateThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InboxCreateThreadMutation,
    InboxCreateThreadMutationVariables
  >(InboxCreateThreadDocument, options)
}
export type InboxCreateThreadMutationHookResult = ReturnType<
  typeof useInboxCreateThreadMutation
>
export type InboxCreateThreadMutationResult =
  Apollo.MutationResult<InboxCreateThreadMutation>
export type InboxCreateThreadMutationOptions = Apollo.BaseMutationOptions<
  InboxCreateThreadMutation,
  InboxCreateThreadMutationVariables
>
export const InboxCurrentUserDocument = gql`
  query InboxCurrentUser {
    currentUser {
      ...InboxCurrentUserData
    }
  }
  ${InboxCurrentUserDataFragmentDoc}
`

/**
 * __useInboxCurrentUserQuery__
 *
 * To run a query within a React component, call `useInboxCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useInboxCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InboxCurrentUserQuery,
    InboxCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InboxCurrentUserQuery, InboxCurrentUserQueryVariables>(
    InboxCurrentUserDocument,
    options,
  )
}
export function useInboxCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxCurrentUserQuery,
    InboxCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InboxCurrentUserQuery,
    InboxCurrentUserQueryVariables
  >(InboxCurrentUserDocument, options)
}
export type InboxCurrentUserQueryHookResult = ReturnType<
  typeof useInboxCurrentUserQuery
>
export type InboxCurrentUserLazyQueryHookResult = ReturnType<
  typeof useInboxCurrentUserLazyQuery
>
export type InboxCurrentUserQueryResult = Apollo.QueryResult<
  InboxCurrentUserQuery,
  InboxCurrentUserQueryVariables
>
export const InboxFetchEventAttendeesDocument = gql`
  query InboxFetchEventAttendees($after: String, $eventDatabaseId: String!) {
    event(databaseId: $eventDatabaseId) {
      participants(connection: { after: $after, first: 20 }, role: ATTENDEE) {
        nodes {
          ...InboxAttendee
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${InboxAttendeeFragmentDoc}
`

/**
 * __useInboxFetchEventAttendeesQuery__
 *
 * To run a query within a React component, call `useInboxFetchEventAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxFetchEventAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxFetchEventAttendeesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      eventDatabaseId: // value for 'eventDatabaseId'
 *   },
 * });
 */
export function useInboxFetchEventAttendeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InboxFetchEventAttendeesQuery,
    InboxFetchEventAttendeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InboxFetchEventAttendeesQuery,
    InboxFetchEventAttendeesQueryVariables
  >(InboxFetchEventAttendeesDocument, options)
}
export function useInboxFetchEventAttendeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxFetchEventAttendeesQuery,
    InboxFetchEventAttendeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InboxFetchEventAttendeesQuery,
    InboxFetchEventAttendeesQueryVariables
  >(InboxFetchEventAttendeesDocument, options)
}
export type InboxFetchEventAttendeesQueryHookResult = ReturnType<
  typeof useInboxFetchEventAttendeesQuery
>
export type InboxFetchEventAttendeesLazyQueryHookResult = ReturnType<
  typeof useInboxFetchEventAttendeesLazyQuery
>
export type InboxFetchEventAttendeesQueryResult = Apollo.QueryResult<
  InboxFetchEventAttendeesQuery,
  InboxFetchEventAttendeesQueryVariables
>
export const InboxFetchEventWaitlistDocument = gql`
  query InboxFetchEventWaitlist(
    $after: String
    $eventDatabaseId: String!
    $status: TicketStatus
  ) {
    event(databaseId: $eventDatabaseId) {
      tickets(connection: { after: $after, first: 10 }, status: $status) {
        nodes {
          ...InboxTicket
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${InboxTicketFragmentDoc}
`

/**
 * __useInboxFetchEventWaitlistQuery__
 *
 * To run a query within a React component, call `useInboxFetchEventWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxFetchEventWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxFetchEventWaitlistQuery({
 *   variables: {
 *      after: // value for 'after'
 *      eventDatabaseId: // value for 'eventDatabaseId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInboxFetchEventWaitlistQuery(
  baseOptions: Apollo.QueryHookOptions<
    InboxFetchEventWaitlistQuery,
    InboxFetchEventWaitlistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InboxFetchEventWaitlistQuery,
    InboxFetchEventWaitlistQueryVariables
  >(InboxFetchEventWaitlistDocument, options)
}
export function useInboxFetchEventWaitlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxFetchEventWaitlistQuery,
    InboxFetchEventWaitlistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InboxFetchEventWaitlistQuery,
    InboxFetchEventWaitlistQueryVariables
  >(InboxFetchEventWaitlistDocument, options)
}
export type InboxFetchEventWaitlistQueryHookResult = ReturnType<
  typeof useInboxFetchEventWaitlistQuery
>
export type InboxFetchEventWaitlistLazyQueryHookResult = ReturnType<
  typeof useInboxFetchEventWaitlistLazyQuery
>
export type InboxFetchEventWaitlistQueryResult = Apollo.QueryResult<
  InboxFetchEventWaitlistQuery,
  InboxFetchEventWaitlistQueryVariables
>
export const InboxFetchUserFromAuth0IdDocument = gql`
  query InboxFetchUserFromAuth0Id($auth0id: String) {
    user(auth0id: $auth0id) {
      fullName
      id
      isBlocked
    }
  }
`

/**
 * __useInboxFetchUserFromAuth0IdQuery__
 *
 * To run a query within a React component, call `useInboxFetchUserFromAuth0IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxFetchUserFromAuth0IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxFetchUserFromAuth0IdQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useInboxFetchUserFromAuth0IdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InboxFetchUserFromAuth0IdQuery,
    InboxFetchUserFromAuth0IdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InboxFetchUserFromAuth0IdQuery,
    InboxFetchUserFromAuth0IdQueryVariables
  >(InboxFetchUserFromAuth0IdDocument, options)
}
export function useInboxFetchUserFromAuth0IdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboxFetchUserFromAuth0IdQuery,
    InboxFetchUserFromAuth0IdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InboxFetchUserFromAuth0IdQuery,
    InboxFetchUserFromAuth0IdQueryVariables
  >(InboxFetchUserFromAuth0IdDocument, options)
}
export type InboxFetchUserFromAuth0IdQueryHookResult = ReturnType<
  typeof useInboxFetchUserFromAuth0IdQuery
>
export type InboxFetchUserFromAuth0IdLazyQueryHookResult = ReturnType<
  typeof useInboxFetchUserFromAuth0IdLazyQuery
>
export type InboxFetchUserFromAuth0IdQueryResult = Apollo.QueryResult<
  InboxFetchUserFromAuth0IdQuery,
  InboxFetchUserFromAuth0IdQueryVariables
>
export const InboxDocument = gql`
  query Inbox(
    $messageBeforeCursor: String
    $read: Boolean
    $shouldFetchThread: Boolean!
    $shouldFetchThreads: Boolean!
    $threadBeforeCursor: String
    $threadId: ID
    $threadParticipantsBeforeCursor: String
    $threadParticipantsLast: Int
    $threadSearch: String
    $threadsMessagesLast: Int
    $threadsParticipantsLast: Int
  ) {
    inbox {
      ...InboxData
    }
  }
  ${InboxDataFragmentDoc}
`

/**
 * __useInboxQuery__
 *
 * To run a query within a React component, call `useInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxQuery({
 *   variables: {
 *      messageBeforeCursor: // value for 'messageBeforeCursor'
 *      read: // value for 'read'
 *      shouldFetchThread: // value for 'shouldFetchThread'
 *      shouldFetchThreads: // value for 'shouldFetchThreads'
 *      threadBeforeCursor: // value for 'threadBeforeCursor'
 *      threadId: // value for 'threadId'
 *      threadParticipantsBeforeCursor: // value for 'threadParticipantsBeforeCursor'
 *      threadParticipantsLast: // value for 'threadParticipantsLast'
 *      threadSearch: // value for 'threadSearch'
 *      threadsMessagesLast: // value for 'threadsMessagesLast'
 *      threadsParticipantsLast: // value for 'threadsParticipantsLast'
 *   },
 * });
 */
export function useInboxQuery(
  baseOptions: Apollo.QueryHookOptions<InboxQuery, InboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InboxQuery, InboxQueryVariables>(
    InboxDocument,
    options,
  )
}
export function useInboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxQuery, InboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InboxQuery, InboxQueryVariables>(
    InboxDocument,
    options,
  )
}
export type InboxQueryHookResult = ReturnType<typeof useInboxQuery>
export type InboxLazyQueryHookResult = ReturnType<typeof useInboxLazyQuery>
export type InboxQueryResult = Apollo.QueryResult<
  InboxQuery,
  InboxQueryVariables
>
export const InboxLeaveThreadDocument = gql`
  mutation InboxLeaveThread($threadId: ID!) {
    inboxLeaveThread(input: { threadId: $threadId }) {
      thread {
        id
      }
    }
  }
`
export type InboxLeaveThreadMutationFn = Apollo.MutationFunction<
  InboxLeaveThreadMutation,
  InboxLeaveThreadMutationVariables
>

/**
 * __useInboxLeaveThreadMutation__
 *
 * To run a mutation, you first call `useInboxLeaveThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxLeaveThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxLeaveThreadMutation, { data, loading, error }] = useInboxLeaveThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useInboxLeaveThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxLeaveThreadMutation,
    InboxLeaveThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InboxLeaveThreadMutation,
    InboxLeaveThreadMutationVariables
  >(InboxLeaveThreadDocument, options)
}
export type InboxLeaveThreadMutationHookResult = ReturnType<
  typeof useInboxLeaveThreadMutation
>
export type InboxLeaveThreadMutationResult =
  Apollo.MutationResult<InboxLeaveThreadMutation>
export type InboxLeaveThreadMutationOptions = Apollo.BaseMutationOptions<
  InboxLeaveThreadMutation,
  InboxLeaveThreadMutationVariables
>
export const InboxMarkThreadAsReadDocument = gql`
  mutation InboxMarkThreadAsRead($threadId: ID!) {
    inboxMarkThreadAsRead(input: { threadId: $threadId }) {
      thread {
        id
      }
    }
  }
`
export type InboxMarkThreadAsReadMutationFn = Apollo.MutationFunction<
  InboxMarkThreadAsReadMutation,
  InboxMarkThreadAsReadMutationVariables
>

/**
 * __useInboxMarkThreadAsReadMutation__
 *
 * To run a mutation, you first call `useInboxMarkThreadAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxMarkThreadAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxMarkThreadAsReadMutation, { data, loading, error }] = useInboxMarkThreadAsReadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useInboxMarkThreadAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxMarkThreadAsReadMutation,
    InboxMarkThreadAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InboxMarkThreadAsReadMutation,
    InboxMarkThreadAsReadMutationVariables
  >(InboxMarkThreadAsReadDocument, options)
}
export type InboxMarkThreadAsReadMutationHookResult = ReturnType<
  typeof useInboxMarkThreadAsReadMutation
>
export type InboxMarkThreadAsReadMutationResult =
  Apollo.MutationResult<InboxMarkThreadAsReadMutation>
export type InboxMarkThreadAsReadMutationOptions = Apollo.BaseMutationOptions<
  InboxMarkThreadAsReadMutation,
  InboxMarkThreadAsReadMutationVariables
>
export const InboxSubmitReplyDocument = gql`
  mutation InboxSubmitReply($textBody: String!, $threadId: ID!) {
    inboxCreateMessage(input: { textBody: $textBody, threadId: $threadId }) {
      message {
        id
        ...InboxThreadMessage
      }
    }
  }
  ${InboxThreadMessageFragmentDoc}
`
export type InboxSubmitReplyMutationFn = Apollo.MutationFunction<
  InboxSubmitReplyMutation,
  InboxSubmitReplyMutationVariables
>

/**
 * __useInboxSubmitReplyMutation__
 *
 * To run a mutation, you first call `useInboxSubmitReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxSubmitReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxSubmitReplyMutation, { data, loading, error }] = useInboxSubmitReplyMutation({
 *   variables: {
 *      textBody: // value for 'textBody'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useInboxSubmitReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InboxSubmitReplyMutation,
    InboxSubmitReplyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InboxSubmitReplyMutation,
    InboxSubmitReplyMutationVariables
  >(InboxSubmitReplyDocument, options)
}
export type InboxSubmitReplyMutationHookResult = ReturnType<
  typeof useInboxSubmitReplyMutation
>
export type InboxSubmitReplyMutationResult =
  Apollo.MutationResult<InboxSubmitReplyMutation>
export type InboxSubmitReplyMutationOptions = Apollo.BaseMutationOptions<
  InboxSubmitReplyMutation,
  InboxSubmitReplyMutationVariables
>
export const MyGroupsProviderDocument = gql`
  query MyGroupsProvider($afterCursor: String) {
    groups(
      connection: { first: 10, after: $afterCursor }
      isMember: true
      orders: [{ by: NAME, direction: ASCENDING }]
    ) {
      edges {
        cursor
        node {
          ...MyGroupsProviderGroup
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${MyGroupsProviderGroupFragmentDoc}
`

/**
 * __useMyGroupsProviderQuery__
 *
 * To run a query within a React component, call `useMyGroupsProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGroupsProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGroupsProviderQuery({
 *   variables: {
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useMyGroupsProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyGroupsProviderQuery,
    MyGroupsProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyGroupsProviderQuery, MyGroupsProviderQueryVariables>(
    MyGroupsProviderDocument,
    options,
  )
}
export function useMyGroupsProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyGroupsProviderQuery,
    MyGroupsProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MyGroupsProviderQuery,
    MyGroupsProviderQueryVariables
  >(MyGroupsProviderDocument, options)
}
export type MyGroupsProviderQueryHookResult = ReturnType<
  typeof useMyGroupsProviderQuery
>
export type MyGroupsProviderLazyQueryHookResult = ReturnType<
  typeof useMyGroupsProviderLazyQuery
>
export type MyGroupsProviderQueryResult = Apollo.QueryResult<
  MyGroupsProviderQuery,
  MyGroupsProviderQueryVariables
>
export const NotificationsDocument = gql`
  query Notifications(
    $sources: [NotificationSource!]
    $connection: ConnectionInput
    $includePageInfo: Boolean!
  ) {
    notifications {
      id
      notifications(sources: $sources, connection: $connection) {
        pageInfo @include(if: $includePageInfo) {
          endCursor
          hasPreviousPage
          hasNextPage
        }
        nodes {
          id
          category
          source
          link
          message
          read
          seen
          type
          pictureUrl
          updatedAt
        }
        pageInfo @include(if: $includePageInfo) {
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      sources: // value for 'sources'
 *      connection: // value for 'connection'
 *      includePageInfo: // value for 'includePageInfo'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  )
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  )
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>
export const NotificationsUnreadCountDocument = gql`
  query NotificationsUnreadCount($source: NotificationSource) {
    notifications {
      id
      unreadCount(source: $source)
    }
  }
`

/**
 * __useNotificationsUnreadCountQuery__
 *
 * To run a query within a React component, call `useNotificationsUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsUnreadCountQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useNotificationsUnreadCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >(NotificationsUnreadCountDocument, options)
}
export function useNotificationsUnreadCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >(NotificationsUnreadCountDocument, options)
}
export type NotificationsUnreadCountQueryHookResult = ReturnType<
  typeof useNotificationsUnreadCountQuery
>
export type NotificationsUnreadCountLazyQueryHookResult = ReturnType<
  typeof useNotificationsUnreadCountLazyQuery
>
export type NotificationsUnreadCountQueryResult = Apollo.QueryResult<
  NotificationsUnreadCountQuery,
  NotificationsUnreadCountQueryVariables
>
export const NotificationsUnseenCountDocument = gql`
  query NotificationsUnseenCount($source: NotificationSource) {
    notifications {
      id
      unseenCount(source: $source)
    }
  }
`

/**
 * __useNotificationsUnseenCountQuery__
 *
 * To run a query within a React component, call `useNotificationsUnseenCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsUnseenCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsUnseenCountQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useNotificationsUnseenCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsUnseenCountQuery,
    NotificationsUnseenCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    NotificationsUnseenCountQuery,
    NotificationsUnseenCountQueryVariables
  >(NotificationsUnseenCountDocument, options)
}
export function useNotificationsUnseenCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsUnseenCountQuery,
    NotificationsUnseenCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    NotificationsUnseenCountQuery,
    NotificationsUnseenCountQueryVariables
  >(NotificationsUnseenCountDocument, options)
}
export type NotificationsUnseenCountQueryHookResult = ReturnType<
  typeof useNotificationsUnseenCountQuery
>
export type NotificationsUnseenCountLazyQueryHookResult = ReturnType<
  typeof useNotificationsUnseenCountLazyQuery
>
export type NotificationsUnseenCountQueryResult = Apollo.QueryResult<
  NotificationsUnseenCountQuery,
  NotificationsUnseenCountQueryVariables
>
export const SearchKeysDocument = gql`
  query SearchKeys {
    searchKey {
      events
      groups
      members
      myGroups: groups(isMember: true)
      threads
    }
  }
`

/**
 * __useSearchKeysQuery__
 *
 * To run a query within a React component, call `useSearchKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchKeysQuery,
    SearchKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchKeysQuery, SearchKeysQueryVariables>(
    SearchKeysDocument,
    options,
  )
}
export function useSearchKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchKeysQuery,
    SearchKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchKeysQuery, SearchKeysQueryVariables>(
    SearchKeysDocument,
    options,
  )
}
export type SearchKeysQueryHookResult = ReturnType<typeof useSearchKeysQuery>
export type SearchKeysLazyQueryHookResult = ReturnType<
  typeof useSearchKeysLazyQuery
>
export type SearchKeysQueryResult = Apollo.QueryResult<
  SearchKeysQuery,
  SearchKeysQueryVariables
>
export const VersionCheckDocument = gql`
  mutation VersionCheck($app: SemanticVersion, $bundle: SemanticVersion) {
    version {
      check(input: { app: $app, bundle: $bundle }) {
        ...VersionCheckVersionStatus
      }
    }
  }
  ${VersionCheckVersionStatusFragmentDoc}
`
export type VersionCheckMutationFn = Apollo.MutationFunction<
  VersionCheckMutation,
  VersionCheckMutationVariables
>

/**
 * __useVersionCheckMutation__
 *
 * To run a mutation, you first call `useVersionCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVersionCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [versionCheckMutation, { data, loading, error }] = useVersionCheckMutation({
 *   variables: {
 *      app: // value for 'app'
 *      bundle: // value for 'bundle'
 *   },
 * });
 */
export function useVersionCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VersionCheckMutation,
    VersionCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VersionCheckMutation,
    VersionCheckMutationVariables
  >(VersionCheckDocument, options)
}
export type VersionCheckMutationHookResult = ReturnType<
  typeof useVersionCheckMutation
>
export type VersionCheckMutationResult =
  Apollo.MutationResult<VersionCheckMutation>
export type VersionCheckMutationOptions = Apollo.BaseMutationOptions<
  VersionCheckMutation,
  VersionCheckMutationVariables
>
export const User__DeleteAccountMutationDocument = gql`
  mutation User__DeleteAccountMutation($id: ID!) {
    user {
      requestDeletion(input: { id: $id }) {
        user {
          id
          moderationStatus
        }
      }
    }
  }
`
export type User__DeleteAccountMutationMutationFn = Apollo.MutationFunction<
  User__DeleteAccountMutationMutation,
  User__DeleteAccountMutationMutationVariables
>

/**
 * __useUser__DeleteAccountMutationMutation__
 *
 * To run a mutation, you first call `useUser__DeleteAccountMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser__DeleteAccountMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteAccountMutationMutation, { data, loading, error }] = useUser__DeleteAccountMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUser__DeleteAccountMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    User__DeleteAccountMutationMutation,
    User__DeleteAccountMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    User__DeleteAccountMutationMutation,
    User__DeleteAccountMutationMutationVariables
  >(User__DeleteAccountMutationDocument, options)
}
export type User__DeleteAccountMutationMutationHookResult = ReturnType<
  typeof useUser__DeleteAccountMutationMutation
>
export type User__DeleteAccountMutationMutationResult =
  Apollo.MutationResult<User__DeleteAccountMutationMutation>
export type User__DeleteAccountMutationMutationOptions =
  Apollo.BaseMutationOptions<
    User__DeleteAccountMutationMutation,
    User__DeleteAccountMutationMutationVariables
  >
export const User__AccountPageQueryDocument = gql`
  query User__AccountPageQuery($auth0id: String) {
    user(auth0id: $auth0id) {
      id
    }
  }
`

/**
 * __useUser__AccountPageQueryQuery__
 *
 * To run a query within a React component, call `useUser__AccountPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUser__AccountPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUser__AccountPageQueryQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useUser__AccountPageQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    User__AccountPageQueryQuery,
    User__AccountPageQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    User__AccountPageQueryQuery,
    User__AccountPageQueryQueryVariables
  >(User__AccountPageQueryDocument, options)
}
export function useUser__AccountPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    User__AccountPageQueryQuery,
    User__AccountPageQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    User__AccountPageQueryQuery,
    User__AccountPageQueryQueryVariables
  >(User__AccountPageQueryDocument, options)
}
export type User__AccountPageQueryQueryHookResult = ReturnType<
  typeof useUser__AccountPageQueryQuery
>
export type User__AccountPageQueryLazyQueryHookResult = ReturnType<
  typeof useUser__AccountPageQueryLazyQuery
>
export type User__AccountPageQueryQueryResult = Apollo.QueryResult<
  User__AccountPageQueryQuery,
  User__AccountPageQueryQueryVariables
>
export const SetEventGroupDocument = gql`
  mutation SetEventGroup($eventId: ID!, $groupId: ID, $textBody: String) {
    event {
      setGroup(
        input: { eventId: $eventId, groupId: $groupId, textBody: $textBody }
      ) {
        groupPost {
          id
          textBody
        }
      }
    }
  }
`
export type SetEventGroupMutationFn = Apollo.MutationFunction<
  SetEventGroupMutation,
  SetEventGroupMutationVariables
>

/**
 * __useSetEventGroupMutation__
 *
 * To run a mutation, you first call `useSetEventGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventGroupMutation, { data, loading, error }] = useSetEventGroupMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      groupId: // value for 'groupId'
 *      textBody: // value for 'textBody'
 *   },
 * });
 */
export function useSetEventGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEventGroupMutation,
    SetEventGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetEventGroupMutation,
    SetEventGroupMutationVariables
  >(SetEventGroupDocument, options)
}
export type SetEventGroupMutationHookResult = ReturnType<
  typeof useSetEventGroupMutation
>
export type SetEventGroupMutationResult =
  Apollo.MutationResult<SetEventGroupMutation>
export type SetEventGroupMutationOptions = Apollo.BaseMutationOptions<
  SetEventGroupMutation,
  SetEventGroupMutationVariables
>
export const FetchEventGroupDocument = gql`
  query FetchEventGroup($databaseId: String, $status: TicketStatus) {
    event(databaseId: $databaseId) {
      draftGroupPost {
        group {
          id
          name
        }
        id
        textBody
      }
      id
      tickets(status: $status) {
        totalCount
      }
    }
  }
`

/**
 * __useFetchEventGroupQuery__
 *
 * To run a query within a React component, call `useFetchEventGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEventGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEventGroupQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFetchEventGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchEventGroupQuery,
    FetchEventGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchEventGroupQuery, FetchEventGroupQueryVariables>(
    FetchEventGroupDocument,
    options,
  )
}
export function useFetchEventGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchEventGroupQuery,
    FetchEventGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchEventGroupQuery,
    FetchEventGroupQueryVariables
  >(FetchEventGroupDocument, options)
}
export type FetchEventGroupQueryHookResult = ReturnType<
  typeof useFetchEventGroupQuery
>
export type FetchEventGroupLazyQueryHookResult = ReturnType<
  typeof useFetchEventGroupLazyQuery
>
export type FetchEventGroupQueryResult = Apollo.QueryResult<
  FetchEventGroupQuery,
  FetchEventGroupQueryVariables
>
export const EventDetailsCancelEventDocument = gql`
  mutation EventDetailsCancelEvent($id: ID!) {
    event {
      cancelEvent(input: { id: $id }) {
        event {
          ...EventDetailsEssentialEvent
        }
      }
    }
  }
  ${EventDetailsEssentialEventFragmentDoc}
`
export type EventDetailsCancelEventMutationFn = Apollo.MutationFunction<
  EventDetailsCancelEventMutation,
  EventDetailsCancelEventMutationVariables
>

/**
 * __useEventDetailsCancelEventMutation__
 *
 * To run a mutation, you first call `useEventDetailsCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventDetailsCancelEventMutation, { data, loading, error }] = useEventDetailsCancelEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventDetailsCancelEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventDetailsCancelEventMutation,
    EventDetailsCancelEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventDetailsCancelEventMutation,
    EventDetailsCancelEventMutationVariables
  >(EventDetailsCancelEventDocument, options)
}
export type EventDetailsCancelEventMutationHookResult = ReturnType<
  typeof useEventDetailsCancelEventMutation
>
export type EventDetailsCancelEventMutationResult =
  Apollo.MutationResult<EventDetailsCancelEventMutation>
export type EventDetailsCancelEventMutationOptions = Apollo.BaseMutationOptions<
  EventDetailsCancelEventMutation,
  EventDetailsCancelEventMutationVariables
>
export const GeocodeAddressDocument = gql`
  query geocodeAddress($address: String!) {
    geocodeAddress(address: $address) {
      latitude
      longitude
    }
  }
`

/**
 * __useGeocodeAddressQuery__
 *
 * To run a query within a React component, call `useGeocodeAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGeocodeAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeocodeAddressQuery,
    GeocodeAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(
    GeocodeAddressDocument,
    options,
  )
}
export function useGeocodeAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeocodeAddressQuery,
    GeocodeAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(
    GeocodeAddressDocument,
    options,
  )
}
export type GeocodeAddressQueryHookResult = ReturnType<
  typeof useGeocodeAddressQuery
>
export type GeocodeAddressLazyQueryHookResult = ReturnType<
  typeof useGeocodeAddressLazyQuery
>
export type GeocodeAddressQueryResult = Apollo.QueryResult<
  GeocodeAddressQuery,
  GeocodeAddressQueryVariables
>
export const GroupAboutDocument = gql`
  query GroupAbout(
    $membersBeforeCursor: String
    $moderatorsAfterCursor: String
    $slug: String!
  ) {
    group(slug: $slug) {
      about
      id
      isMember
      isModerator
      members: participants(
        connection: { last: 100, before: $membersBeforeCursor }
        roles: [MEMBER]
      ) {
        ...Members
      }
      moderators: participants(
        connection: { first: 100, after: $moderatorsAfterCursor }
        roles: [MODERATOR]
      ) {
        ...Moderators
      }
      name
    }
  }
  ${MembersFragmentDoc}
  ${ModeratorsFragmentDoc}
`

/**
 * __useGroupAboutQuery__
 *
 * To run a query within a React component, call `useGroupAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAboutQuery({
 *   variables: {
 *      membersBeforeCursor: // value for 'membersBeforeCursor'
 *      moderatorsAfterCursor: // value for 'moderatorsAfterCursor'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupAboutQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAboutQuery,
    GroupAboutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupAboutQuery, GroupAboutQueryVariables>(
    GroupAboutDocument,
    options,
  )
}
export function useGroupAboutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAboutQuery,
    GroupAboutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupAboutQuery, GroupAboutQueryVariables>(
    GroupAboutDocument,
    options,
  )
}
export type GroupAboutQueryHookResult = ReturnType<typeof useGroupAboutQuery>
export type GroupAboutLazyQueryHookResult = ReturnType<
  typeof useGroupAboutLazyQuery
>
export type GroupAboutQueryResult = Apollo.QueryResult<
  GroupAboutQuery,
  GroupAboutQueryVariables
>
export const GroupDetailLeaveGroupDocument = gql`
  mutation GroupDetailLeaveGroup($groupId: ID!) {
    group {
      leave(input: { groupId: $groupId }) {
        group {
          databaseId
          id
          isMember
          isModerator
          ...MyGroupsProviderGroup
          name
        }
        user {
          auth0Id
          id
        }
      }
    }
  }
  ${MyGroupsProviderGroupFragmentDoc}
`
export type GroupDetailLeaveGroupMutationFn = Apollo.MutationFunction<
  GroupDetailLeaveGroupMutation,
  GroupDetailLeaveGroupMutationVariables
>

/**
 * __useGroupDetailLeaveGroupMutation__
 *
 * To run a mutation, you first call `useGroupDetailLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailLeaveGroupMutation, { data, loading, error }] = useGroupDetailLeaveGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupDetailLeaveGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailLeaveGroupMutation,
    GroupDetailLeaveGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupDetailLeaveGroupMutation,
    GroupDetailLeaveGroupMutationVariables
  >(GroupDetailLeaveGroupDocument, options)
}
export type GroupDetailLeaveGroupMutationHookResult = ReturnType<
  typeof useGroupDetailLeaveGroupMutation
>
export type GroupDetailLeaveGroupMutationResult =
  Apollo.MutationResult<GroupDetailLeaveGroupMutation>
export type GroupDetailLeaveGroupMutationOptions = Apollo.BaseMutationOptions<
  GroupDetailLeaveGroupMutation,
  GroupDetailLeaveGroupMutationVariables
>
export const GroupDiscussionAnchoredPostDocument = gql`
  query GroupDiscussionAnchoredPost(
    $anchoredCommentSequenceId: Int!
    $anchoredNestedCommentSequenceId: Int!
    $anchoredPostSequenceId: Int!
    $fetchAnchoredComment: Boolean!
    $fetchAnchoredNestedComment: Boolean!
    $slug: String!
  ) {
    comment: groupPost(
      input: {
        sequence: { groupSlug: $slug, sequenceId: $anchoredCommentSequenceId }
      }
    ) @include(if: $fetchAnchoredComment) {
      ...GroupDiscussionAnchoredPostComment
    }
    group(slug: $slug) {
      ...GroupDiscussionAnchoredPostGroup
    }
    nestedComment: groupPost(
      input: {
        sequence: {
          groupSlug: $slug
          sequenceId: $anchoredNestedCommentSequenceId
        }
      }
    ) @include(if: $fetchAnchoredNestedComment) {
      ...GroupDiscussionAnchoredPostNestedComment
    }
    post: groupPost(
      input: {
        sequence: { groupSlug: $slug, sequenceId: $anchoredPostSequenceId }
      }
    ) {
      ...GroupDiscussionAnchoredPostPost
    }
  }
  ${GroupDiscussionAnchoredPostCommentFragmentDoc}
  ${GroupDiscussionAnchoredPostGroupFragmentDoc}
  ${GroupDiscussionAnchoredPostNestedCommentFragmentDoc}
  ${GroupDiscussionAnchoredPostPostFragmentDoc}
`

/**
 * __useGroupDiscussionAnchoredPostQuery__
 *
 * To run a query within a React component, call `useGroupDiscussionAnchoredPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDiscussionAnchoredPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDiscussionAnchoredPostQuery({
 *   variables: {
 *      anchoredCommentSequenceId: // value for 'anchoredCommentSequenceId'
 *      anchoredNestedCommentSequenceId: // value for 'anchoredNestedCommentSequenceId'
 *      anchoredPostSequenceId: // value for 'anchoredPostSequenceId'
 *      fetchAnchoredComment: // value for 'fetchAnchoredComment'
 *      fetchAnchoredNestedComment: // value for 'fetchAnchoredNestedComment'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupDiscussionAnchoredPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDiscussionAnchoredPostQuery,
    GroupDiscussionAnchoredPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GroupDiscussionAnchoredPostQuery,
    GroupDiscussionAnchoredPostQueryVariables
  >(GroupDiscussionAnchoredPostDocument, options)
}
export function useGroupDiscussionAnchoredPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDiscussionAnchoredPostQuery,
    GroupDiscussionAnchoredPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GroupDiscussionAnchoredPostQuery,
    GroupDiscussionAnchoredPostQueryVariables
  >(GroupDiscussionAnchoredPostDocument, options)
}
export type GroupDiscussionAnchoredPostQueryHookResult = ReturnType<
  typeof useGroupDiscussionAnchoredPostQuery
>
export type GroupDiscussionAnchoredPostLazyQueryHookResult = ReturnType<
  typeof useGroupDiscussionAnchoredPostLazyQuery
>
export type GroupDiscussionAnchoredPostQueryResult = Apollo.QueryResult<
  GroupDiscussionAnchoredPostQuery,
  GroupDiscussionAnchoredPostQueryVariables
>
export const GroupDiscussion__FeedDocument = gql`
  query GroupDiscussion__Feed(
    $connection: ConnectionInput!
    $orders: [GroupPostOrderInput!]
    $slug: String!
  ) {
    group(slug: $slug) {
      discussionFeed(connection: $connection, orders: $orders) {
        ...DiscussionFeedFragment
        edges {
          node {
            createdAt
            id
            lastActivityAt
          }
        }
      }
      id
    }
  }
  ${DiscussionFeedFragmentFragmentDoc}
`

/**
 * __useGroupDiscussion__FeedQuery__
 *
 * To run a query within a React component, call `useGroupDiscussion__FeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDiscussion__FeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDiscussion__FeedQuery({
 *   variables: {
 *      connection: // value for 'connection'
 *      orders: // value for 'orders'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupDiscussion__FeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDiscussion__FeedQuery,
    GroupDiscussion__FeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GroupDiscussion__FeedQuery,
    GroupDiscussion__FeedQueryVariables
  >(GroupDiscussion__FeedDocument, options)
}
export function useGroupDiscussion__FeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDiscussion__FeedQuery,
    GroupDiscussion__FeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GroupDiscussion__FeedQuery,
    GroupDiscussion__FeedQueryVariables
  >(GroupDiscussion__FeedDocument, options)
}
export type GroupDiscussion__FeedQueryHookResult = ReturnType<
  typeof useGroupDiscussion__FeedQuery
>
export type GroupDiscussion__FeedLazyQueryHookResult = ReturnType<
  typeof useGroupDiscussion__FeedLazyQuery
>
export type GroupDiscussion__FeedQueryResult = Apollo.QueryResult<
  GroupDiscussion__FeedQuery,
  GroupDiscussion__FeedQueryVariables
>
export const Group__DiscussionDocument = gql`
  query Group__Discussion($postBeforeCursor: String, $slug: String!) {
    group(slug: $slug) {
      discussionFeed(connection: { last: 3, before: $postBeforeCursor }) {
        ...DiscussionFeedFragment
        nodes {
          id
        }
      }
      id
      isPrivate
    }
  }
  ${DiscussionFeedFragmentFragmentDoc}
`

/**
 * __useGroup__DiscussionQuery__
 *
 * To run a query within a React component, call `useGroup__DiscussionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroup__DiscussionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroup__DiscussionQuery({
 *   variables: {
 *      postBeforeCursor: // value for 'postBeforeCursor'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroup__DiscussionQuery(
  baseOptions: Apollo.QueryHookOptions<
    Group__DiscussionQuery,
    Group__DiscussionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Group__DiscussionQuery,
    Group__DiscussionQueryVariables
  >(Group__DiscussionDocument, options)
}
export function useGroup__DiscussionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Group__DiscussionQuery,
    Group__DiscussionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Group__DiscussionQuery,
    Group__DiscussionQueryVariables
  >(Group__DiscussionDocument, options)
}
export type Group__DiscussionQueryHookResult = ReturnType<
  typeof useGroup__DiscussionQuery
>
export type Group__DiscussionLazyQueryHookResult = ReturnType<
  typeof useGroup__DiscussionLazyQuery
>
export type Group__DiscussionQueryResult = Apollo.QueryResult<
  Group__DiscussionQuery,
  Group__DiscussionQueryVariables
>
export const GroupEventsDocument = gql`
  query GroupEvents(
    $previousEventsCursor: String
    $slug: String!
    $upcomingEventsCursor: String
  ) {
    group(slug: $slug) {
      id
      isMember
      isPrivate
      previousEvents: events(
        connection: { last: 3, before: $previousEventsCursor }
        states: [COMPLETED]
      ) {
        nodes {
          id
          name
          slug
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      upcomingEvents: events(
        connection: { first: 3, after: $upcomingEventsCursor }
        states: [LIVE]
      ) {
        nodes {
          id
          name
          slug
          startsAt
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

/**
 * __useGroupEventsQuery__
 *
 * To run a query within a React component, call `useGroupEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupEventsQuery({
 *   variables: {
 *      previousEventsCursor: // value for 'previousEventsCursor'
 *      slug: // value for 'slug'
 *      upcomingEventsCursor: // value for 'upcomingEventsCursor'
 *   },
 * });
 */
export function useGroupEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupEventsQuery,
    GroupEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupEventsQuery, GroupEventsQueryVariables>(
    GroupEventsDocument,
    options,
  )
}
export function useGroupEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupEventsQuery,
    GroupEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupEventsQuery, GroupEventsQueryVariables>(
    GroupEventsDocument,
    options,
  )
}
export type GroupEventsQueryHookResult = ReturnType<typeof useGroupEventsQuery>
export type GroupEventsLazyQueryHookResult = ReturnType<
  typeof useGroupEventsLazyQuery
>
export type GroupEventsQueryResult = Apollo.QueryResult<
  GroupEventsQuery,
  GroupEventsQueryVariables
>
export const GroupDetailHeaderJoinGroupDocument = gql`
  mutation GroupDetailHeaderJoinGroup($groupId: ID!) {
    group {
      join(input: { groupId: $groupId }) {
        createdGroupParticipant {
          id
          user {
            auth0Id
            id
          }
        }
        group {
          databaseId
          discussionFeed(connection: { last: 10, before: null }) {
            edges {
              cursor
              node {
                id
                totalCommentCount
              }
            }
          }
          id
          isMember
          isModerator
          ...MyGroupsProviderGroup
          name
        }
      }
    }
  }
  ${MyGroupsProviderGroupFragmentDoc}
`
export type GroupDetailHeaderJoinGroupMutationFn = Apollo.MutationFunction<
  GroupDetailHeaderJoinGroupMutation,
  GroupDetailHeaderJoinGroupMutationVariables
>

/**
 * __useGroupDetailHeaderJoinGroupMutation__
 *
 * To run a mutation, you first call `useGroupDetailHeaderJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailHeaderJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailHeaderJoinGroupMutation, { data, loading, error }] = useGroupDetailHeaderJoinGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupDetailHeaderJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailHeaderJoinGroupMutation,
    GroupDetailHeaderJoinGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupDetailHeaderJoinGroupMutation,
    GroupDetailHeaderJoinGroupMutationVariables
  >(GroupDetailHeaderJoinGroupDocument, options)
}
export type GroupDetailHeaderJoinGroupMutationHookResult = ReturnType<
  typeof useGroupDetailHeaderJoinGroupMutation
>
export type GroupDetailHeaderJoinGroupMutationResult =
  Apollo.MutationResult<GroupDetailHeaderJoinGroupMutation>
export type GroupDetailHeaderJoinGroupMutationOptions =
  Apollo.BaseMutationOptions<
    GroupDetailHeaderJoinGroupMutation,
    GroupDetailHeaderJoinGroupMutationVariables
  >
export const GroupQuestionsDocument = gql`
  query GroupQuestions($id: ID!, $questionsAfterCursor: String) {
    group(id: $id) {
      ...GroupQuestionsCompleteGroup
    }
  }
  ${GroupQuestionsCompleteGroupFragmentDoc}
`

/**
 * __useGroupQuestionsQuery__
 *
 * To run a query within a React component, call `useGroupQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      questionsAfterCursor: // value for 'questionsAfterCursor'
 *   },
 * });
 */
export function useGroupQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupQuestionsQuery,
    GroupQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupQuestionsQuery, GroupQuestionsQueryVariables>(
    GroupQuestionsDocument,
    options,
  )
}
export function useGroupQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupQuestionsQuery,
    GroupQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupQuestionsQuery, GroupQuestionsQueryVariables>(
    GroupQuestionsDocument,
    options,
  )
}
export type GroupQuestionsQueryHookResult = ReturnType<
  typeof useGroupQuestionsQuery
>
export type GroupQuestionsLazyQueryHookResult = ReturnType<
  typeof useGroupQuestionsLazyQuery
>
export type GroupQuestionsQueryResult = Apollo.QueryResult<
  GroupQuestionsQuery,
  GroupQuestionsQueryVariables
>
export const GroupDetailRequestToJoinGroupDocument = gql`
  mutation GroupDetailRequestToJoinGroup(
    $answers: [GroupQuestionAnswerInput!]
    $groupId: ID!
  ) {
    group {
      join(input: { groupId: $groupId, answers: $answers }) {
        createdGroupParticipant {
          id
          user {
            auth0Id
            id
          }
        }
        group {
          databaseId
          discussionFeed(connection: { last: 10, before: null }) {
            edges {
              cursor
              node {
                id
                totalCommentCount
              }
            }
          }
          id
          isMember
          isModerator
          ...MyGroupsProviderGroup
          name
        }
      }
    }
  }
  ${MyGroupsProviderGroupFragmentDoc}
`
export type GroupDetailRequestToJoinGroupMutationFn = Apollo.MutationFunction<
  GroupDetailRequestToJoinGroupMutation,
  GroupDetailRequestToJoinGroupMutationVariables
>

/**
 * __useGroupDetailRequestToJoinGroupMutation__
 *
 * To run a mutation, you first call `useGroupDetailRequestToJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailRequestToJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailRequestToJoinGroupMutation, { data, loading, error }] = useGroupDetailRequestToJoinGroupMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupDetailRequestToJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailRequestToJoinGroupMutation,
    GroupDetailRequestToJoinGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupDetailRequestToJoinGroupMutation,
    GroupDetailRequestToJoinGroupMutationVariables
  >(GroupDetailRequestToJoinGroupDocument, options)
}
export type GroupDetailRequestToJoinGroupMutationHookResult = ReturnType<
  typeof useGroupDetailRequestToJoinGroupMutation
>
export type GroupDetailRequestToJoinGroupMutationResult =
  Apollo.MutationResult<GroupDetailRequestToJoinGroupMutation>
export type GroupDetailRequestToJoinGroupMutationOptions =
  Apollo.BaseMutationOptions<
    GroupDetailRequestToJoinGroupMutation,
    GroupDetailRequestToJoinGroupMutationVariables
  >
export const GroupDetailApproveRequestToJoinGroupDocument = gql`
  mutation GroupDetailApproveRequestToJoinGroup($groupId: ID!, $userId: ID!) {
    group {
      approveMemberRequestToJoin(
        input: { groupId: $groupId, userId: $userId }
      ) {
        groupParticipant {
          id
        }
      }
    }
  }
`
export type GroupDetailApproveRequestToJoinGroupMutationFn =
  Apollo.MutationFunction<
    GroupDetailApproveRequestToJoinGroupMutation,
    GroupDetailApproveRequestToJoinGroupMutationVariables
  >

/**
 * __useGroupDetailApproveRequestToJoinGroupMutation__
 *
 * To run a mutation, you first call `useGroupDetailApproveRequestToJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailApproveRequestToJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailApproveRequestToJoinGroupMutation, { data, loading, error }] = useGroupDetailApproveRequestToJoinGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGroupDetailApproveRequestToJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailApproveRequestToJoinGroupMutation,
    GroupDetailApproveRequestToJoinGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupDetailApproveRequestToJoinGroupMutation,
    GroupDetailApproveRequestToJoinGroupMutationVariables
  >(GroupDetailApproveRequestToJoinGroupDocument, options)
}
export type GroupDetailApproveRequestToJoinGroupMutationHookResult = ReturnType<
  typeof useGroupDetailApproveRequestToJoinGroupMutation
>
export type GroupDetailApproveRequestToJoinGroupMutationResult =
  Apollo.MutationResult<GroupDetailApproveRequestToJoinGroupMutation>
export type GroupDetailApproveRequestToJoinGroupMutationOptions =
  Apollo.BaseMutationOptions<
    GroupDetailApproveRequestToJoinGroupMutation,
    GroupDetailApproveRequestToJoinGroupMutationVariables
  >
export const GroupDetailMemberQuestionAnswersDocument = gql`
  query GroupDetailMemberQuestionAnswers(
    $answersAfterCursor: String
    $id: ID!
  ) {
    groupParticipant(id: $id) {
      ...GroupDetailPendingParticipantAnswers
    }
  }
  ${GroupDetailPendingParticipantAnswersFragmentDoc}
`

/**
 * __useGroupDetailMemberQuestionAnswersQuery__
 *
 * To run a query within a React component, call `useGroupDetailMemberQuestionAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailMemberQuestionAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailMemberQuestionAnswersQuery({
 *   variables: {
 *      answersAfterCursor: // value for 'answersAfterCursor'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupDetailMemberQuestionAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDetailMemberQuestionAnswersQuery,
    GroupDetailMemberQuestionAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GroupDetailMemberQuestionAnswersQuery,
    GroupDetailMemberQuestionAnswersQueryVariables
  >(GroupDetailMemberQuestionAnswersDocument, options)
}
export function useGroupDetailMemberQuestionAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDetailMemberQuestionAnswersQuery,
    GroupDetailMemberQuestionAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GroupDetailMemberQuestionAnswersQuery,
    GroupDetailMemberQuestionAnswersQueryVariables
  >(GroupDetailMemberQuestionAnswersDocument, options)
}
export type GroupDetailMemberQuestionAnswersQueryHookResult = ReturnType<
  typeof useGroupDetailMemberQuestionAnswersQuery
>
export type GroupDetailMemberQuestionAnswersLazyQueryHookResult = ReturnType<
  typeof useGroupDetailMemberQuestionAnswersLazyQuery
>
export type GroupDetailMemberQuestionAnswersQueryResult = Apollo.QueryResult<
  GroupDetailMemberQuestionAnswersQuery,
  GroupDetailMemberQuestionAnswersQueryVariables
>
export const GroupDetailRejectRequestToJoinGroupDocument = gql`
  mutation GroupDetailRejectRequestToJoinGroup($groupId: ID!, $userId: ID!) {
    group {
      rejectMemberRequestToJoin(input: { groupId: $groupId, userId: $userId }) {
        groupParticipant {
          id
        }
      }
    }
  }
`
export type GroupDetailRejectRequestToJoinGroupMutationFn =
  Apollo.MutationFunction<
    GroupDetailRejectRequestToJoinGroupMutation,
    GroupDetailRejectRequestToJoinGroupMutationVariables
  >

/**
 * __useGroupDetailRejectRequestToJoinGroupMutation__
 *
 * To run a mutation, you first call `useGroupDetailRejectRequestToJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailRejectRequestToJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDetailRejectRequestToJoinGroupMutation, { data, loading, error }] = useGroupDetailRejectRequestToJoinGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGroupDetailRejectRequestToJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDetailRejectRequestToJoinGroupMutation,
    GroupDetailRejectRequestToJoinGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GroupDetailRejectRequestToJoinGroupMutation,
    GroupDetailRejectRequestToJoinGroupMutationVariables
  >(GroupDetailRejectRequestToJoinGroupDocument, options)
}
export type GroupDetailRejectRequestToJoinGroupMutationHookResult = ReturnType<
  typeof useGroupDetailRejectRequestToJoinGroupMutation
>
export type GroupDetailRejectRequestToJoinGroupMutationResult =
  Apollo.MutationResult<GroupDetailRejectRequestToJoinGroupMutation>
export type GroupDetailRejectRequestToJoinGroupMutationOptions =
  Apollo.BaseMutationOptions<
    GroupDetailRejectRequestToJoinGroupMutation,
    GroupDetailRejectRequestToJoinGroupMutationVariables
  >
export const GroupDetailMembersTabDocument = gql`
  query GroupDetailMembersTab(
    $pendingParticipantsAfterCursor: String
    $slug: String!
  ) {
    group(slug: $slug) {
      ...GroupDetailPendingParticipants
    }
  }
  ${GroupDetailPendingParticipantsFragmentDoc}
`

/**
 * __useGroupDetailMembersTabQuery__
 *
 * To run a query within a React component, call `useGroupDetailMembersTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailMembersTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailMembersTabQuery({
 *   variables: {
 *      pendingParticipantsAfterCursor: // value for 'pendingParticipantsAfterCursor'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupDetailMembersTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDetailMembersTabQuery,
    GroupDetailMembersTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GroupDetailMembersTabQuery,
    GroupDetailMembersTabQueryVariables
  >(GroupDetailMembersTabDocument, options)
}
export function useGroupDetailMembersTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDetailMembersTabQuery,
    GroupDetailMembersTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GroupDetailMembersTabQuery,
    GroupDetailMembersTabQueryVariables
  >(GroupDetailMembersTabDocument, options)
}
export type GroupDetailMembersTabQueryHookResult = ReturnType<
  typeof useGroupDetailMembersTabQuery
>
export type GroupDetailMembersTabLazyQueryHookResult = ReturnType<
  typeof useGroupDetailMembersTabLazyQuery
>
export type GroupDetailMembersTabQueryResult = Apollo.QueryResult<
  GroupDetailMembersTabQuery,
  GroupDetailMembersTabQueryVariables
>
export const GroupDetailDocument = gql`
  query GroupDetail(
    $moderatorsAfterCursor: String
    $participantsAfterCursor: String
    $pendingParticipantsAfterCursor: String
    $slug: String!
  ) {
    group(slug: $slug) {
      ...GroupDetailCompleteGroup
    }
  }
  ${GroupDetailCompleteGroupFragmentDoc}
`

/**
 * __useGroupDetailQuery__
 *
 * To run a query within a React component, call `useGroupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailQuery({
 *   variables: {
 *      moderatorsAfterCursor: // value for 'moderatorsAfterCursor'
 *      participantsAfterCursor: // value for 'participantsAfterCursor'
 *      pendingParticipantsAfterCursor: // value for 'pendingParticipantsAfterCursor'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDetailQuery,
    GroupDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupDetailQuery, GroupDetailQueryVariables>(
    GroupDetailDocument,
    options,
  )
}
export function useGroupDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDetailQuery,
    GroupDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupDetailQuery, GroupDetailQueryVariables>(
    GroupDetailDocument,
    options,
  )
}
export type GroupDetailQueryHookResult = ReturnType<typeof useGroupDetailQuery>
export type GroupDetailLazyQueryHookResult = ReturnType<
  typeof useGroupDetailLazyQuery
>
export type GroupDetailQueryResult = Apollo.QueryResult<
  GroupDetailQuery,
  GroupDetailQueryVariables
>
export const GroupsDocument = gql`
  query Groups($groupAfterCursor: String) {
    groups(
      connection: { first: 20, after: $groupAfterCursor }
      orders: [
        { by: PIN_ORDER, direction: ASCENDING, nulls: LAST }
        { by: NAME, direction: ASCENDING }
      ]
    ) {
      nodes {
        ...GroupFragment
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${GroupFragmentFragmentDoc}
`

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      groupAfterCursor: // value for 'groupAfterCursor'
 *   },
 * });
 */
export function useGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    options,
  )
}
export function useGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    options,
  )
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>
export type GroupsQueryResult = Apollo.QueryResult<
  GroupsQuery,
  GroupsQueryVariables
>
export const HomeLeftPanelDocument = gql`
  query HomeLeftPanel {
    currentUser {
      ...HomeLeftPanelCurrentUser
    }
  }
  ${HomeLeftPanelCurrentUserFragmentDoc}
`

/**
 * __useHomeLeftPanelQuery__
 *
 * To run a query within a React component, call `useHomeLeftPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLeftPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLeftPanelQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeLeftPanelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeLeftPanelQuery,
    HomeLeftPanelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HomeLeftPanelQuery, HomeLeftPanelQueryVariables>(
    HomeLeftPanelDocument,
    options,
  )
}
export function useHomeLeftPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeLeftPanelQuery,
    HomeLeftPanelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HomeLeftPanelQuery, HomeLeftPanelQueryVariables>(
    HomeLeftPanelDocument,
    options,
  )
}
export type HomeLeftPanelQueryHookResult = ReturnType<
  typeof useHomeLeftPanelQuery
>
export type HomeLeftPanelLazyQueryHookResult = ReturnType<
  typeof useHomeLeftPanelLazyQuery
>
export type HomeLeftPanelQueryResult = Apollo.QueryResult<
  HomeLeftPanelQuery,
  HomeLeftPanelQueryVariables
>
export const MyGroupsDiscussionFeedDocument = gql`
  query MyGroupsDiscussionFeed(
    $authoredById: ID
    $connection: ConnectionInput!
    $membershipStatus: GroupMembershipStatus!
    $orders: [GroupPostOrderInput!]
  ) {
    groupDiscussionFeed(
      authoredById: $authoredById
      connection: $connection
      membershipStatus: $membershipStatus
      orders: $orders
    ) {
      ...DiscussionFeedFragment
    }
  }
  ${DiscussionFeedFragmentFragmentDoc}
`

/**
 * __useMyGroupsDiscussionFeedQuery__
 *
 * To run a query within a React component, call `useMyGroupsDiscussionFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGroupsDiscussionFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGroupsDiscussionFeedQuery({
 *   variables: {
 *      authoredById: // value for 'authoredById'
 *      connection: // value for 'connection'
 *      membershipStatus: // value for 'membershipStatus'
 *      orders: // value for 'orders'
 *   },
 * });
 */
export function useMyGroupsDiscussionFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyGroupsDiscussionFeedQuery,
    MyGroupsDiscussionFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MyGroupsDiscussionFeedQuery,
    MyGroupsDiscussionFeedQueryVariables
  >(MyGroupsDiscussionFeedDocument, options)
}
export function useMyGroupsDiscussionFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyGroupsDiscussionFeedQuery,
    MyGroupsDiscussionFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MyGroupsDiscussionFeedQuery,
    MyGroupsDiscussionFeedQueryVariables
  >(MyGroupsDiscussionFeedDocument, options)
}
export type MyGroupsDiscussionFeedQueryHookResult = ReturnType<
  typeof useMyGroupsDiscussionFeedQuery
>
export type MyGroupsDiscussionFeedLazyQueryHookResult = ReturnType<
  typeof useMyGroupsDiscussionFeedLazyQuery
>
export type MyGroupsDiscussionFeedQueryResult = Apollo.QueryResult<
  MyGroupsDiscussionFeedQuery,
  MyGroupsDiscussionFeedQueryVariables
>
export const RsvpDocument = gql`
  mutation RSVP($eventId: ID!) {
    event {
      RSVP(input: { eventId: $eventId }) {
        ticket {
          id
        }
      }
    }
  }
`
export type RsvpMutationFn = Apollo.MutationFunction<
  RsvpMutation,
  RsvpMutationVariables
>

/**
 * __useRsvpMutation__
 *
 * To run a mutation, you first call `useRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rsvpMutation, { data, loading, error }] = useRsvpMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRsvpMutation(
  baseOptions?: Apollo.MutationHookOptions<RsvpMutation, RsvpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RsvpMutation, RsvpMutationVariables>(
    RsvpDocument,
    options,
  )
}
export type RsvpMutationHookResult = ReturnType<typeof useRsvpMutation>
export type RsvpMutationResult = Apollo.MutationResult<RsvpMutation>
export type RsvpMutationOptions = Apollo.BaseMutationOptions<
  RsvpMutation,
  RsvpMutationVariables
>
export const FetchLifeTransitionsDocument = gql`
  query FetchLifeTransitions($lifeTransitionAfterCursor: String) {
    lifeTransitions(
      connection: { first: 25, after: $lifeTransitionAfterCursor }
    ) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

/**
 * __useFetchLifeTransitionsQuery__
 *
 * To run a query within a React component, call `useFetchLifeTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLifeTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLifeTransitionsQuery({
 *   variables: {
 *      lifeTransitionAfterCursor: // value for 'lifeTransitionAfterCursor'
 *   },
 * });
 */
export function useFetchLifeTransitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchLifeTransitionsQuery,
    FetchLifeTransitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchLifeTransitionsQuery,
    FetchLifeTransitionsQueryVariables
  >(FetchLifeTransitionsDocument, options)
}
export function useFetchLifeTransitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchLifeTransitionsQuery,
    FetchLifeTransitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchLifeTransitionsQuery,
    FetchLifeTransitionsQueryVariables
  >(FetchLifeTransitionsDocument, options)
}
export type FetchLifeTransitionsQueryHookResult = ReturnType<
  typeof useFetchLifeTransitionsQuery
>
export type FetchLifeTransitionsLazyQueryHookResult = ReturnType<
  typeof useFetchLifeTransitionsLazyQuery
>
export type FetchLifeTransitionsQueryResult = Apollo.QueryResult<
  FetchLifeTransitionsQuery,
  FetchLifeTransitionsQueryVariables
>
export const FetchMyLifeTransitionsDocument = gql`
  query FetchMyLifeTransitions($lifeTransitionAfterCursor: String) {
    currentUser {
      lifeTransitions(
        connection: { first: 25, after: $lifeTransitionAfterCursor }
      ) {
        nodes {
          id
          name
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

/**
 * __useFetchMyLifeTransitionsQuery__
 *
 * To run a query within a React component, call `useFetchMyLifeTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyLifeTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyLifeTransitionsQuery({
 *   variables: {
 *      lifeTransitionAfterCursor: // value for 'lifeTransitionAfterCursor'
 *   },
 * });
 */
export function useFetchMyLifeTransitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMyLifeTransitionsQuery,
    FetchMyLifeTransitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMyLifeTransitionsQuery,
    FetchMyLifeTransitionsQueryVariables
  >(FetchMyLifeTransitionsDocument, options)
}
export function useFetchMyLifeTransitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMyLifeTransitionsQuery,
    FetchMyLifeTransitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMyLifeTransitionsQuery,
    FetchMyLifeTransitionsQueryVariables
  >(FetchMyLifeTransitionsDocument, options)
}
export type FetchMyLifeTransitionsQueryHookResult = ReturnType<
  typeof useFetchMyLifeTransitionsQuery
>
export type FetchMyLifeTransitionsLazyQueryHookResult = ReturnType<
  typeof useFetchMyLifeTransitionsLazyQuery
>
export type FetchMyLifeTransitionsQueryResult = Apollo.QueryResult<
  FetchMyLifeTransitionsQuery,
  FetchMyLifeTransitionsQueryVariables
>
export const FetchRecommendedEventsDocument = gql`
  query FetchRecommendedEvents {
    recommendedEvents(isFree: true) {
      city
      endsAt
      eventLocationTypeId: locationTypeId
      hostships(connection: { first: 10, after: null }) {
        nodes {
          id
          user {
            fullName
            id
            picture: profilePictureUrl
            shortName
          }
        }
      }
      id
      image
      name
      startsAt
      state
    }
  }
`

/**
 * __useFetchRecommendedEventsQuery__
 *
 * To run a query within a React component, call `useFetchRecommendedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecommendedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecommendedEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRecommendedEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRecommendedEventsQuery,
    FetchRecommendedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchRecommendedEventsQuery,
    FetchRecommendedEventsQueryVariables
  >(FetchRecommendedEventsDocument, options)
}
export function useFetchRecommendedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRecommendedEventsQuery,
    FetchRecommendedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchRecommendedEventsQuery,
    FetchRecommendedEventsQueryVariables
  >(FetchRecommendedEventsDocument, options)
}
export type FetchRecommendedEventsQueryHookResult = ReturnType<
  typeof useFetchRecommendedEventsQuery
>
export type FetchRecommendedEventsLazyQueryHookResult = ReturnType<
  typeof useFetchRecommendedEventsLazyQuery
>
export type FetchRecommendedEventsQueryResult = Apollo.QueryResult<
  FetchRecommendedEventsQuery,
  FetchRecommendedEventsQueryVariables
>
export const FetchRecommendedGroupsDocument = gql`
  query FetchRecommendedGroups {
    recommendedGroups {
      curators: participants(
        connection: { first: 10, after: null }
        roles: [MODERATOR]
      ) {
        nodes {
          id
          user {
            fullName
            id
            picture: profilePictureUrl
            shortName
          }
        }
      }
      databaseId
      id
      isMember
      name
      participants(connection: { first: 10, after: null }) {
        totalCount
      }
      pictureUrl
      slug
    }
  }
`

/**
 * __useFetchRecommendedGroupsQuery__
 *
 * To run a query within a React component, call `useFetchRecommendedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecommendedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecommendedGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRecommendedGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRecommendedGroupsQuery,
    FetchRecommendedGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchRecommendedGroupsQuery,
    FetchRecommendedGroupsQueryVariables
  >(FetchRecommendedGroupsDocument, options)
}
export function useFetchRecommendedGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRecommendedGroupsQuery,
    FetchRecommendedGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchRecommendedGroupsQuery,
    FetchRecommendedGroupsQueryVariables
  >(FetchRecommendedGroupsDocument, options)
}
export type FetchRecommendedGroupsQueryHookResult = ReturnType<
  typeof useFetchRecommendedGroupsQuery
>
export type FetchRecommendedGroupsLazyQueryHookResult = ReturnType<
  typeof useFetchRecommendedGroupsLazyQuery
>
export type FetchRecommendedGroupsQueryResult = Apollo.QueryResult<
  FetchRecommendedGroupsQuery,
  FetchRecommendedGroupsQueryVariables
>
export const FetchUserJoinedFromDocument = gql`
  query FetchUserJoinedFrom {
    currentUser {
      id
      joinedFromPath
    }
  }
`

/**
 * __useFetchUserJoinedFromQuery__
 *
 * To run a query within a React component, call `useFetchUserJoinedFromQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserJoinedFromQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserJoinedFromQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserJoinedFromQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchUserJoinedFromQuery,
    FetchUserJoinedFromQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchUserJoinedFromQuery,
    FetchUserJoinedFromQueryVariables
  >(FetchUserJoinedFromDocument, options)
}
export function useFetchUserJoinedFromLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserJoinedFromQuery,
    FetchUserJoinedFromQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchUserJoinedFromQuery,
    FetchUserJoinedFromQueryVariables
  >(FetchUserJoinedFromDocument, options)
}
export type FetchUserJoinedFromQueryHookResult = ReturnType<
  typeof useFetchUserJoinedFromQuery
>
export type FetchUserJoinedFromLazyQueryHookResult = ReturnType<
  typeof useFetchUserJoinedFromLazyQuery
>
export type FetchUserJoinedFromQueryResult = Apollo.QueryResult<
  FetchUserJoinedFromQuery,
  FetchUserJoinedFromQueryVariables
>
export const PostJoinModalDocument = gql`
  query PostJoinModal {
    currentUser {
      ...PostJoinModalCurrentUser
    }
  }
  ${PostJoinModalCurrentUserFragmentDoc}
`

/**
 * __usePostJoinModalQuery__
 *
 * To run a query within a React component, call `usePostJoinModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostJoinModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostJoinModalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostJoinModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PostJoinModalQuery,
    PostJoinModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostJoinModalQuery, PostJoinModalQueryVariables>(
    PostJoinModalDocument,
    options,
  )
}
export function usePostJoinModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostJoinModalQuery,
    PostJoinModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostJoinModalQuery, PostJoinModalQueryVariables>(
    PostJoinModalDocument,
    options,
  )
}
export type PostJoinModalQueryHookResult = ReturnType<
  typeof usePostJoinModalQuery
>
export type PostJoinModalLazyQueryHookResult = ReturnType<
  typeof usePostJoinModalLazyQuery
>
export type PostJoinModalQueryResult = Apollo.QueryResult<
  PostJoinModalQuery,
  PostJoinModalQueryVariables
>
export const MarkPostJoinModalAsSeenDocument = gql`
  mutation MarkPostJoinModalAsSeen {
    user {
      markPostJoinModalAsSeen {
        user {
          id
          postJoinModalSeenAt
        }
      }
    }
  }
`
export type MarkPostJoinModalAsSeenMutationFn = Apollo.MutationFunction<
  MarkPostJoinModalAsSeenMutation,
  MarkPostJoinModalAsSeenMutationVariables
>

/**
 * __useMarkPostJoinModalAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkPostJoinModalAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPostJoinModalAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPostJoinModalAsSeenMutation, { data, loading, error }] = useMarkPostJoinModalAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkPostJoinModalAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPostJoinModalAsSeenMutation,
    MarkPostJoinModalAsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkPostJoinModalAsSeenMutation,
    MarkPostJoinModalAsSeenMutationVariables
  >(MarkPostJoinModalAsSeenDocument, options)
}
export type MarkPostJoinModalAsSeenMutationHookResult = ReturnType<
  typeof useMarkPostJoinModalAsSeenMutation
>
export type MarkPostJoinModalAsSeenMutationResult =
  Apollo.MutationResult<MarkPostJoinModalAsSeenMutation>
export type MarkPostJoinModalAsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkPostJoinModalAsSeenMutation,
  MarkPostJoinModalAsSeenMutationVariables
>
export const SetLifeTransitionsDocument = gql`
  mutation SetLifeTransitions($lifeTransitionIds: [ID!]) {
    user {
      setLifeTransitions(input: { lifeTransitionIds: $lifeTransitionIds }) {
        user {
          id
        }
      }
    }
  }
`
export type SetLifeTransitionsMutationFn = Apollo.MutationFunction<
  SetLifeTransitionsMutation,
  SetLifeTransitionsMutationVariables
>

/**
 * __useSetLifeTransitionsMutation__
 *
 * To run a mutation, you first call `useSetLifeTransitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLifeTransitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLifeTransitionsMutation, { data, loading, error }] = useSetLifeTransitionsMutation({
 *   variables: {
 *      lifeTransitionIds: // value for 'lifeTransitionIds'
 *   },
 * });
 */
export function useSetLifeTransitionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetLifeTransitionsMutation,
    SetLifeTransitionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetLifeTransitionsMutation,
    SetLifeTransitionsMutationVariables
  >(SetLifeTransitionsDocument, options)
}
export type SetLifeTransitionsMutationHookResult = ReturnType<
  typeof useSetLifeTransitionsMutation
>
export type SetLifeTransitionsMutationResult =
  Apollo.MutationResult<SetLifeTransitionsMutation>
export type SetLifeTransitionsMutationOptions = Apollo.BaseMutationOptions<
  SetLifeTransitionsMutation,
  SetLifeTransitionsMutationVariables
>
export const HomeDocument = gql`
  query Home {
    currentUser {
      ...HomeCurrentUser
    }
  }
  ${HomeCurrentUserFragmentDoc}
`

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options)
}
export function useHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(
    HomeDocument,
    options,
  )
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>
export const MarkFeedbackModalAsSeenMutationDocument = gql`
  mutation MarkFeedbackModalAsSeenMutation(
    $dbEventId: ID!
    $markRestOfUserTicketsAsSeen: Boolean
  ) {
    event {
      markFeedbackModalAsSeen(
        input: {
          dbEventId: $dbEventId
          markRestOfUserTicketsAsSeen: $markRestOfUserTicketsAsSeen
        }
      ) {
        event {
          id
        }
      }
    }
  }
`
export type MarkFeedbackModalAsSeenMutationMutationFn = Apollo.MutationFunction<
  MarkFeedbackModalAsSeenMutationMutation,
  MarkFeedbackModalAsSeenMutationMutationVariables
>

/**
 * __useMarkFeedbackModalAsSeenMutationMutation__
 *
 * To run a mutation, you first call `useMarkFeedbackModalAsSeenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeedbackModalAsSeenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeedbackModalAsSeenMutationMutation, { data, loading, error }] = useMarkFeedbackModalAsSeenMutationMutation({
 *   variables: {
 *      dbEventId: // value for 'dbEventId'
 *      markRestOfUserTicketsAsSeen: // value for 'markRestOfUserTicketsAsSeen'
 *   },
 * });
 */
export function useMarkFeedbackModalAsSeenMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkFeedbackModalAsSeenMutationMutation,
    MarkFeedbackModalAsSeenMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkFeedbackModalAsSeenMutationMutation,
    MarkFeedbackModalAsSeenMutationMutationVariables
  >(MarkFeedbackModalAsSeenMutationDocument, options)
}
export type MarkFeedbackModalAsSeenMutationMutationHookResult = ReturnType<
  typeof useMarkFeedbackModalAsSeenMutationMutation
>
export type MarkFeedbackModalAsSeenMutationMutationResult =
  Apollo.MutationResult<MarkFeedbackModalAsSeenMutationMutation>
export type MarkFeedbackModalAsSeenMutationMutationOptions =
  Apollo.BaseMutationOptions<
    MarkFeedbackModalAsSeenMutationMutation,
    MarkFeedbackModalAsSeenMutationMutationVariables
  >
export const JoinForm__CurrentUserDocument = gql`
  query JoinForm__CurrentUser {
    currentUser {
      firstName
      hasCompletedSignup
      id
      lastName
    }
  }
`

/**
 * __useJoinForm__CurrentUserQuery__
 *
 * To run a query within a React component, call `useJoinForm__CurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinForm__CurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinForm__CurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinForm__CurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JoinForm__CurrentUserQuery,
    JoinForm__CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    JoinForm__CurrentUserQuery,
    JoinForm__CurrentUserQueryVariables
  >(JoinForm__CurrentUserDocument, options)
}
export function useJoinForm__CurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JoinForm__CurrentUserQuery,
    JoinForm__CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JoinForm__CurrentUserQuery,
    JoinForm__CurrentUserQueryVariables
  >(JoinForm__CurrentUserDocument, options)
}
export type JoinForm__CurrentUserQueryHookResult = ReturnType<
  typeof useJoinForm__CurrentUserQuery
>
export type JoinForm__CurrentUserLazyQueryHookResult = ReturnType<
  typeof useJoinForm__CurrentUserLazyQuery
>
export type JoinForm__CurrentUserQueryResult = Apollo.QueryResult<
  JoinForm__CurrentUserQuery,
  JoinForm__CurrentUserQueryVariables
>
export const EventAddHostMutationDocument = gql`
  mutation EventAddHostMutation($eventId: String!, $userId: String!) {
    eventAddHost(input: { eventId: $eventId, userId: $userId }) {
      hostship {
        hostRole
        id
        user {
          fullName
          id
          profilePictureUrl
        }
      }
    }
  }
`
export type EventAddHostMutationMutationFn = Apollo.MutationFunction<
  EventAddHostMutationMutation,
  EventAddHostMutationMutationVariables
>

/**
 * __useEventAddHostMutationMutation__
 *
 * To run a mutation, you first call `useEventAddHostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventAddHostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventAddHostMutationMutation, { data, loading, error }] = useEventAddHostMutationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEventAddHostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventAddHostMutationMutation,
    EventAddHostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventAddHostMutationMutation,
    EventAddHostMutationMutationVariables
  >(EventAddHostMutationDocument, options)
}
export type EventAddHostMutationMutationHookResult = ReturnType<
  typeof useEventAddHostMutationMutation
>
export type EventAddHostMutationMutationResult =
  Apollo.MutationResult<EventAddHostMutationMutation>
export type EventAddHostMutationMutationOptions = Apollo.BaseMutationOptions<
  EventAddHostMutationMutation,
  EventAddHostMutationMutationVariables
>
export const EventRemoveHostMutationDocument = gql`
  mutation EventRemoveHostMutation($eventId: String!, $userId: String!) {
    eventRemoveHost(input: { eventId: $eventId, userId: $userId }) {
      hostship {
        id
      }
    }
  }
`
export type EventRemoveHostMutationMutationFn = Apollo.MutationFunction<
  EventRemoveHostMutationMutation,
  EventRemoveHostMutationMutationVariables
>

/**
 * __useEventRemoveHostMutationMutation__
 *
 * To run a mutation, you first call `useEventRemoveHostMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRemoveHostMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRemoveHostMutationMutation, { data, loading, error }] = useEventRemoveHostMutationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEventRemoveHostMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventRemoveHostMutationMutation,
    EventRemoveHostMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventRemoveHostMutationMutation,
    EventRemoveHostMutationMutationVariables
  >(EventRemoveHostMutationDocument, options)
}
export type EventRemoveHostMutationMutationHookResult = ReturnType<
  typeof useEventRemoveHostMutationMutation
>
export type EventRemoveHostMutationMutationResult =
  Apollo.MutationResult<EventRemoveHostMutationMutation>
export type EventRemoveHostMutationMutationOptions = Apollo.BaseMutationOptions<
  EventRemoveHostMutationMutation,
  EventRemoveHostMutationMutationVariables
>
export const EventUpdateHostRoleMutationDocument = gql`
  mutation EventUpdateHostRoleMutation(
    $eventId: String!
    $role: String!
    $userId: String!
  ) {
    eventUpdateHostRole(
      input: { eventId: $eventId, userId: $userId, role: $role }
    ) {
      hostships {
        hostRole
        id
        user {
          fullName
          id
          profilePictureUrl
        }
      }
    }
  }
`
export type EventUpdateHostRoleMutationMutationFn = Apollo.MutationFunction<
  EventUpdateHostRoleMutationMutation,
  EventUpdateHostRoleMutationMutationVariables
>

/**
 * __useEventUpdateHostRoleMutationMutation__
 *
 * To run a mutation, you first call `useEventUpdateHostRoleMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventUpdateHostRoleMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventUpdateHostRoleMutationMutation, { data, loading, error }] = useEventUpdateHostRoleMutationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      role: // value for 'role'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEventUpdateHostRoleMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventUpdateHostRoleMutationMutation,
    EventUpdateHostRoleMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EventUpdateHostRoleMutationMutation,
    EventUpdateHostRoleMutationMutationVariables
  >(EventUpdateHostRoleMutationDocument, options)
}
export type EventUpdateHostRoleMutationMutationHookResult = ReturnType<
  typeof useEventUpdateHostRoleMutationMutation
>
export type EventUpdateHostRoleMutationMutationResult =
  Apollo.MutationResult<EventUpdateHostRoleMutationMutation>
export type EventUpdateHostRoleMutationMutationOptions =
  Apollo.BaseMutationOptions<
    EventUpdateHostRoleMutationMutation,
    EventUpdateHostRoleMutationMutationVariables
  >
export const HostManagementDocument = gql`
  query HostManagement($eventId: String!) {
    event(databaseId: $eventId) {
      hostships {
        nodes {
          hostRole
          id
          user {
            auth0Id
            fullName
            id
            profilePictureUrl
          }
        }
      }
    }
  }
`

/**
 * __useHostManagementQuery__
 *
 * To run a query within a React component, call `useHostManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useHostManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHostManagementQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useHostManagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    HostManagementQuery,
    HostManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HostManagementQuery, HostManagementQueryVariables>(
    HostManagementDocument,
    options,
  )
}
export function useHostManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HostManagementQuery,
    HostManagementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HostManagementQuery, HostManagementQueryVariables>(
    HostManagementDocument,
    options,
  )
}
export type HostManagementQueryHookResult = ReturnType<
  typeof useHostManagementQuery
>
export type HostManagementLazyQueryHookResult = ReturnType<
  typeof useHostManagementLazyQuery
>
export type HostManagementQueryResult = Apollo.QueryResult<
  HostManagementQuery,
  HostManagementQueryVariables
>
export const HostRatingDocument = gql`
  query HostRating {
    currentUser {
      averageRating
      id
    }
  }
`

/**
 * __useHostRatingQuery__
 *
 * To run a query within a React component, call `useHostRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHostRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHostRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useHostRatingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HostRatingQuery,
    HostRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HostRatingQuery, HostRatingQueryVariables>(
    HostRatingDocument,
    options,
  )
}
export function useHostRatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HostRatingQuery,
    HostRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HostRatingQuery, HostRatingQueryVariables>(
    HostRatingDocument,
    options,
  )
}
export type HostRatingQueryHookResult = ReturnType<typeof useHostRatingQuery>
export type HostRatingLazyQueryHookResult = ReturnType<
  typeof useHostRatingLazyQuery
>
export type HostRatingQueryResult = Apollo.QueryResult<
  HostRatingQuery,
  HostRatingQueryVariables
>
export const EventCellDocument = gql`
  query EventCell($databaseId: String!) {
    event(databaseId: $databaseId) {
      averageRating
      id
      reviews {
        totalCount
      }
    }
  }
`

/**
 * __useEventCellQuery__
 *
 * To run a query within a React component, call `useEventCellQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventCellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCellQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useEventCellQuery(
  baseOptions: Apollo.QueryHookOptions<EventCellQuery, EventCellQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventCellQuery, EventCellQueryVariables>(
    EventCellDocument,
    options,
  )
}
export function useEventCellLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventCellQuery,
    EventCellQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventCellQuery, EventCellQueryVariables>(
    EventCellDocument,
    options,
  )
}
export type EventCellQueryHookResult = ReturnType<typeof useEventCellQuery>
export type EventCellLazyQueryHookResult = ReturnType<
  typeof useEventCellLazyQuery
>
export type EventCellQueryResult = Apollo.QueryResult<
  EventCellQuery,
  EventCellQueryVariables
>
export const EventReviewsDocument = gql`
  query EventReviews($databaseId: String!, $reviewsAfterCursor: String) {
    event(databaseId: $databaseId) {
      averageRating
      id
      name
      reviews(connection: { first: 10, after: $reviewsAfterCursor }) {
        nodes {
          feedbackForHost
          id
          ratingForHost
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

/**
 * __useEventReviewsQuery__
 *
 * To run a query within a React component, call `useEventReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventReviewsQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      reviewsAfterCursor: // value for 'reviewsAfterCursor'
 *   },
 * });
 */
export function useEventReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventReviewsQuery,
    EventReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventReviewsQuery, EventReviewsQueryVariables>(
    EventReviewsDocument,
    options,
  )
}
export function useEventReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventReviewsQuery,
    EventReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventReviewsQuery, EventReviewsQueryVariables>(
    EventReviewsDocument,
    options,
  )
}
export type EventReviewsQueryHookResult = ReturnType<
  typeof useEventReviewsQuery
>
export type EventReviewsLazyQueryHookResult = ReturnType<
  typeof useEventReviewsLazyQuery
>
export type EventReviewsQueryResult = Apollo.QueryResult<
  EventReviewsQuery,
  EventReviewsQueryVariables
>
export const User__ChangeEmailMutationDocument = gql`
  mutation User__ChangeEmailMutation($email: String!, $id: ID!) {
    user {
      updateEmail(input: { id: $id, email: $email }) {
        user {
          id
        }
      }
    }
  }
`
export type User__ChangeEmailMutationMutationFn = Apollo.MutationFunction<
  User__ChangeEmailMutationMutation,
  User__ChangeEmailMutationMutationVariables
>

/**
 * __useUser__ChangeEmailMutationMutation__
 *
 * To run a mutation, you first call `useUser__ChangeEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser__ChangeEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangeEmailMutationMutation, { data, loading, error }] = useUser__ChangeEmailMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUser__ChangeEmailMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    User__ChangeEmailMutationMutation,
    User__ChangeEmailMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    User__ChangeEmailMutationMutation,
    User__ChangeEmailMutationMutationVariables
  >(User__ChangeEmailMutationDocument, options)
}
export type User__ChangeEmailMutationMutationHookResult = ReturnType<
  typeof useUser__ChangeEmailMutationMutation
>
export type User__ChangeEmailMutationMutationResult =
  Apollo.MutationResult<User__ChangeEmailMutationMutation>
export type User__ChangeEmailMutationMutationOptions =
  Apollo.BaseMutationOptions<
    User__ChangeEmailMutationMutation,
    User__ChangeEmailMutationMutationVariables
  >
export const ProfileDocument = gql`
  query Profile($auth0id: String) {
    user(auth0id: $auth0id) {
      id
      moderationStatus
    }
  }
`

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      auth0id: // value for 'auth0id'
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  )
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  )
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>
export type ProfileQueryResult = Apollo.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>
export const namedOperations = {
  Query: {
    RequiresSubscriptionOnRSVP: "RequiresSubscriptionOnRSVP",
    EventLookup: "EventLookup",
    Events: "Events",
    AddToAddressBookButton: "AddToAddressBookButton",
    FollowHostEventsButton: "FollowHostEventsButton",
    FollowMenu: "FollowMenu",
    GroupPost: "GroupPost",
    GroupPostReaction: "GroupPostReaction",
    EventBySlugOrId: "EventBySlugOrId",
    CurrentUser: "CurrentUser",
    DesktopHeader: "DesktopHeader",
    InboxButton: "InboxButton",
    ProfileLink: "ProfileLink",
    ImpersonateBanner: "ImpersonateBanner",
    Layout: "Layout",
    MemberLookup: "MemberLookup",
    MessageMember: "MessageMember",
    EventForRating: "EventForRating",
    Mentions__Users: "Mentions__Users",
    UseUserRole: "UseUserRole",
    EventDetailsAttendee: "EventDetailsAttendee",
    EventDetailsEssential: "EventDetailsEssential",
    EventDetailsFeed: "EventDetailsFeed",
    EventDetailsHost: "EventDetailsHost",
    EventDetailsFeedSinglePost: "EventDetailsFeedSinglePost",
    InboxCurrentUser: "InboxCurrentUser",
    InboxFetchEventAttendees: "InboxFetchEventAttendees",
    InboxFetchEventWaitlist: "InboxFetchEventWaitlist",
    InboxFetchUserFromAuth0Id: "InboxFetchUserFromAuth0Id",
    Inbox: "Inbox",
    MyGroupsProvider: "MyGroupsProvider",
    Notifications: "Notifications",
    NotificationsUnreadCount: "NotificationsUnreadCount",
    NotificationsUnseenCount: "NotificationsUnseenCount",
    SearchKeys: "SearchKeys",
    User__AccountPageQuery: "User__AccountPageQuery",
    FetchEventGroup: "FetchEventGroup",
    geocodeAddress: "geocodeAddress",
    GroupAbout: "GroupAbout",
    GroupDiscussionAnchoredPost: "GroupDiscussionAnchoredPost",
    GroupDiscussion__Feed: "GroupDiscussion__Feed",
    Group__Discussion: "Group__Discussion",
    GroupEvents: "GroupEvents",
    GroupQuestions: "GroupQuestions",
    GroupDetailMemberQuestionAnswers: "GroupDetailMemberQuestionAnswers",
    GroupDetailMembersTab: "GroupDetailMembersTab",
    GroupDetail: "GroupDetail",
    Groups: "Groups",
    HomeLeftPanel: "HomeLeftPanel",
    MyGroupsDiscussionFeed: "MyGroupsDiscussionFeed",
    FetchLifeTransitions: "FetchLifeTransitions",
    FetchMyLifeTransitions: "FetchMyLifeTransitions",
    FetchRecommendedEvents: "FetchRecommendedEvents",
    FetchRecommendedGroups: "FetchRecommendedGroups",
    FetchUserJoinedFrom: "FetchUserJoinedFrom",
    PostJoinModal: "PostJoinModal",
    Home: "Home",
    JoinForm__CurrentUser: "JoinForm__CurrentUser",
    HostManagement: "HostManagement",
    HostRating: "HostRating",
    EventCell: "EventCell",
    EventReviews: "EventReviews",
    Profile: "Profile",
  },
  Mutation: {
    User__DeleteUserMutation: "User__DeleteUserMutation",
    CreateEventCheckoutSession: "CreateEventCheckoutSession",
    ToggleAddressBook: "ToggleAddressBook",
    BlockUser__RestrictUser: "BlockUser__RestrictUser",
    ContentReport__CreateContentReport: "ContentReport__CreateContentReport",
    ToggleFollowHost: "ToggleFollowHost",
    Group__DeletePostMutation: "Group__DeletePostMutation",
    Group__LockPostMutation: "Group__LockPostMutation",
    Group__PinPostMutation: "Group__PinPostMutation",
    Group__UnlockPostMutation: "Group__UnlockPostMutation",
    Group__UnpinPostMutation: "Group__UnpinPostMutation",
    User__ModerationStatusMutation: "User__ModerationStatusMutation",
    GroupCardJoinGroup: "GroupCardJoinGroup",
    Group__CreatePostMutation: "Group__CreatePostMutation",
    Group__FeaturePostMutation: "Group__FeaturePostMutation",
    CreatePostComment: "CreatePostComment",
    CreatePostReaction: "CreatePostReaction",
    DeletePostReaction: "DeletePostReaction",
    GroupUpdatePostComment: "GroupUpdatePostComment",
    NotificationsMarkAllAsRead: "NotificationsMarkAllAsRead",
    NotificationsMarkAllAsSeen: "NotificationsMarkAllAsSeen",
    NotificationMarkAsRead: "NotificationMarkAsRead",
    PromoteToPowerHost: "PromoteToPowerHost",
    SubmitAttendeeFeedback: "SubmitAttendeeFeedback",
    EventRateAsHost: "EventRateAsHost",
    Device__RemoveDeviceMutation: "Device__RemoveDeviceMutation",
    Device__AddDeviceMutation: "Device__AddDeviceMutation",
    PushNotificationMarkAsRead: "PushNotificationMarkAsRead",
    EventDetailsCreatePost: "EventDetailsCreatePost",
    EventPostDelete: "EventPostDelete",
    EventPostLike: "EventPostLike",
    EventPostUnlike: "EventPostUnlike",
    EventPostUpdate: "EventPostUpdate",
    InboxCreateThread: "InboxCreateThread",
    InboxLeaveThread: "InboxLeaveThread",
    InboxMarkThreadAsRead: "InboxMarkThreadAsRead",
    InboxSubmitReply: "InboxSubmitReply",
    VersionCheck: "VersionCheck",
    User__DeleteAccountMutation: "User__DeleteAccountMutation",
    SetEventGroup: "SetEventGroup",
    EventDetailsCancelEvent: "EventDetailsCancelEvent",
    GroupDetailLeaveGroup: "GroupDetailLeaveGroup",
    GroupDetailHeaderJoinGroup: "GroupDetailHeaderJoinGroup",
    GroupDetailRequestToJoinGroup: "GroupDetailRequestToJoinGroup",
    GroupDetailApproveRequestToJoinGroup:
      "GroupDetailApproveRequestToJoinGroup",
    GroupDetailRejectRequestToJoinGroup: "GroupDetailRejectRequestToJoinGroup",
    RSVP: "RSVP",
    MarkPostJoinModalAsSeen: "MarkPostJoinModalAsSeen",
    SetLifeTransitions: "SetLifeTransitions",
    MarkFeedbackModalAsSeenMutation: "MarkFeedbackModalAsSeenMutation",
    EventAddHostMutation: "EventAddHostMutation",
    EventRemoveHostMutation: "EventRemoveHostMutation",
    EventUpdateHostRoleMutation: "EventUpdateHostRoleMutation",
    User__ChangeEmailMutation: "User__ChangeEmailMutation",
  },
  Fragment: {
    Group__CreatePostMutation__GroupPostFragment:
      "Group__CreatePostMutation__GroupPostFragment",
    CreatePostCommentMutationGroupPost: "CreatePostCommentMutationGroupPost",
    DiscussionFeedPost__CreatedByFragment:
      "DiscussionFeedPost__CreatedByFragment",
    DiscussionFeedPost__GroupPostFragment:
      "DiscussionFeedPost__GroupPostFragment",
    DiscussionFeedPost__GroupPostAttachmentFileFragment:
      "DiscussionFeedPost__GroupPostAttachmentFileFragment",
    DiscussionFeedPost__GroupPostAttachmentEventFragment:
      "DiscussionFeedPost__GroupPostAttachmentEventFragment",
    DiscussionFeedPostFragment: "DiscussionFeedPostFragment",
    GroupPostComment__GroupPostAttachmentFileFragment:
      "GroupPostComment__GroupPostAttachmentFileFragment",
    GroupPostCommentFragment: "GroupPostCommentFragment",
    DiscussionFeedFragment: "DiscussionFeedFragment",
    DiscussionFeed__UserFragment: "DiscussionFeed__UserFragment",
    DesktopHeaderCurrentUser: "DesktopHeaderCurrentUser",
    InboxButtonUnreadMessages: "InboxButtonUnreadMessages",
    InboxButtonInbox: "InboxButtonInbox",
    ProfileLinkCurrentUser: "ProfileLinkCurrentUser",
    ImpersonateBannerCurrentUser: "ImpersonateBannerCurrentUser",
    LayoutCurrentUser: "LayoutCurrentUser",
    PromoteToPowerHostUserFragment: "PromoteToPowerHostUserFragment",
    UserForPostRatingModal: "UserForPostRatingModal",
    EventForRatingData: "EventForRatingData",
    UseUserRoleCurrentUser: "UseUserRoleCurrentUser",
    EventDetailsFeedEventPost: "EventDetailsFeedEventPost",
    EventDetailsAttendeeUser: "EventDetailsAttendeeUser",
    EventDetailsAttendeeEvent: "EventDetailsAttendeeEvent",
    EventDetailsEssentialEvent: "EventDetailsEssentialEvent",
    EventDetailsFeedEvent: "EventDetailsFeedEvent",
    EventDetailsHostUser: "EventDetailsHostUser",
    EventDetailsHostEvent: "EventDetailsHostEvent",
    EventDetailsFeedSinglePostEventPost: "EventDetailsFeedSinglePostEventPost",
    InboxCurrentUserData: "InboxCurrentUserData",
    InboxAttendee: "InboxAttendee",
    InboxTicket: "InboxTicket",
    InboxParticipantConnection: "InboxParticipantConnection",
    InboxThread: "InboxThread",
    InboxData: "InboxData",
    InboxParticipant: "InboxParticipant",
    InboxThreadMessage: "InboxThreadMessage",
    MyGroupsProviderGroup: "MyGroupsProviderGroup",
    VersionCheckVersionStatus: "VersionCheckVersionStatus",
    Moderators: "Moderators",
    Members: "Members",
    GroupDiscussionAnchoredPostPost: "GroupDiscussionAnchoredPostPost",
    GroupDiscussionAnchoredPostComment: "GroupDiscussionAnchoredPostComment",
    GroupDiscussionAnchoredPostNestedComment:
      "GroupDiscussionAnchoredPostNestedComment",
    GroupDiscussionAnchoredPostGroup: "GroupDiscussionAnchoredPostGroup",
    GroupQuestionsCompleteGroup: "GroupQuestionsCompleteGroup",
    GroupDetailPendingParticipantAnswerDetail:
      "GroupDetailPendingParticipantAnswerDetail",
    GroupDetailPendingParticipantAnswers:
      "GroupDetailPendingParticipantAnswers",
    GroupDetailPendingParticipant: "GroupDetailPendingParticipant",
    GroupDetailPendingParticipants: "GroupDetailPendingParticipants",
    GroupDetailModerators: "GroupDetailModerators",
    GroupDetailParticipant: "GroupDetailParticipant",
    GroupDetailGroup: "GroupDetailGroup",
    GroupDetailCompleteGroup: "GroupDetailCompleteGroup",
    GroupFragment: "GroupFragment",
    HomeLeftPanelEvent: "HomeLeftPanelEvent",
    HomeLeftPanelCurrentUser: "HomeLeftPanelCurrentUser",
    PostJoinModalCurrentUser: "PostJoinModalCurrentUser",
    HomeCurrentUser: "HomeCurrentUser",
    NewHostship: "NewHostship",
  },
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    GroupPostAttachment: [
      "GroupPostAttachmentEvent",
      "GroupPostAttachmentFile",
    ],
  },
}
export default result
