export const USER_MODERATION_STATUS = {
  ACTIVE: "ACTIVE",
  FROZEN: "FROZEN",
}

export const USER_ROLES = {
  ADMIN: "Staff",
  STAFF: "Staff",
  CL: "Host",
  POWER_HOST: "Power Host",
  HOST: "Host",
  MODERATOR: "Curator",
  MODERATOR_SHORT: "Curator",
  USER: "Member",
}
