import React, { useCallback, useMemo } from "react"

import { useAuth0 } from "@auth0/auth0-react"

import { cookieExists, IMPERSONATED_USER_ID } from "@services/cookies"
import { getImpersonatedUserIdFromToken } from "@services/tokens"

import RevelSessionContext from "./RevelSessionContext"

const encodeTable = {
  "+": "-",
  "/": "_",
  "=": "~",
}

const RevelSessionProvider = ({ children }) => {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0()

  const getAccessToken = useCallback(async () => {
    let accessToken = ""
    try {
      if (isLoading || isAuthenticated) {
        accessToken = await getAccessTokenSilently()
      }
    } catch (e) {
      // silently ignore
    }
    return accessToken
  }, [getAccessTokenSilently, isAuthenticated, isLoading])

  // Determine user impersonation status
  const realUserId = user?.sub || ""
  const impersonatedUserIdCookieValue = cookieExists(IMPERSONATED_USER_ID)
  const userId = impersonatedUserIdCookieValue
    ? getImpersonatedUserIdFromToken(impersonatedUserIdCookieValue)
    : user?.sub
  // Only auth0 accounts are considered primary accounts, and those accounts are the only ones with a database entry, i.e. a profile
  // This could be false when a user is logged in with Facebook, but they haven't provided an email address to Facebook
  // This means they are logged in and have an auth0user, but they don't have a db record yet
  const hasProfile = !!(userId && userId.startsWith("auth0|"))

  const values = useMemo(() => {
    const gid = `rowidv1/User/${userId}`
    const userGqlId = Buffer.from(gid, "utf8")
      .toString("base64")
      .replace(/[+/=]/g, (match) => {
        return encodeTable[match]
      })
    return {
      userId,
      userGqlId,
      realUserId,
      isLoading,
      isAuthenticated,
      hasProfile,
      getAccessToken,
    }
  }, [
    userId,
    realUserId,
    isLoading,
    isAuthenticated,
    hasProfile,
    getAccessToken,
  ])

  return (
    <RevelSessionContext.Provider value={values}>
      {children}
    </RevelSessionContext.Provider>
  )
}

export default RevelSessionProvider
