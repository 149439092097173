import { createReducer } from "../state/util"

import {
  POST_FOCUS,
  POST_SET_POSTING,
  POST_SET_REPLYING_TO,
  POST_SET_EDIT_MODE,
} from "./actions"

/* eslint-disable no-param-reassign -- TODO: refactor to be non-mutating / unnecessary with graphql */
const focusPost = (state, id) => {
  state.focused = id
}

const setReplyingTo = (state, id) => {
  state.replyingTo = id
  state.focused = id // Focus post as well, for easy visual confirmation
}

const setEditing = (state, value) => {
  state.editing = value
}

const setPosting = (state, value) => {
  state.posting = value
}
/* eslint-enable no-param-reassign -- TODO: refactor to be non-mutating / unnecessary with graphql */

// Map incoming actions to the appropriate handler
const actionMap = {
  [POST_FOCUS]: focusPost,
  [POST_SET_REPLYING_TO]: setReplyingTo,
  [POST_SET_EDIT_MODE]: setEditing,
  [POST_SET_POSTING]: setPosting,
}

const initialState = {
  posting: false,
  focused: null,
  replyingTo: null,
  editing: false,
}

export default createReducer(initialState, actionMap)
