import { useLayoutEffect, useState } from "react"

import { App } from "@capacitor/app"
import { CapacitorUpdater } from "@capgo/capacitor-updater"

import useRevelSession from "@hooks/useRevelSession"

import { LazyComponents } from "@components/lazy"
import LazyViews from "@views/lazy"

import { isNativeApp } from "@services/mobileHelpers"
import { track } from "@services/tracking"

export const getVersionStrings = async (): Promise<{
  version: string
  bundleVersion: string
}> => {
  const { version } = await App.getInfo()
  const bundleVersion = process.env.REACT_APP_BUNDLE_VERSION as string
  return { version, bundleVersion }
}

export const useCapUpdater = (): void => {
  const { sessionReady } = useRevelSession()
  const [capgoReady, setCapgoReady] = useState(false)

  useLayoutEffect(() => {
    const apply = async () => {
      // Preload all views and components before confirming new version
      Promise.all([
        ...Object.values(LazyViews).map(
          (view) => view?.preload && view.preload(),
        ),
        ...LazyComponents.map((comp) => comp?.preload && comp.preload()),
      ]).then(() => {
        CapacitorUpdater.notifyAppReady()
      })

      const { version, bundleVersion } = await getVersionStrings()

      CapacitorUpdater.addListener(
        "majorAvailable",
        ({ version: newVersion }) => {
          console.log(
            `🚀 CapacitorUpdater.majorAvailable v${newVersion}, from v${version}~v${bundleVersion}`,
          )
          track("CapacitorUpdater.majorAvailable", {
            newVersion,
            version,
            bundleVersion,
          })
        },
      )

      CapacitorUpdater.addListener(
        "updateAvailable",
        ({ bundle: { version: newVersion } }) => {
          console.log(
            `🚀 CapacitorUpdater.updateAvailable v${newVersion}, from v${version}~v${bundleVersion}`,
          )
          track("CapacitorUpdater.updateAvailable", {
            newVersion,
            version,
            bundleVersion,
          })
        },
      )

      CapacitorUpdater.addListener(
        "downloadComplete",
        ({ bundle: { version: downloadedVersion, downloaded, checksum } }) => {
          console.log(
            `🚀 CapacitorUpdater.downloaded v${version}~v${bundleVersion}->v${downloadedVersion}, ${checksum}, ${downloaded}`,
          )
          track("CapacitorUpdater.download", { version, bundleVersion })
        },
      )

      CapacitorUpdater.addListener(
        "downloadFailed",
        ({ version: downloadedVersion }) => {
          console.log(
            `🚀 CapacitorUpdater.downloadFailed v${version}~v${bundleVersion}->v${downloadedVersion}`,
          )
          track("CapacitorUpdater.downloadFailed", { version, bundleVersion })
        },
      )
    }
    if (
      isNativeApp({ pluginName: "CapacitorUpdater" }) &&
      sessionReady &&
      !capgoReady
    ) {
      setCapgoReady(true)
      apply()
    }
  }, [sessionReady, capgoReady])
}
