const JOIN_REVEL = {
  category: "Member Signup",
  fields: "Entered",
  attemptedSubmission: "Attempted Form Submission",
  triedSubmitting: "Attempted Completion with Errors",
  completedProfile: "Completed Basic Profile",
  skipped: "Skipped Interests Selection",
  saved: "Saved Interests Selection",
  socialFailed: "Social Login Failed",
}

const ACTIONS = {
  selectedInterest: "Selected Interest",
  selectedTransition: "Selected Transition",
  selectedGroup: "Selected Group",
  selectedEvent: "Signed Up For Event",
}

export { JOIN_REVEL, ACTIONS }
