import { lazzy } from "@services/lazy"

export default {
  AccountBillingSettings: lazzy(
    "AccountBillingSettings",
    () => import("@views/AccountBillingSettings"),
  ),
  AppVersionRejected: lazzy(
    "AppVersionRejected",
    () => import("@views/AppVersionRejected"),
  ),
  CookiePolicy: lazzy("CookiePolicy", () => import("@views/CookiePolicy")),
  CreateEvent: lazzy("CreateEvent", () => import("@views/EventCreation")),
  EventDetail: lazzy("EventDetail", () => import("@views/EventDetail")),
  EventDetails: lazzy("EventDetails", () => import("@views/EventDetails")),
  Events: lazzy("Events", () => import("@views/Events")),
  EventCategory: lazzy("EventCategory", () => import("@views/EventCategory")),
  EventPaymentCompleted: lazzy(
    "EventPaymentCompleted",
    () => import("@views/EventDetail/EventPaymentCompleted"),
  ),
  GenericNotFound: lazzy(
    "GenericNotFound",
    () => import("@views/GenericNotFound"),
  ),
  Home: lazzy("Home", () => import("@views/Home")),
  Information: lazzy("Information", () => import("@views/Information")),
  Login: lazzy("Login", () => import("@components/auth/Login")),
  Logout: lazzy("Logout", () => import("@components/auth/Logout")),
  ManageMyEvents: lazzy(
    "ManageMyEvents",
    () => import("@views/ManageMyEvents"),
  ),
  MemberDirectory: lazzy(
    "MemberDirectory",
    () => import("@views/MemberDirectory"),
  ),
  MobileLanding: lazzy("MobileLanding", () => import("@views/MobileLanding")),
  PostSignup: lazzy(
    "PostSignup",
    () => import("@views/Profile/EditModals/StepContainer"),
  ),
  Join: lazzy("Join", () => import("@views/Join")),
  Profile: lazzy("Profile", () => import("@views/Profile")),
  Referral: lazzy("Referral", () => import("@views/Referral")),
  StyleGuide: lazzy("StyleGuide", () => import("@views/StyleGuide")),
  VirtualLink: lazzy("VirtualLink", () => import("@components/VirtualLink")),
  Inbox: lazzy("Inbox", () => import("@views/Inbox")),
  WhyHost: lazzy("WhyHost", () => import("@views/WhyHost")),
  PowerHost: lazzy("PowerHost", () => import("@views/PowerHost")),
  Communities: lazzy("Communities", () => import("@views/Groups")),
  GroupDetail: lazzy("GroupDetail", () => import("@views/GroupDetail")),
  GroupPost: lazzy("GroupPost", () => import("@views/GroupDetail/Post")),
  ActivityLog: lazzy("ActivityLog", () => import("@views/ActivityLog")),
  RedirectToGroupPost: lazzy(
    "RedirectToGroupPost",
    () => import("@views/GroupDetail/PostRedirect"),
  ),
  Notifications: lazzy("Notifications", () => import("@views/Notifications")),
}
