import { useEffect } from "react"

import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics"

import { isNativeApp } from "@services/mobileHelpers"

// Sets up Crashlytics on initial load if the user is
// accessing Revel from the app
const useCrashlytics = () => {
  useEffect(() => {
    const apply = async () => {
      if (isNativeApp()) {
        await FirebaseCrashlytics.setEnabled({
          enabled: true,
        })
      }
    }
    apply()
  }, [])
}

export default useCrashlytics
