import { Browser } from "@capacitor/browser"
import { Capacitor } from "@capacitor/core"
import { Device, DeviceInfo } from "@capacitor/device"
import { EmailComposer } from "capacitor-email-composer"
import md5 from "md5"

import { SmsManager } from "@plugins/SmsManagerPlugin"

import { makeAbsolute, makeRelative } from "@services/urlHelpers"

import {
  ANDROID_CLIENT_HOST,
  IOS_CLIENT_HOST,
  ROOT_URL,
  getPathWithReturn,
  isReactPage,
  isWebflowPage,
  MOBILE_DASHBOARD,
} from "@constants/routeConstants"

import type { NavigateFunction } from "react-router-dom"

type IsNativeAppProps = { pluginName?: string }
const isNativeApp = (props?: IsNativeAppProps): boolean =>
  Capacitor.isNativePlatform() &&
  (!props?.pluginName || Capacitor.isPluginAvailable(props.pluginName))
const isAndroid = (): boolean => Capacitor.getPlatform() === "android"
const isiOS = (): boolean => Capacitor.getPlatform() === "ios"
const getDeviceInfo = (): Promise<DeviceInfo> => Device.getInfo()

const isAndroidBrowser = (): boolean => {
  const mobileRegex = /Android/i
  return (
    mobileRegex.test(navigator.userAgent) ||
    mobileRegex.test(navigator.platform)
  )
}

const isiOSBrowser = (): boolean => {
  const mobileRegex = /iPhone|iPad|iPod/i
  return (
    mobileRegex.test(navigator.userAgent) ||
    mobileRegex.test(navigator.platform)
  )
}

const isMobileBrowser = (): boolean => {
  return isAndroidBrowser() || isiOSBrowser()
}

/* Newer versions of iOS + Safari have native banner, do not show ours (only show if OS 6-7)
   - Show (iOS 6, Safari): Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25
   - Show (iOS 15.6, Chrome): Mozilla/5.0 (iPhone; CPU iPhone OS 15_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/104.0.5112.99 Mobile/15E148 Safari/604.1
   - Don't show (iOS 15.6, Safari): Mozilla/5.0 (iPhone; CPU iPhone OS 15_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1
 */
const shouldShowAppBanner = (): boolean => {
  // browserTypes: CriOS/Chrome, FxiOS/Firefox, Version/Safari
  const safariBrowserType = "Version"
  const [agent, osVersion, browserType] =
    /(?:iPad|iPhone|iPod).*OS (.*) like.*AppleWebKit.* (\S*)\/.*Mobile.*Safari/.exec(
      navigator.userAgent,
    ) ?? ["", "", ""]
  const showOnIOS =
    isiOSBrowser() && agent
      ? /$[6-7]_/.test(osVersion) || browserType !== safariBrowserType
      : true
  return !isNativeApp() && isMobileBrowser() && showOnIOS
}

const makeUrlWebUrl = ({ url }: { url: string }): string => {
  const absoluteUrl = makeAbsolute({ href: url }) || url
  // Make it work on localhost
  if (absoluteUrl.startsWith(ROOT_URL)) return absoluteUrl
  return absoluteUrl
    .replace(ANDROID_CLIENT_HOST, ROOT_URL)
    .replace(IOS_CLIENT_HOST, ROOT_URL)
}

export const makeUrlAnchorLinkable = ({
  url: urlString,
  userId,
}: {
  url: string
  userId: string
}): string => {
  const { mobileTrackingEnabled } = window
  if (isWebflowPage(urlString) && isNativeApp()) {
    const url = new URL(makeAbsolute({ href: urlString }))
    url.searchParams.set("app", "true")
    if (mobileTrackingEnabled) {
      if (userId && !isiOS()) {
        url.searchParams.set("ajs_user_id", md5(userId))
      }
    } else {
      url.searchParams.set("do-not-track", "true")
    }
    return url.toString()
  }
  if (isReactPage(urlString)) {
    return makeRelative({ href: urlString })
  }
  return urlString
}

export const routeWithinCapacitor = ({
  url,
  navigate,
  userId,
}: {
  url: string
  navigate: NavigateFunction
  userId: string
}): void => {
  console.log(`📩 ${url}`)
  const transformedUrl = makeUrlAnchorLinkable({ url, userId })
  console.log(`📤 ${transformedUrl}`)
  if (isReactPage(url)) {
    console.log(`⚛️`)
    navigate(getPathWithReturn(MOBILE_DASHBOARD, true, transformedUrl))
  } else if (isWebflowPage(url)) {
    console.log(`🕸️`)
    window.location.replace(transformedUrl)
  }
}

const openBrowser = async ({ url }: { url: string }): Promise<void> => {
  if (!isNativeApp()) return

  await Browser.open({ url: makeUrlWebUrl({ url }) })
}

const shareSms = async ({
  message,
  fallbackUrl,
}: {
  message: string
  fallbackUrl: string
}): Promise<void> => {
  if (!isNativeApp()) return

  if (isiOS()) {
    SmsManager.send({
      numbers: [],
      text: message,
    })
      .then(() => {
        // SMS app was opened
      })
      .catch((error) => {
        if (error.message !== "SEND_CANCELLED") {
          console.log(error.message)
        }
      })
  } else {
    window.open(fallbackUrl)
  }
}

const shareEmail = async ({
  subject,
  body,
  to = [],
  cc = [],
  bcc = [],
  isHtml = false,
  fallbackUrl,
}: {
  subject: string
  body: string
  to: string[]
  cc: string[]
  bcc: string[]
  isHtml: boolean
  fallbackUrl: string
}): Promise<void> => {
  if (!isNativeApp()) return

  const hasNativeEmail = isiOS()
    ? await EmailComposer.hasAccount().then(({ hasAccount }) => hasAccount)
    : false

  if (hasNativeEmail) {
    await EmailComposer.open({
      to,
      cc,
      bcc,
      subject,
      body,
      isHtml,
    })
  } else {
    window.open(fallbackUrl)
  }
}

export {
  isNativeApp,
  isAndroid,
  isiOS,
  getDeviceInfo,
  isAndroidBrowser,
  isiOSBrowser,
  isMobileBrowser,
  shouldShowAppBanner,
  makeUrlWebUrl,
  openBrowser,
  shareSms,
  shareEmail,
}
