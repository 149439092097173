import React, { useEffect, useMemo } from "react"

import { useQuery } from "@apollo/client"
import { loader } from "graphql.macro"

import useRevelSession from "@hooks/useRevelSession"

const FETCH_SEARCH_KEYS = loader("./index.graphql")

export const RevelAlgoliaContext = React.createContext({
  events: null,
  members: null,
  threads: null,
  groups: null,
  myGroups: null,
})

const RevelAlgoliaProvider = ({ children }) => {
  const { sessionReady, isLoggedIn } = useRevelSession()
  const { data, refetch } = useQuery(FETCH_SEARCH_KEYS, {
    skip: !sessionReady,
    notifyOnNetworkStatusChange: true,
  })
  const intervalLength = 1000 * 60 * 60 * 24
  setInterval(refetch, intervalLength)

  // Refetch keys if authentication status changes
  useEffect(() => {
    if (sessionReady) refetch()
  }, [sessionReady, isLoggedIn, refetch])

  const searchKeys = data?.searchKey
  const values = useMemo(() => {
    return {
      events: searchKeys?.events,
      members: searchKeys?.members,
      threads: searchKeys?.threads,
      groups: searchKeys?.groups,
      myGroups: searchKeys?.myGroups,
    }
  }, [searchKeys])

  return (
    <RevelAlgoliaContext.Provider value={values}>
      {children}
    </RevelAlgoliaContext.Provider>
  )
}

export default RevelAlgoliaProvider
