import { useCallback } from "react"

import { useMutation } from "react-query"

import useRevelSession from "@hooks/useRevelSession"

const useAuthorizedMutation = (mutator, options = {}) => {
  const { isLoggedIn, getAccessToken } = useRevelSession()

  // Wrap the mutation method in order to supply the `accessToken`
  const wrappedMutator = useCallback(
    async (args) => {
      if (!isLoggedIn) {
        return null
      }
      const accessToken = await getAccessToken()
      return mutator({ ...args, accessToken })
    },
    [mutator, isLoggedIn, getAccessToken],
  )

  return useMutation(wrappedMutator, options)
}

export default useAuthorizedMutation
