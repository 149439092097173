import { useEffect } from "react"

import omit from "lodash/omit"
import queryString from "query-string"
import { useNavigate, useLocation } from "react-router-dom"
import scrollIntoView from "scroll-into-view"

import useLayout from "@hooks/useLayout"

import { CREATE_EVENT, DIRECTORY, INBOX } from "@constants/routeConstants"

const alignWordToNumber = ({ scrollAlign }: { scrollAlign: string }) => {
  // Configure alignment
  // top: 0, left: 0 is the top-left
  // top: 0.5, left: 0.5 is the center
  // top: 1, left: 0 is the bottom-left

  if (scrollAlign === "middle") {
    return {
      top: 0.5,
      left: 0.5,
    }
  }
  if (scrollAlign === "bottom") {
    return {
      top: 1,
      left: 0,
    }
  }

  return {
    top: 0,
    left: 0,
  }
}

export const useScrollOnLoad = (): void => {
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()
  const { tabletDown } = useLayout()

  // Scroll based on query params
  useEffect(() => {
    if (
      // Don't scroll on Directory or the Inbox
      pathname.includes(DIRECTORY) ||
      pathname.includes(INBOX) ||
      // Don't jump on mobile screens for event creation (uses expandable UI)
      (pathname.includes(CREATE_EVENT) && tabletDown)
    ) {
      return
    }

    const query = queryString.parse(search || "")
    const scrollInstantly = query?.scrollInstantly ?? false
    const scrollTo = String(query?.scrollTo ?? (hash || ""))
    const scrollAlign = String(query?.scrollAlign ?? "top")
    const clearScrollOnEnd = query?.clearScrollOnEnd ?? false
    const focusAfterScroll = query?.focusAfterScroll ?? false

    const elementToScrollTo = scrollTo
      ? (document.querySelector(scrollTo) as HTMLElement)
      : null

    if (!elementToScrollTo) {
      // Requested element does not exist, so do nothing
      return
    }

    if (focusAfterScroll && elementToScrollTo?.focus) {
      // Focus on the element, making it the target of keyboard events.
      // Alignment actually works better when focus is called before
      // scrolling. This gives the viewport time to change height when
      // popping up the keyboard on mobile devices.
      elementToScrollTo.focus()
    }

    scrollIntoView(
      elementToScrollTo,
      {
        align: alignWordToNumber({ scrollAlign }),
        // Time it should take to scroll to element
        time: scrollInstantly ? 0 : 500,
        // Can user cancel scrolling?
        cancellable: true,
        // Retry this many times
        maxSynchronousAlignments: 3,
      },
      () => {
        // Callback when scrolling has finished

        if (clearScrollOnEnd) {
          // Clear scroll parameters from the query

          const newQuery = omit(query, [
            "scrollTo",
            "scrollInstantly",
            "scrollAlign",
            "clearScrollOnEnd",
            "focusAfterScroll",
          ])
          navigate(
            {
              search: queryString.stringify(newQuery),
              hash: undefined,
            },
            { replace: true },
          )
        }
      },
    )
  }, [hash, navigate, pathname, search, tabletDown])
}
