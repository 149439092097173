import React, { KeyboardEventHandler } from "react"

import type { Notification as NotificationType } from "@graphql/codegen"
import {
  useNotificationMarkAsReadMutation,
  useNotificationsUnreadCountQuery,
} from "@graphql/codegen"

import Avatar from "@components/Avatar"
import { AnchorLink } from "@components/Link"

import cn from "@services/joinStyles"
import { timeAgoStrict } from "@services/time"

const Notification = ({
  index,
  notification,
  closeNotificationWidget,
}: {
  index: number
  notification: NotificationType
  closeNotificationWidget: () => void
}): JSX.Element => {
  const date = new Date(notification.updatedAt)
  const dateISOString = date.toISOString()

  const [markAsRead] = useNotificationMarkAsReadMutation({
    variables: {
      id: notification.id,
    },
  })

  const refreshUnreadCountQuery = useNotificationsUnreadCountQuery({
    variables: {
      source: notification.source,
    },
  })

  const markThisNotificationAsRead = () => {
    closeNotificationWidget()
    markAsRead().then(() => refreshUnreadCountQuery.refetch())
  }

  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") markThisNotificationAsRead()
  }

  return (
    <div
      role="menuitem"
      tabIndex={index}
      onClick={() => markThisNotificationAsRead()}
      onKeyUp={handleKeyUp}
    >
      <AnchorLink
        to={notification.link}
        className={cn(
          "HeaderDropdown__Notification",
          (refreshUnreadCountQuery.data?.notifications?.unreadCount !== 0 &&
            !notification.read &&
            "HeaderDropdown__Notification--Unread") ||
            "",
        )}
        data-cy="notifications:notification"
      >
        <div className="HeaderDropdown__Notification__Avatar">
          <Avatar
            src={notification?.pictureUrl ?? ""}
            alt="Notification avatar"
            size={40}
          />
        </div>
        <div className="HeaderDropdown__Content">
          <span className="HeaderDropdown__Message">
            {notification.message}
          </span>
          <div className="HeaderDropdown__Meta">
            <span className="HeaderDropdown__Time">
              {timeAgoStrict(dateISOString)}
            </span>
            {refreshUnreadCountQuery.data?.notifications?.unreadCount !== 0 &&
              !notification.read && (
                <div className="HeaderDropdown__UnreadDot" />
              )}
          </div>
        </div>
      </AnchorLink>
    </div>
  )
}

export default Notification
