export const defaultSnack = (variant, message, dataCy = "") => ({
  message,
  options: { variant, "data-cy": dataCy },
})

export const successSnack = (message, dataCy = "") =>
  defaultSnack("success", message, dataCy)

export const errorSnack = (message, dataCy = "") =>
  defaultSnack("error", message, dataCy)

export const warningSnack = (message, dataCy = "") =>
  defaultSnack("warning", message, dataCy)

export const profileUpdateSnack = successSnack(
  `Contact info updated successfully!`,
  "snack:profileUpdate",
)

export const generalErrorSnack = errorSnack(
  "Something went wrong, please try again later.",
)

export const mailingListSignup = successSnack(
  "Registered for Mailing List - thanks for joining!",
)

export const referralSnack = (email) =>
  successSnack(`Thank you for referring ${email}!`, "snack:referral")

export const formErrorSnack = errorSnack(
  "Please make sure all fields are completed and correct.",
)

export const signupSnack = successSnack(
  "Account created - thanks for joining us!",
  "snack:signup",
)

export const ratingSubmittedSnack = successSnack(
  "Thank you for rating!",
  "snack:ratingSubmitted",
)

export const ideaPromotionSnack = successSnack(
  "Your changes have been published and can now be viewed on your event listing!",
  "snack:ideaPromotion",
)

export const profileCompletedSnack = successSnack(
  "Thank you for completing your profile!",
  "snack:profileCompleted",
)

export const userCopyPasteSnack = successSnack(
  "User ID has been copied.",
  "snack:copyPaste",
)

export const virtualLinkCopyPasteSnack = successSnack(
  "The link to join your virtual event has been copied.",
  "snack:virtualLinkCopyPaste",
)

export const eventCopyPasteSnack = successSnack(
  "The event link has been copied.",
  "snack:copyPaste",
)

export const eventIdCopyPasteSnack = successSnack(
  "The event ID has been copied.",
  "snack:copyPaste",
)

export const imageCopyPasteSnack = successSnack(
  "The image link has been copied.",
  "snack:copyPaste",
)

export const savedCookiePreferences = successSnack(
  "Your cookie preferences have been saved.",
  "snack:cookiePreferences",
)

export const eventSnacks = {
  ratingSubmitted: successSnack(
    "Thank you for rating!",
    "snack:ratingSubmitted",
  ),
  attendeeCancellation: successSnack(
    "Your attendance has been cancelled!",
    "snack:cancellation",
  ),
  eventCancellation: successSnack(
    "This event has been cancelled!",
    "snack:eventCancelled",
  ),
  RSVP: successSnack(
    "RSVP Successful - thanks for participating!",
    "snack:RSVP",
  ),
  externalRSVP: successSnack(
    "RSVP Successful - thanks for participating! Please check your email for your ticket.",
    "snack:external-RSVP",
  ),
  waitlist: successSnack(
    "You've joined the waitlist. Keep an eye on your inbox!",
    "snack:waitlist-joined",
  ),
  guestDropped: warningSnack(
    "This event is very popular! Unfortunately, we were not able to reserve a spot for your guest",
    "snack:waitlist-guest-dropped",
  ),
  memberGuest: successSnack(
    "Your guest is already a Revel member!  We created a ticket for her.",
    "snack:waitlist-guest-member",
  ),
  waitlistUpgrade: successSnack(
    "You've claimed your spot - thanks for participating!",
    "snack:waitlist-upgrade",
  ),
  waitlistCancellation: successSnack(
    "You've left the waitlist!",
    "snack:waitlist-cancellation",
  ),
}

export const paymentSnacks = {
  error: (err) => errorSnack(err),
}

export const deleteUserSnacks = {
  success: (user) =>
    successSnack(
      `Success! ${user} will be deleted within 72 hours`,
      "snack:delete-user-success",
    ),
  error: (user) =>
    errorSnack(
      `There was a problem when trying to delete ${user}. Contact #helpdesk for help.`,
      "snack:delete-user-error",
    ),
}

export const changeEmailSnacks = {
  success: (user, newEmail) =>
    successSnack(
      `Success! ${user}'s email was updated to: ${newEmail}`,
      "snack:delete-user-success",
    ),
  error: (user) =>
    errorSnack(
      `There was a problem when trying to update ${user}'s email. Contact #helpdesk for help.`,
      "snack:delete-user-error",
    ),
}

export const freezeUserSnacks = {
  success: (user) =>
    successSnack(`Successfully updated ${user}.`, "snack:freeze-user-success"),
  error: (user) =>
    errorSnack(
      `There was a problem when trying to freeze/defrost ${user}. Contact #helpdesk for help.`,
      "snack:freeze-user-error",
    ),
}

export const featurePostSnacks = {
  success: () =>
    successSnack(
      `Successfully featured the post!`,
      "snack:feature-post-success",
    ),
  error: () =>
    errorSnack(
      `There was a problem featuring the post. Contact #helpdesk for help.`,
      "snack:feature-post-error",
    ),
}
