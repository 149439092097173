import has from "lodash/has"

import getExtras from "@graphql/getExtras"

const mergeConnectionResults = (existing, incoming, meta, options = {}) => {
  if (incoming && existing) {
    // There is incoming and existing data -- time to merge what we've got with what we're receiving.

    const { args, readField } = meta

    // Should we merge edges?
    const hasEdges = !!existing?.edges || !!incoming?.edges

    // Should we merge nodes?
    const hasNodes = !!existing?.nodes || !!incoming?.nodes

    // If requesting the first page, deduplicate and prepend incoming data
    const isFirstPage = incoming?.pageInfo?.hasPreviousPage === false
    if (isFirstPage) {
      // Merge pageInfo to allow pagination to continue
      const result = {
        pageInfo: {
          ...existing.pageInfo,
          startCursor: incoming.pageInfo.startCursor,
          hasPreviousPage: incoming.pageInfo.hasPreviousPage,
        },
      }

      const idLookup = {}
      if (hasEdges) {
        // Fill the lookup with ids from first page of results
        incoming.edges?.forEach((edge) => {
          idLookup[edge.node.id] = true
        })

        // Remove existing records that match incoming records
        const filteredExisting = (existing.edges ?? []).filter((edge) => {
          const nodeId = readField("id", edge.node)
          return !idLookup[nodeId]
        })
        result.nodes =
          incoming.edges?.concat(filteredExisting) ?? filteredExisting
      }

      if (hasNodes) {
        // Fill the lookup with ids from first page of results
        incoming.nodes.forEach((node) => {
          idLookup[node.id] = true
        })

        // Remove existing records that match incoming records
        const filteredExisting = (existing?.nodes ?? []).filter((node) => {
          const nodeId = readField("id", node)
          return !idLookup[nodeId]
        })
        result.nodes = incoming.nodes.concat(filteredExisting)
      }
    }

    // Should we merge pageInfo?
    const hasPageInfo = !!existing?.pageInfo || !!incoming?.pageInfo

    // Sort edges or nodes by sortField if defined
    const { sort, prependIncoming } = options
    const sortBySortField = (a, b) => {
      if (!sort || !sort.fieldName) return 0
      const { fieldName } = sort

      // pass-through if transform or value undefined
      const transform = (value) =>
        sort.transform && value ? sort.transform(value) : value
      const aField = transform(readField(fieldName, a.node ?? a))
      const bField = transform(readField(fieldName, b.node ?? b))

      // something is off, return 0 to leave order unchanged
      if (!aField || !bField) return 0

      // default to ASC but allow override
      if (sort.order === "DESC") {
        return aField < bField ? 1 : -1
      }
      return aField < bField ? -1 : 1
    }

    const first = prependIncoming ? incoming : existing
    const second = prependIncoming ? existing : incoming

    // Merge incoming and existing edges
    const mergedEdges = [...(first?.edges || []), ...(second?.edges || [])]
      .sort(sortBySortField)
      .reduce((accu, edge) => {
        // These are not 'normal' objects, need to use `readField` to retrieve data from Apollo's cache.
        const nodeId =
          // eslint-disable-next-line no-underscore-dangle --  used by apollo graphql
          readField("id", edge.node) ?? edge.node.__ref?.split(":")[1]

        if (!nodeId) {
          console.error(
            "revelPagination() => could not retrieve id of Edge. Make sure to include it in your query (edge { node { id } } )",
            edge,
            nodeId,
          )
        }

        // Using an object here to ensure uniqueness
        if (has(accu, nodeId)) {
          return accu
        }

        return { ...accu, [nodeId]: edge }
      }, {})

    // Merge incoming and existing nodes
    const mergedNodes = [...(first?.nodes || []), ...(second?.nodes || [])]
      .sort(sortBySortField)
      .reduce((accu, node) => {
        // These are not 'normal' objects, need to use `readField` to retrieve data from Apollo's cache.
        // eslint-disable-next-line no-underscore-dangle -- needed for first time reference of nodes
        const nodeId = readField("id", node) ?? node.__ref?.split(":")[1]

        if (!nodeId) {
          console.error(
            "revelPagination() => could not retrieve id of Node. Make sure to include it in your query (node { id } )",
          )
        }

        if (has(accu, nodeId)) {
          return accu
        }
        return { ...accu, [nodeId]: node }
      }, {})

    // Merge incoming and existing page info
    const mergedPageInfo = {
      ...(existing?.pageInfo || {}),
      ...(incoming?.pageInfo || {}),
    }

    return {
      args,

      // extras first so later fields can override
      ...getExtras(existing),
      ...getExtras(incoming),

      // Only splat if exists
      ...(hasEdges ? { edges: Object.values(mergedEdges) } : {}),
      ...(hasNodes ? { nodes: Object.values(mergedNodes) } : {}),
      ...(hasPageInfo ? { pageInfo: mergedPageInfo } : {}),
    }
  }
  if (incoming && !existing) {
    // There is no existing data, but there is incoming
    return incoming
  }

  // If all else fails, just return what we have
  return existing
}

export default mergeConnectionResults
